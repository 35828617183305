import { DirectSalesMediaType, DirectSalesMediaTypeLabel } from 'components/common/types/DirectSalesCampaign.types';
import { isValueRequired } from '../../utils/isValueRequired';
import { getNoRestrictionsDisplayValue } from '../../utils/getNoRestrictionsDisplayValue';

export const getSelectedMediaTypeDisplayValue = (
  selectedPermission?: string,
  selectedMediaType?: DirectSalesMediaType,
): string[] | undefined => {
  if (!selectedPermission) {
    return undefined;
  }

  if (!isValueRequired(selectedPermission)) {
    return getNoRestrictionsDisplayValue(selectedPermission);
  }

  if (isValueRequired(selectedPermission) && selectedMediaType) {
    return [DirectSalesMediaTypeLabel[selectedMediaType]];
  }

  return undefined;
};
