import { DirectSalesStatusOption } from 'components/common/types/DirectSalesCampaign.types';

export const STATUS_PROCESS_END_STATUSES = [
  DirectSalesStatusOption.OPTION,
  DirectSalesStatusOption.CONFIRMED,
  DirectSalesStatusOption.CANCELLED,
  DirectSalesStatusOption.PROPOSAL,
  DirectSalesStatusOption.DRAFT_PRICED,
  DirectSalesStatusOption.DRAFT_FAILED_PRICE,
  DirectSalesStatusOption.DRAFT_FAILED_SOLVE,
];
