import { CodeNameModel } from 'components/common/types';
import { MenuPlacement } from 'react-select';

export interface PrioritySelectProps {
  selectedValue: CodeNameModel | null;
  onChange: (value: CodeNameModel) => void;
  name?: string;
  menuPlacement?: MenuPlacement;
}

export const PRIORITY_OPTIONS = Array.from({ length: 21 }, (_, i) => ({
  code: i.toString(),
  name: i.toString(),
}));
