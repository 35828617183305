import { DirectSalesStatusOption } from './DirectSalesCampaign.types';

export interface CampaignPlanDetails {
  campaignId: string;
  campaignName: string;
  startDate: string;
  endDate: string;
  lastUpdatedAt: string;
  dealLines: {
    dealLineId: string;
    dealLineState: DirectSalesStatusOption;
    dealLineName: string;
  }[];
  totalCampaignImpressions: number;
  totalCampaignFrames: number;
  metaData: {
    isoCountryCode: string;
    timeZone: string;
  };
}

export const CampaignPlanErrorTitle = {
  UNAUTHORIZED: 'Unauthorized',
};

export interface CampaignPlanError {
  detail?: string;
  title?: string;
}

export class CampaignPlanErrorEvent extends Error implements CampaignPlanError {
  public detail?: string;

  public title?: string;

  constructor(params?: { message?: string; detail?: string; title?: string }) {
    super(params?.message);
    this.detail = params?.message || params?.detail;
    this.title = params?.title;
  }
}

export type CampaignPlanResponse = CampaignPlanDetails | CampaignPlanError;
