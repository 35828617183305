import { Action, HostEvent, LiveboardEmbed as LiveboardEmbedRef } from '@thoughtspot/visual-embed-sdk';
import { LiveboardEmbed, useEmbedRef } from '@thoughtspot/visual-embed-sdk/lib/src/react';
import { LiveboardProps } from './LiveboardVisuals.types';

const LiveboardVisuals: React.FC<LiveboardProps> = ({
  liveboardId,
  customizations,
  visibleActions = [],
  visualId,
  filterByOptions,
  updateParameters = [],
}) => {
  const embedRef = useEmbedRef() as React.MutableRefObject<LiveboardEmbedRef>;

  return (
    <LiveboardEmbed
      frameParams={{
        height: '580px',
      }}
      fullHeight
      customizations={customizations}
      className="liveboard-content"
      ref={embedRef}
      liveboardId={liveboardId}
      runtimeFilters={filterByOptions}
      onParameterChanged={() => {
        if (updateParameters.length > 0) {
          embedRef.current.trigger(HostEvent.UpdateParameters, updateParameters);
        }
      }}
      vizId={visualId}
      visibleActions={[
        Action.DownloadAsXlsx,
        Action.Download,
        Action.DownloadAsCsv,
        Action.CopyToClipboard,
        Action.ShowUnderlyingData,
        Action.Explore,
        Action.AxisMenuFilter,
        Action.CrossFilter,
        Action.DrillDown,
        ...visibleActions,
      ]}
    />
  );
};

export default LiveboardVisuals;
