import Auth from 'modules/Auth';
import { PermissionsEnum } from 'consts/permissions';
import labsSvg from 'assets/icons/labs.svg';
import settingsSvg from 'assets/icons/settings.svg';
import barchartSvg from 'assets/icons/bar_chart.svg';
import bullhornSvg from 'assets/icons/bullhorn.svg';
import creativesSvg from 'assets/icons/creatives.svg';
import clipboardSvg from 'assets/icons/clipboard.svg';
import userSvg from 'assets/icons/user.svg';

export const PLANNER_MENU_ITEM = {
  id: 0,
  icon: labsSvg,
  navigationPath: '',
  isVisible: () => true,
  isDisable: false,
  tooltip: 'Planner LABS',
};

export const INSIGHTS_MENU_ITEM = {
  id: 1,
  icon: barchartSvg,
  navigationPath: '/insights',
  isVisible: () => true,
  isDisable: false,
  tooltip: 'Insights',
};

export const DEALS_MENU_ITEM = {
  id: 2,
  icon: bullhornSvg,
  navigationPath: '/deals/programmatic',
  isVisible: () => Auth.hasPermission(PermissionsEnum.DEAL_MANAGEMENT_PAGE_ACCESS),
  isDisable: false,
  tooltip: 'Deals',
};

export const CONTENT_MENU_ITEM = {
  id: 3,
  icon: creativesSvg,
  navigationPath: '/content-management',
  isVisible: () => Auth.hasPermission(PermissionsEnum.CONTENT_PAGE_ACCESS),
  isDisable: false,
  tooltip: 'Content management',
};

export const ADMIN_MENU_ITEM = {
  id: 5,
  icon: clipboardSvg,
  navigationPath: '/administration',
  isVisible: () => Auth.hasPermission(PermissionsEnum.ADMINISTRATION_PAGE_ACCESS),
  isDisable: false,
  tooltip: 'Administration',
};

export const INVENTORY_MENU_ITEM = {
  id: 6,
  icon: settingsSvg,
  navigationPath: '/inventory-management',
  isVisible: () => Auth.hasPermission(PermissionsEnum.SETUP_PAGE_ACCESS),
  isDisable: false,
  tooltip: 'Inventory management',
};

export const USERS_MENU_ITEM = {
  id: 7,
  icon: userSvg,
  navigationPath: '/users',
  isVisible: () => Auth.hasPermission(PermissionsEnum.USERS_PAGE_ACCESS),
  isDisable: false,
  tooltip: 'Users',
};

export const MENU_ITEMS_CONFIG = [
  INSIGHTS_MENU_ITEM,
  DEALS_MENU_ITEM,
  CONTENT_MENU_ITEM,
  ADMIN_MENU_ITEM,
  INVENTORY_MENU_ITEM,
  USERS_MENU_ITEM,
];
