import React from 'react';
import cx from 'classnames';
import Button, { Color, Size } from 'lib/Button';
import allocationReportSVG from 'assets/icons/allocation-report.svg';
import { ReportTypeButtonsProps } from './ReportTypeButtons.types';

export const ReportTypeButtons: React.FC<ReportTypeButtonsProps> = ({ reportTypeButtons }) => {
  return (
    <div className="flex gap-2">
      {reportTypeButtons.map(
        ({ id, isActive, isOnClickEnabled, isVisible, label, onClick }) =>
          isVisible && (
            <React.Fragment key={id}>
              <Button
                onClick={isOnClickEnabled ? onClick : undefined}
                color={isActive ? Color.TERTIARY : Color.TRANSPARENT}
                size={Size.SMALL}
                label={label}
                classNames={cx('border', {
                  'border-primary-600': isActive,
                  'cursor-default': !isOnClickEnabled,
                })}
                ddActionName={`Allocation report / ${label} chip`}
                svg={allocationReportSVG}
              />
            </React.Fragment>
          ),
      )}
    </div>
  );
};
