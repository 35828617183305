import { formatCurrency, formatNumber } from 'modules/I18N';
import { ProductTargets, SelectionPermission } from 'store/productManagement/reducer.types';
import moneySvg from 'assets/icons/money.svg';
import framesSvg from 'assets/icons/frame.svg';
import eyeSvg from 'assets/icons/eye_empty.svg';
import { getNoRestrictionsDisplayValue } from '../../utils/getNoRestrictionsDisplayValue';
import { Target } from './ProductTargetsButton.types';

export const hasTargets = ({ budget, frames, impressions }: ProductTargets): boolean => {
  return Boolean(
    budget?.value ||
      budget?.selectionPermission ||
      frames?.value ||
      frames?.selectionPermission ||
      impressions?.value ||
      impressions?.selectionPermission,
  );
};

export const groupTargets = (
  { budget, frames, impressions }: ProductTargets,
  localeCode: string,
  currencyCode: string,
): Target[] => {
  return [
    {
      key: 'budget',
      label: 'Budget',
      value: budget?.value
        ? formatCurrency({ value: budget?.value, localeCode, currencyCode })
        : getNoRestrictionsDisplayValue(budget?.selectionPermission)[0],
      icon: moneySvg,
    },
    {
      key: 'frames',
      label: 'Frames',
      value: frames?.value
        ? formatNumber({ value: frames?.value, localeCode })
        : getNoRestrictionsDisplayValue(frames?.selectionPermission)[0],
      icon: framesSvg,
    },
    {
      key: 'impressions',
      label: 'Impressions',
      value: impressions?.value
        ? formatNumber({ value: impressions?.value, localeCode })
        : getNoRestrictionsDisplayValue(impressions?.selectionPermission)[0],
      icon: eyeSvg,
    },
  ];
};

export const isSaveDisabled = ({
  budget,
  frames,
  impressions,
  selectedBudgetPermission,
  selectedFramesPermission,
  selectedImpressionsPermission,
}: {
  budget?: number;
  frames?: number;
  impressions?: number;
  selectedBudgetPermission: SelectionPermission;
  selectedFramesPermission: SelectionPermission;
  selectedImpressionsPermission: SelectionPermission;
}): boolean => {
  const isBudgetFilled =
    selectedBudgetPermission === SelectionPermission.ALL_OPTIONS ||
    selectedBudgetPermission === SelectionPermission.FIXED_VALUE;

  const isFramesFilled =
    selectedFramesPermission === SelectionPermission.ALL_OPTIONS ||
    selectedFramesPermission === SelectionPermission.FIXED_VALUE;

  const isImpressionsFilled =
    selectedImpressionsPermission === SelectionPermission.ALL_OPTIONS ||
    selectedImpressionsPermission === SelectionPermission.FIXED_VALUE;

  const isFramesCorrect = selectedFramesPermission === SelectionPermission.FIXED_VALUE ? !!frames : true;
  const isBudgetCorrect = selectedBudgetPermission === SelectionPermission.FIXED_VALUE ? !!budget : true;
  const isImpressionsCorrect = selectedImpressionsPermission === SelectionPermission.FIXED_VALUE ? !!impressions : true;

  const areAllTargetsCorrect = isFramesCorrect && isBudgetCorrect && isImpressionsCorrect;
  return !((isBudgetFilled || isFramesFilled || isImpressionsFilled) && areAllTargetsCorrect);
};
