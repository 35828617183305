import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import xlsx from 'json-as-xlsx';
import { useNavigate } from 'react-router-dom';
import Auth from 'modules/Auth';
import { transformBookingStatusCode } from 'components/pages/DealWithLines/common/transformPostData';
import {
  combineProposalAllocationAndDealSummary,
  transformAvailability,
} from 'components/pages/DealWithLines/AdsDealLines/transformDealDetails';
import { notifySuccess, notifyInfo, clearNotification, notifyError } from 'store/notification/reducer';
import {
  getDealWithLineFormData,
  getDealData,
  getLineFormData,
} from 'components/pages/DealWithLines/AdsDealLines/transformPostData';
import { Store } from 'components/common/types/Store.types';
import {
  createDeal,
  checkAllocate,
  getAllocatedDeal,
  getDealProcessingStatus,
  approveDeal,
  rejectDeal,
  cancelDeal,
  reserveDeal,
  editDeal,
  updateLine,
  stopLine,
} from 'modules/api/adsDeal';
import { getDealDetails } from 'store/dealManagement/actions';
import { useHasFeatureAccess } from 'customHooks/useHasFeatureAccess';
import { FeatureFlags } from 'components/common/types/Features.types';
import {
  LineAllocationData,
  Allocation,
  DealProcessingStatuses,
  DealStatus,
  Availability,
  PreservedAvailability,
  FrontEndType,
  Line,
} from 'components/common/types/Deal.types';
import { NOTIFICATION_TIMEOUT } from 'consts/notifications';
import { PermissionsEnum } from 'consts/permissions';
import {
  changeDealCurrentLineData,
  changeDealData,
  changeDealLineAllocatedImpressions,
  changeFormParams,
  clearDisclaimer,
  hideDisclaimer,
  showDisclaimer,
} from 'store/dealManagement/reducer';
import { getMtbExport } from 'modules/api/mtbExport';
import { getIsAdServer } from 'store/publisher/selectors';
import { useCheckProgrammaticLineLevelChange } from 'components/pages/Planner/hooks/useCheckProgrammaticLineLevelChange';
import { useCheckProgrammaticDealLevelChange } from 'components/pages/Planner/hooks/useCheckProgrammaticDealLevelChange';
import { trackEventToPendo } from 'modules/Pendo';
import { CampaignBookingStatusOptionLabel } from 'components/common/Deal/CampaignHeader/CampaignDrawer/Overview/CampaignBookingStatus/utils';
import { getIsAnyLineFetchingAvailability } from 'store/dealManagement/selectors';
import { useCheckProgrammaticMandatoryFields } from './useCheckProgrammaticMandatoryFields';
import { BookingCode } from './usePlannerActions.types';
import useCommonPlannerActions from './useCommonPlannerActions';
import useCampaignType from './useCampaignType';

interface UpdateDealHandlerCompleteParams {
  allocation?: Allocation;
  lineId?: string;
  dealId?: string;
}

interface UseProgrammaticPlannerActions {
  dealStatusComplete: (result: Allocation) => Promise<void>;
  checkAllocateHandler: () => Promise<void>;
  updateDealDetails: (campaignId: string) => Promise<void>;
  getMtbExportFile: VoidFunction;
  saveDeal: (bookingCode: BookingCode) => void;
  isSavingDisabled: boolean;
  isReadOnly: boolean;
  areButtonsHidden: boolean;
  statusConditions: {
    showConfirm: boolean;
    showSendForApproval: boolean;
    showReject: boolean;
    showSave: boolean;
    showCancel: boolean;
    showTerminate: boolean;
    showMtbExport: boolean;
  };
}

const useProgrammaticPlannerActions = (): UseProgrammaticPlannerActions => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const commonDeal = useSelector((state: Store) => state.dealManagement.commonDeal);
  const isActionsDisabled = useSelector((state: Store) => state.dealManagement.isActionsDisabled);
  const isNewDeal = useSelector((state: Store) => state.dealManagement.isNewDeal);
  const { isMtbCampaignType } = useCampaignType();
  const isFetchingAvailability = useSelector(getIsAnyLineFetchingAvailability);
  const programmatic = useSelector((state: Store) => state.dealManagement.programmatic);
  const isCpmCampaignLevel = useSelector((state: Store) => state.dealManagement.isCpmCampaignLevel);
  const temporaryDealId = useSelector((state: Store) => state.dealManagement.temporaryDealId);
  const lines = useSelector((state: Store) => state.dealManagement.backupFormData.lines);
  const dealType = useSelector((state: Store) => state.dealManagement.commonDeal.dealType);
  const bookingStatusCode = useSelector((state: Store) => state.dealManagement.commonDeal.bookingStatusCode);
  const dealId = useSelector((state: Store) => state.dealManagement.commonDeal.dealId);
  const backupDealSummary = useSelector((state: Store) => state.dealManagement.backupFormData.commonDeal.summary);
  const summary = useSelector((state: Store) => state.dealManagement.commonDeal.summary);
  const currentLine = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine);
  const cpm = useSelector((state: Store) => state.dealManagement.commonDeal.cpm);
  const lineId = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.id);
  const assets = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.availability.assets);
  const dealConfig = useSelector((state: Store) => state.dealConfig);

  const { cancelFunctions, handleObjectives } = useCommonPlannerActions();
  const isAdServerMarket = useSelector(getIsAdServer);

  const { areAllMandatoryFieldsFilled } = useCheckProgrammaticMandatoryFields();
  const isDealLevelChange = useCheckProgrammaticDealLevelChange();
  const isLineLevelChange = useCheckProgrammaticLineLevelChange();

  const isProperPendingApproval = bookingStatusCode === DealStatus.PENDING_APPROVAL;
  const isProperApproved = bookingStatusCode === DealStatus.APPROVED;
  const isProperRejected = bookingStatusCode === DealStatus.REJECTED;
  const isProperLive = bookingStatusCode === DealStatus.LIVE;

  const hasPermissionDealConfirm = Auth.hasPermission(PermissionsEnum.DEAL_CONFIRM);
  const hasPermissionDealReject = Auth.hasPermission(PermissionsEnum.DEAL_REJECT);

  const showMtbExport = useHasFeatureAccess(FeatureFlags.MTB_EXPORT) && isNewDeal && isMtbCampaignType;
  const showConfirm = !showMtbExport && hasPermissionDealConfirm && (isNewDeal || isProperPendingApproval);
  const showSendForApproval =
    !showMtbExport && Auth.hasPermission(PermissionsEnum.DEAL_PROPOSE) && (isNewDeal || isProperRejected);
  const showSave =
    !showMtbExport &&
    hasPermissionDealConfirm &&
    (isDealLevelChange || isLineLevelChange) &&
    (isProperPendingApproval || isProperApproved || isProperRejected || isProperLive);
  const showReject = !showMtbExport && hasPermissionDealReject && bookingStatusCode === DealStatus.PENDING_APPROVAL;
  const showCancel = !showMtbExport && hasPermissionDealReject && bookingStatusCode === DealStatus.APPROVED;
  const showTerminate = !showMtbExport && hasPermissionDealReject && bookingStatusCode === DealStatus.LIVE;

  const isSavingDisabled =
    isActionsDisabled || isFetchingAvailability || !areAllMandatoryFieldsFilled || !assets?.length;
  const isReadOnly = [DealStatus.CANCELLED, DealStatus.TERMINATED, DealStatus.ENDED].includes(bookingStatusCode);
  const areButtonsHidden =
    !showCancel && !showTerminate && !showReject && !showConfirm && !showMtbExport && !showSave && !showSendForApproval;
  const hasAdsDealLevelCPMEnabled = useHasFeatureAccess(FeatureFlags.ADS_DEAL_LEVEL_CPM);
  const hasNonGuaranteedExtendedTargetEnabled = useHasFeatureAccess(FeatureFlags.NON_GUARANTEED_EXTENDED_TARGET);

  const updateDealDetails = async (campaignID: string): Promise<void> => {
    if (dealId) {
      dispatch(
        getDealDetails(
          dealId,
          cancelFunctions,
          navigate,
          dealConfig,
          hasAdsDealLevelCPMEnabled,
          hasNonGuaranteedExtendedTargetEnabled,
          isAdServerMarket,
        ),
      );
    } else if (campaignID) {
      navigate(`/planner/programmatic/${campaignID}`, { replace: true });
    } else {
      navigate('/deals/programmatic', { replace: true });
    }
  };

  const dealStatusComplete = async (result: Allocation): Promise<void> => {
    dispatch(notifySuccess({ message: `Deal saved Successfully - ${result.campaignID ?? dealId}` }));
    dispatch(changeFormParams({ isForecastedAllocation: false }));

    await updateDealDetails(result.campaignID);
  };

  const lineStatusComplete = async (lineLevelId: string, dealLevelId: string): Promise<void> => {
    if (!lineLevelId || !dealLevelId) return;

    dispatch(notifySuccess({ message: `Line changes saved Successfully - ${lineLevelId}` }));
    dispatch(changeFormParams({ isForecastedAllocation: false }));
    dispatch(changeFormParams({ isEditingDisabled: false }));

    await updateDealDetails(dealLevelId);
  };

  const dealAndLineStatusComplete = async (
    allocation: Allocation,
    lineLevelId: string,
    dealLevelId: string,
  ): Promise<void> => {
    dispatch(
      notifySuccess({ message: `Deal ${allocation.campaignID ?? dealId} and line ${lineLevelId} saved Successfully` }),
    );
    dispatch(changeFormParams({ isForecastedAllocation: false }));
    dispatch(changeFormParams({ isEditingDisabled: false }));

    await updateDealDetails(dealLevelId);
  };

  const handleStatusComplete = async (payload: UpdateDealHandlerCompleteParams): Promise<void> => {
    if (isDealLevelChange && isLineLevelChange) {
      await dealAndLineStatusComplete(payload.allocation!, payload.lineId || lineId, payload.dealId || dealId);
    } else if (isDealLevelChange) {
      await dealStatusComplete(payload.allocation!);
    } else if (isLineLevelChange) {
      await lineStatusComplete(payload.lineId || lineId, payload.dealId || dealId);
    }
  };

  const handleErrorMessages = (errors: { lineId: string; message: string }[]): void => {
    const currentLineError = errors.find((error) => error.lineId === lineId) || errors[0];
    const lineName = lines.find(({ id }) => id === currentLineError.lineId)?.name || currentLine.name;

    if (currentLineError) {
      dispatch(
        notifyError({
          message: `${lineName}: ${currentLineError.message}`,
          timeout: NOTIFICATION_TIMEOUT.LONG,
        }),
      );
    }
  };

  const handlePollStatus = async (
    result: Allocation,
    pollCompleteHandler: (result: Allocation) => Promise<void>,
    isRequestAfterPoll = false,
  ): Promise<void> => {
    const poll = getDealProcessingStatus(result.campaignID || dealId, cancelFunctions);

    poll(
      ({
        data: { processingStatus, messages },
      }: {
        data: {
          processingStatus: string;
          campaignID: string;
          messages?: { lineId: string; message: string }[];
        };
      }) => {
        if (processingStatus === DealProcessingStatuses.ERROR) {
          if (messages) {
            handleErrorMessages(messages);
          }

          dispatch(changeFormParams({ isEditingDisabled: false }));

          return false;
        }

        if (processingStatus === DealProcessingStatuses.STILL_PROCESSING) {
          return true;
        }

        if (!isRequestAfterPoll) dispatch(changeFormParams({ isEditingDisabled: false }));

        pollCompleteHandler(result);
        return false;
      },
      1000,
    );
  };

  const handleDealRequest = async (
    requestHandler: () => Promise<Allocation>,
    pollCompleteHandler: (result: Allocation) => Promise<void>,
    catchHandler = () => undefined,
  ): Promise<void> => {
    dispatch(changeFormParams({ isEditingDisabled: true }));
    dispatch(notifyInfo({ message: 'We are validating your solution', timeout: NOTIFICATION_TIMEOUT.DEFAULT }));

    try {
      const result = await requestHandler();

      await handlePollStatus(result, pollCompleteHandler);
    } catch {
      catchHandler();
      dispatch(changeFormParams({ isEditingDisabled: false }));
    }
  };

  const removePoiPointsFromLine = (line: Line): Line => ({
    ...line,
    proximity: {
      ...commonDeal.currentLine.proximity,
      plannerPoi: commonDeal.currentLine.proximity.plannerPoi.map((poi) => ({ ...poi, poiPoints: [] })),
      openStreetMapPoi: commonDeal.currentLine.proximity.openStreetMapPoi.map((poi) => ({
        ...poi,
        poiPoints: [],
      })),
    },
  });

  const updateDealHandler = useCallback(
    async (
      onCompleteHandler?: (params: UpdateDealHandlerCompleteParams) => Promise<void>,
      catchHandler = () => undefined,
    ): Promise<void> => {
      const dealData = {
        ...getDealData({ ...commonDeal, ...programmatic, hasAdsDealLevelCPMEnabled }),
        bookingStatusCode: undefined,
      };

      const lineData = getLineFormData(
        {
          ...commonDeal,
          ...programmatic,
          currentLine: { ...removePoiPointsFromLine(commonDeal.currentLine), cpm: undefined },
        },
        { isAdServerMarket },
        isCpmCampaignLevel,
      );

      dispatch(changeFormParams({ isEditingDisabled: true }));
      dispatch(notifyInfo({ message: 'We are validating your solution', timeout: NOTIFICATION_TIMEOUT.DEFAULT }));

      try {
        if (isDealLevelChange) {
          const editDealResponse: Allocation = await editDeal(cancelFunctions, dealId, dealData);

          await handlePollStatus(
            editDealResponse,
            async (dealLevelAllocation) => {
              try {
                if (isLineLevelChange) {
                  await updateLine(cancelFunctions, lineData, {
                    dealId: commonDeal.dealId,
                    lineId,
                  });
                  await handlePollStatus(editDealResponse, async (lineLevelAllocation) =>
                    onCompleteHandler?.({
                      allocation: lineLevelAllocation,
                      dealId: commonDeal.dealId,
                      lineId,
                    }),
                  );

                  return;
                }

                onCompleteHandler?.({
                  allocation: dealLevelAllocation,
                });
              } catch {
                catchHandler();
                dispatch(changeFormParams({ isEditingDisabled: false }));
              }
            },
            isLineLevelChange,
          );

          return;
        }

        if (!isDealLevelChange && isLineLevelChange) {
          const result = await updateLine(cancelFunctions, lineData, { dealId: commonDeal.dealId, lineId });
          await handlePollStatus(
            result,
            async (allocation) =>
              onCompleteHandler?.({
                allocation,
                lineId,
                dealId: commonDeal.dealId,
              }),
            isLineLevelChange,
          );

          return;
        }
      } catch {
        catchHandler();
        dispatch(changeFormParams({ isEditingDisabled: false }));
      }
    },
    [commonDeal, programmatic, isLineLevelChange, isDealLevelChange],
  );

  const updateDeal = async (catchHandler: Function): Promise<void> => {
    await updateDealHandler(handleStatusComplete, catchHandler);
  };

  const checkAllocatePollComplete = async ({ campaignID }: Allocation): Promise<void> => {
    try {
      const currentLineAllocationProposal: LineAllocationData = await getAllocatedDeal(
        campaignID,
        cancelFunctions,
        dealType,
        lines,
      );

      handleObjectives(currentLineAllocationProposal);
      dispatch(showDisclaimer());

      dispatch(
        changeDealLineAllocatedImpressions(
          transformAvailability(currentLineAllocationProposal) as Availability & PreservedAvailability,
        ),
      );
      dispatch(
        changeDealData({
          summary: combineProposalAllocationAndDealSummary(
            isNewDeal,
            !currentLine.lineId,
            currentLine.preservedAllocation,
            currentLineAllocationProposal,
            summary,
            backupDealSummary,
            dealType,
            hasNonGuaranteedExtendedTargetEnabled,
          ),
        }),
      );
      dispatch(changeFormParams({ temporaryDealId: campaignID }));
      dispatch(
        changeDealCurrentLineData({
          deliveredImpressions: currentLineAllocationProposal.deliveredImpressions,
          isCurrentLineWithProposalAllocation: true,
        }),
      );
      dispatch(changeFormParams({ isForecastedAllocation: true }));
    } catch {
      dispatch(clearNotification());
    }

    dispatch(changeFormParams({ isEditingDisabled: false }));
  };

  const checkAllocateHandler = async (): Promise<void> => {
    dispatch(hideDisclaimer());
    dispatch(clearDisclaimer());

    const formData = getDealWithLineFormData(
      {
        ...commonDeal,
        ...programmatic,
        cpm: isCpmCampaignLevel ? cpm : null,
        bookingStatusCode: DealStatus.PROPOSAL,
        hasAdsDealLevelCPMEnabled,
      },
      { isAdServerMarket },
    );

    await handleDealRequest(() => checkAllocate(cancelFunctions, formData), checkAllocatePollComplete);
  };

  const submitNewDeal = async (bookingCode: BookingCode): Promise<void> => {
    const formData = getDealWithLineFormData(
      {
        ...commonDeal,
        ...programmatic,
        bookingStatusCode: transformBookingStatusCode(bookingCode ?? ''),
        hasAdsDealLevelCPMEnabled,
        currentLine: removePoiPointsFromLine(commonDeal.currentLine),
      },
      { isAdServerMarket },
    );

    await handleDealRequest(() => createDeal(cancelFunctions, formData, FrontEndType.PLANNER), dealStatusComplete);
  };

  const approveDealHandler = async (onCompleteHandler: (result: Allocation) => Promise<void>): Promise<void> => {
    await handleDealRequest(() => approveDeal(cancelFunctions, { dealId }), onCompleteHandler);
  };

  const rejectDealHandler = async (): Promise<void> => {
    await handleDealRequest(() => rejectDeal(cancelFunctions, { dealId }), dealStatusComplete);
  };

  const terminateLineHandler = async (): Promise<void> => {
    await handleDealRequest(() => stopLine(cancelFunctions, { dealId, lineId }), dealStatusComplete);
  };

  const cancelDealHandler = async (): Promise<void> => {
    await handleDealRequest(() => cancelDeal(cancelFunctions, { dealId }), dealStatusComplete);
  };

  const reserveDealHandler = (): Promise<void> =>
    handleDealRequest(() => reserveDeal(cancelFunctions, { dealId }), dealStatusComplete);

  const updateAndApproveDeal = async (catchHandler?: Function): Promise<void> => {
    if (!isDealLevelChange && !isLineLevelChange) {
      await approveDealHandler(dealStatusComplete);
      return;
    }

    await updateDealHandler(async (editDealResult) => {
      await approveDealHandler(async (allocation: Allocation) => {
        await handleStatusComplete({ ...editDealResult, allocation });
      });
    }, catchHandler);
  };

  const saveDeal = useCallback(
    (bookingCode: BookingCode, catchHandler = () => undefined) => {
      dispatch(hideDisclaimer());

      if (!dealId) {
        submitNewDeal(bookingCode);
        if (bookingCode === DealStatus.PENDING_APPROVAL || bookingCode === DealStatus.APPROVED) {
          trackEventToPendo(`/planner/programmatic/* | ${CampaignBookingStatusOptionLabel[bookingCode]}`);
        }

        return;
      }

      switch (bookingCode) {
        case DealStatus.PENDING_APPROVAL:
        case DealStatus.APPROVED:
          updateAndApproveDeal(catchHandler);
          trackEventToPendo(`/planner/programmatic/* | ${CampaignBookingStatusOptionLabel[bookingCode]}`);
          break;
        case DealStatus.REJECTED:
          rejectDealHandler();
          break;
        case DealStatus.TERMINATED:
          terminateLineHandler();
          break;
        case DealStatus.CANCELLED:
          cancelDealHandler();
          break;
        case DealStatus.RESERVED:
          reserveDealHandler();
          break;
        default:
          updateDeal(catchHandler);
      }
    },
    [updateDealHandler],
  );

  const getMtbExportFile = useCallback(async (): Promise<void> => {
    try {
      const data = await getMtbExport(temporaryDealId, cancelFunctions);

      if (data?.records) {
        xlsx(
          [
            {
              sheet: 'MTB Export Data',
              columns: [
                { label: 'Frame ID', value: 'frameId' },
                { label: 'Date', value: 'date' },
                { label: 'Time', value: 'time' },
                { label: 'SOT', value: 'sot' },
              ],
              content: data.records.map(({ frameId, date, time, sot }) => ({
                frameId: frameId || null,
                date: date?.join() || null,
                time: time?.join() || null,
                sot: sot?.toString() || null,
              })),
            },
          ],
          {
            fileName: `mtb-export-proposal-${temporaryDealId}`,
            extraLength: 3,
            writeOptions: {},
          },
        );
      }
    } catch {} // eslint-disable-line no-empty
  }, [temporaryDealId]);

  return {
    dealStatusComplete,
    updateDealDetails,
    areButtonsHidden,
    isSavingDisabled,
    isReadOnly,
    statusConditions: {
      showConfirm,
      showSendForApproval,
      showSave,
      showReject,
      showCancel,
      showTerminate,
      showMtbExport,
    },
    checkAllocateHandler,
    getMtbExportFile,
    saveDeal,
  };
};

export default useProgrammaticPlannerActions;
