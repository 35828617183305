import { DirectSalesLineObjective } from 'components/common/types/DirectSalesCampaign.types';

export const isOneTargetSelected = (objective?: DirectSalesLineObjective): boolean => {
  return (
    !!objective &&
    ((!!objective.budget.value && objective.budget.value > 0) ||
      (!!objective.frames.value && objective.frames.value > 0) ||
      (!!objective.impressions.value && objective.impressions.value > 0))
  );
};

export const isOneFilterSelected = (objective?: DirectSalesLineObjective): boolean => {
  return (
    !!objective &&
    (objective.locations.length > 0 ||
      objective.formats.length > 0 ||
      objective.tags.length > 0 ||
      objective.frameList.listFiles.length > 0 ||
      objective.frameList.routeFrameCodes.length > 0 ||
      objective.poi.length > 0)
  );
};
