import classNames from 'classnames/bind';

import SidebarMenu from 'components/common/SidebarMenu';
import Notifications from 'components/common/Notifications';
import { backgroundColorStyle, Banner, BannerType, borderColorStyle } from 'lib/Banner';

const cx = classNames;

type Props = {
  isRequiredMarketData: boolean;
  bannerType: BannerType;
  isAuthenticatedView: boolean;
  children: React.ReactNode;
};

const AppPageWrapper: React.FC<Props> = ({ children, isRequiredMarketData, bannerType, isAuthenticatedView }) => {
  return (
    <>
      {isAuthenticatedView ? (
        <div className={cx('body-base font-inter bg-primary-950')}>
          <SidebarMenu isRequiredMarketData={isRequiredMarketData} />

          <div
            className={cx(
              'border-6 rounded h-screen overflow-hidden ml-15 flex flex-col',
              bannerType === BannerType.NONE
                ? 'border-primary-950'
                : `${borderColorStyle[bannerType]} ${backgroundColorStyle[bannerType]}`,
            )}
          >
            <div className="flex flex-col h-full">
              <Banner />
              <div className="flex-grow rounded bg-neutral-100 overflow-hidden">{children}</div>
            </div>
          </div>
          <Notifications />
        </div>
      ) : (
        <div className="p-6">{children}</div>
      )}
    </>
  );
};

export default AppPageWrapper;
