import { PermissionsEnum } from 'consts/permissions';
import Auth from 'modules/Auth';

export const SECONDS_IN_MINUTE = 60;
export const MINUTES_IN_HOUR = 60;
export const SECONDS_IN_HOUR = 3600;
export const ONE_DAY_IN_MS = 24 * MINUTES_IN_HOUR * SECONDS_IN_MINUTE * 1000;
export const ONE_DAY_IN_MINUTES = 24 * MINUTES_IN_HOUR;

export const formatISODateToDateObj = (isoDateString, isUTC = true) => {
  if (!isUTC) {
    return new Date(isoDateString);
  }

  const isoDate = new Date(isoDateString);
  const timezoneOffset = isoDate.getTimezoneOffset() * 60000;

  return new Date(isoDate.getTime() + timezoneOffset);
};

export const formatDateObjToIsoDateString = (dateObj, isUTC = true) => {
  const newDate = new Date(dateObj);
  const timezoneOffset = newDate.getTimezoneOffset() * 60000;
  const isoDateString = new Date(newDate.getTime() - timezoneOffset).toISOString();

  if (!isUTC) {
    return isoDateString.replace('Z', '');
  }

  return isoDateString;
};

export const getISOStringWithoutSecAndMillisec = (date) => {
  return formatDateObjToIsoDateString(date).replace(/:[^:]*$/, '');
};

export const getISOStringWithoutTime = (date) => {
  return formatDateObjToIsoDateString(date).replace(/[Tt]([^Zz]*)[Zz]$/, '');
};

export const getPastDate = (date, days) => new Date(date.getTime() - ONE_DAY_IN_MS * (days - 1));

export const getDaylightSavingOffset = (startDate, endDate) =>
  (endDate.getTimezoneOffset() - startDate.getTimezoneOffset()) / ONE_DAY_IN_MINUTES;

export const getDaysDiff = (startDate, endDate) => {
  const daysDiff = (endDate - startDate) / ONE_DAY_IN_MS;
  const daylightSavingOffset = getDaylightSavingOffset(startDate, endDate);
  return daysDiff - daylightSavingOffset;
};

export const getCurrentDateWithTimezone = (timezone) => {
  const timeZoneToUse = timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;

  try {
    // eslint-disable-next-line no-new
    new Intl.DateTimeFormat('en-US', { timeZone: timeZoneToUse });
  } catch (error) {
    return new Date();
  }

  return new Date(new Date().toLocaleString('en-US', { timeZone: timeZoneToUse }));
};

export const getWeekdayNameFromDate = (date, timezone, isShort) => {
  return date.toLocaleDateString('en-US', { weekday: isShort ? 'short' : 'long', timeZone: timezone });
};

export const getMonth = (date) => date.toLocaleString('en-US', { month: 'long' });

export const getHoursAndMinutesFromDate = (date, timezone) => {
  return date.toLocaleTimeString([], { hourCycle: 'h23', hour: '2-digit', minute: '2-digit', timeZone: timezone });
};

export const getNoonTimeFromDate = (date) => {
  const newDate = new Date(date);

  const timezoneOffset = newDate.getTimezoneOffset() * 60000;
  const formattedDate = new Date(newDate.getTime() - timezoneOffset);

  return formattedDate.toLocaleTimeString('en-US', {
    hour12: true,
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'UTC',
  });
};

export const convertHoursAndMinutesToSeconds = (hours, minutes) => hours * 3600 + minutes * 60;

export const parseDateMonthDayYear = (date) => `${getMonth(date)} ${date.getDate()}, ${date.getFullYear()}`;

export const formatDateToMatchVCRequestPayload = (date) => {
  const [formattedDate] = formatDateObjToIsoDateString(
    date,
    Auth.hasPermission(PermissionsEnum.FEATURE_FLAG_TIMEZONE_UTC),
  ).split('.');

  return formattedDate;
};

export const secondsToHHmm = (seconds) => {
  if (!Number.isInteger(seconds)) return false;

  return new Date((seconds % 86400) * 1000).toISOString().substring(11, 16);
};

export const formatDDMMYYYYDate = (date, localeCode) => {
  if (!date || !localeCode) return '';

  const formattedDate = new Date(date);

  return formattedDate.toLocaleDateString(localeCode, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
};
