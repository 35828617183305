import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from 'components/common/types/Store.types';
import PlannerSecondaryPanel from 'components/pages/Planner/PlannerSecondaryPanel';
import DateRangePicker from 'lib/DateRangePicker';
import { Label } from 'lib/Label';
import { hideSecondaryPanel } from 'store/productManagement/reducer';
import { isValueRequired } from 'components/pages/PCM/Products/utils/isValueRequired';
import { CodeNameModel } from 'components/common/types';
import SectionDivider from 'components/pages/Planner/SectionDivider';
import SelectionPermissionDropdown from '../SelectionPermissionDropdown/SelectionPermissionDropdown';
import { rangeSpecificPermissionOptions } from '../../consts';
import { isProductScheduleRangeConfirmDisabled } from './utils';
import { ProductScheduleRangeProps } from './ProductScheduleRange.types';
import { secondaryPanelLayoutStyles } from '../../styles';

const ProductScheduleRangePanel: React.FC<ProductScheduleRangeProps> = ({ onConfirm, isReadOnly }) => {
  const dispatch = useDispatch();
  const defaultScheduleRange = useSelector((state: Store) => state.productManagement.newProduct.range);
  const defaultSelectedPermission =
    rangeSpecificPermissionOptions.find(({ code }) => code === defaultScheduleRange?.selectionPermission) ??
    rangeSpecificPermissionOptions[0];

  const [selectedPermission, setSelectedPermission] = useState<CodeNameModel>(defaultSelectedPermission);
  const [from, setFrom] = useState<Date>(defaultScheduleRange?.value?.startDate ?? new Date());
  const [to, setTo] = useState<Date>(defaultScheduleRange?.value?.endDate ?? new Date());

  return (
    <PlannerSecondaryPanel
      title="Range"
      onSubmit={() => onConfirm({ startDate: from, endDate: to, selectedPermission })}
      onCancel={() => dispatch(hideSecondaryPanel())}
      isDisabled={isProductScheduleRangeConfirmDisabled({
        selectedPermission,
        startDate: from,
        endDate: to,
        previouslySelectedScheduleRangeValues: {
          startDate: defaultScheduleRange?.value?.startDate,
          endDate: defaultScheduleRange?.value?.endDate,
        },
        previouslySelectedPermission: defaultScheduleRange?.selectionPermission,
      })}
      customStyles={secondaryPanelLayoutStyles}
    >
      <SelectionPermissionDropdown
        selectedPermission={selectedPermission}
        setSelectedPermission={setSelectedPermission}
        isReadOnly={isReadOnly}
        permissionOptions={rangeSpecificPermissionOptions}
      />
      {isValueRequired(selectedPermission.code) ? (
        <>
          <div className="my-4">
            <SectionDivider />
          </div>

          <div className="my-4">
            <Label label="Select range" />
          </div>

          <DateRangePicker from={from} to={to} setFrom={setFrom} setTo={setTo} />
        </>
      ) : null}
    </PlannerSecondaryPanel>
  );
};

export default ProductScheduleRangePanel;
