import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { createDirectSalesProductTemplate } from 'modules/api/directSalesProductTemplate';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import { notifySuccess } from 'store/notification/reducer';
import { setToastMessages } from 'store/productManagement/reducer';
import { Store } from 'components/common/types/Store.types';
import { DirectSalesProductTemplateStatus } from 'components/common/types/DirectSalesProductTemplate.types';
import { transformDirectSalesProductToRules } from '../Products/utils/transformDirectSalesProductToRules';

export const useCreateProductTemplate = (): (() => Promise<void>) => {
  const cancelFunctions = useCancelRequest();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const marketId = useSelector((state: Store) => state.publisher.configuration.marketId);
  const product = useSelector((state: Store) => state.productManagement.newProduct);
  const mediaType = useSelector((state: Store) => state.productManagement.newProduct.mediaType);
  const channels = useSelector((state: Store) => state.productManagement.newProduct.channels);
  const pricingMode = useSelector((state: Store) => state.productManagement.newProduct.pricingMode);
  const range = useSelector((state: Store) => state.productManagement.newProduct.range);
  const budget = useSelector((state: Store) => state.productManagement.newProduct?.budget);
  const frames = useSelector((state: Store) => state.productManagement.newProduct?.frames);
  const impressions = useSelector((state: Store) => state.productManagement.newProduct?.impressions);
  const formats = useSelector((state: Store) => state.productManagement.newProduct?.formats);
  const locations = useSelector((state: Store) => state.productManagement.newProduct?.locations);
  const networks = useSelector((state: Store) => state.productManagement.newProduct?.networks);

  let timer: NodeJS.Timeout | null = null;

  useEffect(() => {
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, []);

  return async (): Promise<void> => {
    const productTemplate = {
      mediaType,
      channels,
      pricingMode,
      range,
      budget,
      frames,
      impressions,
      formats,
      locations,
      networks,
    };
    const rules = transformDirectSalesProductToRules(productTemplate);

    const productData = {
      name: product.name,
      folder: product.folder,
      status: DirectSalesProductTemplateStatus.ACTIVE,
      rules,
    };

    if (!product.name) {
      dispatch(
        setToastMessages([
          {
            id: '1',
            header: 'Missing product name',
            description: 'Please input product name to save product',
          },
        ]),
      );

      return;
    }

    if (!product.folder) {
      dispatch(
        setToastMessages([
          {
            id: '2',
            header: 'Missing product folder',
            description: 'Please choose or create new folder to save product to',
          },
        ]),
      );

      return;
    }

    if (
      isEmpty(mediaType) ||
      isEmpty(channels) ||
      isEmpty(pricingMode) ||
      isEmpty(range) ||
      (isEmpty(budget) && isEmpty(impressions) && isEmpty(frames))
    ) {
      dispatch(
        setToastMessages([
          {
            id: '3',
            header: 'Missing fields',
            description: 'Please input all required fields',
          },
        ]),
      );

      return;
    }

    try {
      const savedProduct = await createDirectSalesProductTemplate(productData, marketId, cancelFunctions);

      if (savedProduct?.id) {
        dispatch(notifySuccess({ message: 'Successfully created product' }));
        dispatch(setToastMessages([]));

        timer = setTimeout(() => {
          navigate(`/deals/product-management`);
        }, 5000);

        return;
      }
    } catch {
      dispatch(
        setToastMessages([
          {
            id: '4',
            header: 'Unable to create product',
            description: 'Try again',
          },
        ]),
      );
    }
  };
};
