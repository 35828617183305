export enum FormatTypeEnum {
  CURRENCY = 'currency',
  NUMBER = 'number',
  ROUNDED_NUMBER = 'roundedNumber',
  PERCENT = 'percent',
  DECIMAL_PERCENT = 'decimalPercent',
}

type DateStyle = 'short' | 'full' | 'long' | 'medium' | undefined;

const DEFAULT_PUBLISHER = {
  localeCode: 'en-gb',
  currencyCode: 'GBP',
};

export const getLocaleObject = (): { localeCode: string; currencyCode: string } => {
  return DEFAULT_PUBLISHER;
};

export const getFormattedDateTime = (date: Date, options = {}, localeCode = getLocaleObject().localeCode): string => {
  return new Intl.DateTimeFormat(localeCode, options).format(date);
};

export const isFormattable = (value: number): boolean => {
  if (value === null || Number.isNaN(Number(value))) {
    return false;
  }
  return true;
};

export const formatNumber = ({
  value,
  localeCode = getLocaleObject().localeCode,
  fallback = 0,
  digits = 2,
}: {
  value: number;
  localeCode: string;
  fallback?: number;
  digits?: number;
}): string => {
  if (!isFormattable(value)) {
    if (!isFormattable(fallback)) {
      return fallback as unknown as string;
    }
    return formatNumber({ value: fallback, localeCode });
  }

  return new Intl.NumberFormat(localeCode, {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: digits,
  }).format(value);
};

export const formatCurrency = ({
  value,
  currencyCode = getLocaleObject().currencyCode,
  localeCode = getLocaleObject().localeCode,
  fallback = 0,
}: {
  value: number;
  currencyCode: string;
  localeCode: string;
  fallback?: number;
}): string => {
  if (!isFormattable(value)) {
    if (!isFormattable(fallback)) {
      return fallback as unknown as string;
    }
    return formatCurrency({ value: fallback, currencyCode, localeCode });
  }

  return new Intl.NumberFormat(localeCode, {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
};

export const getCurrency = (
  localeCode: string = getLocaleObject().localeCode,
  currencyCode: string = getLocaleObject().currencyCode,
): string => {
  const value = new Intl.NumberFormat(localeCode, {
    style: 'currency',
    currency: currencyCode,
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }).format(1);

  const valueWithoutNonBreakingSpaces = value.replace(/\xA0/g, '');
  return valueWithoutNonBreakingSpaces.replace(/\d/g, '');
};

export const getSeparators = (
  localeCode = getLocaleObject().localeCode,
): { groupSeparator: string; decimalSeparator: string } => {
  const [{ value: groupSeparator }, { value: decimalSeparator }] = Intl.NumberFormat(localeCode)
    .formatToParts(123456.78)
    .filter((part) => part.type === 'group' || part.type === 'decimal');
  return { groupSeparator, decimalSeparator };
};

export const formatPercent = ({
  value,
  localeCode = getLocaleObject().localeCode,
  maximumFractionDigits = 2,
}: {
  value: number;
  localeCode: string;
  maximumFractionDigits?: number;
}): string => {
  if (!isFormattable(value)) {
    return formatPercent({ value: 0, localeCode });
  }

  return new Intl.NumberFormat(localeCode, {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits,
  }).format(value);
};

export const formatDate = (isoDateString: string | Date, localeCode = getLocaleObject().localeCode): string => {
  return new Intl.DateTimeFormat(localeCode, {
    dateStyle: 'short',
    timeZone: 'UTC',
  }).format(new Date(isoDateString));
};

export const formatDateCustom = (
  isoDateString: string | Date,
  options: Intl.DateTimeFormatOptions,
  localeCode = getLocaleObject().localeCode,
  fallback: string = '-',
): string => {
  try {
    return new Intl.DateTimeFormat(localeCode, {
      ...options,
    }).format(new Date(isoDateString));
  } catch (err) {
    return fallback;
  }
};

export const formatDateNoUTC = (dateString: string | Date, localeCode = getLocaleObject().localeCode): string => {
  return new Intl.DateTimeFormat(localeCode, {
    dateStyle: 'short',
  }).format(new Date(dateString));
};

export const formatDateTime = ({
  isoDateString,
  dateStyle = 'short',
  localeCode = getLocaleObject().localeCode,
}: {
  isoDateString: string | Date;
  dateStyle: DateStyle;
  localeCode: string;
}): string => {
  return new Intl.DateTimeFormat(localeCode, {
    dateStyle,
    timeStyle: 'medium',
    timeZone: 'UTC',
    hour12: true,
  }).format(new Date(isoDateString));
};

export const formatDateTimeNoUTC = (isoDateString: number, localeCode = getLocaleObject().localeCode): string => {
  return new Intl.DateTimeFormat(localeCode, {
    dateStyle: 'short',
    timeStyle: 'medium',
    hour12: true,
  }).format(new Date(isoDateString));
};

export const formatTime = (isoDateString: string, localeCode = getLocaleObject().localeCode): string => {
  return new Intl.DateTimeFormat(localeCode, {
    timeStyle: 'medium',
    hour12: true,
  }).format(new Date(isoDateString));
};

export const formatValue = (
  value: number,
  formatType: FormatTypeEnum,
  currencyCode: string,
  localeCode: string,
  fallback?: number,
): string => {
  switch (formatType) {
    case FormatTypeEnum.CURRENCY:
      return formatCurrency({ value, currencyCode, localeCode, fallback });
    case FormatTypeEnum.PERCENT:
      return formatPercent({ value, localeCode, maximumFractionDigits: fallback });
    case FormatTypeEnum.DECIMAL_PERCENT:
      return formatPercent({ value, localeCode, maximumFractionDigits: 4 });
    case FormatTypeEnum.NUMBER:
      return formatNumber({ value, fallback, localeCode });
    case FormatTypeEnum.ROUNDED_NUMBER:
      return formatNumber({ value, fallback, digits: 0, localeCode });
    default:
      return value as unknown as string;
  }
};
