import { DirectSalesLineObjective } from 'components/common/types/DirectSalesCampaign.types';

export const getNewObjectiveId = (objectives: DirectSalesLineObjective[]): number => {
  if (!objectives.length) return 1;
  const currentMaxId = Math.max(...objectives.map((objective) => objective.objectiveGroupId));

  return currentMaxId + 1;
};

export const getNewTargetId = (targetType: number = 0): number => {
  return (new Date().getTime() % 10 ** 9) + targetType;
};

export const getUniqueObjectiveName = (objectives: DirectSalesLineObjective[], idx: number): string => {
  const existingObjectivesNames = objectives.map(({ name }) => name.toLowerCase());
  const newObjectiveName = `Objective ${idx}`;

  if (!existingObjectivesNames.includes(newObjectiveName.toLowerCase())) return newObjectiveName;

  return getUniqueObjectiveName(objectives, idx + 1);
};
