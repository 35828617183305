import { DirectSalesPricingSolutionResponse } from 'components/common/types/DirectSalesCampaign.types';

const transformDirectSalesPrice = (
  pricingSolution: DirectSalesPricingSolutionResponse,
): { campaignPrice: number; linePrices: Record<string, number> } => {
  return {
    campaignPrice: Number(pricingSolution.totalCampaignPrice) || 0,
    linePrices: Object.entries(pricingSolution.dealLineTotals).reduce(
      (acc: Record<string, number>, [lineId, price]) => {
        acc[lineId] = Number(price) || 0;
        return acc;
      },
      {},
    ),
  };
};

export default transformDirectSalesPrice;
