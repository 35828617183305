import { useEffect, useMemo, useState } from 'react';
import { CaptionElementProps, Modifier, NavbarElementProps } from 'react-day-picker';
import DayPicker from 'react-day-picker/DayPicker';
import { isAfter, isSameDay } from 'date-fns';
import cx from 'classnames';
import YearMonthForm from 'components/patterns/YearMonthForm';
import Button, { Color, Size } from 'lib/Button';
import arrowLightLeft from 'assets/icons/arrow_light_left.svg';
import arrowLightRight from 'assets/icons/arrow_light_right.svg';
import { RangeSelection } from './DateRangePicker.types';
import './DateRangePicker.pcss';

export const formatDate = (date?: Date): string => {
  if (!date) return 'Select';

  const monthNumber = date.getMonth() + 1;

  return `${date.getDate()}/${monthNumber}/${date.getFullYear()}`;
};

const FIRST_DAY_OF_THE_WEEK = 1;
const YEARS_BEFORE = 0;
const YEARS_AFTER = 3;

export type DateRangePickerProps = {
  from: Date;
  setFrom: (from: Date) => void;
  to: Date;
  setTo: (to: Date) => void;
  isFloatingRangeVisible?: boolean;
  floatingFrom?: Date;
  floatingTo?: Date;
  setFloatingFrom?: (floatingFrom: Date | undefined) => void;
  setFloatingTo?: (floatingTo: Date | undefined) => void;
};

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  from,
  to,
  setFrom,
  setTo,
  isFloatingRangeVisible = false,
  floatingFrom,
  floatingTo,
  setFloatingFrom,
  setFloatingTo,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}: DateRangePickerProps) => {
  const today = new Date();
  const [month, setMonth] = useState<Date>(new Date(from || today));
  const [editedDate, setEditedDate] = useState<RangeSelection>('FROM');

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const handleDayClick = (day: Date): void => {
    if (from.getTime() === to.getTime() && day > from && editedDate === 'FROM') {
      setFrom(day);
      setTo(day);
      setEditedDate('TO');
    }

    if (
      (!!floatingFrom && isSameDay(day, floatingFrom)) ||
      (!!floatingTo && isSameDay(day, floatingTo)) ||
      (!!floatingTo && isAfter(from, floatingTo))
    ) {
      setFloatingFrom?.(undefined);
      setFloatingTo?.(undefined);
    }

    if (day < from && editedDate === 'TO') {
      setFrom(day);
      return;
    }

    if (day > to && editedDate === 'FROM') {
      setTo(day);
      return;
    }

    if (editedDate === 'FROM') {
      setFrom(day);
      setEditedDate('TO');
    }

    if (editedDate === 'TO') {
      setTo(day);
      setEditedDate(isFloatingRangeVisible ? 'FLOATING_FROM' : 'FROM');
    }

    if (isFloatingRangeVisible && editedDate === 'FLOATING_FROM' && day > to) {
      setFloatingTo?.(day);

      if (!floatingFrom) {
        setFloatingFrom?.(from);
      }

      return;
    }

    if (isFloatingRangeVisible && editedDate === 'FLOATING_TO' && day < from) {
      setFloatingFrom?.(day);

      if (!floatingTo) {
        setFloatingTo?.(to);
      }

      return;
    }

    if (isFloatingRangeVisible && editedDate === 'FLOATING_FROM') {
      setFloatingFrom?.(day);

      if (!floatingTo) {
        setFloatingTo?.(to);
      }

      setEditedDate('FLOATING_TO');
      return;
    }

    if (isFloatingRangeVisible && editedDate === 'FLOATING_TO') {
      setFloatingTo?.(day);

      if (!floatingFrom) {
        setFloatingFrom?.(from);
      }

      setEditedDate('FLOATING_FROM');
    }
  };

  const handleYearMonthChange = (newDate: Date): void => setMonth(newDate);

  const modifiers: Partial<Modifier> = {
    fixedRange: { from, to },
    floatingRange: { from: floatingFrom, to: floatingTo },
    fixedRangeStart: from,
    fixedRangeEnd: to,
    floatingRangeStart: floatingFrom,
    floatingRangeEnd: floatingTo,
    rangeOverlapFrom: floatingFrom && floatingFrom < from ? from : undefined,
    rangeOverlapEnd: floatingTo && floatingTo > to ? to : undefined,
  };

  const disabledDays: Modifier[] = useMemo(() => {
    return [
      {
        before: new Date(),
      },
      ...(editedDate === 'FLOATING_FROM' || editedDate === 'FLOATING_TO'
        ? [
            {
              from,
              to,
            },
          ]
        : []),
    ];
  }, [editedDate, from, to]);

  useEffect(() => {
    setEditedDate(isFloatingRangeVisible ? 'FLOATING_FROM' : 'FROM');

    return () => {
      setEditedDate('FLOATING_FROM');
    };
  }, [isFloatingRangeVisible]);

  const renderDateButton = (
    date: string,
    label: string,
    rangeSelection: RangeSelection,
    dataTestId: string,
  ): JSX.Element => (
    <button
      className={cx('flex flex-col w-1/2 border border-neutral-300 p-2.5 bg-white text-left', {
        'ring-1 border-primary-600 ring-primary-600 bg-primary-50 z-2': editedDate === rangeSelection,
        'rounded-l-md': rangeSelection === 'FROM' || rangeSelection === 'FLOATING_FROM',
        'rounded-r-md': rangeSelection === 'TO' || rangeSelection === 'FLOATING_TO',
      })}
      data-test-id={dataTestId}
      onClick={() => setEditedDate(rangeSelection)}
      type="button"
    >
      <span className="text-essential-primary leading-5">{label}</span>
      <span className="text-essential-tertiary leading-5">{date}</span>
    </button>
  );

  return (
    <div
      className="DateTimePicker DateTimePicker-range flex flex-col items-center"
      data-test-id="date-range-picker-calendar"
    >
      <div className="flex flex-row items-center w-full px-3">
        <div className="h-9 w-1 bg-primary-600 rounded-sm mr-2" />
        {renderDateButton(formatDate(from), 'Start date', 'FROM', 'date-start')}
        {renderDateButton(formatDate(to), 'End date', 'TO', 'date-end')}
      </div>
      {isFloatingRangeVisible && (
        <div className="flex flex-row items-center w-full px-3 mt-4 mb-1">
          <div className="h-9 w-1 bg-blue-500-opacity-30 rounded-sm mr-2" />
          {renderDateButton(formatDate(floatingFrom), '', 'FLOATING_FROM', 'floating-date-start')}
          {renderDateButton(formatDate(floatingTo), '', 'FLOATING_TO', 'floating-date-end')}
        </div>
      )}
      <DayPicker
        modifiers={modifiers}
        showOutsideDays
        firstDayOfWeek={FIRST_DAY_OF_THE_WEEK}
        selectedDays={[
          { from, to },
          { from: floatingFrom, to: floatingTo },
        ]}
        disabledDays={disabledDays}
        month={month}
        onDayClick={handleDayClick}
        captionElement={(props: CaptionElementProps) => (
          <YearMonthForm
            date={month}
            onChange={handleYearMonthChange}
            localeUtils={props.localeUtils}
            yearsBefore={YEARS_BEFORE}
            yearsAfter={YEARS_AFTER}
          />
        )}
        navbarElement={(props: NavbarElementProps) => (
          <div className="absolute w-[320px] flex justify-between">
            <Button
              onClick={() => {
                props.onPreviousClick();
                setMonth(props.previousMonth);
              }}
              svg={arrowLightLeft}
              color={Color.SECONDARY}
              classNames="w-10 h-10 flex items-center justify-center z-20"
              svgClassnames="text-primary w-6 h-6"
            />
            <Button
              onClick={() => {
                props.onNextClick();
                setMonth(props.nextMonth);
              }}
              size={Size.MEDIUM}
              svg={arrowLightRight}
              color={Color.SECONDARY}
              classNames="w-10 h-10 flex items-center justify-center z-20"
              svgClassnames="text-primary w-6 h-6"
            />
          </div>
        )}
      />
    </div>
  );
};

export default DateRangePicker;
