import { Dsp } from './Deal.types';
import { Features } from './Features.types';

type ExternalId = {
  system: string;
  ids: string[];
};

export type Locale = {
  localeCode: string;
  timezone: string;
  latitude: string;
  currencyCode: string;
  longitude: string;
};

export enum PublisherType {
  PUBLISHER = 'PUBLISHER',
  RESELLER = 'RESELLER',
}

export enum PublisherBackEndSystem {
  adServer = 'adserver',
  automation = 'automation',
}

export type PublisherDirectSalesPricing = {
  gridConfiguration?: string;
};

export type TransformedPublisherDirectSalesPricing = {
  type: string;
  startDay: string;
  ids: string[];
  pivotFields: string[];
  reportingFields: string[];
};

export type PublisherConfiguration = {
  backEndSystem: PublisherBackEndSystem;
  currencyCode: string;
  flag?: string;
  iso2CountryCode: string;
  marketId: string;
  latitude: number;
  localeCode: string;
  longitude: number;
  openStreetMapCountryName: string;
  timezone: string;
  type: PublisherType;
};

export type PublisherConfigurationResponse = {
  appId: string;
  applicationConfiguration: {
    countryName: string;
    localeCode: string;
    latitude: string;
    longitude: string;
    flag: string;
  };
  backEndSystem: PublisherBackEndSystem;
  currencyCode: string;
  id: string;
  identifier: string;
  iso2CountryCode: string;
  iso3CountryCode: string;
  timeZone: string;
  type: PublisherType;
};

export type PublisherFeatures = {
  [featureName: string]: string;
};

export type TransformedPublisherFeatures = {
  [featureName: string]: string[];
};

export type PublisherDspsMediaOwner = { [dspId: string]: Dsp & { mediaOwners: string[] } };

export type PublisherStore = {
  configuration: PublisherConfiguration;
  directSalesPricing: TransformedPublisherDirectSalesPricing;
  dsps: PublisherDspsMediaOwner;
  publisherFeatures: TransformedPublisherFeatures;
};

export type PublisherTestConfiguration = {
  [key: string]: {
    configuration: {
      currencyCode: string;
      localeCode: string;
      marketId: string;
    };
  };
};

export type Publisher = {
  id: string;
  marketId: string | null;
  name: string;
  externalIds: ExternalId[];
  version: number;
  created: string;
  updated: string;
  backEndSystem: string | null;
  domain: string | null;
  currency: string | null;
  timeZone: string | null;
  iso2CountryCode: string | null;
  iso3CountryCode: string | null;
  dspTradingName: string | null;
  locale?: Locale;
  features?: Features;
};

export type IsAdServerProps = {
  backEndSystem: PublisherBackEndSystem;
  publisherFeatures: TransformedPublisherFeatures;
  environmentId: string;
};
