import { useDispatch, useSelector } from 'react-redux';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import useDirectSalesPlannerActions from 'components/pages/Planner/hooks/useDirectSalesPlannerActions';
import { getDirectSalesCampaign } from 'modules/api/directSalesCampaign';
import { transformDirectSalesBookingResponse } from 'components/pages/Planner/hooks/utils/transformDirectSalesCampaign';
import { notifyError, notifyInfo } from 'store/notification/reducer';
import { NOTIFICATION_TIMEOUT } from 'consts/notifications';
import { useParams } from 'react-router-dom';
import { Store } from '../types/Store.types';
import { CampaignDuplicationModalMessages } from './consts';
import { DuplicateCampaignProps, UseCampaignDuplication } from './useCampaignDuplication.types';

const useCampaignDuplication = (): UseCampaignDuplication => {
  const cancelFunctions = useCancelRequest();
  const { campaignId: paramCampaignId } = useParams();
  const dispatch = useDispatch();
  const { duplicateCampaign } = useDirectSalesPlannerActions();

  const marketId = useSelector((state: Store) => state.publisher.configuration.marketId);

  const isPlannerOpen = paramCampaignId?.length;

  const duplicatePlannerCampaign = async ({
    selectedDate,
    areCancelledLinesIncluded,
    onComplete,
  }: DuplicateCampaignProps): Promise<void> => {
    onComplete();
    await duplicateCampaign({ campaignStartDate: selectedDate, areCancelledLinesIncluded });
  };

  const duplicateDealManagementCampaign = async ({
    selectedDate,
    areCancelledLinesIncluded,
    campaignId,
    onComplete,
  }: DuplicateCampaignProps): Promise<void> => {
    try {
      dispatch(notifyInfo({ message: CampaignDuplicationModalMessages.DUPLICATION_INFO_MESSAGE }));

      const result = await getDirectSalesCampaign(campaignId, marketId, cancelFunctions);
      const { lines: linesData } = transformDirectSalesBookingResponse(result);

      const {
        campaign: {
          body: { campaignDefinition: campaignDefinitionData },
          header: { salesPerson, salesTeam, adminPerson },
        },
      } = result;

      onComplete();
      await duplicateCampaign({
        campaignStartDate: selectedDate,
        areCancelledLinesIncluded,
        campaignDefinitionData: { ...campaignDefinitionData, salesPerson, salesTeam, adminPerson },
        linesData,
      });
    } catch (error) {
      dispatch(
        notifyError({
          message: `${error.message} - ${CampaignDuplicationModalMessages.CAMPAIGN_RETRIEVAL_ERROR}`,
          timeout: NOTIFICATION_TIMEOUT.LONG,
        }),
      );
    }
  };

  return async (props: DuplicateCampaignProps) => {
    if (isPlannerOpen) await duplicatePlannerCampaign(props);
    else await duplicateDealManagementCampaign(props);
  };
};

export default useCampaignDuplication;
