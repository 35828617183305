import DealsTable from './DealsTable/DealsTable';
import Filters from './Filters/Filters';

const DirectSalesDeals: React.FC = () => {
  return (
    <div className="py-4 px-14">
      <Filters />
      <div className="mt-4">
        <DealsTable />
      </div>
    </div>
  );
};

export default DirectSalesDeals;
