import { useEffect, useRef, useState } from 'react';
import {
  MIN_TOUCHPAD_WHEEL_DISTANCE_TO_SCROLL_NOTIFICATION,
  MIN_MOUSE_WHEEL_DISTANCE_TO_SCROLL_NOTIFICATION,
  MOUSE_WHEEL_SCROLL_TICK,
  UseToasterScroller,
} from './Toaster.types';

export const useToasterScroller: UseToasterScroller = (containerRef, totalToasts, maxVisibleToasts) => {
  const accumulatedWheelDistance = useRef(0);
  const [numOfScrolledToasts, setNumOfScrolledToasts] = useState(0);

  const increaseScrolledToasts = (): void => {
    setNumOfScrolledToasts((prev) => {
      const maxPossibleIndexMargin = totalToasts - maxVisibleToasts;
      if (prev < maxPossibleIndexMargin) return prev + 1;
      return prev;
    });
  };

  const decreaseScrolledToasts = (): void => {
    setNumOfScrolledToasts((prev) => (prev > 0 ? prev - 1 : prev));
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    const handleScroll = (event: WheelEvent): void => {
      const isScrollingUsingTouchpad = Math.abs(event.deltaY) < MOUSE_WHEEL_SCROLL_TICK;
      const distanceRequiredToScrollNotification = isScrollingUsingTouchpad
        ? MIN_TOUCHPAD_WHEEL_DISTANCE_TO_SCROLL_NOTIFICATION
        : MIN_MOUSE_WHEEL_DISTANCE_TO_SCROLL_NOTIFICATION;

      const isScrollingInDifferentDirection =
        accumulatedWheelDistance.current !== 0 &&
        Math.sign(accumulatedWheelDistance.current) !== Math.sign(event.deltaY);

      if (isScrollingInDifferentDirection) accumulatedWheelDistance.current = 0;

      accumulatedWheelDistance.current += event.deltaY;

      if (accumulatedWheelDistance.current <= -distanceRequiredToScrollNotification) {
        increaseScrolledToasts();
        accumulatedWheelDistance.current = 0;
      } else if (accumulatedWheelDistance.current >= distanceRequiredToScrollNotification) {
        decreaseScrolledToasts();
        accumulatedWheelDistance.current = 0;
      }
    };

    containerRef.current?.removeEventListener('wheel', handleScroll);

    if (totalToasts === 0) {
      setNumOfScrolledToasts(0);
      return;
    }

    containerRef.current?.addEventListener('wheel', handleScroll);

    // eslint-disable-next-line consistent-return
    return () => {
      containerRef.current?.removeEventListener('wheel', handleScroll);
    };
  }, [totalToasts, maxVisibleToasts]);

  return { numOfScrolledToasts, decreaseScrolledToasts, containerRef };
};
