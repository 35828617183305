import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { formatNumber } from 'modules/I18N';
import Chip from 'lib/Chip';
import { Color, Size } from 'lib/Chip/Chip.types';
import { TargetFeedbackProps } from './TargetFeedback.types';

const TargetFeedback: React.FC<TargetFeedbackProps> = ({
  dataTestId,
  allocated,
  available,
  feedbackColor,
  icon,
  textSymbol,
}) => {
  const { localeCode } = useSelector((state: RootState) => state.publisher.configuration);

  const value = allocated ?? available;

  if (value === undefined) return null;

  const currency = textSymbol ? `${textSymbol} ` : '';
  const status = allocated !== undefined ? 'allocated' : 'available';
  const formattedValue = formatNumber({ value, localeCode, fallback: 0, digits: 2 });

  const label = `${currency}${status} ${formattedValue}`;

  const isAllocatedWithFeedback = Boolean(allocated !== undefined && feedbackColor);
  const style = isAllocatedWithFeedback ? feedbackColor : Color.GREY;

  return (
    <Chip
      dataTestId={dataTestId}
      color={style}
      icon={icon}
      size={Size.SMALL}
      label={label}
      hasIndicator={isAllocatedWithFeedback}
    />
  );
};

export default TargetFeedback;
