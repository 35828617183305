import { CodeNameModel } from 'components/common/types';
import AsyncSelect from 'components/patterns/AsyncSelect';
import { useLoadLookup } from '../useLoadLookup';
import { FilterProductCategoryLookupProps } from './FilterProductCategoryLookup.types';

const FilterProductCategoryLookup: React.FC<FilterProductCategoryLookupProps> = ({
  changeFilters,
  productCategory,
}) => {
  const { onLoadProductCategory } = useLoadLookup();

  const handleSelectProductCategoryLookup = (_name: string, value: CodeNameModel): void => {
    changeFilters({ productCategory: value });
  };

  return (
    <AsyncSelect
      name="productCategory"
      loadData={onLoadProductCategory}
      placeholder="Select product category"
      selectedValue={productCategory}
      onSelect={handleSelectProductCategoryLookup}
      dataTestId="productCategories-filter"
      textKey="name"
    />
  );
};

export default FilterProductCategoryLookup;
