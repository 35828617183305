import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './ToggleSwitch.pcss';

const cx = classNames.bind(styles);

export const ToggleTheme = {
  DARK: 'dark',
  LIGHT: 'light',
  SMALL_LIGHT: 'smallLight',
  PINK: 'pink',
};

const ToggleSwitch = ({ onChange, isChecked, label, theme, isDisabled, dataTestId, ddActionName, id, dataPendoId }) => (
  <label data-dd-action-name={ddActionName ?? label} data-pendo-id={dataPendoId}>
    <input
      id={id}
      name={id}
      aria-label={id}
      data-test-id={dataTestId}
      type="checkbox"
      className={cx('switchinput')}
      checked={isChecked}
      onChange={(event) => onChange(event.target.checked)}
      disabled={isDisabled}
    />
    <span
      className={cx('icon', theme, {
        checked: isChecked,
        disabled: isDisabled,
      })}
    />
    {label && <span className="pl-1 select-none body-base">{label}</span>}
  </label>
);

ToggleSwitch.propTypes = {
  onChange: PropTypes.func,
  isChecked: PropTypes.bool,
  label: PropTypes.string,
  theme: PropTypes.oneOf(Object.values(ToggleTheme)),
  isDisabled: PropTypes.bool,
  dataTestId: PropTypes.string,
  ddActionName: PropTypes.string,
  dataPendoId: PropTypes.string,
  id: PropTypes.string,
};

ToggleSwitch.defaultProps = {
  onChange: () => undefined,
  isChecked: false,
  theme: ToggleTheme.LIGHT,
  label: '',
  isDisabled: false,
  dataTestId: 'toggle-switch',
};

export default ToggleSwitch;
