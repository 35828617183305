import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideBanner, showBanner } from 'store/banner/reducer';
import { BannerType } from 'lib/Banner';
import { Toaster } from 'lib/Toaster';
import useChannels from 'customHooks/useChannels';
import { Store } from 'components/common/types/Store.types';
import useNetworkReferenceData from 'components/pages/Planner/hooks/useNetworkReferenceData';
import useReferenceData from 'components/pages/Planner/hooks/useReferenceData';
import { hideSecondaryPanel, clearNewProduct, setToastMessages } from 'store/productManagement/reducer';
import { getDefaultMandatoryData } from 'components/pages/PCM/Products/utils/getDefaultMandatoryData';
import { CreateProductContent } from './CreateProductContent/CreateProductContent';
import { CreateProductSidebar } from './CreateProductSidebar.tsx/CreateProductSidebar';
import { useCreateProductTemplate } from '../hooks/useCreateProductTemplate';

const mandatoryData = getDefaultMandatoryData();

const CreateProduct: React.FC = () => {
  const dispatch = useDispatch();
  const saveProduct = useCreateProductTemplate();
  const productTemplate = useSelector((state: Store) => state.productManagement.newProduct);
  const toastMessages = useSelector((state: Store) => state.productManagement.toastMessages);

  useEffect(() => {
    dispatch(clearNewProduct());
    dispatch(hideSecondaryPanel());
  }, []);

  useChannels();
  useReferenceData(mandatoryData);
  useNetworkReferenceData(mandatoryData);

  useEffect(() => {
    dispatch(
      showBanner({
        type: BannerType.INFO,
        headerInfo: 'Product creation manager',
        confirmButton: {
          buttonLabel: 'Save',
          actionCallback: saveProduct,
        },
      }),
    );

    return () => {
      dispatch(hideBanner());
      dispatch(hideSecondaryPanel());
      dispatch(setToastMessages([]));
    };
  }, [productTemplate]);

  const handleToastRemove = (toastId: string): void => {
    const removeSelectedToast = toastMessages.filter((toast) => toast.id !== toastId);

    dispatch(setToastMessages(removeSelectedToast));
  };

  return (
    <div className="flex relative flex-1 overflow-y-auto overflow-x-hidden h-screen">
      <CreateProductSidebar />
      <div className="w-full grid grid-cols-[3fr_2fr]">
        <CreateProductContent />
      </div>
      <Toaster toasts={toastMessages} onToastRemove={handleToastRemove} />
    </div>
  );
};

export default CreateProduct;
