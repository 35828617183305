import { ChipTheme, Color } from 'lib/Chip/Chip.types';
import { DirectSalesAllocationSolutionStatus } from 'components/common/types/DirectSalesAllocationSolution';

export const SECTION_BUTTON_STATUS_TO_COLOR_MAPPING = {
  [DirectSalesAllocationSolutionStatus.GREEN]: `${ChipTheme[Color.GREEN].wrapperStyles} ${ChipTheme[Color.GREEN].labelStyles}`,
  [DirectSalesAllocationSolutionStatus.ORANGE]: `${ChipTheme[Color.ORANGE].wrapperStyles} ${ChipTheme[Color.ORANGE].labelStyles}`,
  [DirectSalesAllocationSolutionStatus.RED]: `${ChipTheme[Color.RED].wrapperStyles} ${ChipTheme[Color.RED].labelStyles}`,
};

export const SECTION_BUTTON_STATUS_TO_VALUE_COLOR_MAPPING = {
  [DirectSalesAllocationSolutionStatus.GREEN]: ChipTheme[Color.GREEN].subLabelStyles,
  [DirectSalesAllocationSolutionStatus.ORANGE]: ChipTheme[Color.ORANGE].subLabelStyles,
  [DirectSalesAllocationSolutionStatus.RED]: ChipTheme[Color.RED].subLabelStyles,
};

export const STATUS_TO_COLOR_MAPPING = {
  [DirectSalesAllocationSolutionStatus.GREEN]: Color.GREEN,
  [DirectSalesAllocationSolutionStatus.ORANGE]: Color.ORANGE,
  [DirectSalesAllocationSolutionStatus.RED]: Color.RED,
};
