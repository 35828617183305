import { SelectionPermission } from 'store/productManagement/reducer.types';

export const isValueRequired = (selectedPermission?: string): boolean => {
  if (!selectedPermission) return false;

  return [
    SelectionPermission.DEFAULT_VALUE,
    SelectionPermission.FIXED_VALUE,
    SelectionPermission.ALLOWED_VALUES,
  ].includes(selectedPermission as SelectionPermission);
};
