import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Store } from 'components/common/types/Store.types';
import { isEqual } from 'lodash';
import { DirectSalesLine } from 'components/common/types/DirectSalesCampaign.types';
import { getBackupLine } from 'store/dealManagement/selectors';

export const useCheckDirectSalesLineLevelChange = (): boolean => {
  const backupLine = useSelector(getBackupLine) as DirectSalesLine | undefined;
  const currentLine = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine);
  const isNewDeal = useSelector((state: Store) => state.dealManagement.isNewDeal);
  const mediaType = useSelector((state: Store) => state.dealManagement.directSales.mediaType);

  const { startDate, endDate, environments, selectedDays } = currentLine;

  const isDirectSalesLineChange = useMemo(() => {
    if (!backupLine) return false;

    return !isNewDeal && mediaType !== backupLine?.mediaType;
  }, [backupLine, mediaType, isNewDeal]);

  const isCommonDealLineLevelChange = useMemo(
    () =>
      !isNewDeal &&
      !isEqual(
        {
          startDate: backupLine?.startDate,
          endDate: backupLine?.endDate,
          environments: backupLine?.environments,
          selectedDays: backupLine?.selectedDays,
        },
        {
          startDate,
          endDate,
          environments,
          selectedDays,
        },
      ),
    [backupLine, currentLine, isNewDeal],
  );

  return isCommonDealLineLevelChange || isDirectSalesLineChange;
};
