import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { formatDateCustom } from 'modules/I18N';
import { TimelineItem } from 'components/patterns/Timeline';
import { ProhibitionModel } from 'components/common/prop-types/inventory';

const ProhibitionInfo = ({ prohibitionGroup }) => {
  const { localeCode } = useSelector((state) => state.publisher.configuration);
  const dateFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
    timeZone: 'UTC',
  };

  return (
    <TimelineItem
      title={
        <div className="grid grid-cols-3 gap-x-4 items-center" data-test-id="prohibition-group">
          {prohibitionGroup.map(({ productCategoryUuid, productCategoryName }) => (
            <p key={productCategoryUuid} className="body-sm break-all">
              {productCategoryName}
            </p>
          ))}
        </div>
      }
    >
      <div className="grid grid-cols-3 gap-x-4">
        {prohibitionGroup.map(({ productCategoryUuid, startDate, endDate }) => (
          <p
            key={productCategoryUuid}
            className="body-sm text-neutral-950-opacity-60"
          >{`${formatDateCustom(startDate, dateFormatOptions, localeCode)}-${formatDateCustom(endDate, dateFormatOptions, localeCode)}`}</p>
        ))}
      </div>
    </TimelineItem>
  );
};

ProhibitionInfo.propTypes = {
  prohibitionGroup: PropTypes.arrayOf(ProhibitionModel).isRequired,
};

export default ProhibitionInfo;
