import {
  DirectSalesCampaignDealLineResponse,
  DirectSalesTransientDealLineState,
  DirectSalesIntendedTargetState,
} from 'components/common/types/DirectSalesCampaign.types';

export const transformTargetStatusesToTransientDealLinesState = (
  dealLines: Pick<DirectSalesCampaignDealLineResponse, 'id' | 'targetState'>[],
): DirectSalesTransientDealLineState => {
  const targetStatuses = dealLines
    .map(({ id, targetState }) => ({
      id,
      targetState,
    }))
    .filter((lineTargetStatus) => lineTargetStatus.targetState);

  return Object.fromEntries(
    targetStatuses.map(({ id, targetState }: { id: string; targetState: DirectSalesIntendedTargetState }) => [
      `${id}`,
      { status: targetState.intendedDealLineState, expires: targetState.expires },
    ]),
  );
};
