import { URLS, HTTP_METHODS, HEADERS_CONTENT_TYPE } from 'modules/api/constants';
import handleRequest from 'modules/api/common';
import { CancelFunctions } from 'components/common/types';
import {
  DirectSalesProductTemplateRequest,
  DirectSalesProductTemplateResponse,
} from 'components/common/types/DirectSalesProductTemplate.types';

export const createDirectSalesProductTemplate = (
  data: Partial<DirectSalesProductTemplateRequest>,
  marketId: string,
  cancelFunctions: CancelFunctions,
): Promise<DirectSalesProductTemplateResponse> => {
  const reqOptions = {
    url: URLS().CREATE_DIRECT_SALES_PRODUCT_TEMPLATE(marketId),
    method: HTTP_METHODS.POST,
    data,
    headers: {
      'Content-Type': HEADERS_CONTENT_TYPE.APPLICATION_JSON,
    },
    notificationTimeout: 0,
  };

  return handleRequest(reqOptions, cancelFunctions, 'CREATE_DIRECT_SALES_PRODUCT_TEMPLATE');
};

export const fetchDirectSalesProductFolder = (
  marketId: string,
  cancelFunctions: CancelFunctions,
): Promise<{ folder: string }[]> => {
  const reqOptions = {
    url: URLS().FETCH_DIRECT_SALES_PRODUCT_FOLDER(marketId),
    method: HTTP_METHODS.GET,
    headers: {
      'Content-Type': HEADERS_CONTENT_TYPE.APPLICATION_JSON,
    },
  };

  return handleRequest(reqOptions, cancelFunctions, 'FETCH_DIRECT_SALES_PRODUCT_FOLDER');
};
