import cx from 'classnames';
import { formatDate } from 'modules/I18N';
import { useRef, useState } from 'react';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import SVG from 'react-inlinesvg';
import calendarSvg from 'assets/icons/calendar.svg';

import { getNoonTimeFromDate } from 'utils/dateFormatUtil';
import DateTimePicker from 'lib/DateTimePicker/DateTimePicker';
import { isValid } from 'date-fns';
import { DateTimePickerPopoverProps } from './DateTimePickerPopover.types';

const DateTimePickerPopover: React.FC<DateTimePickerPopoverProps> = ({
  dataTestId = 'date-time-picker-popover',
  gap = 8,
  ...pickerProps
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { value } = pickerProps;
  const parentRef = useRef<HTMLDivElement>(null);

  const isValidDate = isValid(value);

  return (
    <div ref={parentRef} data-test-id={dataTestId} className="relative">
      <Popover
        containerClassName="z-20 py-1"
        parentElement={parentRef.current as HTMLElement}
        align="end"
        positions={['bottom', 'top']}
        isOpen={isOpen}
        boundaryElement={document.body}
        onClickOutside={() => setIsOpen(false)}
        reposition
        content={({ position, childRect, popoverRect }) => (
          <ArrowContainer
            className="!px-0"
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor="var(--transparent-color)"
            arrowSize={gap}
          >
            <div className="relative bg-neutral-50 shadow-md border border-neutral-300 rounded-xl">
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <DateTimePicker {...pickerProps} />
            </div>
          </ArrowContainer>
        )}
      >
        <button
          className={cx(
            'bg-neutral-50 flex justify-center items-center space-x-2 px-2.5 py-1 sub-header-base border border-gray-300 rounded-md border-neutral-950-opacity-10 shadow-inner-sm ',
            {
              'ring-2 border-1 border-primary-600 ring-primary-600 outline-none': isOpen,
            },
          )}
          type="button"
          onClick={() => setIsOpen(!isOpen)}
        >
          <SVG className="w-4 h-4 text-neutral-500" src={calendarSvg} />
          <p>{isValidDate ? `${formatDate(value)} ${getNoonTimeFromDate(value)}` : 'Date & Time'}</p>
        </button>
      </Popover>
    </div>
  );
};

export default DateTimePickerPopover;
