import cx from 'classnames';
import IconButton from 'lib/IconButton';
import Button, { Color, Size as SizeButton } from 'lib/Button';
import sidebarSimpleRightIcon from 'assets/icons/sidebar-simple-right.svg';
import mapIcon from 'assets/icons/map.svg';
import overviewIcon from 'assets/icons/columns-plus-right.svg';
import reviewIcon from 'assets/icons/route_frame_lists.svg';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from 'components/common/types/Store.types';
import {
  CampaignPlannerMode,
  changeCampaignPlannerMode,
  changeFormParams,
  setDirectSalesAssetList,
  updatePlannerSettings,
} from 'store/dealManagement/reducer';
import { FrontEndType } from 'components/common/types/Deal.types';
import useCampaignType from 'components/pages/Planner/hooks/useCampaignType';
import useUnsavedChange from 'components/pages/Planner/hooks/useUnsavedChange';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import { useEffect, useState } from 'react';
import { isDirectSalesLinePriced } from 'components/pages/Planner/hooks/utils/getIsDirectSalesLinePriced';
import { getEveryLineState, getIsAnyLineFetchingAvailability } from 'store/dealManagement/selectors';
import { getDirectSalesAssetList } from 'modules/api/directSalesCampaign';
import { transformAssetList } from 'components/pages/Planner/hooks/utils/transformAssetList';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import { DirectSalesStatusOption } from 'components/common/types/DirectSalesCampaign.types';
// import locationsIcon from 'assets/icons/locations.svg';

// eslint-disable-next-line sonarjs/cognitive-complexity
const CampaignActionButtons: React.FC = () => {
  const dispatch = useDispatch();
  const { isDirectSalesCampaignType } = useCampaignType();
  const handleUnsavedChange = useUnsavedChange();
  const cancelFunctions = useCancelRequest();

  const marketId = useSelector((state: Store) => state.publisher.configuration.marketId);
  const isLoading = useSelector((state: Store) => state.dealManagement.isLoading);
  const campaignPlannerMode = useSelector((state: Store) => state.dealManagement.campaignPlannerMode);
  const frontEndType = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.frontEndType);
  const dealId = useSelector((state: Store) => state.dealManagement.commonDeal.dealId);
  const isDirectSummaryPanelOpen = useSelector(
    (state: Store) => state.dealManagement.plannerSettings.isDirectSummaryPanelOpen,
  );
  const status = useSelector((state: Store) => state.dealManagement.directSales.state.status);
  const linesStates = useSelector(getEveryLineState);
  const { availableFrames, availableImpressions } = useSelector(
    (state: Store) => state.dealManagement.commonDeal.currentLine.availability,
  );
  const isFetchingAvailability = useSelector(getIsAnyLineFetchingAvailability);

  const [isTooltip, setIsTooltip] = useState(false);

  const isMapMode = campaignPlannerMode === CampaignPlannerMode.MAP;
  const isOverviewMode = campaignPlannerMode === CampaignPlannerMode.OVERVIEW;
  const isReviewMode = campaignPlannerMode === CampaignPlannerMode.REVIEW;

  const isAllocated = isDirectSalesLinePriced(status);
  const isAvailability = !isAllocated && (availableFrames || availableImpressions);

  const isSummaryPanelButtonEnabled = Boolean(isAllocated || isAvailability) && !isReviewMode;

  useEffect(() => {
    if (isReviewMode) return;

    dispatch(updatePlannerSettings({ isDirectSummaryPanelOpen: isSummaryPanelButtonEnabled }));
  }, [isReviewMode, isSummaryPanelButtonEnabled]);

  const isActionButtonDisabled = isLoading || isFetchingAvailability;

  const toggleRightSidebar = (): void => {
    if (!isDirectSalesCampaignType) return;

    dispatch(updatePlannerSettings({ isDirectSummaryPanelOpen: !isDirectSummaryPanelOpen }));
  };

  const handleAssetList = async (): Promise<void> => {
    try {
      dispatch(changeFormParams({ isLoading: true, isEditingDisabled: true }));
      const data = await getDirectSalesAssetList(dealId, marketId, cancelFunctions);

      dispatch(setDirectSalesAssetList(transformAssetList(data)));
      dispatch(
        changeFormParams({
          isLoading: false,
          isEditingDisabled: false,
        }),
      );
    } catch {
      dispatch(
        changeFormParams({
          isLoading: false,
          isEditingDisabled: false,
        }),
      );
    }
  };

  const handleModeChange = async (nextMode: CampaignPlannerMode): Promise<void> => {
    if (isDirectSalesCampaignType) {
      dispatch(changeFormParams({ isCampaignDrawerOpen: false }));
    }

    if (nextMode === campaignPlannerMode) return;

    handleUnsavedChange(() => dispatch(changeCampaignPlannerMode(nextMode)));

    const isCampaignCancelled = linesStates.every((state) => state.status === DirectSalesStatusOption.CANCELLED);

    if (!isCampaignCancelled && isAllocated && dealId && nextMode === CampaignPlannerMode.MAP) {
      await handleAssetList();
    }
  };

  return (
    <div
      data-test-id="action-buttons"
      className="flex flex-shrink-0 gap-1 px-[8px] justify-end items-center border-l border-neutral-950-opacity-10 w-[var(--right-sidebar-width)]"
    >
      {/* Hidden until switching view functionality is enabled */}
      {/* <Button color={Color.BLACK_AND_WHITE} size={SizeButton.SMALL} svg={locationsIcon} label="Open in planner" /> */}

      {frontEndType === FrontEndType.PLANNER && (
        <Button
          color={isMapMode ? Color.TERTIARY : Color.BLACK_AND_WHITE}
          size={SizeButton.SMALL}
          svg={mapIcon}
          label="Map"
          classNames={cx('border leading-tight', { 'border-primary-600': isMapMode })}
          svgClassnames="!w-3 !h-3"
          onClick={() => handleModeChange(CampaignPlannerMode.MAP)}
          isDisabled={(isDirectSalesCampaignType && !dealId) || (!isMapMode && isActionButtonDisabled)}
        />
      )}

      {frontEndType === FrontEndType.PLANNER && isDirectSalesCampaignType && (
        <>
          <Button
            color={isOverviewMode ? Color.TERTIARY : Color.BLACK_AND_WHITE}
            size={SizeButton.SMALL}
            svg={overviewIcon}
            label="Overview"
            classNames={cx('border leading-tight', { 'border-primary-600': isOverviewMode })}
            svgClassnames="!w-3 !h-3"
            onClick={() => handleModeChange(CampaignPlannerMode.OVERVIEW)}
            isDisabled={!dealId || (!isOverviewMode && isActionButtonDisabled)}
          />

          {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
          <div onMouseOver={() => setIsTooltip(!isAllocated)} onMouseOut={() => setIsTooltip(false)}>
            <Popover
              isOpen={isTooltip}
              align="center"
              positions={['bottom']}
              onClickOutside={() => setIsTooltip(false)}
              containerClassName="z-50"
              content={({ position, childRect, popoverRect }) => (
                <ArrowContainer
                  position={position}
                  childRect={childRect}
                  popoverRect={popoverRect}
                  arrowColor="var(--neutral-950)"
                  arrowSize={8}
                >
                  <div className="w-48 flex flex-col text-center bg-neutral-950 text-neutral-50 rounded-md px-4 py-2 ">
                    No priced campaign solution
                  </div>
                </ArrowContainer>
              )}
            >
              <div>
                <Button
                  color={isReviewMode ? Color.TERTIARY : Color.BLACK_AND_WHITE}
                  size={SizeButton.SMALL}
                  svg={reviewIcon}
                  label="Review"
                  classNames={cx('border leading-tight', { 'border-primary-600': isReviewMode })}
                  svgClassnames="!w-3 !h-3"
                  onClick={() => handleModeChange(CampaignPlannerMode.REVIEW)}
                  isDisabled={!dealId || !isAllocated || (!isReviewMode && isActionButtonDisabled)}
                />
              </div>
            </Popover>
          </div>
        </>
      )}

      <IconButton
        dataTestId="planner-right-sidebar-button"
        icon={sidebarSimpleRightIcon}
        name="Toggle right sidebar"
        handleOnClick={toggleRightSidebar}
        isDisabled={!isSummaryPanelButtonEnabled}
      />
    </div>
  );
};

export default CampaignActionButtons;
