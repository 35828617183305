import {
  DirectSalesProductCategoryExclusivityType,
  DirectSalesProductCategoryExclusivityLabel,
} from 'components/common/types/DirectSalesCampaign.types';

import { CodeNameModel } from 'components/common/types';

export const codeNameProductCategoryExclusivity: CodeNameModel[] = [
  { code: DirectSalesProductCategoryExclusivityType.OFF, name: DirectSalesProductCategoryExclusivityLabel.OFF },
  { code: DirectSalesProductCategoryExclusivityType.FRAME, name: DirectSalesProductCategoryExclusivityLabel.FRAME },
  {
    code: DirectSalesProductCategoryExclusivityType.FURNITURE,
    name: DirectSalesProductCategoryExclusivityLabel.FURNITURE,
  },
];
