import { lazy } from 'react';
import DealWithLines from 'components/pages/DealWithLines';
import Deals from 'components/pages/Deals';
import InventoryManagement from 'components/pages/InventoryManagement';
import Landing from 'components/pages/Landing';
import AccessDenied from 'components/pages/AccessDenied';
import UserManagement from 'components/pages/UserManagement';
import Administration from 'components/pages/Administration';
import ContentManagement from 'components/pages/ContentManagement';
import PublicCampaignPlan from 'components/pages/PublicCampaignPlan';
import Insights from 'components/pages/Insights';
import CreateProduct from 'components/pages/PCM/CreateProduct';
import { PermissionsEnum } from 'consts/permissions';
import { Route } from 'components/common/types/Route.types';

const ProgrammaticPlanner = lazy(() => import('./components/pages/Planner/containers/ProgrammaticPlanner'));
const DirectSalesPlanner = lazy(() => import('./components/pages/Planner/containers/DirectSalesPlanner'));

const getRoutes = (): Route[] => [
  {
    id: 1,
    path: '/landing',
    main: Landing,
    pageAccessPermission: PermissionsEnum.ENVIRONMENT_SELECTION_PAGE_ACCESS,
  },
  {
    id: 2,
    path: '/insights/*',
    main: Insights,
    pageTitle: 'Insights',
    pageAccessPermission: undefined,
  },
  {
    id: 5,
    path: '/deals/*',
    main: Deals,
    pageAccessPermission: PermissionsEnum.DEAL_MANAGEMENT_PAGE_ACCESS,
  },
  {
    id: 6,
    path: '/deal',
    main: DealWithLines,
    pageAccessPermission: PermissionsEnum.DEAL_MANAGEMENT_PAGE_ACCESS,
  },
  {
    id: 6,
    path: '/deal/:internalId',
    main: DealWithLines,
    pageAccessPermission: PermissionsEnum.DEAL_MANAGEMENT_PAGE_ACCESS,
  },
  {
    id: 7,
    path: `/planner/programmatic`,
    main: ProgrammaticPlanner,
    pageAccessPermission: PermissionsEnum.DEAL_MANAGEMENT_PAGE_ACCESS,
  },
  {
    id: 15,
    path: `/planner/direct-sales`,
    main: DirectSalesPlanner,
    pageAccessPermission: PermissionsEnum.DEAL_MANAGEMENT_PAGE_ACCESS,
  },
  {
    id: 8,
    path: `/planner/programmatic/:campaignId`,
    main: ProgrammaticPlanner,
    pageAccessPermission: PermissionsEnum.DEAL_MANAGEMENT_PAGE_ACCESS,
  },
  {
    id: 16,
    path: `/planner/direct-sales/:campaignId`,
    main: DirectSalesPlanner,
    pageAccessPermission: PermissionsEnum.DEAL_MANAGEMENT_PAGE_ACCESS,
  },
  {
    id: 8,
    path: '/administration',
    main: Administration,
    pageAccessPermission: PermissionsEnum.ADMINISTRATION_PAGE_ACCESS,
  },
  {
    id: 9,
    path: '/inventory-management/*',
    pageTitle: 'Inventory management',
    main: InventoryManagement,
    pageAccessPermission: PermissionsEnum.SETUP_PAGE_ACCESS, // maybe a new permission? or rename existing one?
  },
  {
    id: 12,
    path: '/content-management/*',
    pageTitle: 'Content management',
    main: ContentManagement,
    pageAccessPermission: PermissionsEnum.CONTENT_PAGE_ACCESS,
  },
  {
    id: 13,
    path: '/users',
    main: UserManagement,
    pageAccessPermission: PermissionsEnum.USERS_PAGE_ACCESS,
  },
  {
    id: 17,
    path: '/product',
    main: CreateProduct,
    pageAccessPermission: PermissionsEnum.DEAL_MANAGEMENT_PAGE_ACCESS,
  },
];

const publicAllowRoutes = [
  {
    id: 14,
    path: '/accessdenied',
    main: AccessDenied,
  },
  {
    id: 18,
    path: '/campaign-plan/:token',
    main: PublicCampaignPlan,
  },
];

export const publicRoutes = publicAllowRoutes;

export default getRoutes;
