import { useDispatch, useSelector } from 'react-redux';

import { Label } from 'lib/Label';
import { CustomBaseSelectProps } from 'lib/BaseSelect/BaseSelect.types';
import { useDealFormReadOnly } from 'customHooks/useDealFormReadOnly';
import { changeDealInfo } from 'store/dealManagement/actions';
import { FilterProductCategory } from 'components/common/FilterArea/Filters';
import useIsReadOnly from 'components/pages/Planner/useIsReadOnly';
import { Store } from 'components/common/types/Store.types';
import { DealStatus } from 'components/common/types/Deal.types';
import { InputTheme } from 'components/common/types/Input.types';
import { SOURCE_SYSTEM } from 'consts/sourceSystem';

interface SelectProductCategoryProps
  extends Pick<CustomBaseSelectProps, 'theme' | 'size' | 'padding' | 'shadow' | 'shape'> {
  dataTestId?: string;
  withLabel?: boolean;
}

export const SelectProductCategory: React.FC<SelectProductCategoryProps> = ({
  dataTestId,
  theme,
  size,
  padding,
  shadow,
  shape,
  withLabel = false,
}) => {
  const meta = useSelector((state: Store) => state.dealManagement.meta);
  const bookingStatusCode = useSelector((state: Store) => state.dealManagement.commonDeal.bookingStatusCode);
  const productCategory = useSelector((state: Store) => state.dealManagement.commonDeal.productCategory);
  const sourceSystem = useSelector((state: Store) => state.dealManagement.commonDeal.sourceSystem);

  const dispatch = useDispatch();
  const readOnly = useDealFormReadOnly();
  const { isCampaignReadOnly } = useIsReadOnly();
  const isPendingReservation = bookingStatusCode === DealStatus.PENDING_RESERVATION;

  return (
    <>
      {withLabel ? (
        <Label
          id="product-category"
          theme={InputTheme.FLAT_GRAY}
          label="Product category"
          isRequired={sourceSystem !== SOURCE_SYSTEM.DV360}
        />
      ) : null}
      <FilterProductCategory
        dataTestId={dataTestId}
        options={meta.productCategoryGroups
          .map((productCategoryGroup) => productCategoryGroup.productCategories!)
          .flat()}
        selectedValue={{ code: productCategory?.code || '', name: productCategory?.name || '' }}
        onSelect={(dropdownName, value) => dispatch(changeDealInfo({ [dropdownName]: value }))}
        isDisabled={(readOnly && !isPendingReservation) || isCampaignReadOnly}
        theme={theme}
        size={size}
        padding={padding}
        shadow={shadow}
        shape={shape}
      />
    </>
  );
};
