import Accordion from 'lib/Accordion';
import slidersSVG from 'assets/icons/sliders.svg';
import LineCPMSwitch from './LineCPMSwitch';
import BidsSubmissionSwitch from './BidsSubmissionSwitch';
import DspSyncSwitch from './DspSyncSwitch';
import { OpenMarketplaceSwitch } from './OpenMarketplaceSwitch/OpenMarketplaceSwitch';

interface CampaignSettingsProps {
  showLineCPMSwitch?: boolean;
}

const CampaignSettings: React.FC<CampaignSettingsProps> = ({ showLineCPMSwitch = false }) => {
  return (
    <div className="mx-6">
      <Accordion
        dataTestId="campaign-settings"
        label="Campaign settings"
        icon={slidersSVG}
        labelClassNames="text-essential-tertiary body-sm"
      >
        <div className="grid grid-cols-1">
          <DspSyncSwitch />
          <BidsSubmissionSwitch />
          {showLineCPMSwitch ? <LineCPMSwitch /> : null}
          <OpenMarketplaceSwitch />
        </div>
      </Accordion>
    </div>
  );
};

export default CampaignSettings;
