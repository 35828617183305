import Overlay from 'lib/Overlay';
import useCampaignType from 'components/pages/Planner/hooks/useCampaignType';
import { useSelector } from 'react-redux';
import { Store } from 'components/common/types/Store.types';
import { useMemo } from 'react';
import { isDirectSalesCampaignCloned } from 'components/pages/Planner/hooks/utils/isDirectSalesCampaignCloned';
import { DirectSalesLine } from 'components/common/types/DirectSalesCampaign.types';
import { AllocationReportModalProps } from './AllocationReportModal.types';
import { ToggleAllocationReportView } from './ToggleAllocationReportView';
import AllocationReport from '../AllocationReport';
import { ReportType, SelectedLine } from '../AllocationReport.types';

// eslint-disable-next-line sonarjs/cognitive-complexity
const AllocationReportModal: React.FC<AllocationReportModalProps> = ({ onClose, defaultReportType }) => {
  const { isProgrammaticCampaignType, isDirectSalesCampaignType } = useCampaignType();

  const dealId = useSelector((state: Store) => state.dealManagement.commonDeal.dealId);
  const currentLineId = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.lineId);
  const currentLineName = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.name);
  const currentLineStatus = useSelector(
    (state: Store) => state.dealManagement.commonDeal.currentLine.bookingStatusCode,
  );
  const proposalCampaignId = useSelector((state: Store) => state.dealManagement.temporaryDealId);
  const backupLines = useSelector((state: Store) => state.dealManagement.backupFormData.lines);

  const defaultCurrentLine: SelectedLine = {
    lineDefaultReportType:
      isDirectSalesCampaignType && isDirectSalesCampaignCloned(dealId) ? ReportType.SAVED : ReportType.NOT_SAVED,
    lineId: currentLineId,
    lineName: currentLineName,
    lineStatus: currentLineStatus,
  };

  const dealLines: SelectedLine[] = useMemo(() => {
    if (!backupLines.length) {
      return [defaultCurrentLine];
    }

    const lines: SelectedLine[] = backupLines.map(({ name, lineId, bookingStatusCode, state }: DirectSalesLine) => {
      let lineDefaultReportType;
      let lineStatus;

      if (isDirectSalesCampaignType) {
        lineDefaultReportType = isDirectSalesCampaignCloned(dealId) ? ReportType.NOT_SAVED : ReportType.SAVED;
        lineStatus = state?.status;
      } else {
        lineDefaultReportType =
          lineId === currentLineId && proposalCampaignId && defaultReportType === ReportType.NOT_SAVED
            ? ReportType.NOT_SAVED
            : ReportType.SAVED;
        lineStatus = bookingStatusCode;
      }

      return {
        lineDefaultReportType,
        lineId,
        lineName: name,
        lineStatus,
      };
    });

    if (!currentLineId) {
      lines.push(defaultCurrentLine);
    }

    return lines;
  }, [backupLines, currentLineId, proposalCampaignId]);

  const defaultSelectedLine: SelectedLine =
    dealLines.find(({ lineId }) => lineId === currentLineId) || defaultCurrentLine;

  return (
    <Overlay
      header={
        <header className="w-full flex justify-between items-center pr-6">
          <h3 className="sub-header-lg">Allocation report</h3>
          {isProgrammaticCampaignType && <ToggleAllocationReportView />}
        </header>
      }
      onClose={onClose}
      isOpen
    >
      <AllocationReport dealLines={dealLines} defaultSelectedLine={defaultSelectedLine} />
    </Overlay>
  );
};
export default AllocationReportModal;
