import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PlannerSecondaryPanelType } from 'store/dealManagement/reducer';
import { ProductTargets as ProductTargetsType } from 'store/productManagement/reducer.types';
import { Store } from 'components/common/types/Store.types';
import { changeNewProductTargets, hideSecondaryPanel } from 'store/productManagement/reducer';
import ProductTargetsPanel from './ProductTargetsPanel';
import { ProductTargetsButton } from './ProductTargetsButton';

export const ProductTargets: React.FC = () => {
  const dispatch = useDispatch();
  const secondaryPanelType = useSelector((state: Store) => state.productManagement.secondaryPanel);

  const isProductTargetsOpen = secondaryPanelType === PlannerSecondaryPanelType.TARGETS;

  const handleSubmit = (targets: ProductTargetsType): void => {
    dispatch(changeNewProductTargets(targets));
    dispatch(hideSecondaryPanel());
  };

  return (
    <>
      <ProductTargetsButton />
      {isProductTargetsOpen && <ProductTargetsPanel onConfirm={handleSubmit} />}
    </>
  );
};
