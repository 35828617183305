import Input from 'lib/Input';
import { UsePageFilters } from 'store/pagesFilters/types';
import closeIcon from 'assets/icons/close.svg';
import IconButton from 'lib/IconButton';
import { Theme } from 'lib/IconButton/IconButton.types';
import { useState } from 'react';

type DynamicStringRecord = { [keyName: string]: string };

type FilterTextInputProps = {
  changeFilters: UsePageFilters<DynamicStringRecord>['changeFilters'];
  keyName: keyof DynamicStringRecord;
  value?: string;
  name: string;
  placeholderText: string;
};

const FilterTextInput: React.FC<FilterTextInputProps> = ({ changeFilters, keyName, value, name, placeholderText }) => {
  const [currentValue, setCurrentValue] = useState(value);

  const handleChange = (newValue?: string | number): void => {
    setCurrentValue(newValue?.toString());
    changeFilters({ [keyName]: newValue?.toString() });
  };

  return (
    <Input
      name={name}
      value={currentValue}
      placeholder={placeholderText}
      onChange={(newValue) => handleChange(newValue)}
      debounceTime={1000}
      endComponent={
        currentValue ? (
          <IconButton theme={Theme.TRANSPARENT} name="Clear" icon={closeIcon} handleOnClick={() => handleChange('')} />
        ) : null
      }
    />
  );
};

export default FilterTextInput;
