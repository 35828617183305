import { DirectSalesLine } from 'components/common/types/DirectSalesCampaign.types';
import { addDays, compareAsc, differenceInDays } from 'date-fns';

export const updateDatesForDirectSalesLines = (
  lines: Partial<DirectSalesLine>[],
  newCampaignStartDate: Date,
): Partial<DirectSalesLine>[] => {
  newCampaignStartDate.setHours(0, 0, 0, 0);

  const earliestLineStartDate = lines
    .map((line) => new Date(line.startDate ?? ''))
    .sort((dateA: Date, dateB: Date) => compareAsc(dateA, dateB))[0];

  return lines.map((line) => {
    const { startDate, endDate } = line;
    const lineStartDate = new Date(startDate ?? '');
    const lineEndDate = new Date(endDate ?? '');

    const newLineStartDate = addDays(newCampaignStartDate, differenceInDays(lineStartDate, earliestLineStartDate));
    const newLineEndDate = addDays(newLineStartDate, differenceInDays(lineEndDate, lineStartDate));

    return { ...line, startDate: newLineStartDate, endDate: newLineEndDate };
  });
};
