import { isEqual, sortBy } from 'lodash';
import { CodeNameModel } from 'components/common/types';
import { NetworkCriteriaType } from 'components/common/types/DirectSalesNetworks.types';
import { UseNetworkTarget } from 'components/common/types/UseNetworkTarget.types';

const isSelectedAndSavedOptionsEqual = (
  selectedOptions: NetworkCriteriaType[],
  savedNetworks: NetworkCriteriaType[],
): boolean => {
  const sortedArray1 = sortBy(selectedOptions, 'code');
  const sortedArray2 = sortBy(savedNetworks, 'code');
  return isEqual(sortedArray1, sortedArray2);
};

const useNetworkTarget = ({
  selectedOptions,
  setSelectedOptions,
  savedNetworks,
}: {
  selectedOptions: NetworkCriteriaType[];
  setSelectedOptions: (options: NetworkCriteriaType[]) => void;
  savedNetworks: NetworkCriteriaType[];
}): UseNetworkTarget => {
  const isChange = !isSelectedAndSavedOptionsEqual(selectedOptions, savedNetworks);
  const isChangeValid = selectedOptions.every(({ target }) => !!target);
  const isToleranceError = selectedOptions.some(
    ({ greenTolerance, orangeTolerance }) => greenTolerance > orangeTolerance,
  );

  const updateCurrentItemInputValues = ({
    networkCode,
    networkName,
    updatedValue,
  }: {
    networkCode: string;
    networkName: string;
    updatedValue: Partial<NetworkCriteriaType>;
  }): void => {
    const foundItemIndex = selectedOptions.findIndex((network) => network.code === networkCode);

    let updatedOptions: NetworkCriteriaType[];

    if (foundItemIndex === -1) {
      const newItem: NetworkCriteriaType = {
        code: networkCode,
        name: networkName,
        include: true,
        category: updatedValue.category ?? undefined,
        target: '1',
        priority: { code: '1', name: '1' },
        greenTolerance: 0,
        orangeTolerance: 0,
        allocated: undefined,
        status: undefined,
        available: updatedValue.available ?? 0,
      };

      updatedOptions = [...selectedOptions, newItem];
    } else {
      const existingItem = selectedOptions[foundItemIndex];

      if (updatedValue.include === false) {
        updatedOptions = selectedOptions.filter((network) => network.code !== networkCode);
      } else {
        const updatedItem = {
          ...existingItem,
          ...updatedValue,
        };

        updatedOptions = [...selectedOptions];
        updatedOptions[foundItemIndex] = updatedItem;
      }
    }
    setSelectedOptions(updatedOptions);
  };

  return {
    priority: { code: '', name: '' },
    greenTolerance: 0,
    orangeTolerance: 0,
    handleValueChange: (value: number, option: NetworkCriteriaType) =>
      updateCurrentItemInputValues({
        networkCode: option.code,
        networkName: option.name,
        updatedValue: { target: value?.toString() ?? '' },
      }),
    handlePriorityChange: (value: CodeNameModel, option: NetworkCriteriaType) =>
      updateCurrentItemInputValues({
        networkCode: option.code,
        networkName: option.name,
        updatedValue: { priority: value },
      }),
    handleGreenToleranceChange: (value: number, option: NetworkCriteriaType) =>
      updateCurrentItemInputValues({
        networkCode: option.code,
        networkName: option.name,
        updatedValue: { greenTolerance: value },
      }),
    handleOrangeToleranceChange: (value: number, option: NetworkCriteriaType) =>
      updateCurrentItemInputValues({
        networkCode: option.code,
        networkName: option.name,
        updatedValue: { orangeTolerance: value },
      }),
    handleSelectionToggle: (option: NetworkCriteriaType, category: string, isSelected: boolean) =>
      updateCurrentItemInputValues({
        networkCode: option.code,
        networkName: option.name,
        updatedValue: { category, include: !isSelected, available: option.available },
      }),
    isToleranceError,
    isChange,
    isChangeValid,
    selectedOptions,
  };
};

export default useNetworkTarget;
