import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeProgrammaticDealInfo } from 'store/dealManagement/actions';
import { Store } from 'components/common/types/Store.types';
import { DealStatus } from 'components/common/types/Deal.types';
import { useDspSettings } from 'customHooks/useDspSettings';

interface UseOpenMarketplace {
  handleToggle: () => void;
  isToggleDisabled: boolean;
  isOpenMarketplaceEnabled: boolean;
  isSelectedDspSupportingOpenMarketplace: boolean;
}

export const useOpenMarketplace = (): UseOpenMarketplace => {
  const dispatch = useDispatch();
  const isNewDeal = useSelector((state: Store) => state.dealManagement.isNewDeal);
  const isOpenMarketplaceEnabled = useSelector(
    (state: Store) => state.dealManagement.programmatic.enableOpenMarketplace,
  );
  const bookingStatusCode = useSelector((state: Store) => state.dealManagement.commonDeal.bookingStatusCode);
  const { isSelectedDspSupportingOpenMarketplace } = useDspSettings();

  const handleToggle = async (): Promise<void> =>
    dispatch(
      changeProgrammaticDealInfo({
        enableOpenMarketplace: !isOpenMarketplaceEnabled,
      }),
    );

  const isToggleDisabled = useMemo(() => {
    return !(isNewDeal || [DealStatus.PENDING_APPROVAL].includes(bookingStatusCode));
  }, [isNewDeal, bookingStatusCode]);

  return {
    handleToggle,
    isToggleDisabled,
    isOpenMarketplaceEnabled,
    isSelectedDspSupportingOpenMarketplace,
  };
};
