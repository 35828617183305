import { DIRECT_SALES_PRICING_MODES, DirectSalesPricingMode } from 'components/common/types/DirectSalesCampaign.types';
import SelectOption from 'lib/SelectOption';
import { PricingModeOptionsProps } from './PricingModeOptions.types';

const PricingModeOptions: React.FC<PricingModeOptionsProps> = ({
  newPricingMode,
  isNetworkPricingMode,
  setNewPricingMode,
}) => {
  return (
    <>
      {Object.entries(DIRECT_SALES_PRICING_MODES).map(([key, label]: [DirectSalesPricingMode, string]) => {
        const isSelected = newPricingMode === key;

        return (
          <SelectOption
            isSelected={isSelected}
            onClick={() => setNewPricingMode(key)}
            value={{
              code: key,
              name: label,
            }}
            dataTestId={`select-option-${key}`}
            key={key}
            isDisabled={
              (key === DirectSalesPricingMode.NETWORK && !isNetworkPricingMode) ||
              (key !== DirectSalesPricingMode.NETWORK && isNetworkPricingMode)
            }
          >
            {label}
          </SelectOption>
        );
      })}
    </>
  );
};

export default PricingModeOptions;
