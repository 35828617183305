import { useSelector } from 'react-redux';
import {
  DirectSalesLockStatusOption,
  DirectSalesState,
  DirectSalesStatusOption,
  DirectSalesTransientDealLineState,
} from 'components/common/types/DirectSalesCampaign.types';
import { Store } from 'components/common/types/Store.types';
import { formatDate } from 'modules/I18N';
import { isValid } from 'date-fns';
import { getNoonTimeFromDate } from 'utils/dateFormatUtil';
import { STATUS_SETTINGS } from 'components/pages/Planner/PlannerLineActions/DirectSalesPlannerLineActions/Status/StatusPanel/StatusPanel.types';
import { getIsDirectSalesSessionLockedAndReadOnly } from 'store/dealManagement/selectors';
import { Color } from 'lib/Chip/Chip.types';
import { isEmpty } from 'lodash';

export const getIsDraftStatus = (currentStatus: DirectSalesStatusOption): boolean => currentStatus.includes('DRAFT');

export const getIsLineLockedAndCurrentUserSession = (): boolean => {
  const { isSessionLockedByCurrentUser } = useSelector(getIsDirectSalesSessionLockedAndReadOnly);
  const lockStatus = useSelector((state: Store) => state.dealManagement.directSales.lockState.status);

  const isLineLocked = !!lockStatus && lockStatus === DirectSalesLockStatusOption.LOCKED;

  return isLineLocked && isSessionLockedByCurrentUser;
};

export const isCancelCampaign = (groupedStates: Record<string, DirectSalesState>, lineId: string): boolean => {
  if (isEmpty(groupedStates) || !lineId) {
    return false;
  }

  const { [lineId]: currentLineState, ...linesExcludingCurrent } = groupedStates;

  return Object.values(linesExcludingCurrent).every(
    (line) => line.status === DirectSalesStatusOption.CANCELLED || line.status === DirectSalesStatusOption.DELETED,
  );
};

export const getStatusDisplayValues = (
  lineState: DirectSalesState,
  lineId: string,
  transientStates?: DirectSalesTransientDealLineState,
): {
  statusText: string;
  statusStyle: Color;
  statusSubLabel?: string;
} => {
  const lineTransientState = transientStates?.[lineId]?.status;
  const expires = transientStates?.[lineId]?.expires;
  const lineTransientExpires = expires ? new Date(expires) : undefined;

  const isOptionStatus =
    lineTransientState === DirectSalesStatusOption.OPTION || lineState.status === DirectSalesStatusOption.OPTION;

  const isValidOptionStatus = isOptionStatus && isValid(lineTransientExpires || lineState.expires);

  const statusKey = lineTransientState || lineState.status;
  const { text: statusText, style: statusStyle } = STATUS_SETTINGS[statusKey];

  let statusSubLabel: string | undefined;
  if (isValidOptionStatus) {
    const expiryDate = (lineTransientExpires as Date) || (lineState.expires as Date);
    statusSubLabel = `${formatDate(expiryDate)} ${getNoonTimeFromDate(expiryDate)}`;
  }

  return {
    statusText,
    statusStyle,
    statusSubLabel,
  };
};

export const isValidStatusChange = (status: DirectSalesStatusOption, expires: Date | string): boolean => {
  if (status === DirectSalesStatusOption.OPTION && !expires) return false;

  if (getIsDraftStatus(status)) return false;

  return true;
};
