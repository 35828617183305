import { DirectSalesMediaType } from 'components/common/types/DirectSalesCampaign.types';
import { UseReferenceDataProps } from 'components/pages/Planner/hooks/UseReferenceData.types';

export const getDefaultMandatoryData = (): UseReferenceDataProps => {
  // TODO: Remove once VC have updated to endpoint to not expect mandatory data
  const environments = [
    {
      code: 'CH_002',
      name: 'Mall',
      audienceCategoryGroupCode: '',
      dayPartGroupCode: '',
    },
    {
      code: 'CH_003',
      name: 'Large Format',
      dayPartGroupCode: '1',
      audienceCategoryGroupCode: 'Test_digital',
    },
  ];
  const mediaType = DirectSalesMediaType.DIGITAL;
  const startDate = new Date('1/1/2026');
  const endDate = new Date('6/1/2026');

  return {
    environments,
    mediaType,
    startDate,
    endDate,
  };
};
