import cx from 'classnames';
import { PropsWithChildren } from 'react';
import { StatusOptionProps } from './StatusOption.types';

const StatusOption: React.FC<PropsWithChildren<StatusOptionProps>> = ({ onClick, isSelected, text, children }) => {
  return (
    <div
      role="button"
      onClick={onClick}
      onKeyDown={onClick}
      tabIndex={0}
      className={cx('h-9 px-2 flex justify-between items-center body-base cursor-pointer', {
        'bg-primary-100': isSelected,
        'bg-transparent hover:bg-neutral-100': !isSelected,
      })}
    >
      <p>{text}</p>
      {children}
    </div>
  );
};

export default StatusOption;
