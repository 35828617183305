import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import cx from 'classnames';
import plusSvg from 'assets/icons/planner_plus.svg';
import minusSvg from 'assets/icons/planner_minus.svg';

import { AccordionProps, BorderStyle } from './Accordion.types';

const Accordion: React.FC<PropsWithChildren<AccordionProps>> = ({
  children,
  dataTestId,
  label,
  onOpen,
  onClose,
  border = BorderStyle.NONE,
  isDisabled = false,
  isBorderHiddenWhenOpen = false,
  isOpenOnInit = false,
  isExternalOpen,
  icon,
  extendedChildren,
  labelClassNames,
  toggleIconPosition = 'right',
  wrapperClassNames,
  toggleIconClassNames,
}) => {
  const [isOpen, setIsOpen] = useState(isOpenOnInit);

  useEffect(() => {
    setIsOpen(isOpenOnInit);
  }, [isOpenOnInit]);

  const handleClick = useCallback(() => {
    if (isExternalOpen ?? isOpen) {
      onClose?.();
    } else {
      onOpen?.();
    }

    setIsOpen(!isOpen);
  }, [isOpen, isExternalOpen]);

  const borderStyle = isBorderHiddenWhenOpen && isOpen ? BorderStyle.NONE : border;

  const toggleIcon = (
    <SVG
      className={toggleIconClassNames ?? cx('w-4 h-4 text-neutral-800 font-light')}
      src={(isExternalOpen ?? isOpen) ? minusSvg : plusSvg}
    />
  );

  return (
    <div className={`${borderStyle}`} data-test-id={dataTestId}>
      <button
        type="button"
        onClick={handleClick}
        disabled={isDisabled}
        className={wrapperClassNames ?? cx('w-full py-4 flex justify-between items-center sub-header-base')}
      >
        {toggleIconPosition === 'left' && toggleIcon}
        <div className={cx('flex gap-1 items-center', labelClassNames)}>
          {!!icon && <SVG className="w-4 h-4" src={icon} />}
          {label}
        </div>
        {extendedChildren}
        {toggleIconPosition === 'right' && toggleIcon}
      </button>
      {(isExternalOpen ?? isOpen) && children}
    </div>
  );
};

export default Accordion;
