import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import SVG from 'react-inlinesvg';
import chevronDown from 'assets/icons/chevron_down.svg';
import { useToggle } from 'customHooks/useToggle';
import Button, { Color } from 'lib/Button';
import { SortDir } from 'components/common/types/SortDir.types';
import { CollapsibleListProps, SortSettings } from './CollapsibleList.types';

const CollapsibleList: React.FC<PropsWithChildren<CollapsibleListProps>> = ({
  label,
  children,
  headerComponent,
  onSort,
  onClearAll,
  onToggle,
  isSortVisible,
  isClearAllVisible,
  icon = chevronDown,
  sortDir = SortDir.NONE,
  isOpen = false,
}) => {
  const ref = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [containerHeight, setContainerHeight] = useState(0);

  const { isOpen: isToggledOpen, toggle } = useToggle(isOpen);

  useEffect(() => {
    if (isOpen !== isToggledOpen) {
      toggle();
    }
  }, [isOpen]);

  useEffect(() => {
    const observer = new MutationObserver(() => {
      if (ref.current) {
        const height = ref.current.offsetHeight;
        setContainerHeight(height);
      }
    });

    if (ref.current) {
      observer.observe(ref.current, { childList: true, subtree: true });
    }

    return () => observer.disconnect();
  }, []);

  const onSortClick = (): void => {
    if (!onSort) return;

    const { nextDir } = SortSettings[sortDir];

    onSort(nextDir);
  };

  const handleToggle = (): void => {
    toggle();
    onToggle?.();
  };

  return (
    <>
      <div className="flex justify-between w-full items-center">
        <p
          data-test-id="collapsible-list-label"
          className={cx('sub-header-base truncate', {
            'text-neutral-900': !isToggledOpen,
            'text-primary-700': isToggledOpen,
          })}
        >
          {label}
        </p>
        {headerComponent}
        <div>
          <Button
            onClick={handleToggle}
            dataTestId="collapsible-list-header-button"
            svg={icon}
            color={Color.TRANSPARENT}
            classNames={cx('hover:bg-transparent active:bg-transparent')}
            svgClassnames={cx('transition-all duration-300', {
              'text-neutral-900 rotate-0': !isToggledOpen,
              'text-primary-600 rotate-180': isToggledOpen,
            })}
          />
        </div>
      </div>
      <div
        className={cx('transition-all duration-300 w-full overflow-y-hidden', {
          'mt-2': isToggledOpen,
        })}
        data-test-id={`collapse-container-${isToggledOpen ? 'expanded' : 'collapsed'}`}
        style={{
          height: `${isToggledOpen ? containerHeight : 0}px`,
        }}
      >
        <div ref={ref}>
          <div className="flex justify-between" ref={ref}>
            {isSortVisible ? (
              <button
                data-test-id="collapsible-list-sort"
                className="flex items-center gap-x-2 body-sm text-essential-tertiary"
                type="button"
                onClick={onSortClick}
              >
                Alphabetical <SVG src={SortSettings[sortDir].icon} className="w-3 h-3" />
              </button>
            ) : null}
            {isClearAllVisible ? <Button color={Color.TRANSPARENT} label="Clear all" onClick={onClearAll} /> : null}
          </div>
          {children}
        </div>
      </div>
    </>
  );
};

export default CollapsibleList;
