import cx from 'classnames';
import React from 'react';
import { CodeNameModel } from 'components/common/types';
import BaseSelect from 'lib/BaseSelect';
import { Padding } from 'lib/BaseSelect/BaseSelect.types';
import { PRIORITY_OPTIONS, PrioritySelectProps } from './PrioritySelect.types';

const PrioritySelect: React.FC<PrioritySelectProps> = ({
  selectedValue,
  onChange,
  name = 'priority-select',
  menuPlacement = 'auto',
}) => {
  return (
    <div className="relative w-full flex justify-between">
      <div className="relative flex gap-x-2 py-2 items-center">
        <p className="body-sm text-secondary">Set priority</p>
        <div
          className={cx({
            'w-16': !selectedValue,
          })}
        >
          <BaseSelect
            name={name}
            dataTestId={name}
            selectedValue={selectedValue}
            options={PRIORITY_OPTIONS}
            onSelect={(_, value: CodeNameModel) => onChange(value)}
            menuPlacement={menuPlacement}
            isPlaceholderVisible={false}
            padding={Padding.NONE}
            maxMenuHeight={150}
          />
        </div>
      </div>
    </div>
  );
};

export default PrioritySelect;
