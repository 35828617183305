import cx from 'classnames';
import { CircularLoadingSpinner } from 'lib/CircularLoadingSpinner/CircularLoadingSpinner';
import AllocationReport from 'components/common/AllocationReport';
import { CampaignPlanErrorTitle } from 'components/common/types/CampaignPlan.types';
import PublicCampaignPlanContent from './PublicCampaignPlanContent';
import { usePublicCampaignPlan } from './usePublicCampaignPlan';
import { Header } from './Header/Header';
import { Footer } from './Footer/Footer';
import { LoadError } from './LoadError';

const PublicCampaignPlan: React.FC = () => {
  const { allocationReportDealLines, campaignPlanDetails, campaignPlanError, campaignStatus, isLoading, token } =
    usePublicCampaignPlan();

  const isRefreshVisible = campaignPlanError?.title !== CampaignPlanErrorTitle.UNAUTHORIZED;

  return (
    <div className="h-screen flex flex-col justify-between overflow-y-auto bg-white">
      <Header />
      <main
        role="main"
        className={cx('py-6', {
          'h-screen flex items-center justify-center': campaignPlanError,
          'px-7 md:px-14 flex-1': !campaignPlanError,
        })}
      >
        {campaignPlanError ? (
          <LoadError
            message={campaignPlanError?.detail}
            isRefreshVisible={isRefreshVisible}
            onRefreshClick={() => window.location.reload()}
          />
        ) : (
          <>
            {isLoading && <CircularLoadingSpinner />}
            {!isLoading && campaignPlanDetails && (
              <div className="max-w-7xl mx-auto md:px-7">
                <PublicCampaignPlanContent campaignPlanDetails={campaignPlanDetails} campaignStatus={campaignStatus} />
                <div className="p-4 border rounded-lg bg-neutral-100">
                  <div className="header-base mb-4">Allocation report</div>
                  <div className="h-screen">
                    <AllocationReport
                      dealLines={allocationReportDealLines}
                      defaultSelectedLine={allocationReportDealLines[0]}
                      publicToken={token}
                      publicLocaleCode={campaignPlanDetails.metaData.isoCountryCode}
                    />
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </main>
      <Footer />
    </div>
  );
};

export default PublicCampaignPlan;
