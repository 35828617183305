import { cloneDeep, filter, orderBy, size } from 'lodash';
import { CodeNameModel } from 'components/common/types';
import { SortDir } from 'components/common/types/SortDir.types';
import { FormatLabel, LocationLabel } from 'store/userOptions/reducer.types';

export const FilterLabels = { ...FormatLabel, ...LocationLabel };

export const filterOptions = (
  options: Record<string, CodeNameModel[]>,
  searchText: string = '',
): Record<string, CodeNameModel[]> => {
  if (!size(options) || searchText.length < 3) return options;

  const lowercaseSearchText = searchText.toLowerCase();

  const clonedOptions = cloneDeep(options);

  Object.keys(clonedOptions).forEach((clonedOption) => {
    clonedOptions[clonedOption] = filter(clonedOptions[clonedOption], (value) => {
      const lowercaseValueName = value.name.toLowerCase();
      return lowercaseValueName.includes(lowercaseSearchText);
    });

    if (!clonedOptions[clonedOption].length) delete clonedOptions[clonedOption];
  });

  return clonedOptions;
};

export const sortOptions = (
  options: Record<string, CodeNameModel[]>,
  sortDir = SortDir.NONE,
): Record<string, CodeNameModel[]> => {
  if (sortDir === SortDir.NONE) return options;

  const clonedOptions = cloneDeep(options);

  Object.keys(clonedOptions).forEach((clonedOption) => {
    clonedOptions[clonedOption] = orderBy(clonedOptions[clonedOption], ['name'], [sortDir]);
  });

  return clonedOptions;
};

export const hasAtLeastOneOptionResult = (options: Record<string, CodeNameModel[]>): boolean => {
  return Object.keys(options).some((option: string) => {
    const value = options[option];
    return Array.isArray(value) && value.length > 0;
  });
};

export const canDisplayOptionResults = (options?: Record<string, CodeNameModel[]>): boolean => {
  if (!options) {
    return false;
  }

  if (!hasAtLeastOneOptionResult(options)) {
    return false;
  }

  return true;
};

const updateIncludeExclude = (options: CodeNameModel[], value: CodeNameModel): CodeNameModel[] => {
  return options.map((option) =>
    option.code === value.code && option.category === value.category ? { ...option, include: value.include } : option,
  );
};

const removeOptionByCodeCategory = (options: CodeNameModel[], code: string, category?: string): CodeNameModel[] => {
  return options.filter((option) => !(option.code === code && option.category === category));
};

export const updateSelectedOptions = (options: CodeNameModel[], value: CodeNameModel): CodeNameModel[] => {
  const optionExists = options.find(({ code, category }) => code === value.code && category === value.category);

  if (optionExists) {
    if (value.include !== undefined) {
      return updateIncludeExclude(options, value);
    }

    return removeOptionByCodeCategory(options, value.code, value.category);
  }

  return [...options, value];
};

export const isCategoryEqual = (baseCategory: string, selectedCategory?: string): boolean => {
  if (baseCategory in FilterLabels) return FilterLabels[baseCategory as keyof typeof FilterLabels] === selectedCategory;

  return baseCategory === selectedCategory;
};
