import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import PrivateRoute from 'components/common/Auth/Private';
import { PageWrapper } from 'components/common/layout/PageWrapper/PageWrapper';
import { ChildRoute } from 'components/common/types/Route.types';
import DealsHeader from 'components/pages/Deals/DealsHeader';
import { FeatureFlags } from 'components/common/types/Features.types';
import { useHasFeatureAccess } from 'customHooks/useHasFeatureAccess';
import ProgrammaticDeals from './ProgrammaticDeals';
import DirectSalesDeals from './DirectSalesDeals';
import Products from '../PCM/Products';

const Deals: React.FC = () => {
  const hasDirectSalesAccess = useHasFeatureAccess(FeatureFlags.DIRECT_SALES_PLANNER);

  const childRoutes: ChildRoute[] = [
    {
      id: 1,
      path: `programmatic`,
      main: ProgrammaticDeals,
      pageAccessPermission: undefined,
    },
    ...(hasDirectSalesAccess
      ? [
          {
            id: 2,
            path: `direct-sales`,
            main: DirectSalesDeals,
            pageAccessPermission: undefined,
          },
          {
            id: 3,
            path: `product-management`,
            main: () => <Products />,
            pageAccessPermission: undefined,
          },
        ]
      : []),
    {
      id: 4,
      path: '*',
      main: () => <Navigate to="programmatic" />,
      pageAccessPermission: undefined,
    },
  ];

  return (
    <PageWrapper>
      <DealsHeader />
      <Routes>
        {childRoutes.map((route) => (
          <Route
            key={route.id}
            path={route.path}
            element={<PrivateRoute component={route.main} pageAccessPermission={route.pageAccessPermission} />}
          />
        ))}
      </Routes>
    </PageWrapper>
  );
};

export default Deals;
