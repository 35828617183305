import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import frameSvg from 'assets/icons/frame.svg';
import { Store } from 'components/common/types/Store.types';
import { CodeNameModel } from 'components/common/types';
import { Environment } from 'components/common/types/Deal.types';
import SectionButton from 'lib/SectionButton';
import { SectionButtonTheme } from 'lib/SectionButton/SectionButton.types';
import { PlannerSecondaryPanelType } from 'store/dealManagement/reducer';
import { ProductFieldName } from 'store/productManagement/reducer.types';
import { changeNewProduct, clearItemInNewProduct, hideSecondaryPanel } from 'store/productManagement/reducer';
import { useOnChangePanelVisibility } from 'components/pages/PCM/hooks/useOnChangePanelVisibility';
import { getSelectedChannelsDisplayValue } from './utils';
import ProductChannelsPanel from './ProductChannelsPanel';

const fieldName = ProductFieldName.CHANNELS;

const ProductChannels: React.FC = () => {
  const dispatch = useDispatch();
  const secondaryPanelType = useSelector((state: Store) => state.productManagement.secondaryPanel);
  const defaultSelectedPermission = useSelector(
    (state: Store) => state.productManagement.newProduct.channels?.selectionPermission,
  );
  const selectedChannels = useSelector((state: Store) => state.productManagement.newProduct.channels?.value);
  const onChangePanelVisibility = useOnChangePanelVisibility();

  const isProductChannelsPanelOpen = secondaryPanelType === PlannerSecondaryPanelType.CHANNEL;

  const handleSubmit = ({
    value,
    selectedPermission,
  }: {
    value: Environment[];
    selectedPermission: CodeNameModel;
  }): void => {
    dispatch(
      changeNewProduct({
        fieldName,
        value,
        selectedPermission,
      }),
    );
    dispatch(hideSecondaryPanel());
  };

  return (
    <>
      <SectionButton
        dataTestId="section-button-channels"
        theme={SectionButtonTheme.SOLID}
        label="Channel"
        onClick={() => onChangePanelVisibility(PlannerSecondaryPanelType.CHANNEL)}
        icon={frameSvg}
        isActive={isProductChannelsPanelOpen}
        selected={getSelectedChannelsDisplayValue(defaultSelectedPermission, selectedChannels)}
        onClear={() => {
          dispatch(clearItemInNewProduct({ fieldName }));
          dispatch(hideSecondaryPanel());
        }}
        isRequired
      />
      {isProductChannelsPanelOpen && <ProductChannelsPanel onConfirm={handleSubmit} />}
    </>
  );
};

export default ProductChannels;
