import DayPicker from 'components/patterns/DayPicker';
import { DATE_FORMAT } from 'modules/api/constants';
import { FilterDateRangeProps } from './FilterDateRange.types';

const FilterDateRange: React.FC<FilterDateRangeProps> = ({
  changeFilters,
  isDisabled,
  placeholderEndDate,
  placeholderStartDate,
  selectedStartDate: startDate,
  selectedEndDate: endDate,
}) => {
  return (
    <div className="flex items-center">
      <div>
        <DayPicker
          dataTestId="start-date-picker"
          onDayChange={(value: Date) => {
            changeFilters({
              startDate: value,
              endDate: endDate && value > endDate ? undefined : endDate,
            });
          }}
          placeholder={placeholderStartDate || 'Search by timeframe'}
          format={DATE_FORMAT}
          selectedDay={startDate}
          isDisabled={isDisabled}
          isClearable
        />
      </div>
      <div className="mx-4 text-neutral-600">to</div>
      <div>
        <DayPicker
          dataTestId="end-date-picker"
          onDayChange={(value: Date) => {
            changeFilters({ endDate: value });
          }}
          placeholder={placeholderEndDate || 'Search by timeframe'}
          format={DATE_FORMAT}
          selectedDay={endDate}
          disabledDays={[{ before: startDate }]}
          isDisabled={isDisabled}
          isClearable
        />
      </div>
    </div>
  );
};

export default FilterDateRange;
