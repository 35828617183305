import { ProductCreationInterface } from '../ProductsTable/ProductsTable.types';

export const defaultAllFoldersProductOption = {
  code: 'All folders',
  name: 'All folders',
};

export const getProductsByFolderName = ({
  folderName,
  products,
}: {
  folderName: string;
  products: ProductCreationInterface[];
}): ProductCreationInterface[] => {
  const normalizedFolderName = folderName.toLowerCase();

  if (normalizedFolderName === defaultAllFoldersProductOption.code.toLowerCase()) {
    return products;
  }

  return products.filter(({ folder }) => folder.toLowerCase() === normalizedFolderName);
};
