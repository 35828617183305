import { groupBy, isEmpty } from 'lodash';
import { DirectSalesCampaignResponse } from 'components/common/types/DirectSalesCampaignResponse.types';
import {
  Advertiser,
  Agency,
  Brand,
  CommonDeal,
  DealType,
  Environment,
  Line,
  RouteFrameCode,
} from 'components/common/types/Deal.types';
import { CodeNameModel, FileList } from 'components/common/types';
import {
  DirectSales,
  DirectSalesAllocationSolutionResponse,
  DirectSalesCampaignDealLevelDefinition,
  DirectSalesCampaignDealLineRequest,
  DirectSalesCampaignDealLineResponse,
  DirectSalesCampaignType,
  DirectSalesExclusionsRequest,
  DirectSalesFilter,
  DirectSalesFilterGroupCriteria,
  DirectSalesFilterItem,
  DirectSalesFilterTypes,
  DirectSalesLine,
  DirectSalesLineObjective,
  DirectSalesLockState,
  DirectSalesMediaType,
  DirectSalesObjectiveTarget,
  DirectSalesPOI,
  DirectSalesPricingMode,
  DirectSalesProductCategoryExclusivityType,
  DirectSalesRequestFormatCriteria,
  DirectSalesState,
  DirectSalesStatusOption,
  DirectSalesSweep,
  DirectSalesTargetCriteria,
  DirectSalesTargetTolerancePriority,
  DirectSalesTransformedDealLineRequest,
} from 'components/common/types/DirectSalesCampaign.types';
import { DirectSalesTargetTolerance } from 'components/common/types/DirectSalesTargetTolerance.types';
import { NetworkCriteriaType } from 'components/common/types/DirectSalesNetworks.types';
import { PatternOption } from 'components/common/DaypartTargeting/DaypartTargeting.types';
import { DirectSalesCampaignRequest } from 'components/common/types/DirectSalesCampaignRequest.types';
import { getDateInRequestFormat } from 'utils/dateUtil';
import { isAllSelectedFullDays } from 'components/common/DaypartTargeting/utils';
import { DEFAULT_CRITERIA } from 'consts/targetCriteria';
import { DEFAULT_OBJECTIVE } from 'consts/objective';
import { DirectSalesExclusionFlag, DirectSalesExclusions } from 'components/common/types/DirectSalesDeal.types';
import {
  TransformedAvailabilityRequest,
  TransformedCampaignLevelAvailabilityRequest,
  TransformedLineLevelAvailabilityRequest,
} from 'components/common/types/Availability';
import { transformUnitListFiltersFromResponse } from './transformUnitListFiltersFromResponse';
import { reverseTransformDaypartTargeting, transformRangePattern } from './transformDirectSalesDaypartRangePattern';
import {
  mapLocationFilter,
  mappedFormatFilterName,
  mapPoiFilter,
  mapTagFilter,
  mergeFilters,
  reverseTransformDealLineFilters,
} from './transformFilters';
import transformDirectSalesLineAllocation from './transformDirectSalesLineAllocation';
import { transformPatternRepeatTypeResponse } from './transformPatternUtils';

export type DirectSalesCampaignWithLineFormData = {
  commonDeal: Partial<CommonDeal>;
  directSales: Partial<DirectSales>;
  currentLine: Partial<Line>;
};

export type TransformedDirectSalesBookingResponse = {
  deal: Partial<CommonDeal>;
  lines: Partial<DirectSalesLine>[];
  sessionLockState: DirectSalesLockState;
};

export const hasFilters = ({
  dealLineFormats,
  locations,
  poi,
  tags,
  channels,
  mediaType,
  listFiles,
  routeFrameCodes,
  networks,
}: {
  dealLineFormats: CodeNameModel[];
  locations: CodeNameModel[];
  poi: DirectSalesPOI[];
  tags: CodeNameModel[];
  channels: Environment[];
  mediaType?: DirectSalesMediaType;
  listFiles?: FileList[];
  routeFrameCodes?: RouteFrameCode[];
  networks: NetworkCriteriaType[];
}): boolean => {
  if (dealLineFormats?.length) return true;

  if (locations?.length) return true;

  if (poi?.length) return true;

  if (tags?.length) return true;

  if (channels?.length) return true;

  if (mediaType) return true;

  if (listFiles?.length) return true;

  if (routeFrameCodes?.length) return true;

  if (networks?.length) return true;

  return false;
};

export const transformDirectSalesDealRequest = ({
  name,
  advertiser,
  brand,
  productCategory,
  agency,
  specialist,
  salesPerson,
  salesTeam,
  adminPerson,
  productCategoryExclusivity,
  exclusions,
  duplicationExclusion,
  juxtapositionExclusion,
  specialistBlockExclusion,
  agencyBlockExclusion,
  advertiserBlockExclusion,
}: {
  name?: string;
  advertiser: Advertiser;
  brand: Brand;
  productCategory: CodeNameModel;
  agency?: Agency;
  specialist?: CodeNameModel;
  salesPerson: CodeNameModel | null;
  salesTeam: CodeNameModel | null;
  adminPerson?: CodeNameModel | null;
  productCategoryExclusivity: DirectSalesProductCategoryExclusivityType;
  duplicationExclusion: DirectSalesExclusionFlag;
  exclusions: DirectSalesExclusions;
  juxtapositionExclusion: DirectSalesExclusionFlag;
  specialistBlockExclusion: DirectSalesExclusionFlag;
  agencyBlockExclusion: DirectSalesExclusionFlag;
  advertiserBlockExclusion: DirectSalesExclusionFlag;
}): DirectSalesExclusionsRequest & DirectSalesCampaignDealLevelDefinition => {
  return {
    name: name || '',
    campaignGroup: DirectSalesCampaignType.STANDARD,
    campaignType: DirectSalesCampaignType.STANDARD,
    advertiserId: advertiser?.code,
    brandId: brand?.code,
    productCategory: productCategory?.code,
    agencyId: agency?.code || '',
    specialistId: specialist?.code || '',
    ...(salesPerson ? { salesPerson } : {}),
    ...(salesTeam ? { salesTeam } : {}),
    ...(adminPerson ? { adminPerson } : {}),
    productCategoryExclusivity,
    exclusions: {
      organisations: exclusions.organisations.map((org) => org.code),
      productCategories: exclusions.productCategories.map((category) => category.code),
    },
    duplicationExclusion,
    juxtapositionExclusion,
    specialistBlockExclusion,
    agencyBlockExclusion,
    advertiserBlockExclusion,
  };
};

const transformTargetCriteriaValue = (
  target: number,
  { greenTolerance, orangeTolerance, priority }: DirectSalesTargetTolerancePriority,
): DirectSalesRequestFormatCriteria => {
  return {
    target,
    greenTolerance: greenTolerance / 100,
    orangeTolerance: orangeTolerance / 100,
    priority: +priority.code,
  };
};

const transformDirectSalesObjectivesTarget = ({
  name,
  value,
  objectiveId,
  greenTolerance,
  orangeTolerance,
  priority: { code },
  objectiveGroupId,
  criteriaType,
  filter,
}: DirectSalesObjectiveTarget & {
  name: string;
  objectiveGroupId: number;
  criteriaType: DirectSalesFilterGroupCriteria;
  filter: DirectSalesFilter;
}): DirectSalesTargetCriteria => ({
  name,
  criteriaType,
  value: {
    target: Number(value),
    greenTolerance: greenTolerance / 100,
    orangeTolerance: orangeTolerance / 100,
    priority: Number(code),
  },
  objectiveId: Number(objectiveId),
  objectiveGroupId,
  filter,
});

const transformObjectivesRequest = (objectives: DirectSalesLineObjective[]): DirectSalesTargetCriteria[] => {
  const targetObjectives: DirectSalesTargetCriteria[] = [];

  objectives.forEach(
    ({
      budget,
      frames,
      impressions,
      name,
      objectiveGroupId,
      locations,
      formats,
      tags,
      poi,
      frameList: { listFiles, routeFrameCodes },
    }) => {
      const filter = mergeFilters({
        dealLineFormats: formats,
        locations,
        poi,
        tags,
        channels: [],
        listFiles,
        routeFrameCodes,
      });

      if (budget.value && budget.objectiveId && objectiveGroupId) {
        const budgetObjective = transformDirectSalesObjectivesTarget({
          ...budget,
          name,
          criteriaType: DirectSalesFilterGroupCriteria.BUDGET_TARGET,
          objectiveGroupId,
          filter,
        });

        targetObjectives.push(budgetObjective);
      }

      if (frames.value && frames.objectiveId && objectiveGroupId) {
        const framesObjective = transformDirectSalesObjectivesTarget({
          ...frames,
          name,
          criteriaType: DirectSalesFilterGroupCriteria.FRAME_TARGET,
          objectiveGroupId,
          filter,
        });

        targetObjectives.push(framesObjective);
      }

      if (impressions.value && impressions.objectiveId && objectiveGroupId) {
        const impressionsObjective = transformDirectSalesObjectivesTarget({
          ...impressions,
          name,
          criteriaType: DirectSalesFilterGroupCriteria.VOLUME_TARGET,
          objectiveGroupId,
          filter,
        });

        targetObjectives.push(impressionsObjective);
      }
    },
  );

  return targetObjectives;
};

export const transformDirectSalesDealLineRequest = ({
  id,
  name,
  startDate,
  endDate,
  floatingRange,
  filter,
  impressions,
  budget,
  frames,
  objectives,
  budgetCriteria,
  framesCriteria,
  impressionsCriteria,
  networkCriteria,
  sot,
  sotObjectiveId,
  sweep,
  selectedDays,
  patternLength,
  patternRepeatType,
  pricingMode,
  productTemplate,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}: DirectSalesCampaignDealLineRequest): DirectSalesTransformedDealLineRequest => {
  const targetCriteria: DirectSalesTargetCriteria[] = [];

  if (budget && budgetCriteria.objectiveId) {
    const budgetTarget: DirectSalesTargetCriteria = {
      objectiveId: budgetCriteria.objectiveId,
      criteriaType: DirectSalesFilterGroupCriteria.BUDGET_TARGET,
      value: transformTargetCriteriaValue(budget, budgetCriteria),
    };

    targetCriteria.push(budgetTarget);
  }

  if (frames && framesCriteria.objectiveId) {
    const frameTarget: DirectSalesTargetCriteria = {
      objectiveId: framesCriteria.objectiveId,
      criteriaType: DirectSalesFilterGroupCriteria.FRAME_TARGET,
      value: transformTargetCriteriaValue(frames, framesCriteria),
    };

    targetCriteria.push(frameTarget);
  }

  if (impressions && impressionsCriteria.objectiveId) {
    const impressionsTarget: DirectSalesTargetCriteria = {
      objectiveId: impressionsCriteria.objectiveId,
      criteriaType: DirectSalesFilterGroupCriteria.VOLUME_TARGET,
      value: transformTargetCriteriaValue(impressions, impressionsCriteria),
    };

    targetCriteria.push(impressionsTarget);
  }

  if (sot && sotObjectiveId) {
    const sotTarget: DirectSalesTargetCriteria = {
      objectiveId: sotObjectiveId,
      criteriaType: DirectSalesFilterGroupCriteria.SOT_TARGET,
      value: transformTargetCriteriaValue(Math.round(sot * 100) / 10000, DEFAULT_CRITERIA),
    };

    targetCriteria.push(sotTarget);
  }

  if (networkCriteria.length) {
    networkCriteria.forEach((criteria) => {
      const networkTarget: DirectSalesTargetCriteria = {
        objectiveId: criteria.objectiveId ?? 0,
        criteriaType: DirectSalesFilterGroupCriteria.NETWORK_TARGET,
        value: {
          target: Number(criteria.target),
          priority: Number(criteria.priority.code),
          networkId: criteria.code,
          greenTolerance: criteria.greenTolerance / 100,
          orangeTolerance: criteria.orangeTolerance / 100,
        },
      };
      targetCriteria.push(networkTarget);
    });
  }

  if (objectives?.length) {
    targetCriteria.push(...transformObjectivesRequest(objectives));
  }

  const dealLine = {
    id,
    name,
    templateId: productTemplate ? productTemplate.id : undefined,
    range: {
      startDate,
      endDate,
      ...(floatingRange && floatingRange.floatingStartDate && floatingRange.floatingEndDate
        ? {
            floatingRange,
          }
        : { floatingRange: null }),
    },
    ...(filter ? { filter } : {}),
    ...(sweep ? { sweep } : { sweep: DirectSalesSweep.NONE }),
    targetCriteria,
    pricingMode: networkCriteria.length >= 1 ? DirectSalesPricingMode.NETWORK : pricingMode,
  };

  const selectedDaysAllFullDays = isAllSelectedFullDays(selectedDays);

  if (!selectedDaysAllFullDays && !isEmpty(selectedDays)) {
    const isRepeated = patternRepeatType !== PatternOption.NO_REPEAT;
    const rangePattern = transformRangePattern(selectedDays, isRepeated, patternLength || 1);

    return {
      ...dealLine,
      rangePattern,
    };
  }

  return dealLine;
};

type TransformedFilterGroupCriteria = {
  [filterGroupCriteria in DirectSalesFilterGroupCriteria]?: DirectSalesTargetTolerance & {
    objectiveId: number;
    priority: CodeNameModel;
    target: number;
  };
};

export const transformTargetCriteria = (
  targetCriteria: DirectSalesTargetCriteria[],
): TransformedFilterGroupCriteria => {
  const criteria: TransformedFilterGroupCriteria = {};

  targetCriteria?.forEach((criterion) => {
    criteria[criterion.criteriaType] = {
      objectiveId: criterion.objectiveId,
      target:
        criterion.criteriaType === DirectSalesFilterGroupCriteria.SOT_TARGET
          ? criterion.value.target * 100
          : criterion.value.target,
      greenTolerance: criterion.value.greenTolerance * 100,
      orangeTolerance: criterion.value.orangeTolerance * 100,
      priority: {
        code: criterion.value.priority?.toString() ?? '',
        name: criterion.value.priority?.toString() ?? '',
      },
    };
  });

  return criteria;
};

export const transformNetworkCriteria = (targetCriteria: DirectSalesTargetCriteria[] = []): NetworkCriteriaType[] => {
  if (!targetCriteria.length) return [];

  const networkCriteria = targetCriteria.filter(
    (criteria) => criteria.criteriaType === DirectSalesFilterGroupCriteria.NETWORK_TARGET,
  );

  return networkCriteria.map((criteria) => {
    return {
      code: criteria.value.networkId ?? '',
      objectiveId: criteria.objectiveId,
      name: '',
      category: '',
      target: criteria.value.target.toString(),
      greenTolerance: criteria.value.greenTolerance * 100,
      orangeTolerance: criteria.value.orangeTolerance * 100,
      priority: {
        code: criteria.value.priority?.toString() || '',
        name: criteria.value.priority?.toString() || '',
      },
      include: true,
    };
  });
};

const transformObjectiveTarget = ({
  objectiveId,
  value: { priority, target, greenTolerance, orangeTolerance },
}: DirectSalesTargetCriteria): DirectSalesObjectiveTarget => {
  return {
    value: target,
    greenTolerance: greenTolerance * 100,
    orangeTolerance: orangeTolerance * 100,
    objectiveId,
    priority: { code: `${priority}`, name: `${priority}` },
  };
};

export const transformObjectivesResponse = (
  objectivesData: DirectSalesTargetCriteria[] = [],
): DirectSalesLineObjective[] => {
  const groupedObjectives = groupBy(objectivesData, 'objectiveGroupId');

  // eslint-disable-next-line sonarjs/cognitive-complexity
  return Object.values(groupedObjectives).map((objectiveTargets) => {
    const [{ filter }] = objectiveTargets;
    const { listFiles, routeFrameCodes } = transformUnitListFiltersFromResponse(filter);

    return objectiveTargets.reduce(
      (acc, curr) => ({
        ...acc,
        ...(Number(curr.objectiveGroupId) ? { objectiveGroupId: Number(curr.objectiveGroupId) } : {}),
        ...(curr.name ? { name: curr.name } : {}),
        ...(curr.criteriaType === DirectSalesFilterGroupCriteria.BUDGET_TARGET
          ? { budget: transformObjectiveTarget(curr) }
          : {}),
        ...(curr.criteriaType === DirectSalesFilterGroupCriteria.FRAME_TARGET
          ? { frames: transformObjectiveTarget(curr) }
          : {}),
        ...(curr.criteriaType === DirectSalesFilterGroupCriteria.VOLUME_TARGET
          ? { impressions: transformObjectiveTarget(curr) }
          : {}),
        ...(filter ? { formats: reverseTransformDealLineFilters(filter, mappedFormatFilterName) } : { formats: [] }),
        ...(filter ? { locations: reverseTransformDealLineFilters(filter, mapLocationFilter) } : { locations: [] }),
        ...(filter ? { tags: reverseTransformDealLineFilters(filter, mapTagFilter) } : { tags: [] }),
        ...(filter ? { poi: reverseTransformDealLineFilters(filter, mapPoiFilter) as DirectSalesPOI[] } : { poi: [] }),
        frameList: {
          listFiles,
          routeFrameCodes,
        },
        objectiveStatus: curr.objectiveStatus,
      }),
      { ...DEFAULT_OBJECTIVE },
    );
  });
};

export const transformLineStatus = (state: DirectSalesState): DirectSalesState => {
  if (!state?.status)
    return {
      status: DirectSalesStatusOption.DRAFT_UPDATING,
    };

  return {
    status: state.status,
    ...(state.expires && state.status === DirectSalesStatusOption.OPTION ? { expires: new Date(state.expires) } : {}),
  };
};

export const transformDirectSalesDealLineResponse = (
  dealLines: DirectSalesCampaignDealLineResponse[],
  campaignAllocation: DirectSalesAllocationSolutionResponse | null,
): Partial<DirectSalesLine>[] => {
  return dealLines.map(
    ({
      id: lineId,
      name: lineName,
      filter,
      range: { startDate, endDate, floatingDateRange },
      rangePattern,
      targetCriteria,
      sweep,
      state: { status },
      state,
      lockState,
      pricingMode,
      templateId,
      // eslint-disable-next-line sonarjs/cognitive-complexity
    }) => {
      const { targetsData, objectivesData } = targetCriteria.reduce(
        (
          acc: { targetsData: DirectSalesTargetCriteria[]; objectivesData: DirectSalesTargetCriteria[] },
          curr: DirectSalesTargetCriteria,
        ) => {
          if (curr.objectiveGroupId || curr.name || curr.filter) acc.objectivesData.push(curr);
          else acc.targetsData.push(curr);

          return acc;
        },
        { targetsData: [], objectivesData: [] },
      );

      const targets = transformTargetCriteria(targetsData);
      const networkCriteria = transformNetworkCriteria(targetsData);
      const objectives = transformObjectivesResponse(objectivesData);
      const allocation = transformDirectSalesLineAllocation(campaignAllocation, status, lineId);

      const patternRepeatType = rangePattern
        ? transformPatternRepeatTypeResponse(rangePattern.length, startDate, endDate)
        : PatternOption.NO_REPEAT;

      const foundMediaTypeFilter = filter?.filters.find(({ type }) => type === 'MediaTypeFilter');

      const mediaType =
        foundMediaTypeFilter && 'mediaType' in foundMediaTypeFilter
          ? (foundMediaTypeFilter.mediaType as DirectSalesMediaType)
          : undefined;

      const orFilters = filter?.filters.find(
        ({ type }) => type === DirectSalesFilterTypes.OrFilter,
      ) as DirectSalesFilter;

      const environments =
        orFilters?.filters
          .filter(({ type }) => type === 'ChannelFilter')
          .map((item: DirectSalesFilterItem) => ({
            code: item.id,
            name: item.name,
            audienceCategoryGroupCode: '',
            dayPartGroupCode: '',
          })) ?? [];

      const { listFiles, routeFrameCodes } = transformUnitListFiltersFromResponse(filter);

      const templateName = ''; // TODO: this will have to be filled when we fetch product if templateId exists

      return {
        lineId,
        name: lineName,
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        impressions: targets.VOLUME_TARGET?.target,
        frames: targets.FRAME_TARGET?.target,
        budget: targets.BUDGET_TARGET?.target,
        sot: targets.SOT_TARGET?.target,
        sotObjectiveId: targets.SOT_TARGET?.objectiveId,
        budgetCriteria: targets.BUDGET_TARGET,
        framesCriteria: targets.FRAME_TARGET,
        impressionsCriteria: targets.VOLUME_TARGET,
        networkCriteria,
        objectives,
        environments,
        sweep,
        state: transformLineStatus(state),
        patternLength: rangePattern?.length ?? 1,
        patternRepeatType,
        selectedDays: rangePattern
          ? reverseTransformDaypartTargeting(rangePattern, startDate, endDate, patternRepeatType)
          : {},
        lockState,
        pricingMode,
        dealLineFormats: filter ? reverseTransformDealLineFilters(filter, mappedFormatFilterName) : [],
        locations: filter ? reverseTransformDealLineFilters(filter, mapLocationFilter) : [],
        poi: filter ? (reverseTransformDealLineFilters(filter, mapPoiFilter) as DirectSalesPOI[]) : [],
        tags: filter ? reverseTransformDealLineFilters(filter, mapTagFilter) : [],
        allocation,
        ...(mediaType ? { mediaType } : {}),
        routeFrameCodes,
        listFiles,
        uploadedFrameLists: listFiles,
        productTemplate: {
          id: templateId ?? '',
          name: templateName,
        },
        floatingRange: floatingDateRange,
      };
    },
  );
};

export const transformDirectSalesMultiDealLinesRequest = (
  lines: DirectSalesCampaignDealLineRequest[],
): {
  dealLines: DirectSalesTransformedDealLineRequest[];
} => {
  return {
    dealLines: lines.map((line) => transformDirectSalesDealLineRequest(line)),
  };
};

export const transformDirectSalesWithLineFormRequest = ({
  deal: dealFormData,
  lines,
}: {
  deal: {
    name?: string;
    advertiser: Advertiser;
    brand: Brand;
    productCategory: CodeNameModel;
    agency?: Agency;
    specialist?: CodeNameModel;
    salesPerson: CodeNameModel | null;
    salesTeam: CodeNameModel | null;
    adminPerson?: CodeNameModel | null;
    productCategoryExclusivity: DirectSalesProductCategoryExclusivityType;
    exclusions: DirectSalesExclusions;
    duplicationExclusion: DirectSalesExclusionFlag;
    juxtapositionExclusion: DirectSalesExclusionFlag;
    specialistBlockExclusion: DirectSalesExclusionFlag;
    agencyBlockExclusion: DirectSalesExclusionFlag;
    advertiserBlockExclusion: DirectSalesExclusionFlag;
  };
  lines: DirectSalesCampaignDealLineRequest[];
}): DirectSalesCampaignRequest => {
  const deal = transformDirectSalesDealRequest(dealFormData);
  const dealLines = transformDirectSalesMultiDealLinesRequest(lines);

  return {
    campaignDefinition: {
      ...deal,
      ...dealLines,
    },
  };
};

export const transformDirectSalesBookingResponse = ({
  campaign: {
    body,
    header: { id, salesPerson, salesTeam, adminPerson, sessionLockStatus },
  },
}: DirectSalesCampaignResponse): TransformedDirectSalesBookingResponse => {
  const {
    campaignDefinition,
    campaignDefinition: {
      advertiserId,
      advertiserName,
      brandId,
      brandName,
      productCategory,
      productCategoryName,
      dealLines,
    },
    allocationSolution,
  } = body;

  const deal = {
    dealName: campaignDefinition.name || '',
    dealType: DealType.GUARANTEED,
    dealId: id,
    internalId: '',
    advertiser: {
      code: advertiserId,
      name: advertiserName,
    },
    brand: {
      code: brandId,
      name: brandName,
    },
    duplicationExclusion: campaignDefinition.duplicationExclusion || DirectSalesExclusionFlag.ON,
    exclusions: campaignDefinition.exclusions || {
      productCategories: [],
      productCategoryGroups: [],
      organisations: [],
      indexedProductCategories: [],
    },
    juxtapositionExclusion: campaignDefinition.juxtapositionExclusion || DirectSalesExclusionFlag.ON,
    productCategory: {
      code: productCategory,
      name: productCategoryName,
    },
    productCategoryExclusivity:
      campaignDefinition.productCategoryExclusivity || DirectSalesProductCategoryExclusivityType.OFF,
    specialistBlockExclusion: campaignDefinition.specialistBlockExclusion || DirectSalesExclusionFlag.ON,
    agencyBlockExclusion: campaignDefinition.agencyBlockExclusion || DirectSalesExclusionFlag.ON,
    advertiserBlockExclusion: campaignDefinition.advertiserBlockExclusion || DirectSalesExclusionFlag.ON,
    ...(campaignDefinition.agencyName
      ? {
          agency: {
            code: campaignDefinition.agencyId,
            name: campaignDefinition.agencyName,
          },
        }
      : {}),
    ...(campaignDefinition.specialistName
      ? {
          specialist: {
            code: campaignDefinition.specialistId,
            name: campaignDefinition.specialistName,
          },
        }
      : {}),
    ...(salesPerson ? { salesPerson } : { salesPerson: null }),
    ...(salesTeam ? { salesTeam } : { salesTeam: null }),
    ...(adminPerson ? { adminPerson } : { adminPerson: null }),
  };

  const availability = {
    totalCost: 0,
    allocatedFrames: 0,
    allocatedImpressions: 0,
    availableImpressions: 0,
    availableFrames: 0,
    assets: [],
    assetCpmDistribution: { weightedMean: 0, min: 0, max: 0 },
  };

  return {
    deal,
    lines: transformDirectSalesDealLineResponse(dealLines, allocationSolution).map((line) => ({
      ...line,
      id,
      availability,
    })),
    sessionLockState: sessionLockStatus,
  };
};

export const transformCampaignLevelAvailabilityRequest = ({
  advertiser,
  brand,
  productCategory,
  agency,
  specialist,
}: Partial<CommonDeal>): TransformedCampaignLevelAvailabilityRequest => {
  return {
    advertiserCode: advertiser?.code || '',
    brandCode: brand?.code || '',
    productCategoryCode: productCategory?.code || '',
    ...(agency?.code ? { agencyId: agency.code } : {}),
    ...(specialist?.code ? { specialistId: specialist.code } : {}),
  };
};

export const transformLineLevelAvailabilityRequest = ({
  environments,
  startDate,
  endDate,
  mediaType,
  selectedDays,
  patternLength,
  patternRepeatType,
}: Partial<DirectSalesLine>): TransformedLineLevelAvailabilityRequest => {
  const dealLine = {
    environmentCode: environments?.map(({ code }) => code) || [],
    startDate: getDateInRequestFormat(startDate as Date, [0, 0, 0, 0]),
    endDate: getDateInRequestFormat(endDate as Date, [23, 59, 59, 999]),
    mediaType: mediaType || DirectSalesMediaType.DIGITAL,
  };

  const selectedDaysAllFullDays = isAllSelectedFullDays(selectedDays);

  if (!selectedDaysAllFullDays && !isEmpty(selectedDays)) {
    const isRepeated = patternRepeatType !== PatternOption.NO_REPEAT;
    const rangePattern = transformRangePattern(selectedDays, isRepeated, patternLength || 1);

    return {
      dealLine: {
        ...dealLine,
        rangePattern,
      },
    };
  }

  return {
    dealLine,
  };
};

export const getDirectSalesAvailabilityPayload = ({
  commonDeal,
  directSales,
  currentLine,
}: DirectSalesCampaignWithLineFormData): TransformedAvailabilityRequest => {
  const deal = transformCampaignLevelAvailabilityRequest({ ...commonDeal });
  const dealLine = transformLineLevelAvailabilityRequest({ ...currentLine, ...directSales });

  return {
    ...deal,
    ...dealLine,
    mode: 'REDUCED',
  };
};

export const transformAllLineData = (lines: Partial<Line>[]): DirectSalesCampaignDealLineRequest[] => {
  return lines.map(
    ({
      lineId,
      name,
      startDate = new Date(),
      endDate = new Date(),
      floatingRange = null,
      tags,
      routeFrameCodes,
      impressions,
      frames,
      budget,
      impressionsCriteria,
      framesCriteria,
      budgetCriteria,
      networkCriteria,
      dealLineFormats,
      mediaType,
      locations,
      sot,
      sotObjectiveId,
      sweep,
      environments,
      selectedDays,
      listFiles,
      patternLength,
      patternRepeatType,
      state,
      lockState,
      pricingMode,
      objectives,
      poi,
      productTemplate,
    }: DirectSalesLine) => {
      return {
        name,
        id: lineId,
        startDate: getDateInRequestFormat(startDate as Date, [0, 0, 0, 0]),
        endDate: getDateInRequestFormat(endDate as Date, [23, 59, 59, 999]),
        ...(floatingRange && floatingRange.floatingStartDate && floatingRange.floatingEndDate
          ? {
              floatingRange: {
                floatingStartDate: getDateInRequestFormat(floatingRange.floatingStartDate as Date, [0, 0, 0, 0]),
                floatingEndDate: getDateInRequestFormat(floatingRange.floatingEndDate as Date, [23, 59, 59, 999]),
              },
            }
          : { floatingRange: null }),
        ...(hasFilters({
          dealLineFormats,
          locations,
          poi,
          tags,
          channels: environments,
          mediaType,
          listFiles,
          routeFrameCodes,
          networks: networkCriteria,
        })
          ? {
              filter: mergeFilters({
                dealLineFormats,
                locations,
                poi,
                tags,
                channels: environments,
                mediaType,
                listFiles,
                routeFrameCodes,
                networks: networkCriteria,
              }),
            }
          : {}),
        impressions,
        budget,
        frames,
        impressionsCriteria,
        budgetCriteria,
        framesCriteria,
        networkCriteria,
        objectives,
        sot,
        sotObjectiveId,
        sweep,
        selectedDays,
        patternLength,
        patternRepeatType,
        state,
        lockState,
        pricingMode,
        productTemplate,
      };
    },
  );
};

export const mergeCurrentLineToAllLines = (lines: DirectSalesLine[], currentLine: Line): DirectSalesLine[] =>
  lines.map((line) => (line.lineId === currentLine.lineId ? (currentLine as DirectSalesLine) : line));
