import { CodeName } from './CodeName.types';

export enum DirectSalesExclusionFlag {
  ON = 'ON',
  OFF = 'OFF',
}

export type DirectSalesExclusions = {
  organisations: CodeName[];
  productCategories: CodeName[];
};
