import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PlannerSecondaryPanelType } from 'store/dealManagement/reducer';
import { isValueRequired } from 'components/pages/PCM/Products/utils/isValueRequired';
import { ToastProps } from 'lib/Toaster';
import { DirectSalesPricingMode } from 'components/common/types/DirectSalesCampaign.types';
import {
  NewProductCreationPayload,
  ProductManagement,
  SelectedProductTemplateFields,
  ClearNewProductCreationPayload,
  ProductTargets,
  SelectionPermission,
  ClearSingleTargetPayload,
} from './reducer.types';

const initialState: ProductManagement = {
  newProduct: {
    id: '',
    name: '',
    folder: '',
    mediaType: undefined,
    pricingMode: undefined,
    range: undefined,
    channels: undefined,
    locations: undefined,
    formats: undefined,
    budget: undefined,
    frames: undefined,
    impressions: undefined,
  },
  selectedProduct: {
    id: '',
  },
  secondaryPanel: PlannerSecondaryPanelType.NONE,
  toastMessages: [],
};

export const productManagementSlice = createSlice({
  name: 'productManagement',
  initialState,
  reducers: {
    changeSelectedProduct: (state, action: PayloadAction<SelectedProductTemplateFields>) => {
      state.selectedProduct = action.payload;
    },
    changeNewProductName: (state, action: PayloadAction<string>) => {
      state.newProduct.name = action.payload;
    },
    changeSelectedFolder: (state, action: PayloadAction<string>) => {
      state.newProduct.folder = action.payload;
    },
    changeNewProduct: (state, action: PayloadAction<NewProductCreationPayload>) => {
      const { selectedPermission, fieldName, value } = action.payload;

      const currentNewProduct = { ...state.newProduct };
      const updatedNewProduct = {
        ...currentNewProduct,
        [fieldName]: {
          selectionPermission: selectedPermission.code,
          value: isValueRequired(selectedPermission.code) ? value : undefined,
        },
      };

      state.newProduct = updatedNewProduct;
    },
    changeNewProductTargets: (state, action: PayloadAction<ProductTargets>) => {
      state.newProduct.budget = action.payload.budget;
      state.newProduct.frames = action.payload.frames;
      state.newProduct.impressions = action.payload.impressions;
    },
    changeNetworkAndPricingMode: (state, action: PayloadAction<NewProductCreationPayload>) => {
      const { selectedPermission, fieldName, value } = action.payload;

      const currentNewProduct = { ...state.newProduct };
      let updatedNewProduct = {
        ...currentNewProduct,
        [fieldName]: {
          selectionPermission: selectedPermission.code,
          value: isValueRequired(selectedPermission.code) ? value : undefined,
        },
      };

      if (isValueRequired(selectedPermission.code)) {
        updatedNewProduct = {
          ...updatedNewProduct,
          pricingMode: {
            selectionPermission: isValueRequired(selectedPermission.code)
              ? SelectionPermission.FIXED_VALUE
              : SelectionPermission.ALL_OPTIONS,
            value: isValueRequired(selectedPermission.code) ? DirectSalesPricingMode.NETWORK : undefined,
          },
        };
      } else if (
        state.newProduct.pricingMode?.value === DirectSalesPricingMode.NETWORK &&
        !isValueRequired(selectedPermission.code)
      ) {
        updatedNewProduct = {
          ...updatedNewProduct,
          pricingMode: undefined,
        };
      }

      state.newProduct = updatedNewProduct;
    },
    clearNetworkAndPricing: (state, action: PayloadAction<ClearNewProductCreationPayload>) => {
      const { fieldName } = action.payload;

      const currentNewProduct = { ...state.newProduct };
      const updatedNewProduct = {
        ...currentNewProduct,
        [fieldName]: undefined,
        pricingMode: undefined,
      };

      state.newProduct = updatedNewProduct;
    },
    clearSingleTarget: (state, action: PayloadAction<ClearSingleTargetPayload>) => {
      const { targetName } = action.payload;

      const currentNewProduct = { ...state.newProduct };
      const updatedNewProduct = {
        ...currentNewProduct,
        [targetName]: undefined,
      };

      state.newProduct = updatedNewProduct;
    },
    clearItemInNewProduct: (state, action: PayloadAction<ClearNewProductCreationPayload>) => {
      const { fieldName } = action.payload;

      const currentNewProduct = { ...state.newProduct };
      const updatedNewProduct = {
        ...currentNewProduct,
        [fieldName]: undefined,
      };

      state.newProduct = updatedNewProduct;
    },
    changeSecondaryPanel: (state, action: PayloadAction<PlannerSecondaryPanelType>) => {
      state.secondaryPanel = action.payload;
    },
    hideSecondaryPanel: (state) => {
      state.secondaryPanel = PlannerSecondaryPanelType.NONE;
    },
    clearNewProduct: (state) => {
      state.newProduct = initialState.newProduct;
    },
    setToastMessages: (state, action: PayloadAction<ToastProps[]>) => {
      state.toastMessages = action.payload;
    },
  },
});

export const {
  changeSelectedProduct,
  changeNewProduct,
  changeSelectedFolder,
  changeNewProductTargets,
  changeNewProductName,
  changeSecondaryPanel,
  hideSecondaryPanel,
  clearItemInNewProduct,
  clearNewProduct,
  clearSingleTarget,
  setToastMessages,
  changeNetworkAndPricingMode,
  clearNetworkAndPricing,
} = productManagementSlice.actions;

export default productManagementSlice.reducer;
