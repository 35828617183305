import { DirectSalesDealLineAllocationFailureResponse } from 'components/common/types/DirectSalesCampaign.types';
import { ToastProps } from 'lib/Toaster/Toaster.types';

const LINE_NAME_MAX_LENGTH = 40;

export type FailedDealLine = {
  id?: string;
  name: string;
  allocationFailures?: DirectSalesDealLineAllocationFailureResponse[];
};

export const transformLinesAllocationFailuresToToasts = (dealLinesResponse: FailedDealLine[]): ToastProps[] => {
  const truncateLineName = (lineName: string): string => {
    return lineName.length > LINE_NAME_MAX_LENGTH ? `${lineName.substring(0, LINE_NAME_MAX_LENGTH)}...` : lineName;
  };

  return dealLinesResponse.reduce<ToastProps[]>(
    (acc, { id, name, allocationFailures = [] }) =>
      id
        ? [
            ...acc,
            ...allocationFailures.map<ToastProps>(() => ({
              id,
              header: `${truncateLineName(name)} allocation failed`,
              description: `Locked allocation is no longer available. Unlock and reallocate your campaign.`,
            })),
          ]
        : acc,
    [],
  );
};
