export enum SpinnerSize {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

interface Props {
  size?: SpinnerSize;
}

const largerSpinnerTheme = {
  [SpinnerSize.LARGE]: 'w-20 h-20 border-6',
  [SpinnerSize.MEDIUM]: 'w-10 h-10 border-3',
  [SpinnerSize.SMALL]: 'w-5 h-5 border-2',
};

const smallerSpinnerTheme = {
  [SpinnerSize.LARGE]: 'w-16 h-16 border-6',
  [SpinnerSize.MEDIUM]: 'w-8 h-8 border-3',
  [SpinnerSize.SMALL]: 'w-4 h-4  border-2',
};

export const CircularLoadingSpinner: React.FC<Props> = ({ size = SpinnerSize.LARGE }) => {
  return (
    <div className="relative w-full h-full" data-test-id="circular-spinner">
      <div
        className={`animate-spin-2s absolute ${largerSpinnerTheme[size]} top-0 bottom-0 left-0 right-0 m-auto rounded-full border-t-primary border-r-transparent border-b-primary border-l-transparent`}
      />
      <div
        className={`animate-reverse-spin-2s absolute ${smallerSpinnerTheme[size]} top-0 bottom-0 left-0 right-0 m-auto rounded-full border-t-transparent border-r-sky border-b-transparent border-l-blueSky`}
      />
    </div>
  );
};
