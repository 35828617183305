import { SectionButtonChipType } from 'lib/SectionButton/SectionButton.types';
import { getChipColor } from 'utils/getChipColor';
import { DirectSalesAllocationSolutionStatus } from 'components/common/types/DirectSalesAllocationSolution';
import { NetworkCriteriaType } from 'components/common/types/DirectSalesNetworks.types';

interface DirectSalesAllocationSelectedValues extends SectionButtonChipType {
  status?: DirectSalesAllocationSolutionStatus;
}

const generateSelectedValueWithStatusStyling = ({
  code,
  status,
  target,
  allocated,
}: {
  code: string;
  status?: DirectSalesAllocationSolutionStatus;
  target: string;
  allocated?: number;
}): DirectSalesAllocationSelectedValues => ({
  code,
  name: allocated === undefined ? target : `${allocated} of ${target}`,
  color: getChipColor(status),
});

export const sortNetworkCriteriaBySelectedValue = (
  data: NetworkCriteriaType[],
): Record<string, DirectSalesAllocationSelectedValues[]> => {
  return data.reduce(
    (acc: Record<string, DirectSalesAllocationSelectedValues[]>, { code, name, target, status, allocated }) => {
      if (!acc[name]) {
        acc[name] = [generateSelectedValueWithStatusStyling({ code, status, target, allocated })];
      } else {
        acc[name].push(generateSelectedValueWithStatusStyling({ code, status, target, allocated }));
      }
      return acc;
    },
    {},
  );
};
