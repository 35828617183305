/* eslint-disable sonarjs/cognitive-complexity */
import { useSelector } from 'react-redux';

import { Store } from 'components/common/types/Store.types';
import { DealType } from 'components/common/types/Deal.types';
import { formatCurrency, formatNumber, formatPercent, FormatTypeEnum, formatValue } from 'modules/I18N';
import Tooltip from 'components/patterns/Tooltip';
import { PieChart, SETTINGS } from 'components/patterns/HighChart';
import { handleValueFormat } from 'components/pages/DealWithLines/common/RightSidebar/utils';
import { RootState } from 'store';
import { FeatureFlags } from 'components/common/types/Features.types';
import { useHasFeatureAccess } from 'customHooks/useHasFeatureAccess';
import { theme } from 'tailwind-config';
import { ReportType } from 'components/common/AllocationReport/AllocationReport.types';
import { Asset } from 'components/common/types/Asset.types';
import { DealSummaryProps } from './DealSummary.types';
import { DealSummaryFrameList } from '../DealSummaryFrameList/DealSummaryFrameList';
import { ButtonColor } from '../DealSummaryFrameList/DealSummaryFrameList.types';
import { DealSummaryAllocationReport } from '../DealSummaryAllocationReport/DealSummaryAllocationReport';

const DealSummary = ({
  availability,
  bidRequests,
  bidResponses,
  showNetGrossValue = false,
  isAvailabilityReportDisabled,
  isFrameListDisabled,
  isForecastedAllocation,
  dealType,
}: DealSummaryProps): React.ReactElement => {
  const { currencyCode, localeCode } = useSelector((state: RootState) => state.publisher.configuration);
  const temporaryDealId = useSelector((state: Store) => state.dealManagement.temporaryDealId);
  const currentLineAssets = useSelector(
    (state: Store) => state.dealManagement.commonDeal.currentLine.availability.assets,
  ) as Asset[];

  const stats = [
    {
      id: 1,
      header: 'Allocated frms',
      value: availability.allocatedFrames,
      valueType: FormatTypeEnum.ROUNDED_NUMBER,
      tooltip: 'Allocated Frames',
      forecastState: isForecastedAllocation,
    },
    {
      id: 2,
      header: 'Avail. frms',
      value: availability.availableFrames,
      valueType: FormatTypeEnum.ROUNDED_NUMBER,
      tooltip: 'Available frames',
      forecastState: isForecastedAllocation,
    },
    {
      id: 3,
      header: 'Allocated imps',
      value: availability.allocatedImpressions,
      valueType: FormatTypeEnum.ROUNDED_NUMBER,
      tooltip: 'Allocated Impressions',
      forecastState: false,
    },
    {
      id: 4,
      header: 'Avail. imps',
      value: availability.availableImpressions,
      valueType: FormatTypeEnum.ROUNDED_NUMBER,
      tooltip: 'Available impressions',
      forecastState: false,
    },
  ];

  const hasNetGrossValueEnabled = useHasFeatureAccess(FeatureFlags.NET_GROSS_VALUE);

  const hasAllocationReportEnabled = useHasFeatureAccess(FeatureFlags.ALLOCATION_REPORT);
  const isDisabled = isFrameListDisabled || !availability.assets?.length;
  const isAvailabilityReportButtonVisible = hasAllocationReportEnabled;

  return (
    <div className="relative bg-neutral-100 rounded-2xl shadow-2xl mb-6">
      <div className="pt-3.5 pb-2.5">
        <h3 className="text-center header-2xl text-neutral-900" data-test-id="deal-summary-total-cost">
          {formatValue(availability.totalCost, FormatTypeEnum.CURRENCY, currencyCode, localeCode)}
        </h3>
        {hasNetGrossValueEnabled && showNetGrossValue && availability.grossMediaValue !== availability.netMediaValue ? (
          <div className="text-center text-neutral-600 flex justify-center">
            <Tooltip tooltip="Net value">
              <p data-test-id="deal-summary-net-media-value">
                <span className="body-sm">Net: </span>
                <span className="sub-header-sm pl-1">
                  {formatCurrency({
                    value: availability.netMediaValue as number,
                    currencyCode,
                    localeCode,
                  })}
                </span>
              </p>
            </Tooltip>
            <p className="text-neutral-600 mx-3"> | </p>
            <Tooltip tooltip="Gross value">
              <p data-test-id="deal-summary-gross-media-value">
                <span className="body-sm">Gross: </span>
                <span className="sub-header-sm pl-1">
                  {formatCurrency({ value: availability.grossMediaValue as number, currencyCode, localeCode })}
                </span>
              </p>
            </Tooltip>
          </div>
        ) : null}
      </div>
      {bidRequests !== undefined && bidResponses !== undefined && (
        <div
          className="bg-neutral-950-opacity-10 flex items-center justify-between pl-5 py-0.5 mb-3"
          data-test-id="bids-summary"
        >
          <div className="text-neutral-950 space-y-2">
            <p className="body-sm">Bids</p>
            <div className="grid grid-cols-2 gap-y-0.5 gap-x-1">
              <p className="body-base">Requests</p>
              <p className="break-all sub-header-base" data-test-id="deal-summary-bid-requests">
                {formatNumber({ value: bidRequests, localeCode })}
              </p>
              <p className="body-base">Responses</p>
              <p className="break-all sub-header-base" data-test-id="deal-summary-bid-responses">
                {formatNumber({ value: bidResponses, localeCode })}
              </p>
            </div>
          </div>
          <PieChart
            data={[
              {
                name: 'Req.',
                y: bidRequests,
                color: theme.extend.colors.green.DEFAULT,
                sliced: true,
              },
              {
                name: 'Res.',
                y: bidResponses,
                color: theme.extend.colors.purple[600],
              },
            ]}
            options={{
              chart: {
                width: 100,
                height: 80,
                margin: [12, 0, 12, 0],
                backgroundColor: SETTINGS.COLORS.transparent,
              },
              plotOptions: {
                series: { events: { click: () => false } },
                pie: {
                  borderWidth: 0,
                  slicedOffset: 3,
                },
              },
              tooltip: {
                hideDelay: 0,
                /* eslint-disable react/no-this-in-sfc */
                formatter() {
                  return this.series.data.reduce(
                    (
                      acc: string,
                      point: {
                        name: string;
                        percentage: number;
                      },
                    ) =>
                      `<span class="body-sm">${acc}${point.name}: </span><span class="sub-header-sm">${formatPercent({
                        value: point.percentage / 100,
                        localeCode,
                      })}</span><br />`,
                    '',
                  );
                },
                /* eslint-enable react/no-this-in-sfc */
              },
            }}
            enableDataLabel={false}
            enableExporting={false}
            showInLegend={false}
            onClick={() => false}
          />
        </div>
      )}
      <div className="grid grid-cols-2 gap-y-5 px-4 pb-7">
        {stats.map((stat) => (
          <div key={stat.id} data-test-id={`deal-summary-${stat.tooltip.replaceAll(' ', '-').toLowerCase()}`}>
            <Tooltip tooltip={stat.tooltip}>
              <p className="text-neutral-600 body-base mb-1">{stat.header}</p>
            </Tooltip>
            <p
              className={`flex items-center header-lg ${
                stat.forecastState ? 'text-neutral-600' : 'text-neutral-950-opacity-90'
              } `}
            >
              {handleValueFormat({ valueType: stat.valueType, value: stat.value, currencyCode, localeCode })}
            </p>
          </div>
        ))}
      </div>
      <div className="text-center px-4 pb-3.5">
        {isAvailabilityReportButtonVisible && (
          <DealSummaryAllocationReport
            buttonLabel={dealType === DealType.GUARANTEED ? 'Allocation report' : 'Availability Report'}
            isDisabled={isAvailabilityReportDisabled}
            reportType={temporaryDealId ? ReportType.NOT_SAVED : ReportType.SAVED}
          />
        )}
        <DealSummaryFrameList assets={currentLineAssets} isDisabled={isDisabled} buttonColor={ButtonColor.PRIMARY} />
      </div>
    </div>
  );
};

export default DealSummary;
/* eslint-enable sonarjs/cognitive-complexity */
