import { createSelector } from '@reduxjs/toolkit';
import Auth from 'modules/Auth';
import { Store } from 'components/common/types/Store.types';
import { RootState } from 'store';
import { SOURCE_SYSTEM } from 'consts/sourceSystem';
import { getIsAdServer, getIsReseller } from 'store/publisher/selectors';
import { FeatureFlags } from 'components/common/types/Features.types';
import { CampaignType } from 'components/pages/Planner/PlannerSections/types';
import { DealStatus, Line } from 'components/common/types/Deal.types';
import {
  DirectSalesLine,
  DirectSalesLockStatusOption,
  DirectSalesState,
  DirectSalesStatusOption,
} from 'components/common/types/DirectSalesCampaign.types';
import { addYears, startOfTomorrow, subDays, subYears } from 'date-fns';
import { Modifier } from 'react-day-picker';
import { DATE_TIME_PICKER_YEARS_AFTER, DATE_TIME_PICKER_YEARS_BEFORE } from 'consts/dateTimePicker';
import { VisibleAsset } from '../../components/common/types/Planner.types';

export const getFrameIdIndexesOnViewport = createSelector(
  (state: Store) => state.dealManagement.plannerSettings.frameIdIndexes,
  (state: Store) => state.dealManagement.plannerSettings.assetsOnViewport,
  (frameIdIndexes = [], assetsOnViewport = []) =>
    frameIdIndexes.length
      ? frameIdIndexes.filter((frameIdIndex) =>
          assetsOnViewport.some((asset: VisibleAsset) => asset.isVisible && asset.frameId === frameIdIndex.frameId),
        )
      : [],
);

export const getIsValidAvailabilityRequest = createSelector(
  getIsAdServer,
  getIsReseller,
  (state: RootState) => state.dealManagement.campaignType,
  (state: RootState) => state.dealManagement.isCpmCampaignLevel,
  (state: RootState) => state.dealManagement.commonDeal.advertiser,
  (state: RootState) => state.dealManagement.commonDeal.brand,
  (state: RootState) => state.dealManagement.commonDeal.cpm,
  (state: RootState) => state.dealManagement.commonDeal.productCategory,
  (state: RootState) => state.dealManagement.commonDeal.sourceSystem,
  (state: RootState) => state.dealManagement.commonDeal.currentLine.cpm,
  (state: RootState) => state.dealManagement.commonDeal.currentLine.maxCPM,
  (state: RootState) => state.dealManagement.commonDeal.currentLine.environments,
  (state: RootState) => state.dealManagement.programmatic.dsp,
  (state: RootState) => state.environment.environmentId,
  (state: RootState) => state.publisher.publisherFeatures,
  (
    isAdServer,
    isReseller,
    campaignType,
    isCpmCampaignLevel,
    advertiser,
    brand,
    dealCpm,
    productCategory,
    sourceSystem,
    lineCpm,
    maxCPM,
    environments,
    dsp,
    environmentId,
    publisherFeatures,
  ) => {
    const hasAdsDealLevelCPMEnabled = publisherFeatures[btoa(FeatureFlags.ADS_DEAL_LEVEL_CPM)]?.includes(environmentId);
    const cpm = !isAdServer || (hasAdsDealLevelCPMEnabled && isCpmCampaignLevel) ? dealCpm : lineCpm;
    const isCorrectCpmValue = cpm || (!cpm && campaignType === CampaignType.DIRECT_MTB_EXPORT);
    const isDV360Deal = sourceSystem === SOURCE_SYSTEM.DV360;
    const hasRequiredMaxCPM = isReseller ? !!maxCPM && maxCPM <= cpm : true;
    const hasRequiredEnvironments = isReseller || environments?.length > 0;

    if (
      (!isDV360Deal &&
        (!advertiser?.code || !brand?.code || !productCategory?.code || !isCorrectCpmValue || !hasRequiredMaxCPM)) ||
      !dsp?.code ||
      !hasRequiredEnvironments
    ) {
      return false;
    }

    return true;
  },
);

export const getIsValidDirectSalesAvailabilityRequest = createSelector(
  (state: Store) => state.dealManagement.commonDeal.advertiser,
  (state: Store) => state.dealManagement.commonDeal.brand,
  (state: Store) => state.dealManagement.commonDeal.productCategory,
  (state: Store) => state.dealManagement.commonDeal.currentLine.environments,
  (state: Store) => state.dealManagement.directSales.mediaType,
  (advertiser, brand, productCategory, environments, mediaType) => {
    return !!(advertiser?.code && brand?.code && productCategory?.code && environments.length !== 0 && mediaType);
  },
);

export const getIsNewAvailabilityFetched = createSelector(
  (state: RootState) => state.dealManagement.isForecastedAllocation,
  (state: RootState) => state.dealManagement.commonDeal.currentLine.availability.allocatedFrames,
  (state: RootState) => state.dealManagement.commonDeal.currentLine.availability.allocatedImpressions,
  (state: RootState) => state.dealManagement.commonDeal.currentLine.availability.totalCost,
  (isForecastedAllocation, allocatedFrames, allocatedImpressions, totalCost) => {
    return !isForecastedAllocation && !allocatedFrames && !allocatedImpressions && !!totalCost;
  },
);

export const getDealAllocationData = createSelector(
  (state: Store) => state.dealManagement.isForecastedAllocation,
  (state: Store) => state.dealManagement.commonDeal.summary.availability.allocatedFrames,
  (state: Store) => state.dealManagement.commonDeal.summary.availability.allocatedImpressions,
  (state: Store) => state.dealManagement.commonDeal.summary.availability.totalCost,
  (state: Store) => state.dealManagement.backupFormData.commonDeal.summary?.availability.allocatedFrames,
  (state: Store) => state.dealManagement.backupFormData.commonDeal.summary?.availability.allocatedImpressions,
  (state: Store) => state.dealManagement.backupFormData.commonDeal.summary?.availability.totalCost,
  (
    isForecastedAllocation,
    allocatedFrames,
    allocatedImpressions,
    totalCost,
    preservedAllocatedFrames,
    preservedAllocatedImpressions,
    preservedTotalCost,
  ) => {
    if (isForecastedAllocation) {
      return {
        allocatedFrames: preservedAllocatedFrames,
        allocatedImpressions: preservedAllocatedImpressions,
        totalCost: preservedTotalCost,
      };
    }

    return {
      allocatedFrames,
      allocatedImpressions,
      totalCost,
    };
  },
);

export const getBackupCurrentLineAllocation = createSelector(
  (state: Store) => state.dealManagement.commonDeal.currentLine.id,
  (state: Store) => state.dealManagement.backupFormData.lines,
  (currentLineId = '', backupFormDataLines = []) => {
    const backupAvailability = backupFormDataLines.find((line) => line.id === currentLineId)?.availability;

    return {
      allocatedFrames: backupAvailability?.allocatedFrames || 0,
      allocatedImpressions: backupAvailability?.allocatedImpressions || 0,
      assets: backupAvailability?.assets || [],
      totalCost: backupAvailability?.totalCost || 0,
    };
  },
);

export const getLowerCaseLineNames = createSelector(
  (state: Store) => state.dealManagement.backupFormData.lines,
  (lines = []) => lines.map((line) => line.name?.toLowerCase()),
);

export const getIsEveryLineWithId = createSelector(
  (state: Store) => state.dealManagement.backupFormData.lines,
  (lines = []) => lines.every((line) => !!line.lineId),
);

export const getEveryLineState = createSelector(
  (state: Store) => state.dealManagement.backupFormData?.lines,
  (lines = []) => {
    if (!lines) return [];

    return lines.filter((line: Line | DirectSalesLine) => 'state' in line).map((line: DirectSalesLine) => line.state);
  },
);

export const getEveryLineStatus = createSelector(
  (state: Store) => state.dealManagement.backupFormData?.lines,
  (lines = []) =>
    lines.filter((line: Line | DirectSalesLine) => 'state' in line).map((line: DirectSalesLine) => line.state.status),
);

export const getEveryLineStateGroupedByLineId = createSelector(
  (state: Store) => state.dealManagement.backupFormData.lines,
  (lines = []) =>
    lines.reduce((acc: Record<string, DirectSalesState>, curr: DirectSalesLine) => {
      acc[curr.lineId] = curr.state;
      return acc;
    }, {}),
);

export const getCampaignOptionDropDateDisabledDays = createSelector(
  (state: Store) => state.dealManagement.backupFormData.lines,
  (lines = []): Modifier => {
    const tomorrow = startOfTomorrow();
    const [firstStartDate, ...restStartDates] = lines.map((line: DirectSalesLine) => new Date(line.startDate ?? ''));

    const minDate = restStartDates.reduce((acc, curr) => {
      if (curr.getTime() < acc.getTime()) return curr;
      return acc;
    }, firstStartDate);

    const maxCampaignDropDate = subDays(minDate, 1);

    if (maxCampaignDropDate.getTime() < tomorrow.getTime()) {
      return {
        from: subYears(new Date(), DATE_TIME_PICKER_YEARS_BEFORE + 1),
        to: addYears(new Date(), DATE_TIME_PICKER_YEARS_AFTER + 1),
      };
    }

    return { before: tomorrow, after: maxCampaignDropDate };
  },
);

export const getLineOptionDropDateDisabledDays = createSelector(
  (state: Store) => state.dealManagement.commonDeal.currentLine,
  (line: DirectSalesLine): Modifier => {
    const tomorrow = startOfTomorrow();
    const maxLineDropDate = subDays(new Date(line.startDate ?? ''), 1);

    if (maxLineDropDate.getTime() < tomorrow.getTime()) {
      return {
        from: subYears(new Date(), DATE_TIME_PICKER_YEARS_BEFORE + 1),
        to: addYears(new Date(), DATE_TIME_PICKER_YEARS_AFTER + 1),
      };
    }

    return { before: tomorrow, after: maxLineDropDate };
  },
);

export const getIsAdvertiserInfoChanged = createSelector(
  (state: Store) => state.dealManagement.backupFormData.commonDeal.advertiser?.code,
  (state: Store) => state.dealManagement.backupFormData.commonDeal.brand?.code,
  (state: Store) => state.dealManagement.backupFormData.commonDeal.productCategory?.code,
  (state: Store) => state.dealManagement.backupFormData.commonDeal.agency?.code,
  (state: Store) => state.dealManagement.backupFormData.commonDeal.specialist?.code,
  (state: Store) => state.dealManagement.commonDeal.advertiser?.code,
  (state: Store) => state.dealManagement.commonDeal.brand?.code,
  (state: Store) => state.dealManagement.commonDeal.productCategory?.code,
  (state: Store) => state.dealManagement.commonDeal.agency?.code,
  (state: Store) => state.dealManagement.commonDeal.specialist?.code,
  (
    backupAdvertiserCode,
    backupBrandCode,
    backupProductCategoryCode,
    backupAgencyCode,
    backupSpecialistCode,
    advertiserCode,
    brandCode,
    productCategoryCode,
    agencyCode,
    specialistCode,
  ) => {
    const checkIfChanged = <T>(
      backupValueCode: T | undefined,
      currentValueCode: T | undefined,
      isRequired: boolean,
    ): boolean => {
      if (isRequired) {
        return !!backupValueCode && !!currentValueCode && backupValueCode !== currentValueCode;
      }
      return backupValueCode !== currentValueCode;
    };

    const isAdvertiserChanged = checkIfChanged(backupAdvertiserCode, advertiserCode, true);
    const isBrandChanged = checkIfChanged(backupBrandCode, brandCode, true);
    const isProductCategoryChanged = checkIfChanged(backupProductCategoryCode, productCategoryCode, true);
    const isAgencyChanged = checkIfChanged(backupAgencyCode, agencyCode, false);
    const isSpecialistChanged = checkIfChanged(backupSpecialistCode, specialistCode, false);

    return {
      isAdvertiserChanged,
      isBrandChanged,
      isProductCategoryChanged,
      isAgencyChanged,
      isSpecialistChanged,
      isAnyChanged:
        isAdvertiserChanged || isBrandChanged || isProductCategoryChanged || isAgencyChanged || isSpecialistChanged,
    };
  },
);

export const getModalVisibilityConditions = createSelector(
  getIsAdServer,
  (state: Store) => getIsAdvertiserInfoChanged(state).isAnyChanged,
  (state: Store) => state.dealManagement.commonDeal.bookingStatusCode,
  (isAdServerMarket, isAnyChanged, bookingStatusCode) => {
    const shouldShowAdvertiserInfoModal =
      isAdServerMarket &&
      [DealStatus.APPROVED, DealStatus.PENDING_APPROVAL, DealStatus.REJECTED, DealStatus.LIVE].includes(
        bookingStatusCode,
      ) &&
      isAnyChanged;

    return {
      shouldShowAdvertiserInfoModal,
    };
  },
);

export const getIsNewLine = createSelector(
  getIsAdServer,
  (state: Store) => state.dealManagement.commonDeal.currentLine?.id,
  (state: Store) => state.dealManagement.commonDeal.currentLine?.lineId,
  (isAdServerMarket, id, lineId) => {
    return !id || (!lineId && isAdServerMarket);
  },
);

export const getIsDirectSalesSessionLockedAndReadOnly = createSelector(
  (state: Store) => state.dealManagement.directSalesSessionLock,
  (directSalesSessionLock) => {
    const { userName } = Auth.getData() || {};

    const isSessionLocked = Boolean(directSalesSessionLock?.expires && directSalesSessionLock?.owner);
    const isSessionLockedByCurrentUser = isSessionLocked && userName === directSalesSessionLock.owner;

    return {
      isDirectSalesSessionLockedAndReadOnly: isSessionLocked && !isSessionLockedByCurrentUser,
      isSessionLockedByCurrentUser,
    };
  },
);

export const getAllocation = createSelector(
  [(state: Store) => state.dealManagement.directSales.allocation, (_, id?: number) => id],
  (allocation, id) => {
    if (!id || !allocation[id])
      return {
        allocated: undefined,
        status: undefined,
      };

    return {
      allocated: allocation[id].allocated,
      status: allocation[id].status,
    };
  },
);

export const getSessionExpirationDate = createSelector(
  (state: Store) => state.dealManagement.directSalesSessionLock?.expires,
  (expiresDate: Date | string) => (expiresDate ? new Date(expiresDate) : null),
);

export const getBackupLine = createSelector(
  (state: Store) => state.dealManagement.backupFormData.lines,
  (state: Store) => state.dealManagement.commonDeal.currentLine.lineId,
  (lines, currentLineId) => lines.find((line) => line.lineId === currentLineId),
);

export const getBackupLineNameByLineId = createSelector(
  [(state: Store) => state.dealManagement.backupFormData.lines, (_, id: string) => id],
  (lines, id) => {
    return lines.find((line) => line.lineId === id)?.name;
  },
);

export const getIsSingleLine = createSelector(
  (state: Store) => state.dealManagement.backupFormData.lines,
  (lines: DirectSalesLine[] | Line[]) => lines.length === 1,
);

export const getIsAnyLineFetchingAvailability = createSelector(
  (state: Store) => state.dealManagement.commonDeal.currentLine.isFetchingAvailability,
  (state: Store) => state.dealManagement.backupFormData.lines,
  (currentLineIsFetchingAvailability, lines) => {
    return currentLineIsFetchingAvailability || lines.some((line) => line.isFetchingAvailability);
  },
);

export const getIsLineBeingSaved = createSelector(
  [
    (state: Store) => state.dealManagement.commonDeal.currentLine,
    (state: Store) => state.dealManagement.backupFormData,
    (_, lineId?: string) => lineId,
  ],
  (currentLine, backupFormData, lineId) => {
    if (backupFormData?.lines?.some((line) => line.isSaving)) return true;

    if (!lineId || lineId === currentLine.lineId) return currentLine?.isSaving;

    const idx = backupFormData?.lines.findIndex((line) => line.lineId === lineId);

    return backupFormData?.lines[idx]?.isSaving;
  },
);

export const getIsLineCancelled = createSelector(
  [
    (state: Store) => state.dealManagement.commonDeal.currentLine,
    (state: Store) => state.dealManagement.directSales?.state?.status,
    (state: Store) => state.dealManagement.backupFormData?.lines as DirectSalesLine[],
    (_, lineId?: string) => lineId,
  ],
  (currentLine, currentLineStatus, backupLines, lineId) => {
    if (!lineId || lineId === currentLine.lineId) return currentLineStatus === DirectSalesStatusOption.CANCELLED;

    const idx = backupLines.findIndex((line) => line.lineId === lineId);

    return backupLines[idx]?.state.status === DirectSalesStatusOption.CANCELLED;
  },
);

export const getIsLineLocked = createSelector(
  [
    (state: Store) => state.dealManagement.commonDeal.currentLine,
    (state: Store) => state.dealManagement.directSales?.lockState?.status,
    (state: Store) => state.dealManagement.backupFormData?.lines as DirectSalesLine[],
    (_, lineId?: string) => lineId,
  ],
  (currentLine, currentLineLockedStatus, backupLines, lineId) => {
    if (!lineId || lineId === currentLine.lineId) return currentLineLockedStatus === DirectSalesLockStatusOption.LOCKED;

    const idx = backupLines.findIndex((line) => line.lineId === lineId);

    return backupLines[idx]?.lockState?.status === DirectSalesLockStatusOption.LOCKED;
  },
);

export const getAssetsLength = createSelector(
  (state: Store) => state.dealManagement.commonDeal.currentLine,
  (line) => line.availability.assets.length,
);
