import { useDispatch } from 'react-redux';
import { addDraftFailureToast, changeDirectSalesData } from 'store/dealManagement/reducer';
import {
  FailedDealLine,
  transformLinesAllocationFailuresToToasts,
} from 'components/pages/Planner/hooks/utils/transformLinesAllocationFailuresToToasts';
import {
  DirectSalesCampaignDefinitionResponse,
  DirectSalesAllocationSolutionResponse,
  DirectSalesPricingSolutionResponse,
  DirectSalesStatusOption,
  DirectSalesFailedStatus,
} from 'components/common/types/DirectSalesCampaign.types';

export type PollCompleteHandlerResult = {
  campaignDefinition: DirectSalesCampaignDefinitionResponse;
  allocationSolution: DirectSalesAllocationSolutionResponse | null;
  pricingSolution: DirectSalesPricingSolutionResponse | null;
};

export type AllocationFailureOption = {
  status: DirectSalesFailedStatus;
};

type UsePollCompleteErrorHandler = {
  handleDraftFail: (option?: AllocationFailureOption) => Promise<void>;
  handleDraftFailedLines: (failedLines: FailedDealLine[], options?: AllocationFailureOption) => void;
  handlePollCompleteDraftFail: (
    pollCompleteHandler: Promise<PollCompleteHandlerResult>,
    option?: AllocationFailureOption,
  ) => Promise<void>;
};

export const useDirectSalesDraftErrorHandler = (): UsePollCompleteErrorHandler => {
  const dispatch = useDispatch();

  const handleDraftFail = async (option?: AllocationFailureOption): Promise<void> => {
    dispatch(
      addDraftFailureToast({
        header:
          option?.status === DirectSalesStatusOption.DRAFT_FAILED_PRICE
            ? 'Campaign pricing failed'
            : 'Campaign allocation failed',
        description: 'Please reallocate your campaign',
      }),
    );
  };

  const handleDraftFailedLines = (failedLines: FailedDealLine[], options?: AllocationFailureOption): void => {
    const draftFailuresToasts = transformLinesAllocationFailuresToToasts(failedLines ?? []);

    if (draftFailuresToasts.length) {
      dispatch(changeDirectSalesData({ draftFailuresToasts }));
    } else {
      handleDraftFail(options);
    }
  };

  const handlePollCompleteDraftFail = async (
    pollCompleteHandler?: Promise<PollCompleteHandlerResult>,
    option?: AllocationFailureOption,
  ): Promise<void> => {
    const campaignDefinition = (await pollCompleteHandler)?.campaignDefinition ?? { dealLines: [] };

    handleDraftFailedLines(campaignDefinition.dealLines ?? [], option);
  };

  return { handleDraftFail, handlePollCompleteDraftFail, handleDraftFailedLines };
};
