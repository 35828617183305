import classNames from 'classnames/bind';
import { FeatureFlags } from 'components/common/types/Features.types';
import withLoader from 'components/hocs/withLoader';
import DealsTable from 'components/pages/Deals/ProgrammaticDeals/DealsTable';
import { useHasFeatureAccess } from 'customHooks/useHasFeatureAccess';
import styles from './ProgrammaticDeals.pcss';
import Filters from './Filters';

const cx = classNames.bind(styles);

const DealsContent: React.FC = () => {
  const hasOutOfChargeEnabled = useHasFeatureAccess(FeatureFlags.OUT_OF_CHARGE);

  return (
    <div className="py-4 px-14">
      <Filters />
      <div className={cx('deals mt-4')}>
        <DealsTable hasOutOfChargeEnabled={hasOutOfChargeEnabled} />
      </div>
    </div>
  );
};

export default withLoader(DealsContent);
