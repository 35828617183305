import { Environment } from 'components/common/types/Deal.types';
import { isValueRequired } from '../../utils/isValueRequired';
import { getNoRestrictionsDisplayValue } from '../../utils/getNoRestrictionsDisplayValue';

export const getSelectedChannelsDisplayValue = (
  selectedPermission?: string,
  selectedChannels?: Environment[],
): string[] | undefined => {
  if (!selectedPermission) {
    return undefined;
  }

  if (!isValueRequired(selectedPermission)) {
    return getNoRestrictionsDisplayValue(selectedPermission);
  }

  if (isValueRequired(selectedPermission) && selectedChannels) {
    return selectedChannels.map(({ name }) => name);
  }

  return undefined;
};
