import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { getIsDirectSalesSessionLockedAndReadOnly } from 'store/dealManagement/selectors';
import linkSvg from 'assets/icons/link.svg';
import IconButton from 'lib/IconButton';
import { createShareCampaignPlanToken } from 'modules/api/shareCampaignPlan';
import { disableDirectSalesSharedCampaign, enableDirectSalesSharedCampaign } from 'modules/api/directSalesCampaign';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import Tooltip, { TooltipDirection, TooltipSize, TooltipTheme } from 'components/patterns/Tooltip';
import { ShareCampaignPlanModal, useShareCampaignPlanModal } from 'components/common/Modals/ShareCampaignPlanModal';
import { Store } from 'components/common/types/Store.types';
import { createCampaignPlanUrlFromToken } from './createCampaignPlanUrlFromToken';

interface Props {
  isDisabled?: boolean;
}

export const ShareCampaignPlan: React.FC<Props> = ({ isDisabled = false }) => {
  const cancelFunctions = useCancelRequest();

  const marketId = useSelector((state: RootState) => state.publisher.configuration.marketId);
  const campaignId = useSelector((state: Store) => state.dealManagement.commonDeal.dealId);
  const enabledForSharing = useSelector((state: Store) => state.dealManagement.directSales.enabledForSharing);
  const { isDirectSalesSessionLockedAndReadOnly } = useSelector(getIsDirectSalesSessionLockedAndReadOnly);

  const {
    setIsOpen,
    modalIsOpen,
    shareCampaignPlan,
    toggleCampaignPlan,
    shareCampaignPlan: { setParams: setShareParams },
    toggleCampaignPlan: { isLoading: isToggleLoading, isChecked: isToggleChecked, setParams: setToggleParams },
  } = useShareCampaignPlanModal();

  useEffect(() => {
    setToggleParams({ isChecked: enabledForSharing === false, isDisabled: isDirectSalesSessionLockedAndReadOnly });
  }, [enabledForSharing, isDirectSalesSessionLockedAndReadOnly]);

  const handleShareCampaignPlan = async (): Promise<void> => {
    setIsOpen(true);
    setShareParams({ isLoading: true, url: '', error: '', expiresAt: '' });
    setToggleParams({ error: '' });

    try {
      const response = await createShareCampaignPlanToken(cancelFunctions, marketId, campaignId);
      setShareParams({
        url: createCampaignPlanUrlFromToken(response.token),
        expiresAt: response.expiresAt,
      });
    } catch (err) {
      setShareParams({
        error:
          'There was a network issue preventing the campaign plan link from generating. Refresh the page or check your connection and try again.',
      });
    } finally {
      setShareParams({ isLoading: false });
    }
  };

  const handleToggleSwitchChange = async (): Promise<void> => {
    if (isToggleLoading) return;

    setToggleParams({ isLoading: true, error: '' });

    try {
      const toggleMethod = isToggleChecked ? enableDirectSalesSharedCampaign : disableDirectSalesSharedCampaign;

      const {
        campaign: { header },
      } = await toggleMethod(campaignId, marketId, cancelFunctions);

      setToggleParams({ isChecked: !header.enabledForSharing });
    } catch (error) {
      setToggleParams({
        error: `There was a network issue preventing the campaign plan from ${isToggleChecked ? 'enabling' : 'disabling'}. Refresh the page or check your connection and try again.`,
      });
    } finally {
      setToggleParams({ isLoading: false });
    }
  };

  return (
    <>
      <Tooltip
        tooltip="Share campaign plan"
        contentSize={TooltipSize.MAX}
        direction={TooltipDirection.BOTTOM}
        theme={TooltipTheme.LIGHT}
      >
        <IconButton
          dataTestId="share-campaign-plan-button"
          icon={linkSvg}
          name="Share campaign plan"
          handleOnClick={handleShareCampaignPlan}
          isDisabled={isDisabled}
        />
      </Tooltip>

      {modalIsOpen && (
        <ShareCampaignPlanModal
          shareCampaignPlan={shareCampaignPlan}
          toggleCampaignPlan={toggleCampaignPlan}
          onClose={() => setIsOpen(false)}
          onToggleSwitchChange={handleToggleSwitchChange}
        />
      )}
    </>
  );
};
