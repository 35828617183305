import SVG from 'react-inlinesvg';
import startSvg from 'assets/icons/start.svg';
import diamondWarningSvg from 'assets/icons/diamond_warning.svg';
import Button, { Color, Size } from 'lib/Button';

interface Props {
  isRefreshVisible?: boolean;
  onRefreshClick?: () => void;
  header?: string;
  message?: string;
}

const defaultHeader = 'Unable to load campaign';
const defaultMessage = 'Check the link and try again, or contact support if the issue persists.';

export const LoadError: React.FC<Props> = ({
  header = defaultHeader,
  message = defaultMessage,
  isRefreshVisible = false,
  onRefreshClick = () => {},
}) => {
  return (
    <div className="w-full flex space-y-6 flex-col items-center text-center">
      <div>
        <SVG src={diamondWarningSvg} className="fill-current text-pinkRed-900 w-6 h-6" />
      </div>
      <div className="flex flex-col space-y-1">
        <p className="header-base">{header}</p>
        <p className="body-lg text-neutral-600">{message}</p>
      </div>

      {isRefreshVisible && (
        <Button label="Refresh" svg={startSvg} color={Color.PRIMARY} size={Size.LARGE} onClick={onRefreshClick} />
      )}
    </div>
  );
};
