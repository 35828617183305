import { SectionButtonRounded } from 'lib/SectionButton/SectionButton.types';
import { TargetKey } from '../Targets.types';
import { FormatTargetsProps } from './formatTargets.types';

export interface TargetsButtonProps extends FormatTargetsProps {
  isActive: boolean;
  onClear: (key: TargetKey) => void;
  isDisabled?: boolean;
  onShowMoreMenuClick?: () => void;
}

export const TARGET_KEY_TO_BORDER_RADIUS_MAPPING = {
  budget: SectionButtonRounded.ROUNDED_TOP,
  frames: SectionButtonRounded.NONE,
  impressions: SectionButtonRounded.ROUNDED_BOTTOM,
};
