import ToggleSwitch from 'components/patterns/ToggleSwitch';
import { Label } from 'lib/Label';
import { DirectSalesExclusionFlag } from 'components/common/types/DirectSalesDeal.types';
import BlockedExclusionProps from './BlockedExclusion.types';

const BlockedExclusion: React.FC<BlockedExclusionProps> = ({
  advertiserBlocklistExclusion,
  agencyBlocklistExclusion,
  specialistBlocklistExclusion,
  setAdvertiserExclusion,
  setAgencyExclusion,
  setSpecialistExclusion,
}) => {
  const isAdvertiserBlocklistEnabled = advertiserBlocklistExclusion === DirectSalesExclusionFlag.ON;
  const isAgencyBlocklistEnabled = agencyBlocklistExclusion === DirectSalesExclusionFlag.ON;
  const isSpecialistBlocklistEnabled = specialistBlocklistExclusion === DirectSalesExclusionFlag.ON;

  return (
    <>
      <div className="flex justify-between">
        <Label id="advertiser-blocklist-label" label="Advertiser blocklist" />
        <ToggleSwitch
          isChecked={isAdvertiserBlocklistEnabled}
          onChange={(isEnabled: boolean) =>
            setAdvertiserExclusion(isEnabled ? DirectSalesExclusionFlag.ON : DirectSalesExclusionFlag.OFF)
          }
          dataTestId="advertiser-blocklist-switch"
          id="advertiser-blocklist"
        />
      </div>
      <div className="flex justify-between">
        <Label id="agency-blocklist-label" label="Agency blocklist" />
        <ToggleSwitch
          isChecked={isAgencyBlocklistEnabled}
          onChange={(isEnabled: boolean) =>
            setAgencyExclusion(isEnabled ? DirectSalesExclusionFlag.ON : DirectSalesExclusionFlag.OFF)
          }
          dataTestId="agency-blocklist-switch"
          id="agency-blocklist"
        />
      </div>
      <div className="flex justify-between">
        <Label id="specialist-blocklist-label" label="Specialist blocklist" />
        <ToggleSwitch
          isChecked={isSpecialistBlocklistEnabled}
          onChange={(isEnabled: boolean) =>
            setSpecialistExclusion(isEnabled ? DirectSalesExclusionFlag.ON : DirectSalesExclusionFlag.OFF)
          }
          dataTestId="specialist-blocklist-switch"
          id="specialist-blocklist"
        />
      </div>
    </>
  );
};

export default BlockedExclusion;
