import { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { getCampaignPlan } from 'modules/api/campaignPlan';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import { ReportType, SelectedLine } from 'components/common/AllocationReport/AllocationReport.types';
import { CampaignPlanDetails, CampaignPlanError } from 'components/common/types/CampaignPlan.types';
import { DirectSalesStatusOption } from 'components/common/types/DirectSalesCampaign.types';

export interface UsePublicCampaignPlan {
  allocationReportDealLines: SelectedLine[];
  campaignPlanDetails: CampaignPlanDetails | null;
  campaignPlanError: CampaignPlanError | null;
  campaignStatus: DirectSalesStatusOption;
  isLoading: boolean;
  token?: string;
}

export const usePublicCampaignPlan = (): UsePublicCampaignPlan => {
  const cancelFunctions = useCancelRequest();
  const { token } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [campaignPlanDetails, setCampaignPlanDetails] = useState<CampaignPlanDetails | null>(null);
  const [campaignPlanError, setCampaignPlanError] = useState<CampaignPlanError | null>(null);

  const campaignStatus = useMemo(() => {
    const uniqueStatuses = [...new Set(campaignPlanDetails?.dealLines.map(({ dealLineState }) => dealLineState))];

    return uniqueStatuses.length === 1 ? uniqueStatuses[0] : DirectSalesStatusOption.MIXED;
  }, [campaignPlanDetails?.dealLines]);

  const allocationReportDealLines: SelectedLine[] = useMemo(() => {
    if (campaignPlanDetails) {
      return campaignPlanDetails.dealLines.map((line) => ({
        lineDefaultReportType: ReportType.SAVED,
        lineId: line.dealLineId,
        lineName: line.dealLineName,
        lineStatus: line.dealLineState,
      }));
    }

    return [];
  }, [campaignPlanDetails?.dealLines]);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      if (!token) return;

      try {
        setIsLoading(true);

        const response = await getCampaignPlan(token, cancelFunctions);

        setCampaignPlanDetails(response);
      } catch (error) {
        setCampaignPlanError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    return () => {
      setCampaignPlanDetails(null);
      setCampaignPlanError(null);
    };
  }, [token]);

  return {
    allocationReportDealLines,
    campaignPlanDetails,
    campaignPlanError,
    campaignStatus,
    isLoading,
    token,
  };
};
