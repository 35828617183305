import { Preloader } from 'components/patterns/Loader';
import Button, { Color, Size } from 'lib/Button';
import downloadSvg from 'assets/icons/download.svg';
import { AllocationReportProps } from './AllocationReport.types';
import { DropdownDealLine } from './Filters/DropdownDealLine';
import { DropdownMetric } from './Filters/DropdownMetric';
import { useAllocationReport } from './useAllocationReport';
import { AllocationReportTable } from './Table/AllocationReportTable';
import { DropdownGroupBy } from './Filters/DropdownGroupBy';
import { DirectSalesReportTypeButtons } from './ReportTypeButtons/DirectSalesReportTypeButtons';
import { ProgrammaticReportTypeButtons } from './ReportTypeButtons/ProgrammaticReportTypeButtons';

const AllocationReport: React.FC<AllocationReportProps> = ({
  defaultSelectedLine,
  dealLines,
  publicToken,
  publicLocaleCode,
}) => {
  const {
    activeReportType,
    allocationReportTableParams: { gridOptions, onGridReady, rowData },
    handleChangeLine,
    handleChangeMetricType,
    handleExportReport,
    handleFetchAllocationReport,
    isLoading,
    isDirectSalesCampaignType,
    selectedLine,
    selectedMetricType,
    selectedRowGroupColumns,
    handleToggleRowGroupColumn,
  } = useAllocationReport({
    defaultSelectedLine,
    dealLines,
    publicToken,
    publicLocaleCode,
  });

  const renderReportTypeButtons = (): JSX.Element => {
    return isDirectSalesCampaignType ? (
      <DirectSalesReportTypeButtons
        activeReportType={activeReportType}
        handleFetchDirectSalesAllocationReport={handleFetchAllocationReport}
        selectedLine={selectedLine}
      />
    ) : (
      <ProgrammaticReportTypeButtons
        activeReportType={activeReportType}
        handleFetchAllocationReport={handleFetchAllocationReport}
        selectedLine={selectedLine}
      />
    );
  };

  return (
    <div className="h-full flex flex-col overflow-hidden bg-white">
      <header className="flex flex-col gap-3 p-4 border-b border-neutral-950-opacity-10 mb-2">
        {!publicToken && renderReportTypeButtons()}
        <div className="flex items-end gap-3">
          <DropdownDealLine
            dealLines={dealLines}
            handleChangeLine={handleChangeLine}
            selectedLine={selectedLine}
            showLineStatus
          />
          <DropdownGroupBy
            handleToggleRowGroupColumn={handleToggleRowGroupColumn}
            selectedRowGroupColumns={selectedRowGroupColumns}
          />
          <DropdownMetric handleChangeMetricType={handleChangeMetricType} selectedMetricType={selectedMetricType} />
          {!publicToken && (
            <Button
              classNames="ml-auto"
              svg={downloadSvg}
              label="Export report"
              size={Size.LARGE}
              color={Color.TRANSPARENT}
              onClick={handleExportReport}
              ddActionName="Allocation report / Export button"
            />
          )}
        </div>
      </header>
      {isLoading ? (
        <Preloader isLoading />
      ) : (
        <AllocationReportTable gridOptions={gridOptions} onGridReady={onGridReady} rowData={rowData} />
      )}
    </div>
  );
};

export default AllocationReport;
