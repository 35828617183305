import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeFormParams } from 'store/dealManagement/reducer';
import { Store } from 'components/common/types/Store.types';
import ThoughtSpotAllocationReport from 'components/pages/DealWithLines/ThoughtSpotAllocationReport';
import useCampaignType from 'components/pages/Planner/hooks/useCampaignType';
import { getIsAllocationReportFeatureEnabled } from '../selectors';
import { ActiveAllocationReportSolution, AllocationReportsProps } from './AllocationReports.types';
import AllocationReportModal from '../AllocationReportModal/AllocationReportModal';

export const AllocationReports: React.FC<AllocationReportsProps> = ({
  handleAllocationModalClose,
  isAllocationReportOpen,
  reportType,
  defaultLevelType,
}) => {
  const dispatch = useDispatch();
  const { isDirectSalesCampaignType } = useCampaignType();
  const isAllocationReportFeatureEnabled = useSelector(getIsAllocationReportFeatureEnabled);
  const activeAllocationReportSolution = useSelector(
    (state: Store) => state.dealManagement.activeAllocationReportSolution,
  );

  useEffect(() => {
    if (isAllocationReportFeatureEnabled || isDirectSalesCampaignType) {
      dispatch(changeFormParams({ activeAllocationReportSolution: ActiveAllocationReportSolution.NATIVE }));
    } else {
      dispatch(changeFormParams({ activeAllocationReportSolution: ActiveAllocationReportSolution.THOUGHTSPOT }));
    }
  }, [isAllocationReportFeatureEnabled, isDirectSalesCampaignType]);

  if (!isAllocationReportOpen) {
    return null;
  }

  if (activeAllocationReportSolution === ActiveAllocationReportSolution.THOUGHTSPOT) {
    return (
      <ThoughtSpotAllocationReport
        openModal={isAllocationReportOpen}
        closeModal={handleAllocationModalClose}
        defaultLevelType={defaultLevelType}
        reportType={reportType}
      />
    );
  }

  if (activeAllocationReportSolution === ActiveAllocationReportSolution.NATIVE) {
    return <AllocationReportModal onClose={handleAllocationModalClose} defaultReportType={reportType} />;
  }

  return null;
};
