import { PropsWithChildren } from 'react';
import SelectOption from 'lib/SelectOption/SelectOption';
import { CustomSelectOptionProps } from './CustomSelectOption.types';

const CustomSelectOption: React.FC<PropsWithChildren<CustomSelectOptionProps>> = ({
  isSelected,
  onClick,
  selectOptionChildren,
  value,
  onIncludeExcludeChange,
  hasCheckbox = true,
  isIncludeExcludeAlwaysVisible = false,
  isDisabled = false,
  isMultiple = false,
  classNames,
  children,
}) => {
  return (
    <>
      <SelectOption
        isSelected={isSelected}
        onClick={onClick}
        value={value}
        onIncludeExcludeChange={onIncludeExcludeChange}
        hasCheckbox={hasCheckbox}
        isIncludeExcludeAlwaysVisible={isIncludeExcludeAlwaysVisible}
        isDisabled={isDisabled}
        isMultiple={isMultiple}
        classNames={classNames}
      >
        {selectOptionChildren}
      </SelectOption>
      {children}
    </>
  );
};

export default CustomSelectOption;
