const DEFAULT_MINUTE_FORMAT = 'numeric';
const DEFAULT_HOUR_FORMAT = 'numeric';
const DEFAULT_DAY_FORMAT = '2-digit';
const DEFAULT_MONTH_FORMAT = '2-digit';
const DEFAULT_YEAR_FORMAT = 'numeric';

export const LAST_UPDATED_DATE_TIME_FORMAT: Intl.DateTimeFormatOptions = {
  minute: DEFAULT_MINUTE_FORMAT,
  hour: DEFAULT_HOUR_FORMAT,
  day: DEFAULT_DAY_FORMAT,
  month: DEFAULT_MONTH_FORMAT,
  year: DEFAULT_YEAR_FORMAT,
};

export const DATE_RANGE_FORMAT: Intl.DateTimeFormatOptions = {
  day: DEFAULT_DAY_FORMAT,
  month: DEFAULT_MONTH_FORMAT,
  year: DEFAULT_YEAR_FORMAT,
};

export const FORMAT_DATE_FALLBACK = '-';
