import { useDispatch, useSelector } from 'react-redux';
import { ProductFieldName } from 'store/productManagement/reducer.types';
import { clearItemInNewProduct, clearSingleTarget, hideSecondaryPanel } from 'store/productManagement/reducer';
import { PlannerSecondaryPanelType } from 'store/dealManagement/reducer';
import SectionButton from 'lib/SectionButton';
import Chip from 'lib/Chip';
import { Color } from 'lib/Chip/Chip.types';
import { SectionButtonShadow, SectionButtonTheme } from 'lib/SectionButton/SectionButton.types';
import { Store } from 'components/common/types/Store.types';
import { useOnChangePanelVisibility } from 'components/pages/PCM/hooks/useOnChangePanelVisibility';
import { TARGET_KEY_TO_BORDER_RADIUS_MAPPING } from 'components/pages/Planner/PlannerSections/AllocationDirectSalesContent/Targets/TargetsButton/TargetsButton.types';
import indexSvg from 'assets/icons/index.svg';
import { groupTargets, hasTargets } from './utils';

const fieldName = ProductFieldName.TARGETS;

export const ProductTargetsButton: React.FC = () => {
  const budget = useSelector((state: Store) => state.productManagement.newProduct?.budget);
  const frames = useSelector((state: Store) => state.productManagement.newProduct?.frames);
  const impressions = useSelector((state: Store) => state.productManagement.newProduct?.impressions);

  const secondaryPanelType = useSelector((state: Store) => state.productManagement.secondaryPanel);
  const isTargetsOpen = secondaryPanelType === PlannerSecondaryPanelType.TARGETS;

  const { localeCode, currencyCode } = useSelector((state: Store) => state.publisher.configuration);

  const dispatch = useDispatch();
  const onChangePanelVisibility = useOnChangePanelVisibility();

  const groupedTargets = groupTargets({ budget, frames, impressions }, localeCode, currencyCode);

  return hasTargets({ budget, frames, impressions }) ? (
    <div data-test-id="grouped-section-button" className="shadow-inner-sm rounded-md">
      {groupedTargets.map(({ label, value, key, icon }) => {
        return (
          <SectionButton
            key={key}
            dataTestId={`product-${key}-targets-button`}
            theme={SectionButtonTheme.SOLID}
            label={label}
            onClick={() => onChangePanelVisibility(PlannerSecondaryPanelType.TARGETS)}
            onClear={() => {
              dispatch(clearSingleTarget({ targetName: key }));
              dispatch(hideSecondaryPanel());
            }}
            icon={icon}
            rounded={TARGET_KEY_TO_BORDER_RADIUS_MAPPING[key]}
            shadow={SectionButtonShadow.NONE}
            isActive={isTargetsOpen}
          >
            {value ? <Chip dataTestId={`${key}-targets-button-chip`} label={value} color={Color.PRIMARY} /> : null}
          </SectionButton>
        );
      })}
    </div>
  ) : (
    <SectionButton
      dataTestId="product-targets-button"
      theme={SectionButtonTheme.SOLID}
      label="Targets"
      onClear={() => {
        dispatch(clearItemInNewProduct({ fieldName }));
        dispatch(hideSecondaryPanel());
      }}
      onClick={() => onChangePanelVisibility(PlannerSecondaryPanelType.TARGETS)}
      icon={indexSvg}
      isActive={isTargetsOpen}
      isRequired
    />
  );
};
