import { useDispatch, useSelector } from 'react-redux';
import { changeFormParams, hidePlannerSecondaryPanel, setLoadedDirectSalesData } from 'store/dealManagement/reducer';
import { useParams } from 'react-router-dom';
import { Store } from 'components/common/types/Store.types';
import { NOTIFICATION_TIMEOUT } from 'consts/notifications';
import { notifySuccess } from 'store/notification/reducer';
import useDirectSalesPlannerActions from './useDirectSalesPlannerActions';
import { PollCompleteHandlerResult, useDirectSalesDraftErrorHandler } from './useDirectSalesDraftErrorHandler';
import { OnPollComplete } from './useOnPollComplete.types';
import { useDirectSalesPricedSolution } from './useDirectSalesPricedSolution';

const useOnPollComplete = (): OnPollComplete => {
  const dispatch = useDispatch();
  const { campaignId: originalCampaignId } = useParams();

  const marketId = useSelector((state: Store) => state.publisher.configuration.marketId);

  const { getUpdatedDirectSalesCampaign } = useDirectSalesPlannerActions();
  const { handlePollCompleteDraftFail } = useDirectSalesDraftErrorHandler();
  const handlePricedSolution = useDirectSalesPricedSolution();

  return {
    onPollCompleteWithSuccess: async (message: string) => {
      const {
        campaign: {
          body: { pricingSolution },
        },
        deal: commonDeal,
        lines: updatedLines,
      } = await getUpdatedDirectSalesCampaign(originalCampaignId);

      if (!commonDeal.dealId) return;

      dispatch(
        setLoadedDirectSalesData({
          commonDeal,
          lines: updatedLines,
        }),
      );

      if (commonDeal.dealId) {
        await handlePricedSolution({ campaignId: commonDeal.dealId, marketId, pricingSolution, lines: updatedLines });
      }

      dispatch(hidePlannerSecondaryPanel());
      dispatch(
        changeFormParams({
          isEditingDisabled: false,
          isLoading: false,
        }),
      );
      dispatch(
        notifySuccess({
          message,
          timeout: NOTIFICATION_TIMEOUT.DEFAULT,
        }),
      );
    },
    onPollCompleteWithFailure: async () => {
      const pollCompleteHandler = async (): Promise<PollCompleteHandlerResult> => {
        const {
          campaign: {
            body: { allocationSolution, campaignDefinition, pricingSolution },
          },
        } = await getUpdatedDirectSalesCampaign();
        return { allocationSolution, campaignDefinition, pricingSolution };
      };

      handlePollCompleteDraftFail(pollCompleteHandler());
      dispatch(changeFormParams({ isEditingDisabled: false, isLoading: false }));
      dispatch(hidePlannerSecondaryPanel());
    },
  };
};

export default useOnPollComplete;
