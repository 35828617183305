import { InputTheme } from 'components/common/types/Input.types';
import ToggleSwitch, { ToggleTheme } from 'components/patterns/ToggleSwitch';
import { DspSettings } from 'components/common/types/DspSettings.types';
import { useOpenMarketplace } from 'components/common/Deal/OpenMarketplace/useOpenMarketplace';
import { Label } from 'lib/Label';

export const OpenMarketplaceSwitch: React.FC<DspSettings> = ({ theme = 'LIGHT' }) => {
  const { handleToggle, isToggleDisabled, isOpenMarketplaceEnabled, isSelectedDspSupportingOpenMarketplace } =
    useOpenMarketplace();

  if (!isSelectedDspSupportingOpenMarketplace) return null;

  return (
    <div className="flex justify-between items-center h-8">
      <Label theme={InputTheme.FLAT_GRAY} label="Enable open marketplace" />
      <ToggleSwitch
        onChange={handleToggle}
        isChecked={isOpenMarketplaceEnabled}
        isDisabled={isToggleDisabled}
        dataTestId="open-marketplace-switch"
        theme={ToggleTheme[theme]}
      />
    </div>
  );
};
