import { isEmpty } from 'lodash';
import { isAfter, isBefore } from 'date-fns';
import {
  DirectSalesLine,
  DirectSalesState,
  DirectSalesStatusOption,
  DirectSalesTransientDealLineState,
  LINE_LEVEL_VALID_CAMPAIGN_VERSIONING_COMMIT_STATES,
  VALID_CAMPAIGN_VERSIONING_COMMIT_STATES,
} from 'components/common/types/DirectSalesCampaign.types';
import { CancelFunctions } from 'components/common/types';
import { fetchCampaignState } from 'modules/api/directSalesCampaign';
import { DirectSalesCampaignDealLineStateResponse } from 'components/common/types/DirectSalesCampaignResponse.types';
import { CampaignVersioningErrors } from 'consts/directSalesCampaignVersioning';
import { isDirectSalesLinePriced } from './getIsDirectSalesLinePriced';

export const isEveryDealLineIsValidStatus = (
  filteredTransientStateLines: DirectSalesTransientDealLineState,
  isLineLevelStatusDisabled: boolean,
): boolean => {
  if (isEmpty(filteredTransientStateLines)) return false;

  const validCampaignStatuses = isLineLevelStatusDisabled
    ? VALID_CAMPAIGN_VERSIONING_COMMIT_STATES
    : LINE_LEVEL_VALID_CAMPAIGN_VERSIONING_COMMIT_STATES;

  return Object.values(filteredTransientStateLines).every(({ status }) => validCampaignStatuses.includes(status));
};

export const isEveryDealLinePriced = (statuses: DirectSalesStatusOption[]): boolean => {
  if (!statuses.length) return false;

  return statuses.every((status) => isDirectSalesLinePriced(status));
};

export const hasOptionStatusAndDatePassed = (
  transientLineStates: DirectSalesTransientDealLineState,
  lines: Pick<DirectSalesLine, 'lineId' | 'endDate'>[],
): boolean => {
  return Object.entries(transientLineStates).some(([id, { status, expires }]) => {
    const currentLine = lines.find((line) => line.lineId === id);
    const endDate = currentLine?.endDate && new Date(currentLine?.endDate);

    if (status !== DirectSalesStatusOption.OPTION) {
      return false;
    }

    if (!expires) {
      return true;
    }

    if (expires) {
      const expiresDate = new Date(expires);

      if (isBefore(expiresDate, new Date())) {
        return true;
      }

      if (endDate && isAfter(expiresDate, endDate)) {
        return true;
      }
    }

    return false;
  });
};

export const isTransientCommitDisabled = ({
  areAllMandatoryFieldsFilled,
  transientStateDealLines,
  campaignState,
  linesStatuses,
  isLineLevelStatusDisabled,
}: {
  areAllMandatoryFieldsFilled: boolean;
  transientStateDealLines?: DirectSalesTransientDealLineState;
  campaignState?: DirectSalesState;
  linesStatuses: DirectSalesStatusOption[];
  isLineLevelStatusDisabled: boolean;
}): boolean => {
  if (!areAllMandatoryFieldsFilled) {
    return true;
  }

  if (!isEmpty(transientStateDealLines) || !!transientStateDealLines) {
    const filteredTransientStateLines = Object.fromEntries(
      Object.entries(transientStateDealLines).filter(
        ([, value]) =>
          value.status !== DirectSalesStatusOption.DELETED && value.status !== DirectSalesStatusOption.CANCELLED,
      ),
    );

    const everyDealLineIsValidStatus = isEveryDealLineIsValidStatus(
      filteredTransientStateLines,
      isLineLevelStatusDisabled,
    );
    const everyDealLineIsPriced = isEveryDealLinePriced(linesStatuses);
    const isCampaignCancelled = campaignState?.status === DirectSalesStatusOption.CANCELLED;

    return (everyDealLineIsValidStatus === false || everyDealLineIsPriced === false) && !isCampaignCancelled;
  }

  return true;
};

export type ValidationResult = { isValid: boolean; errorStatus?: CampaignVersioningErrors };

export const validateAttemptToConfirmChanges = ({
  transientStateDealLines,
  lines,
}: {
  transientStateDealLines: DirectSalesTransientDealLineState;
  lines: DirectSalesLine[];
}): ValidationResult => {
  const hasNewDealLineSinceOriginal = lines.length > Object.keys(transientStateDealLines).length;

  if (hasNewDealLineSinceOriginal) {
    return {
      isValid: false,
      errorStatus: CampaignVersioningErrors.STATUS_REQUIRED,
    };
  }

  const hasInvalidOptionState = hasOptionStatusAndDatePassed(transientStateDealLines, lines);

  if (hasInvalidOptionState) {
    return {
      isValid: false,
      errorStatus: CampaignVersioningErrors.OPTION_DATE_REQUIRED,
    };
  }

  return {
    isValid: true,
  };
};

export const getOriginalCampaignStatuses = async (
  marketId: string,
  campaignId: string,
  cancelFunctions: CancelFunctions,
): Promise<{
  fetchedCampaignStatuses: DirectSalesCampaignDealLineStateResponse;
  originalCampaignStatusesToIntendedFormat: DirectSalesTransientDealLineState;
}> => {
  const fetchedCampaignStatuses = await fetchCampaignState(marketId, campaignId, cancelFunctions);

  const originalCampaignStatusesToIntendedFormat = Object.fromEntries(
    Object.entries(fetchedCampaignStatuses.states).map(([key, value]) => [
      key,
      { status: value.state.status, expires: value.state.expires },
    ]),
  );

  return {
    fetchedCampaignStatuses,
    originalCampaignStatusesToIntendedFormat,
  };
};
