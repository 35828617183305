import useIsReadOnly from 'components/pages/Planner/useIsReadOnly';
import { getCurrency } from 'modules/I18N';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import InputNumber from 'lib/InputNumber';
import { Store } from 'components/common/types/Store.types';
import { InputAlign } from 'lib/BaseInput';
import { getChipColor } from 'utils/getChipColor';
import PrioritySelect from '../AllocationDirectSalesContent/Targets/TargetsPanel/PrioritySelect';
import TargetFeedback from '../AllocationDirectSalesContent/Targets/TargetsPanel/TargetFeedback';
import TargetTolerance from '../AllocationDirectSalesContent/Targets/TargetsPanel/TargetTolerance';
import { TargetSectionProps, TargetType } from './TargetSection.types';

const TargetSection: React.FC<TargetSectionProps> = ({
  label,
  target,
  targetType,
  icon,
  hasBorder,
  currentOption,
  isTargetValueRequired = false,
}) => {
  const { currencyCode, localeCode } = useSelector((state: Store) => state.publisher.configuration);
  const { isLineReadOnly } = useIsReadOnly();

  return (
    <div
      className={cx('p-2', {
        'border border-neutral-200 rounded-lg': hasBorder,
      })}
      data-test-id={`targets-panel-${targetType}-section`}
    >
      <InputNumber
        value={currentOption?.target ?? target.value}
        onChange={(value: number) => target.handleValueChange(value, currentOption ?? undefined)}
        id={targetType}
        dataTestId={`direct-sales-panel-${targetType}`}
        label={label}
        textSymbol={targetType === TargetType.BUDGET ? currencyCode : undefined}
        isEmpty
        align={InputAlign.RIGHT}
        isDisabled={isLineReadOnly}
        errorMessage={
          isTargetValueRequired && !currentOption?.target && !target.value ? 'Target number is required' : ''
        }
      />
      <div className="flex justify-between items-center">
        <PrioritySelect
          name={`${targetType}-priority`}
          selectedValue={currentOption?.priority ?? target.priority}
          onChange={(value) => target.handlePriorityChange(value, currentOption ?? undefined)}
        />

        <TargetFeedback
          dataTestId={`${targetType}-target-feedback`}
          allocated={currentOption?.allocated ?? target.allocated}
          available={currentOption?.available ?? target.available}
          textSymbol={targetType === TargetType.BUDGET ? getCurrency(localeCode, currencyCode) : undefined}
          feedbackColor={getChipColor(currentOption?.status ?? target.feedback)}
          icon={icon}
        />
      </div>
      <TargetTolerance
        dataTestId={`${targetType}-tolerance`}
        initialGreen={currentOption?.greenTolerance ?? target.greenTolerance}
        initialOrange={currentOption?.orangeTolerance ?? target.orangeTolerance}
        updateGreenTolerance={(value) => target.handleGreenToleranceChange(value, currentOption ?? undefined)}
        updateOrangeTolerance={(value) => target.handleOrangeToleranceChange(value, currentOption ?? undefined)}
      />
    </div>
  );
};

export default TargetSection;
