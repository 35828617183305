import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import frameSvg from 'assets/icons/frame.svg';
import { Store } from 'components/common/types/Store.types';
import { CodeNameModel } from 'components/common/types';
import SectionButton from 'lib/SectionButton';
import { SectionButtonTheme } from 'lib/SectionButton/SectionButton.types';
import { PlannerSecondaryPanelType } from 'store/dealManagement/reducer';
import { ProductFieldName } from 'store/productManagement/reducer.types';
import { changeNewProduct, clearItemInNewProduct, hideSecondaryPanel } from 'store/productManagement/reducer';
import { useOnChangePanelVisibility } from 'components/pages/PCM/hooks/useOnChangePanelVisibility';
import { DirectSalesMediaType } from 'components/common/types/DirectSalesCampaign.types';
import { getSelectedMediaTypeDisplayValue } from './utils';
import ProductMediaTypePanel from './ProductMediaTypePanel';

const fieldName = ProductFieldName.MEDIA_TYPE;

const ProductMediaType: React.FC = () => {
  const dispatch = useDispatch();
  const secondaryPanelType = useSelector((state: Store) => state.productManagement.secondaryPanel);
  const defaultSelectedPermission = useSelector(
    (state: Store) => state.productManagement.newProduct.mediaType?.selectionPermission,
  );
  const selectedMediaType = useSelector((state: Store) => state.productManagement.newProduct.mediaType?.value);
  const onChangePanelVisibility = useOnChangePanelVisibility();

  const isProductMediaTypePanelOpen = secondaryPanelType === PlannerSecondaryPanelType.MEDIA_TYPE;

  const handleSubmit = ({
    value,
    selectedPermission,
  }: {
    value: DirectSalesMediaType;
    selectedPermission: CodeNameModel;
  }): void => {
    dispatch(
      changeNewProduct({
        fieldName,
        value,
        selectedPermission,
      }),
    );
    dispatch(hideSecondaryPanel());
  };

  return (
    <>
      <SectionButton
        dataTestId="section-button-media-type"
        theme={SectionButtonTheme.SOLID}
        label="Media type"
        onClick={() => onChangePanelVisibility(PlannerSecondaryPanelType.MEDIA_TYPE)}
        icon={frameSvg}
        isActive={isProductMediaTypePanelOpen}
        selected={getSelectedMediaTypeDisplayValue(defaultSelectedPermission, selectedMediaType)}
        onClear={() => {
          dispatch(clearItemInNewProduct({ fieldName }));
          dispatch(hideSecondaryPanel());
        }}
        isRequired
      />
      {isProductMediaTypePanelOpen && <ProductMediaTypePanel onConfirm={handleSubmit} />}
    </>
  );
};

export default ProductMediaType;
