import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import Input from 'lib/Input';
import { Label } from 'lib/Label';
import { CodeNameModel } from 'components/common/types';
import { Store } from 'components/common/types/Store.types';
import { InputType } from 'components/common/types/Input.types';
import SectionDivider from 'components/pages/Planner/SectionDivider';
import PlannerSecondaryPanel from 'components/pages/Planner/PlannerSecondaryPanel';
import PlannerCategoryOptions from 'components/pages/Planner/PlannerSecondaryPanel/PlannerCategoryOptions/PlannerCategoryOptions';
import { FormatLabel } from 'store/userOptions/reducer.types';
import { DirectSalesCodeNameModel } from 'components/common/types/DirectSalesCampaign.types';
import SelectionPermissionDropdown from '../SelectionPermissionDropdown/SelectionPermissionDropdown';
import { permissionOptions } from '../../consts';
import { isValueRequired } from '../../utils/isValueRequired';
import { isPanelConfirmDisabled } from '../../utils/isPanelConfirmDisabled';
import { ProductFormatsPanelProps } from './ProductFormatsPanelProps.types';
import { secondaryPanelLayoutStyles } from '../../styles';

export const ProductFormatsPanel: React.FC<ProductFormatsPanelProps> = ({ onConfirm, onCancel }) => {
  const productFormats = useSelector((state: Store) => state.productManagement.newProduct?.formats);
  const storedOptions = productFormats?.value || [];
  const defaultSelectedProductFormats =
    permissionOptions.find(({ code }) => code === productFormats?.selectionPermission) ?? permissionOptions[0];
  const [selectedPermission, setSelectedPermission] = useState<CodeNameModel>(defaultSelectedProductFormats);
  const [formats] = useState(productFormats?.value || []);
  const formatOptions = useSelector(
    (state: Store) => state.userOptions.referenceOptions.format as unknown as Record<string, CodeNameModel[]>,
  );
  const [searchText, setSearchText] = useState<string>('');
  const [selectedOptions, setSelectedOptions] = useState<DirectSalesCodeNameModel[]>([]);

  const isChange = useMemo(() => !isEqual(selectedOptions, formats), [selectedOptions, formats]);

  useEffect(() => {
    setSelectedOptions(storedOptions);
  }, [productFormats]);

  return (
    <PlannerSecondaryPanel
      title="Format"
      isDisabled={isPanelConfirmDisabled({
        field: productFormats,
        selectedPermission,
        newFieldValue: selectedOptions.length ? selectedOptions : undefined,
      })}
      onSubmit={() => onConfirm({ selectedPermission, formats: selectedOptions })}
      onCancel={onCancel}
      isChange={isChange}
      customStyles={secondaryPanelLayoutStyles}
    >
      <SelectionPermissionDropdown
        selectedPermission={selectedPermission}
        setSelectedPermission={setSelectedPermission}
      />

      {isValueRequired(selectedPermission.code) && (
        <>
          <div className="my-4">
            <SectionDivider />
          </div>

          <Label label="Select formats" />

          <div className="mb-2">
            <Input
              type={InputType.SEARCH}
              value={searchText}
              placeholder="Search formats"
              onChange={(value: string) => setSearchText(value)}
            />
          </div>

          <PlannerCategoryOptions
            searchText={searchText}
            labelMap={FormatLabel}
            selectedOptions={selectedOptions}
            options={formatOptions}
            localStateSetter={setSelectedOptions}
          />
        </>
      )}
    </PlannerSecondaryPanel>
  );
};
