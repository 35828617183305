import { useCallback, useEffect, useMemo, useState } from 'react';
import { CodeNameModel } from 'components/common/types';
import { SortDir } from 'components/common/types/SortDir.types';
import { SortSettings } from 'lib/CollapsibleList/CollapsibleList.types';
import {
  filterOptions,
  isCategoryEqual,
  sortOptions,
  updateSelectedOptions,
} from 'components/pages/Planner/PlannerSecondaryPanel/utils';

interface UseCategoryOptions {
  onCategoryDirectionSort: () => void;
  searchText: string;
  openCategory: string;
  onFilterTextChange: (value: string) => void;
  selectedOptions: CodeNameModel[];
  setSelectedOptions: React.Dispatch<React.SetStateAction<CodeNameModel[]>>;
  filteredOptions: Record<string, CodeNameModel[]>;
  getNumberOfFilteredOptions: (option: string, _selectedOptions: CodeNameModel[]) => number;
  sortDirection: SortDir;
  includedOptions: CodeNameModel[];
  excludedOptions: CodeNameModel[];
  isOptionSelected: (code: string, category: string) => boolean;
  isOptionIncluded: (code: string, category: string) => boolean;
  onOptionIncludeExcludeChange: (option: CodeNameModel) => void;
  onToggleCategory: (category: string) => void;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
}

export const useCategoryOptions = (
  options: Record<string, CodeNameModel[]>,
  initialSelectedOptions: CodeNameModel[],
): UseCategoryOptions => {
  const [selectedOptions, setSelectedOptions] = useState(initialSelectedOptions);
  const [filteredOptions, setFilteredOptions] = useState<Record<string, CodeNameModel[]>>(options);
  const [sortDirection, setSortDirection] = useState(SortDir.ASC);
  const [searchText, setSearchText] = useState<string>('');
  const [openCategory, setOpenCategory] = useState<string>('');

  useEffect(() => {
    setSelectedOptions(initialSelectedOptions);
  }, [initialSelectedOptions]);

  useEffect(() => {
    const newOptions = filterOptions(options, searchText);
    setFilteredOptions(sortOptions(newOptions, sortDirection));
  }, [options, sortDirection, searchText]);

  const onFilterTextChange = (value: string): void => {
    setSearchText(value);
  };

  const isOptionSelected = useCallback(
    (code: string, category: string) =>
      selectedOptions.some((option) => option.code === code && isCategoryEqual(category, option.category)),
    [selectedOptions],
  );

  const onOptionIncludeExcludeChange = (option: CodeNameModel): void => {
    const updatedOptions = updateSelectedOptions(selectedOptions, option);

    setSelectedOptions(updatedOptions);
  };

  const isOptionIncluded = (code: string, category: string): boolean =>
    selectedOptions?.find((option) => option.code === code && isCategoryEqual(category, option.category))?.include ||
    false;

  const includedOptions = useMemo(() => selectedOptions.filter((option) => option.include), [selectedOptions]);
  const excludedOptions = useMemo(() => selectedOptions.filter((option) => !option.include), [selectedOptions]);

  const getNumberOfFilteredOptions = (option: string, _selectedOptions: CodeNameModel[]): number => {
    return _selectedOptions.filter((selectedOption) =>
      options[option].some(
        (formatOption: CodeNameModel) =>
          formatOption.code === selectedOption.code && isCategoryEqual(option, selectedOption.category),
      ),
    ).length;
  };

  const onCategoryDirectionSort = useCallback((): void => {
    setSortDirection(SortSettings[sortDirection].nextDir);
  }, [sortDirection]);

  const onToggleCategory = useCallback(
    (category: string) => {
      if (category === openCategory) setOpenCategory('');
      else setOpenCategory(category);
    },
    [openCategory],
  );

  return {
    onCategoryDirectionSort,
    searchText,
    openCategory,
    onFilterTextChange,
    selectedOptions,
    setSelectedOptions,
    filteredOptions,
    getNumberOfFilteredOptions,
    sortDirection,
    includedOptions,
    excludedOptions,
    isOptionSelected,
    isOptionIncluded,
    onOptionIncludeExcludeChange,
    onToggleCategory,
    setSearchText,
  };
};
