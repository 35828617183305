import { useSelector } from 'react-redux';

import { formatCurrency, formatDate, formatNumber, formatPercent, formatDateNoUTC } from 'modules/I18N';
import { DASH_SLASH } from 'consts/placeholders';
import { getIsAdServer } from 'store/publisher/selectors';
import { getDealTypeLabel } from 'utils/getDealTypeLabel';
import { getDaysDiff } from 'utils/dateFormatUtil';
import Timeline, { TimelineItem } from 'components/patterns/Timeline';
import ListWithDivider from 'components/common/Deal/ListWithDivider';
import AssetsMap from 'components/common/AssetsMap';
import { Location } from 'components/common/Deal/TimelineItems';
import { SWEEP_OPTIONS_NAMES } from 'consts/deal';
import Auth from 'modules/Auth';
import { PermissionsEnum } from 'consts/permissions';
import { FeatureFlags } from 'components/common/types/Features.types';
import { useHasFeatureAccess } from 'customHooks/useHasFeatureAccess';
import DealAvailabilityInfo from './DealAvailabilityInfo';
import CpmCard from '../CpmCard';
import useCpmRange from './useCpmRange';
import DealLevelCards from '../DealLevelCards';

// eslint-disable-next-line sonarjs/cognitive-complexity
const DealInfo = () => {
  const isCpmCampaignLevel = useSelector((state) => state.dealManagement.isCpmCampaignLevel);
  const advertiser = useSelector((state) => state.dealManagement.commonDeal.advertiser);
  const brand = useSelector((state) => state.dealManagement.commonDeal.brand);
  const productCategory = useSelector((state) => state.dealManagement.commonDeal.productCategory);
  const dsp = useSelector((state) => state.dealManagement.programmatic.dsp);
  const agency = useSelector((state) => state.dealManagement.commonDeal.agency);
  const dealCpm = useSelector((state) => state.dealManagement.commonDeal.cpm);
  const externalReference = useSelector((state) => state.dealManagement.commonDeal.externalReference);
  const dealType = useSelector((state) => state.dealManagement.commonDeal.dealType);
  const lineCpm = useSelector((state) => state.dealManagement.commonDeal.currentLine.cpm);
  const {
    startDate,
    endDate,
    availability: {
      assets,
      assetCpmDistribution: { min, max, weightedMean },
    },
    tags,
    countries,
    streets,
    cities,
    postCodes,
    counties,
    impressions,
    budget,
    frames,
    sot,
    sweeps,
    environments,
    productFormats,
  } = useSelector((state) => state.dealManagement.commonDeal.summary);
  const { currencyCode, localeCode } = useSelector((state) => state.publisher.configuration);
  const isAdServerMarket = useSelector(getIsAdServer);

  const getFormattedDate = (date) =>
    Auth.hasPermission(PermissionsEnum.FEATURE_FLAG_TIMEZONE_UTC)
      ? formatDate(date, localeCode)
      : formatDateNoUTC(date, localeCode);

  const getScheduleTitle = () => {
    const days =
      getDaysDiff(
        new Date(new Date(startDate).setHours(0, 0, 0, 0)),
        new Date(new Date(endDate).setHours(0, 0, 0, 0)),
      ) + 1;

    return (
      <div className="grid grid-cols-2 gap-x-4 gap-y-1 sub-header-base" data-test-id="summary-schedule-header">
        <p>Schedule</p>
        <p>
          {days} {days > 1 ? 'days' : 'day'}
        </p>
      </div>
    );
  };

  const cpm = isCpmCampaignLevel ? dealCpm : lineCpm;

  const range = useCpmRange(cpm);

  return (
    <>
      {isAdServerMarket ? <DealLevelCards /> : <DealAvailabilityInfo />}
      <CpmCard
        range={range}
        currentValue={cpm}
        average={weightedMean}
        minDistribution={min}
        maxDistribution={max}
        isCampaignLevel
      />
      <Timeline>
        <TimelineItem
          title={
            <p className="sub-header-base" data-test-id="summary-deal-type-header">
              Deal type
            </p>
          }
          dataTestId="summary-deal-type"
        >
          <p className="body-sm">{getDealTypeLabel(dealType)}</p>
        </TimelineItem>
        <TimelineItem title={<p className="sub-header-base">Deal information</p>}>
          <div data-test-id="deal-info" className="grid grid-cols-2 gap-x-4 gap-y-1 body-sm">
            <p>Advertiser</p>
            <p>{advertiser?.name || DASH_SLASH}</p>
            <p>Brand</p>
            <p>{brand?.name || DASH_SLASH}</p>
            <p>Product category</p>
            <p>{productCategory?.name || DASH_SLASH}</p>
            <p>DSP</p>
            <p>{dsp?.name || DASH_SLASH}</p>
            <p>Agency</p>
            <p>{agency?.name || DASH_SLASH}</p>
            <p>External ref.</p>
            <p>{externalReference || DASH_SLASH}</p>
          </div>
        </TimelineItem>
        <TimelineItem
          title={
            <p className="sub-header-base" data-test-id="summary-cpm-header">
              CPM
            </p>
          }
          dataTestId="summary-cpm"
        >
          <p className="body-sm">{cpm ? formatCurrency({ value: cpm, currencyCode, localeCode }) : DASH_SLASH}</p>
        </TimelineItem>
        <TimelineItem title={getScheduleTitle()} dataTestId="summary-schedule">
          <p className="body-sm">{`${getFormattedDate(startDate)} - ${getFormattedDate(endDate)}`}</p>
        </TimelineItem>
        <TimelineItem
          title={
            <p className="sub-header-base" data-test-id="summary-environments-header">
              Environment
            </p>
          }
        >
          <ListWithDivider list={environments} dataTestId="summary-environments" />
        </TimelineItem>
        <TimelineItem
          title={
            <p className="sub-header-base" data-test-id="summary-formats-header">
              Format
            </p>
          }
        >
          <ListWithDivider list={productFormats} dataTestId="summary-formats" />
        </TimelineItem>
        <TimelineItem title={<p className="sub-header-base">Target</p>}>
          <div className="grid grid-cols-2 gap-x-4 gap-y-1 body-sm" data-test-id="summary-target">
            <p>Impressions</p>
            <p data-test-id="summary-target-impressions">
              {impressions ? formatNumber({ value: impressions, localeCode }) : DASH_SLASH}
            </p>
            <p>Budget</p>
            <p data-test-id="summary-target-budget">
              {budget ? formatNumber({ value: budget, localeCode }) : DASH_SLASH}
            </p>
            <p>Frames</p>
            <p data-test-id="summary-target-frames">
              {frames ? formatNumber({ value: frames, localeCode }) : DASH_SLASH}
            </p>
            <p>Sweep</p>
            <ListWithDivider
              list={(sweeps || []).flatMap((code) => ({ code, name: SWEEP_OPTIONS_NAMES[code] }))}
              dataTestId="summary-target-sweeps"
            />
            <p>SoT</p>
            <ListWithDivider
              list={(sot || []).flatMap((item) => ({
                code: item,
                name: formatPercent({ value: item / 100, localeCode }),
              }))}
              dataTestId="summary-target-sot"
            />
          </div>
        </TimelineItem>
        <Location countries={countries} streets={streets} cities={cities} postCodes={postCodes} counties={counties} />
        <TimelineItem
          title={
            <p className="sub-header-base" data-test-id="summary-tags-header">
              Tags
            </p>
          }
        >
          <ListWithDivider list={tags} dataTestId="summary-tags" />
        </TimelineItem>
      </Timeline>
      {useHasFeatureAccess(FeatureFlags.GOOGLEMAPS) ? (
        <div className="mt-6 mb-6 h-36" data-test-id="deal-map-container">
          <AssetsMap assets={assets} />
        </div>
      ) : null}
    </>
  );
};

export default DealInfo;
