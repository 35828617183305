import { useState } from 'react';
import { ShareCampaignPlanProps, ToggleCampaignPlanProps } from './ShareCampaignPlanModal.types';

type SetParams<T> = {
  setParams: (params: Partial<T>) => void;
};

interface UseShareCampaignPlanModal {
  modalIsOpen: boolean;
  shareCampaignPlan: ShareCampaignPlanProps & SetParams<ShareCampaignPlanProps>;
  toggleCampaignPlan: ToggleCampaignPlanProps & SetParams<ToggleCampaignPlanProps>;
  setIsOpen: (isOpen: boolean) => void;
}

export const useShareCampaignPlanModal = (): UseShareCampaignPlanModal => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [shareCampaignPlan, setShareCampaignPlan] = useState<ShareCampaignPlanProps>({
    isLoading: false,
    url: '',
    error: '',
    expiresAt: '',
  });
  const [toggleCampaignPlan, setToggleCampaignPlan] = useState<ToggleCampaignPlanProps>({
    isLoading: false,
    isChecked: false,
    isDisabled: false,
    error: '',
  });

  const setShareCampaignPlanParams = (params: Partial<ShareCampaignPlanProps>): void => {
    setShareCampaignPlan((prev: ShareCampaignPlanProps) => ({
      ...prev,
      ...params,
    }));
  };

  const setToggleCampaignPlanParams = (params: Partial<ToggleCampaignPlanProps>): void => {
    setToggleCampaignPlan((prev: ToggleCampaignPlanProps) => ({
      ...prev,
      ...params,
    }));
  };

  const setIsOpen = (isOpen: boolean): void => {
    setModalIsOpen(isOpen);
  };

  return {
    modalIsOpen,
    setIsOpen,
    shareCampaignPlan: {
      ...shareCampaignPlan,
      setParams: setShareCampaignPlanParams,
    },
    toggleCampaignPlan: {
      ...toggleCampaignPlan,
      setParams: setToggleCampaignPlanParams,
    },
  };
};
