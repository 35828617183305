import cx from 'classnames';
import { DealSummaryCardSectionProps } from './DealSummaryCardSection.types';

const DealSummaryCardSectionLineStyles = {
  header: {
    label: 'sub-header-base text-essential-primary',
    value: 'header-xl',
  },
  subheader: {
    label: 'body-base text-essential-tertiary',
    value: 'body-lg',
  },
  subheaderSecondary: {
    label: 'body-base text-essential-tertiary',
    value: 'body-lg text-essential-tertiary',
  },
};

const DealSummaryCardSection: React.FC<DealSummaryCardSectionProps> = ({
  id,
  lines = [],
  children,
  isReadOnly,
}): React.ReactElement => {
  return (
    <div
      id={id}
      data-test-id={id}
      className="flex flex-col gap-y-2 rounded-t-xl border-solid border-t border-neutral-300 p-3"
    >
      {lines.length > 0 &&
        lines.map(({ label, value, lineType }) => (
          <div key={`${label}-${value}`} className="flex items-baseline justify-between">
            <div className={DealSummaryCardSectionLineStyles[lineType].label}>{label}</div>
            <div
              data-test-id={`${id}-value`}
              className={cx(DealSummaryCardSectionLineStyles[lineType].value, {
                'text-gray-900 text-opacity-30': isReadOnly,
              })}
            >
              {value}
            </div>
          </div>
        ))}
      {children}
    </div>
  );
};

export default DealSummaryCardSection;
