const plugin = require('tailwindcss/plugin');

module.exports = {
  content: ['./src/**/*.{js,jsx,ts,tsx}'],
  theme: {
    extend: {
      content: {
        checkmark: '"\\2713\\FE0F"',
      },
      colors: {
        essential: {
          DEFAULT: 'var(--essential-primary)',
          primary: 'var(--essential-primary)',
          secondary: 'var(--essential-secondary)',
          tertiary: 'var(--essential-tertiary)',
        },
        primary: {
          DEFAULT: 'var(--primary-800)',
          50: 'var(--primary-50)',
          100: 'var(--primary-100)',
          200: 'var(--primary-200)',
          300: 'var(--primary-300)',
          400: 'var(--primary-400)',
          500: 'var(--primary-500)',
          600: {
            DEFAULT: 'var(--primary-600)',
            opacity: {
              5: 'var(--primary-600-opacity-5)',
              10: 'var(--primary-600-opacity-10)',
              20: 'var(--primary-600-opacity-20)',
              80: 'var(--primary-600-opacity-80)',
            },
          },
          700: 'var(--primary-700)',
          800: 'var(--primary-800)',
          900: {
            DEFAULT: 'var(--primary-900)',
            opacity: {
              5: 'var(--primary-900-opacity-5)',
            },
          },
          950: 'var(--primary-950)',
        },
        purple: {
          DEFAULT: 'var(--purple-500)',
          50: 'var(--purple-50)',
          100: 'var(--purple-100)',
          200: 'var(--purple-200)',
          300: 'var(--purple-300)',
          400: 'var(--purple-400)',
          500: 'var(--purple-500)',
          600: {
            DEFAULT: 'var(--purple-600)',
            opacity: {
              5: 'var(--purple-600-opacity-5)',
              10: 'var(--purple-600-opacity-10)',
              20: 'var(--purple-600-opacity-20)',
            },
          },
          700: 'var(--purple-700)',
          800: 'var(--purple-800)',
          900: 'var(--purple-900)',
          950: 'var(--purple-950)',
        },
        neutral: {
          DEFAULT: 'rgb(var(--neutral-500)',
          50: 'var(--neutral-50)',
          100: {
            DEFAULT: 'var(--neutral-100)',
            opacity: {
              5: 'var(--neutral-100-opacity-5)',
              10: 'var(--neutral-100-opacity-10)',
              30: 'var(--neutral-100-opacity-30)',
              40: 'var(--neutral-100-opacity-40)',
              50: 'var(--neutral-100-opacity-50)',
              70: 'var(--neutral-100-opacity-70)',
              80: 'var(--neutral-100-opacity-80)',
            },
          },
          200: 'var(--neutral-200)',
          300: 'var(--neutral-300)',
          400: 'var(--neutral-400)',
          500: 'var(--neutral-500)',
          600: 'var(--neutral-600)',
          700: 'var(--neutral-700)',
          800: 'var(--neutral-800)',
          900: 'var(--neutral-900)',
          950: {
            DEFAULT: 'var(--neutral-950)',
            opacity: {
              5: 'var(--neutral-950-opacity-5)',
              10: 'var(--neutral-950-opacity-10)',
              20: 'var(--neutral-950-opacity-20)',
              30: 'var(--neutral-950-opacity-30)',
              50: 'var(--neutral-950-opacity-50)',
              60: 'var(--neutral-950-opacity-60)',
              70: 'var(--neutral-950-opacity-70)',
              80: 'var(--neutral-950-opacity-80)',
              90: 'var(--neutral-950-opacity-90)',
            },
          },
        },
        sky: {
          DEFAULT: 'var(--sky-500)',
          50: 'var(--sky-50)',
          100: 'var(--sky-100)',
          200: 'var(--sky-200)',
          300: 'var(--sky-300)',
          400: 'var(--sky-400)',
          500: {
            DEFAULT: 'var(--sky-500)',
            opacity: {
              5: 'var(--sky-500-opacity-5)',
              10: 'var(--sky-500-opacity-10)',
              20: 'var(--sky-500-opacity-20)',
            },
          },
          600: 'var(--sky-600)',
          700: 'var(--sky-700)',
          800: 'var(--sky-800)',
          900: 'var(--sky-900)',
          950: 'var(--sky-950)',
        },
        green: {
          DEFAULT: 'var(--green-400)',
          50: 'var(--green-50)',
          100: 'var(--green-100)',
          200: 'var(--green-200)',
          300: 'var(--green-300)',
          400: {
            DEFAULT: 'var(--green-400)',
            opacity: {
              20: 'var(--green-400-opacity-20)',
            },
          },
          500: {
            DEFAULT: 'var(--green-500)',
            opacity: {
              5: 'var(--green-500-opacity-5)',
              10: 'var(--green-500-opacity-10)',
              20: 'var(--green-500-opacity-20)',
            },
          },
          600: 'var(--green-600)',
          700: 'var(--green-700)',
          800: 'var(--green-800)',
          900: 'var(--green-900)',
          950: 'var(--green-950)',
        },
        yellow: {
          DEFAULT: 'var(--yellow-500)',
          50: 'var(--yellow-50)',
          100: 'var(--yellow-100)',
          200: 'var(--yellow-200)',
          300: 'var(--yellow-300)',
          400: 'var(--yellow-400)',
          500: {
            DEFAULT: 'var(--yellow-500)',
            opacity: {
              20: 'var(--yellow-500-opacity-20)',
            },
          },
          600: {
            DEFAULT: 'var(--yellow-600)',
            opacity: {
              5: 'var(--yellow-600-opacity-5)',
              10: 'var(--yellow-600-opacity-10)',
              20: 'var(--yellow-600-opacity-20)',
            },
          },
          700: 'var(--yellow-700)',
          800: 'var(--yellow-800)',
          900: 'var(--yellow-900)',
          950: 'var(--yellow-950)',
        },
        pinkRed: {
          DEFAULT: 'var(--pinkRed-600)',
          50: 'var(--pinkRed-50)',
          100: 'var(--pinkRed-100)',
          200: 'var(--pinkRed-200)',
          300: 'var(--pinkRed-300)',
          400: 'var(--pinkRed-400)',
          500: 'var(--pinkRed-500)',
          600: {
            DEFAULT: 'var(--pinkRed-600)',
            opacity: {
              5: 'var(--pinkRed-600-opacity-5)',
              10: 'var(--pinkRed-600-opacity-10)',
              20: 'var(--pinkRed-600-opacity-20)',
            },
          },
          700: 'var(--pinkRed-700)',
          800: 'var(--pinkRed-800)',
          900: 'var(--pinkRed-900)',
          950: 'var(--pinkRed-950)',
        },
        lime: {
          DEFAULT: 'var(--lime-600)',
          50: 'var(--lime-50)',
          100: 'var(--lime-100)',
          200: 'var(--lime-200)',
          300: 'var(--lime-300)',
          400: 'var(--lime-400)',
          500: 'var(--lime-500)',
          600: {
            DEFAULT: 'var(--lime-600)',
            opacity: {
              5: 'var(--lime-600-opacity-5)',
              10: 'var(--lime-600-opacity-10)',
              20: 'var(--lime-600-opacity-20)',
            },
          },
          700: 'var(--lime-700)',
          800: 'var(--lime-800)',
          900: 'var(--lime-900)',
          950: 'var(--lime-950)',
        },
        orange: {
          DEFAULT: 'var(--orange-500)',
          50: 'var(--orange-50)',
          100: 'var(--orange-100)',
          200: 'var(--orange-200)',
          300: 'var(--orange-300)',
          400: 'var(--orange-400)',
          500: 'var(--orange-500)',
          600: {
            DEFAULT: 'var(--orange-600)',
            opacity: {
              5: 'var(--orange-600-opacity-5)',
              10: 'var(--orange-600-opacity-10)',
              20: 'var(--orange-600-opacity-20)',
            },
          },
          700: 'var(--orange-700)',
          800: 'var(--orange-800)',
          900: 'var(--orange-900)',
          950: 'var(--orange-950)',
        },
        blue: {
          DEFAULT: 'var(--blue-500)',
          50: 'var(--blue-50)',
          100: 'var(--blue-100)',
          200: 'var(--blue-200)',
          300: 'var(--blue-300)',
          400: 'var(--blue-400)',
          500: {
            DEFAULT: 'var(--blue-500)',
            opacity: {
              30: 'var(--blue-500-opacity-30)',
            },
          },
          600: 'var(--blue-600)',
          700: 'var(--blue-700)',
          800: 'var(--blue-800)',
          900: 'var(--blue-900)',
          950: 'var(--blue-950)',
        },
        pink: {
          DEFAULT: 'var(--pink-500)',
          50: 'var(--pink-50)',
          100: 'var(--pink-100)',
          200: 'var(--pink-200)',
          300: 'var(--pink-300)',
          400: 'var(--pink-400)',
          500: 'var(--pink-500)',
          600: {
            DEFAULT: 'var(--pink-600)',
            opacity: {
              5: 'var(--pink-600-opacity-5)',
              10: 'var(--pink-600-opacity-10)',
              20: 'var(--pink-600-opacity-20)',
            },
          },
          650: 'var(--pink-650)',
          700: 'var(--pink-700)',
          800: 'var(--pink-800)',
          900: 'var(--pink-900)',
          950: 'var(--pink-950)',
        },
        brown: {
          DEFAULT: 'var(--brown-500)',
          50: 'var(--brown-50)',
          100: 'var(--brown-100)',
          200: 'var(--brown-200)',
          300: 'var(--brown-300)',
          400: 'var(--brown-400)',
          500: 'var(--brown-500)',
          600: 'var(--brown-600)',
          700: 'var(--brown-700)',
          800: 'var(--brown-800)',
          900: 'var(--brown-900)',
          950: 'var(--brown-950)',
        },
        modalOverlay: {
          dark: 'rgba(0, 0, 0, 0.2)',
          darker: 'rgba(0, 0, 0, 0.6)',
        },
      },
      cursor: {
        grab: 'grab',
        grabbing: 'grabbing',
      },
      margin: {
        75: '18.75rem',
        86: '21.875rem',
      },
      width: {
        4.5: '1.125rem',
        22: '5.5rem',
        30: '7.5rem',
        66.4: '16.6rem',
        70: '17.5rem',
        75: '18.75rem',
        80: '20rem',
        86: '21.875rem',
        88: '22.5rem',
        95: '23.75rem',
        108: '27rem',
        120: '30rem',
        125: '31.25rem',
        200: '50rem',
        300: '75rem',
        '9/20': '45%',
        '4ch': '4ch',
      },
      minWidth: {
        7.5: '1.875rem',
        36: '9rem',
        50: '12.5rem',
        60: '15rem',
        62.5: '15.625rem',
        70: '17.5rem',
        80: '20rem',
        86: '21.875rem',
        88: '22.5rem',
        100: '25rem',
        120: '30rem',
        144: '36rem',
        150: '37.5rem',
        300: '75rem',
      },
      maxWidth: {
        '1/2': '50%',
        50: '12.5rem',
        60: '15rem',
        62.5: '15.625rem',
        65: '16.25rem',
        70: '17.5rem',
        80: '20rem',
        86: '21.875rem',
        88: '22.5rem',
        100: '25rem',
        120: '30rem',
        140: '35rem',
        144: '36rem',
        150: '37.5rem',
      },
      minHeight: {
        mainContent: 'calc(100vh - var(--header-height))',
      },
      height: {
        mainContent: 'calc(100vh - var(--header-height) - 0.8rem)',
        4.5: '1.125rem',
        30: '7.5rem',
        125: '31.25rem',
        180: '45rem',
      },
      maxHeight: {
        108: '27rem',
        popup: '80vh',
        popupContent: 'calc(80vh - 10rem)',
        mainContentWithMargin: 'calc(100vh - var(--header-height) - 1rem)',
      },
      fontFamily: {
        inter: ['Inter', 'sans-serif'],
      },
      zIndex: {
        1: 1,
        2: 2,
        51: 51,
        199: 199,
      },
      lineHeight: {
        normal: 'normal',
      },
      boxShadow: {
        dropdownMenu: '0 20px 40px 0 rgba(7, 10, 63, 0.1), 0 2px 10px 0 rgba(5, 35, 105, 0.15)',
        imageVideo: '0px 20px 40px 0px rgb(0, 0, 0, 0.2)',
        button: '0 2px 4px 0 rgba(5, 35, 105, 0.15), 0 1px 1px 0 rgba(16, 25, 189, 0.1)',
        card: '0 20px 40px -2px rgba(0, 0, 0, 0.1)',
        'inner-sm': 'var(--shadow-inner-sm)',
        'inner-md': 'var(--shadow-inner-md)',
      },
      borderWidth: {
        6: '6px',
      },
      borderColor: {
        arrowTop: 'var(--transparent-color) var(--transparent-color) currentColor var(--transparent-color)',
      },
      inset: {
        imageOverlay: 'calc(50% - calc(var(--spacing-vl) * 2))',
        6.5: '26px',
        15: '60px',
      },
      spacing: {
        '3px': '3px',
        15: '3.75rem',
        18: '4.5rem',
        22: '5.5rem',
        90: '22.859rem',
      },
      screens: {
        '2xl': '1536px',
        xxl: '1920px',
      },
      animation: {
        'spin-2s': 'spin 2s linear infinite',
        'reverse-spin': 'reverse-spin 1s linear infinite',
        'reverse-spin-2s': 'reverse-spin 2s linear infinite',
        'pop-in-0.3s': 'pop-in 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both',
        'pop-in-0.5s': 'pop-in 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both',
        'pop-out-0.15s': 'pop-out 0.15s cubic-bezier(0.250, 0.460, 0.450, 0.940) both',
        'pop-out-0.3s': 'pop-out 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both',
        'pop-out-0.5s': 'pop-out 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both',
        'moveX-to-zero': 'translateX-to-zero 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both',
        '-moveX-to-zero': '-translateX-to-zero 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both',
        'fade-in': 'fade-in 0.5s linear',
        'fade-out': 'fade-out 0.5s linear',
      },
      keyframes: {
        'reverse-spin': {
          from: {
            transform: 'rotate(360deg)',
          },
          to: {
            transform: 'rotate(0deg)',
          },
        },
        'pop-in': {
          from: {
            opacity: 0,
            transform: 'scale(0.1)',
          },
          to: {
            opacity: 1,
            transform: 'scale(1)',
          },
        },
        'pop-out': {
          from: {
            opacity: 1,
            transform: 'scale(1)',
          },
          to: {
            opacity: 0,
            transform: 'scale(0.1)',
          },
        },
        'translateX-to-zero': {
          from: {
            transform: 'translateX(100%)',
          },
          to: {
            transform: 'translateX(0)',
          },
        },
        '-translateX-to-zero': {
          from: {
            transform: 'translateX(-100%)',
          },
          to: {
            transform: 'translateX(0)',
          },
        },
        'fade-in': {
          from: {
            opacity: 0,
          },
          to: {
            opacity: 1,
          },
        },
        'fade-out': {
          from: {
            opacity: 1,
          },
          to: {
            opacity: 0,
          },
        },
      },
    },
  },
  plugins: [
    plugin(({ addComponents }) => {
      addComponents({
        '.section-header': {
          fontSize: '9px',
          lineHeight: '.75rem',
          fontWeight: '700',
          letterSpacing: '0.1em',
          textTransform: 'uppercase',
        },
        '.body-sm': {
          fontSize: '11px',
          lineHeight: '1.25rem',
          fontWeight: '400',
        },
        '.body-base': {
          fontSize: '13px',
          lineHeight: '1.25rem',
          fontWeight: '400',
        },
        '.body-lg': {
          fontSize: '15px',
          lineHeight: '1.5rem',
          fontWeight: '400',
        },
        '.sub-header-sm': {
          fontSize: '11px',
          lineHeight: '1.25rem',
          fontWeight: '500',
        },
        '.sub-header-base': {
          fontSize: '13px',
          lineHeight: '1.25rem',
          fontWeight: '500',
        },
        '.sub-header-lg': {
          fontSize: '15px',
          lineHeight: '1.5rem',
          fontWeight: '500',
        },
        '.header-base': {
          fontSize: '15px',
          lineHeight: '1.25rem',
          fontWeight: '600',
        },
        '.header-lg': {
          fontSize: '17px',
          lineHeight: '1.5rem',
          fontWeight: '600',
        },
        '.header-xl': {
          fontSize: '20px',
          lineHeight: '1.75rem',
          fontWeight: '600',
        },
        '.header-2xl': {
          fontSize: '24px',
          lineHeight: '2rem',
          fontWeight: '600',
        },
        '.header-3xl': {
          fontSize: '32px',
          lineHeight: '2.5rem',
          fontWeight: '600',
        },
      });
    }),
  ],
};
