import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { addDays, isValid } from 'date-fns';
import cx from 'classnames';
import PlannerSecondaryPanel from 'components/pages/Planner/PlannerSecondaryPanel';
import DateTimePicker from 'lib/DateTimePicker';
import { formatDate } from 'lib/DateRangePicker/DateRangePicker';
import { DirectSalesStatusOption } from 'components/common/types/DirectSalesCampaign.types';
import Checkbox from 'lib/Checkbox';
import { CampaignDuplicationModalProps } from './CampaignDuplicationModal.types';
import useCampaignDuplication from './useCampaignDuplication';

const CampaignDuplicationModal: React.FC<CampaignDuplicationModalProps> = ({
  isOpen,
  onClose,
  campaignId = '',
  dealLineStates,
}) => {
  const handleCampaignDuplication = useCampaignDuplication();
  const [selectedDate, setSelectedDate] = useState(new Date(''));
  const [areCancelledLinesIncluded, setAreCancelledLinesIncluded] = useState(true);

  const dateLabel = isValid(selectedDate) ? formatDate(selectedDate) : 'Select date';

  const areAllLinesCancelled = dealLineStates.length
    ? dealLineStates.every((state) => state === DirectSalesStatusOption.CANCELLED)
    : false;

  useEffect(() => {
    setSelectedDate(new Date(''));

    if (!areCancelledLinesIncluded) setAreCancelledLinesIncluded(true);
  }, [isOpen]);

  const handleDuplicationConfirm = async (): Promise<void> => {
    await handleCampaignDuplication({ selectedDate, areCancelledLinesIncluded, campaignId, onComplete: onClose });
  };

  return (
    <ReactModal
      className="absolute w-full h-full flex justify-center items-center p-4"
      overlayClassName="flex bg-transparent/30"
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <PlannerSecondaryPanel
        customStyles={{ height: '100%', left: undefined, top: undefined }}
        title="Duplicate Campaign"
        onCancel={onClose}
        showBackButton={false}
        onClose={onClose}
        onSubmit={handleDuplicationConfirm}
        cancelButtonLabel="Discard"
        showName={false}
        isDisabled={!isValid(selectedDate)}
      >
        <div data-test-id="campaign-duplicate-modal">
          <div className="p-5 gap-0.5 -m-3 mb-4 bg-primary-100 body-sm leading-4">
            <p className="font-medium">Select campaign start date</p>
            <p>
              Select the earliest deal line start date for the duplicate campaign. Please note all dates can be edited
              after duplication.
            </p>
          </div>
          <div data-test-id="duplicate-campaign-selected-date">
            <button
              className="w-full rounded-md p-2.5 bg-white text-left border border-primary-600 ring-1 ring-primary-600 bg-primary-50"
              type="button"
            >
              <p className="text-essential-primary body-sm font-medium leading-4">Start date</p>
              <p className="text-essential-tertiary body-base">{dateLabel}</p>
            </button>
          </div>
          <div className="flex w-full justify-center pt-4">
            <DateTimePicker
              value={selectedDate}
              disabledDays={{ before: addDays(new Date(), 1) }}
              onChange={setSelectedDate}
              isTimePickerEnabled={false}
            />
          </div>
          <div
            data-test-id="duplicate-cancelled-lines-checkbox"
            className={cx('py-4', {
              'cursor-not-allowed': areAllLinesCancelled,
            })}
          >
            <div
              role="presentation"
              className={cx('flex items-center gap-2', {
                'pointer-events-none text-neutral-500': areAllLinesCancelled,
              })}
              onClick={() => setAreCancelledLinesIncluded(!areCancelledLinesIncluded)}
            >
              <Checkbox isSelected={areCancelledLinesIncluded} />
              <p className="body-base font-medium select-none">Duplicate cancelled deal lines</p>
            </div>
          </div>
        </div>
      </PlannerSecondaryPanel>
    </ReactModal>
  );
};

export default CampaignDuplicationModal;
