import React from 'react';
import moneySvg from 'assets/icons/money.svg';
import SectionButton from 'lib/SectionButton';
import { SectionButtonTheme } from 'lib/SectionButton/SectionButton.types';
import { PricingModeButtonProps } from './PricingModeButton.types';

const PricingModeButton: React.FC<PricingModeButtonProps> = ({
  isActive,
  onClear,
  onClick,
  selectedValue,
  isReadOnly,
  isRequired,
  isClearAllDisabled = false,
  onShowMoreMenuClick,
}) => (
  <SectionButton
    dataTestId="section-button-pricing-mode"
    label="Pricing mode"
    icon={moneySvg}
    onClick={onClick}
    theme={SectionButtonTheme.SOLID}
    isActive={isActive}
    selected={!selectedValue.length ? undefined : selectedValue}
    onClear={onClear}
    isDisabled={isReadOnly}
    isRequired={isRequired}
    isClearAllDisabled={isClearAllDisabled}
    onShowMoreMenuClick={onShowMoreMenuClick}
  />
);

export default PricingModeButton;
