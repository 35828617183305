import SVG from 'react-inlinesvg';
import warningSvg from 'assets/icons/diamond_warning.svg';

interface Props {
  header: string;
  errorMessage: string;
}

export const Error: React.FC<Props> = ({ header, errorMessage }) => {
  return (
    <div className="flex flex-col space-y-4 w-full max-w-lg mx-auto rounded-lg p-4 relative bg-pinkRed-200 border border-gray-200 text-pinkRed-950">
      <div className="flex flex-row space-x-3 items-center">
        <SVG src={warningSvg} className="w-5 h-5" />
        <h2 className="sub-header-base">{header}</h2>
      </div>
      <div className="space-y-1">
        <p className="body-base">{errorMessage}</p>
      </div>
    </div>
  );
};
