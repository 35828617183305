import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import SVG from 'react-inlinesvg';
import Auth from 'modules/Auth';
import Session from 'modules/Session';
import { getPublisherConfiguration, getPublisherFeatures, getDirectSalesPublisherPricing } from 'modules/api/publisher';
import { dataDogLogExternalPublisherId } from 'modules/DataDog';
import { updatePendoOptions } from 'vendor/pendo';
import { changeEnvironmentId } from 'store/environment/reducer';
import {
  changeConfiguration,
  changeFeatures,
  changeDirectSalesPricing,
  clearPublisherData,
} from 'store/publisher/reducer';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import { mergePublisherAndEnvironmentFeatures } from 'utils/mergePublisherAndEnvironmentFeatures';
import { formatMarket } from 'utils/formatters';
import arrowUp from 'assets/icons/arrow_up.svg';
import arrowDown from 'assets/icons/arrow_down.svg';
import chevronRightSvg from 'assets/icons/chevron_right.svg';
import { Preloader } from 'components/patterns/Loader';
import { resetDealConfig } from 'store/dealConfig/reducer';
import { changeAvailableMediaOwners } from 'store/reseller/reducer';
import multiPublisherRequest from 'modules/api/multiPublisherRequest';
import { PermissionsEnum } from 'consts/permissions';
import { PublisherFeatures, PublisherType } from 'components/common/types/Publisher.types';
import { datadogLogs } from '@datadog/browser-logs';
import { SortOrder } from './Landing.types';

// eslint-disable-next-line sonarjs/cognitive-complexity
const Landing: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cancelFunctions = useCancelRequest();

  const { email, markets, userName } = Auth.getData();
  const isUserReseller = Auth.hasPermission(PermissionsEnum.RESELLER_ONLY);

  const [environments, setEnvironments] = useState<string[]>([]);
  const [sortOrder, setSortOrder] = useState(SortOrder.NONE);
  const [isLoadingPublisherConfig, setIsLoadingPublisherConfig] = useState(false);

  const handleSelectEnvironment = async (environmentId: string): Promise<void> => {
    if (process.env.NODE_ENV === 'production') {
      updatePendoOptions(email, environmentId, Session.getEnvironmentId());
    }

    try {
      setIsLoadingPublisherConfig(true);

      const configuration = await getPublisherConfiguration({ envId: environmentId, cancelFunctions });
      dispatch(changeConfiguration(configuration));

      const publisherFeatures = await getPublisherFeatures({ envId: environmentId, cancelFunctions });
      const features = mergePublisherAndEnvironmentFeatures(environmentId, publisherFeatures);
      dispatch(changeFeatures(features));

      const publisherPricing = await getDirectSalesPublisherPricing({ envId: environmentId, cancelFunctions });
      dispatch(changeDirectSalesPricing(publisherPricing));

      Session.setEnvironmentId(environmentId);
      dataDogLogExternalPublisherId();
      dispatch(changeEnvironmentId(environmentId));

      setIsLoadingPublisherConfig(false);

      navigate('/insights');
    } catch (e) {
      setIsLoadingPublisherConfig(false);
    }
  };

  const handleSelectDefaultEnvironment = async (): Promise<void> => {
    if (markets.length === 0) {
      navigate('/accessdenied');

      return;
    }

    if (markets.length === 1) {
      handleSelectEnvironment(markets[0]);
    }
  };

  const handleSortEnvironments = (): void => {
    const selectedSortOrder =
      sortOrder === SortOrder.NONE || sortOrder === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC;

    const sortedEnvironments = environments.sort((environment1, environment2) => {
      const lowerCaseEnvironment1 = environment1.toLowerCase().replace(/market:/g, '');
      const lowerCaseEnvironment2 = environment2.toLowerCase().replace(/market:/g, '');

      return selectedSortOrder === SortOrder.ASC
        ? lowerCaseEnvironment1.localeCompare(lowerCaseEnvironment2)
        : lowerCaseEnvironment2.localeCompare(lowerCaseEnvironment1);
    });

    setSortOrder(selectedSortOrder);
    setEnvironments(sortedEnvironments);
  };

  const loadEnvironments = async (): Promise<void> => {
    try {
      if (isUserReseller) {
        setIsLoadingPublisherConfig(true);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const response = (await multiPublisherRequest(markets, getPublisherConfiguration as any, {
          cancelFunctions,
        })) as PublisherFeatures[];
        const resellerPublishers = response
          .filter((publisherConfiguration) => publisherConfiguration.type === PublisherType.RESELLER)
          .map((reseller) => reseller.identifier);

        setEnvironments(resellerPublishers);
        setIsLoadingPublisherConfig(false);
      } else {
        setEnvironments(markets);
      }
    } catch (error) {
      datadogLogs.logger.error('Loading environments failed', { error });
    }
  };

  useEffect(() => {
    if (markets.length > 0) {
      loadEnvironments();
    }

    Session.setEnvironmentId('');
    dispatch(clearPublisherData());
    dispatch(changeEnvironmentId(''));
    dispatch(resetDealConfig());
    dispatch(changeAvailableMediaOwners([]));

    handleSelectDefaultEnvironment();
  }, []);

  return (
    <div className="h-full flex justify-center pt-4 overflow-auto">
      <div className="w-80">
        <h1 className="text-center mx-5">
          <span className="header-2xl text-sky-600">Hello!</span>
          <span className="block header-2xl text-sky">{userName || 'Dear user'}</span>
        </h1>
        <button
          type="button"
          className="flex pl-6 py-3 justify-start items-center group"
          onClick={handleSortEnvironments}
          data-test-id="select-environment-button"
        >
          <p className="py-2.5 px-4 sub-header-lg text-neutral-950-opacity-60">Select environment</p>
          <SVG
            className={
              sortOrder === SortOrder.NONE
                ? 'ml-3 text-neutral-950-opacity-60 opacity-0 group-hover:opacity-100 transition-opacity duration-100'
                : 'ml-3 text-neutral-950-opacity-60'
            }
            src={sortOrder === SortOrder.ASC ? arrowDown : arrowUp}
          />
        </button>
        <div className="px-4 rounded-lg border border-neutral-300 bg-neutral-100">
          <ul className="p-0 m-0 w-full list-none">
            {environments.map((environment) => (
              <li key={environment} className="body-base w-full border-b border-neutral-200">
                <button
                  className="px-5 py-4 w-full flex justify-between items-center"
                  type="button"
                  onClick={() => handleSelectEnvironment(environment)}
                >
                  {formatMarket(environment)}
                  <SVG src={chevronRightSvg} className="text-neutral-200" />
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <Preloader isLoading={isLoadingPublisherConfig} isFixed />
    </div>
  );
};

export default Landing;
