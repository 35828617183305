import config from 'config';

export const createCampaignPlanUrlFromToken = (shareCampaignPlanToken: string): string => {
  if (!shareCampaignPlanToken) return '';

  const url = new URL(`${config.app.protocol}://${config.app.host}`);
  if (config.app.port) {
    url.port = config.app.port;
  }
  url.pathname = `/campaign-plan/${shareCampaignPlanToken}`;

  return url.toString();
};
