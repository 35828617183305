import { useDispatch, useSelector } from 'react-redux';
import { ShadowStyle } from 'consts/shadow';
import { changeDealInfo } from 'store/dealManagement/actions';
import { Label } from 'lib/Label';
import { Padding } from 'lib/BaseSelect/BaseSelect.types';
import { InputShape, InputSize, InputTheme } from 'components/common/types/Input.types';
import AsyncSelect from 'components/patterns/AsyncSelect';
import useIsReadOnly from 'components/pages/Planner/useIsReadOnly';
import { Store } from 'components/common/types/Store.types';
import { CodeNameModel } from 'components/common/types';
import { isReadOnly } from 'utils/isReadOnly';
import { getIsReseller } from 'store/publisher/selectors';
import { useLoadLookup } from 'components/common/FilterArea/Filters/useLoadLookup';

const SelectSpecialist: React.FC = () => {
  const dispatch = useDispatch();

  const specialist = useSelector((state: Store) => state.dealManagement.commonDeal.specialist);
  const isEditingDisabled = useSelector((state: Store) => state.dealManagement.isEditingDisabled);
  const bookingStatusCode = useSelector((state: Store) => state.dealManagement.commonDeal.bookingStatusCode);
  const isReseller = useSelector(getIsReseller);

  const { onLoadLookup } = useLoadLookup();

  const { isCampaignReadOnly } = useIsReadOnly();

  const handleSelectSpecialist = (key: string, value: CodeNameModel): Promise<void> =>
    dispatch(changeDealInfo({ [key]: value }));

  if (isReseller) return null;

  return (
    <>
      <Label id="specialist" theme={InputTheme.FLAT_GRAY} label="Specialist" />
      <AsyncSelect
        name="specialist"
        dataTestId="async-select-specialist"
        placeholder=""
        theme={InputTheme.FLAT_GRAY}
        size={InputSize.EXTRA_SMALL}
        padding={Padding.NONE}
        shadow={ShadowStyle.NONE}
        shape={InputShape.ROUNDED}
        selectedValue={specialist}
        loadData={onLoadLookup}
        onSelect={handleSelectSpecialist}
        defaultOptions={specialist}
        isDisabled={isReadOnly(bookingStatusCode, isEditingDisabled) || isCampaignReadOnly}
      />
    </>
  );
};

export default SelectSpecialist;
