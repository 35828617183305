import cx from 'classnames';
import syncedSvg from 'assets/icons/synced.svg';
import excludeSvg from 'assets/icons/exclude.svg';
import SVG from 'react-inlinesvg';
import { CodeNameModel } from 'components/common/types';
import { IncludeExcludeToggleProps } from './IncludeExcludeToggle.types';

const getUpdatedOption = (option: CodeNameModel, isOptionNameSelectable: boolean, include: boolean): CodeNameModel => {
  if (option.include === include && !isOptionNameSelectable) {
    return { ...option, include: undefined };
  }

  return { ...option, include };
};

const IncludeExcludeToggle: React.FC<IncludeExcludeToggleProps> = ({
  onIncludeExcludeChange,
  option,
  isHovered,
  isSelected,
  isAlwaysVisible,
  isOptionNameSelectable,
}: IncludeExcludeToggleProps) => {
  if (!isAlwaysVisible && !isHovered && !isSelected) return null;

  return (
    <div className="flex rounded-sm">
      <button
        data-test-id="include-exclude-button-exclude"
        disabled={!isSelected && !isAlwaysVisible}
        className={cx('flex items-center w-content h-[28px] cursor-pointer border px-2 py-1', {
          'shadow-sm rounded-l-sm': isSelected && !option.include,
          'bg-neutral-950-opacity-5 opacity-60 border-transparent rounded-l-sm': !isSelected || option.include,
          'border-transparent': isHovered && !isSelected,
        })}
        type="button"
        onClick={() => {
          const updatedOption = getUpdatedOption(option, isOptionNameSelectable, false);
          onIncludeExcludeChange(updatedOption);
        }}
      >
        <SVG src={excludeSvg} className="w-4 h-4" />
      </button>
      <button
        data-test-id="include-exclude-button-include"
        disabled={!isSelected && !isAlwaysVisible}
        className={cx('flex items-center w-content h-[28px] px-2 py-1 cursor-pointer border', {
          'shadow-sm rounded-r-sm': isSelected && option.include,
          'bg-neutral-950-opacity-5 opacity-60 rounded-r-sm border-transparent': !isSelected || !option.include,
          'border-transparent': isHovered && !isSelected,
        })}
        type="button"
        onClick={() => {
          const updatedOption = getUpdatedOption(option, isOptionNameSelectable, true);
          onIncludeExcludeChange(updatedOption);
        }}
      >
        <SVG src={syncedSvg} className="w-4 h-4" />
      </button>
    </div>
  );
};

export default IncludeExcludeToggle;
