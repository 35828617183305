import cx from 'classnames';
import { CircularLoadingSpinner } from 'lib/CircularLoadingSpinner/CircularLoadingSpinner';

type Props = {
  isLoading?: boolean;
  isFixed?: boolean;
};

export const Preloader: React.FC<Props> = ({ isLoading = true, isFixed = false }) => {
  if (!isLoading) return null;

  return (
    <div
      className={cx('w-full h-full top-0 bottom-0 left-0 right-0 m-auto bg-neutral-950-opacity-10 z-199', {
        fixed: isFixed,
        absolute: !isFixed,
      })}
      data-test-id="loader-container"
    >
      <CircularLoadingSpinner />
    </div>
  );
};
