import React from 'react';
import ShowMoreMenu from 'lib/ShowMoreMenu';
import SVG from 'react-inlinesvg';
import cx from 'classnames';
import eraseSvg from 'assets/icons/erase.svg';
import eyeSvg from 'assets/icons/eye_empty.svg';
import cloneSvg from 'assets/icons/clone.svg';
import SectionDivider from 'components/pages/Planner/SectionDivider';
import { useToggle } from 'customHooks/useToggle';
import { SectionButtonMenuProps } from './SectionButtonMenu.props';

const SectionButtonMenu: React.FC<SectionButtonMenuProps> = ({
  onClear,
  onToggle,
  onDuplicate,
  isCollapsed,
  isToggleDisabled,
  align,
  isEllipsisDisabled = false,
  clearButtonLabel = 'Clear',
  isClearAllDisabled = false,
  onShowMoreMenuClick,
}) => {
  const { isOpen, toggle, close } = useToggle(false);

  const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, actionCallback: VoidFunction): void => {
    e.stopPropagation();
    close();
    actionCallback();
  };

  return (
    <ShowMoreMenu
      isOpen={isOpen}
      onToggle={toggle}
      align={align}
      isDisabled={isEllipsisDisabled}
      svgClassName="rotate-90 cursor-pointer"
      onClick={onShowMoreMenuClick}
    >
      {onDuplicate ? (
        <button
          className="py-2 px-2 w-full flex justify-start items-center text-essential-secondary body-base space-x-2 enabled:hover:bg-primary-50"
          type="button"
          onClick={(e) => onClick(e, onDuplicate)}
        >
          <p className="w-10/12 text-left body-base truncate whitespace-nowrap overflow-hidden">Duplicate</p>
          <SVG src={cloneSvg} className="w-4 h-4" />
        </button>
      ) : (
        <button
          className={cx(
            'py-2 px-2 w-full flex justify-start items-center text-essential-secondary body-base space-x-2 enabled:hover:bg-primary-50',
            {
              'cursor-not-allowed opacity-50': isToggleDisabled,
            },
          )}
          type="button"
          onClick={(e) => onClick(e, onToggle)}
          disabled={isToggleDisabled}
        >
          <p className="w-10/12 text-left body-base truncate whitespace-nowrap overflow-hidden">
            {isCollapsed ? 'Show more' : 'Show less'}
          </p>
          <SVG src={eyeSvg} className="w-4 h-4" />
        </button>
      )}
      <SectionDivider />
      <button
        className={cx(
          'py-2 px-2 w-full flex justify-start items-center body-base space-x-2 enabled:hover:bg-primary-50',
          {
            'text-red-900': !isClearAllDisabled,
            'cursor-not-allowed opacity-50 text-essential-secondary': isClearAllDisabled,
          },
        )}
        type="button"
        disabled={isClearAllDisabled}
        onClick={(e) => onClick(e, onClear)}
      >
        <p className="w-10/12 text-left body-base truncate whitespace-nowrap overflow-hidden">{clearButtonLabel}</p>
        <SVG src={eraseSvg} className="w-4 h-4" />
      </button>
    </ShowMoreMenu>
  );
};

export default SectionButtonMenu;
