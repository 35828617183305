import ProductScheduleRange from 'components/pages/PCM/Products/ProductFields/ProductScheduleRange/ProductScheduleRange';
import ProductPricingMode from '../../Products/ProductFields/ProductPricingMode/ProductPricingMode';
import ProductMediaType from '../../Products/ProductFields/ProductMediaType/ProductMediaType';
import ProductNetworks from '../../Products/ProductFields/ProductNetworks/ProductNetworks';
import { ProductFormats } from '../../Products/ProductFields/ProductFormats/ProductFormats';
import { ProductObjectives } from '../../Products/ProductFields/ProductObjectives/ProductObjectives';
import ProductChannels from '../../Products/ProductFields/ProductChannels/ProductChannels';
import { CreateProductHeader } from './CreateProductHeader/CreateProductHeader';
import { SectionWrapper } from './SectionWrapper/SectionWrapper';
import { ProductTargets } from '../../Products/ProductFields/ProductTargets/ProductTargets';
import ProductLocations from '../../Products/ProductFields/ProductLocations/ProductLocations';

export const CreateProductContent: React.FC = () => {
  return (
    <div className="p-4" data-test-id="create-product-content">
      <div className="px-2 py-3 min-w-[450px]">
        <CreateProductHeader />
        <SectionWrapper title="Setting:">
          <ProductPricingMode />
        </SectionWrapper>
        <SectionWrapper title="Deal Line Info:">
          <ProductScheduleRange />
          <ProductMediaType />
          <ProductChannels />
        </SectionWrapper>
        <SectionWrapper title="Filters:">
          <ProductFormats />
          <ProductLocations />
        </SectionWrapper>
        <SectionWrapper title="Targets:">
          <ProductTargets />
          <ProductNetworks />
        </SectionWrapper>
        <SectionWrapper title="Objectives:">
          <ProductObjectives />
        </SectionWrapper>
      </div>
    </div>
  );
};
