import { ReactElement } from 'react';

const COMMON_STYLE = 'whitespace-nowrap flex items-center gap-1 rounded-full px-2 py-0.5 text-sm';

export const CollapsibleListStatsStyle = {
  PINK_RED: `${COMMON_STYLE} bg-pinkRed-100 text-pinkRed-800`,
  GREEN: `${COMMON_STYLE} bg-green-50 text-green-800`,
  PRIMARY: `${COMMON_STYLE} bg-primary-50 text-primary-600`,
  NEUTRAL: `${COMMON_STYLE} bg-neutral-100 text-neutral-600`,
};

type CollapsibleListStatsStyleType = (typeof CollapsibleListStatsStyle)[keyof typeof CollapsibleListStatsStyle];

export interface CollapsibleListStatsHeaderProps {
  included?: number;
  excluded?: number;
  available?: number;
  availableLengthSuffix?: ReactElement;
  isDisabled?: boolean;
  includedStyle?: CollapsibleListStatsStyleType;
  excludedStyle?: CollapsibleListStatsStyleType;
}
