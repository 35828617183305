import { DirectSalesState, DirectSalesStatusOption } from 'components/common/types/DirectSalesCampaign.types';
import { Color } from 'lib/Chip/Chip.types';

export interface StatusPanelProps {
  lineState: DirectSalesState;
}

export enum DirectSalesStatusLabel {
  DRAFT = 'Draft',
  OPTION = 'Option',
  PROPOSAL = 'Proposal',
  CONFIRMED = 'Confirmed',
  CANCELLED = 'Cancelled',
  DELETED = 'Deleted',
  MIXED = 'Mixed',
}

interface StatusSettingsRecord {
  text: DirectSalesStatusLabel;
  style: Color;
}

export type StatusSettings = Record<DirectSalesStatusOption, StatusSettingsRecord>;

const DRAFT_SETTINGS = {
  text: DirectSalesStatusLabel.DRAFT,
  style: Color.YELLOW,
};

export const STATUS_SETTINGS: StatusSettings = {
  [DirectSalesStatusOption.DRAFT_UPDATING]: DRAFT_SETTINGS,
  [DirectSalesStatusOption.DRAFT_SOLVED]: DRAFT_SETTINGS,
  [DirectSalesStatusOption.DRAFT_FAILED_SOLVE]: DRAFT_SETTINGS,
  [DirectSalesStatusOption.DRAFT_PRICED]: DRAFT_SETTINGS,
  [DirectSalesStatusOption.DRAFT_FAILED_PRICE]: DRAFT_SETTINGS,
  [DirectSalesStatusOption.DRAFT_SOLVING]: DRAFT_SETTINGS,
  [DirectSalesStatusOption.DRAFT_PROPOSAL_SOLVING]: DRAFT_SETTINGS,
  [DirectSalesStatusOption.DRAFT_SOLVING_OPTION_RESERVATION]: DRAFT_SETTINGS,
  [DirectSalesStatusOption.DRAFT_SOLVING_CONFIRMED_RESERVATION]: DRAFT_SETTINGS,
  [DirectSalesStatusOption.MIXED]: {
    text: DirectSalesStatusLabel.MIXED,
    style: Color.YELLOW,
  },
  [DirectSalesStatusOption.OPTION]: {
    text: DirectSalesStatusLabel.OPTION,
    style: Color.ORANGE,
  },
  [DirectSalesStatusOption.PROPOSAL]: {
    text: DirectSalesStatusLabel.PROPOSAL,
    style: Color.PRIMARY,
  },
  [DirectSalesStatusOption.CONFIRMED]: {
    text: DirectSalesStatusLabel.CONFIRMED,
    style: Color.GREEN,
  },
  [DirectSalesStatusOption.CANCELLED]: {
    text: DirectSalesStatusLabel.CANCELLED,
    style: Color.GREY,
  },
  [DirectSalesStatusOption.DELETED]: {
    text: DirectSalesStatusLabel.DELETED,
    style: Color.GREY,
  },
};
