import { useSelector } from 'react-redux';
import cx from 'classnames';
import { getIsAdServer } from 'store/publisher/selectors';
import Button, { Align, Color, Size } from 'lib/Button';
import { Store } from 'components/common/types/Store.types';
import { Line } from 'components/common/types/Deal.types';
import Tooltip, { TooltipDirection, TooltipSize, TooltipTheme } from 'components/patterns/Tooltip';
import LineSelect from 'components/pages/Planner/PlannerHeader/LineSelect';
import useCampaignType from 'components/pages/Planner/hooks/useCampaignType';
import { CampaignPlannerMode } from 'store/dealManagement/reducer';
import { getIsAnyLineFetchingAvailability, getIsLineBeingSaved } from 'store/dealManagement/selectors';
import { DealLinesProps } from './DealLines.types';
import { DirectSalesLockedLineStatusIcon, getIsDirectSalesLineLocked } from '../../LockStateStatusIcon';
import AddNewLineButton from './AddNewLineButton/AddNewLineButton';

export const DealLines: React.FC<DealLinesProps> = ({
  displayedLines,
  displayDealLinesDropdown,
  onLineSelect,
  onNewLineClick,
  isAddNewLineDisabled,
  isSelectLineDisabled,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const lines = useSelector((state: Store) => state.dealManagement.backupFormData.lines);
  const dealId = useSelector((state: Store) => state.dealManagement.commonDeal.dealId);
  const currentLine = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine);
  const isEditingDisabled = useSelector((state: Store) => state.dealManagement.isEditingDisabled);
  const isAdServerMarket = useSelector(getIsAdServer);
  const isReviewMode =
    useSelector((state: Store) => state.dealManagement.campaignPlannerMode) === CampaignPlannerMode.REVIEW;
  const { isDirectSalesCampaignType } = useCampaignType();
  const isFetchingAvailability = useSelector(getIsAnyLineFetchingAvailability);
  const isLineBeingSaved = useSelector(getIsLineBeingSaved);

  const noDirectSalesCampaign = isDirectSalesCampaignType && !dealId;

  const isLineActive = (index: number, line: Line): boolean => {
    const isSingleLine = index === 0 && displayedLines.length === 1;

    if (isDirectSalesCampaignType) return isSingleLine || currentLine.lineId === line.lineId;
    return isSingleLine || isAdServerMarket ? currentLine.lineId === line.lineId : currentLine.id === line.id;
  };

  return (
    <>
      {displayedLines?.map((line, index) => {
        const isDisplayedLineActive = isLineActive(index, line);
        const isLineButtonDisabled =
          noDirectSalesCampaign ||
          ((isSelectLineDisabled || isEditingDisabled || isFetchingAvailability || isLineBeingSaved) &&
            !isDisplayedLineActive);
        const isLineLocked = getIsDirectSalesLineLocked(line);

        const getButtonColor = (): Color => {
          if (isLineLocked && isDisplayedLineActive) return Color.ORANGE;
          return isDisplayedLineActive ? Color.TERTIARY : Color.BLACK_AND_WHITE;
        };

        return (
          <Tooltip
            tooltip={line.name}
            direction={TooltipDirection.BOTTOM}
            contentSize={TooltipSize.MAX}
            theme={TooltipTheme.DARK}
            delay
            key={`${line.name}-${line.id}`}
          >
            <Button
              onClick={() => (isDisplayedLineActive ? undefined : onLineSelect?.(line))}
              color={getButtonColor()}
              dataTestId={`planner-header-button-${line.name?.replace(/ /g, '')}`}
              isDisabled={isLineButtonDisabled}
              size={Size.SMALL}
              label={!line.name ? 'Deal line 1' : line.name}
              align={Align.START}
              classNames={cx('border flex flex-row-reverse max-w-[15ch] overflow-hidden leading-tight', {
                'border-primary-600': isDisplayedLineActive && !isLineLocked,
              })}
            >
              {isLineLocked && <DirectSalesLockedLineStatusIcon />}
            </Button>
          </Tooltip>
        );
      })}
      {displayDealLinesDropdown ? (
        <LineSelect
          lines={lines}
          onSelectOne={onLineSelect}
          onNewLineClick={onNewLineClick}
          selectedLineId={currentLine.lineId}
          isDisabled={isEditingDisabled}
          isAddNewLineDisabled={isAddNewLineDisabled}
          isSelectLineDisabled={isSelectLineDisabled}
        />
      ) : (
        !isReviewMode && <AddNewLineButton isDisabled={isAddNewLineDisabled} onClick={onNewLineClick} />
      )}
    </>
  );
};
