import { FC, useEffect, useMemo, useState } from 'react';

import { isEqual } from 'lodash';

import { useDispatch, useSelector } from 'react-redux';
import { changePlannerSecondaryPanelType, PlannerSecondaryPanelType } from 'store/dealManagement/reducer';

import PlannerSecondaryPanel from 'components/pages/Planner/PlannerSecondaryPanel';
import { useCategoryOptions } from 'components/pages/Planner/PlannerSecondaryPanel/useCategoryOptions';
import { canDisplayOptionResults } from 'components/pages/Planner/PlannerSecondaryPanel/utils';
import CollapsibleList from 'lib/CollapsibleList';
import CollapsibleListStatsHeader from 'lib/CollapsibleList/CollapsibleListStatsHeader';
import Input from 'lib/Input';
import SelectOption from 'lib/SelectOption';

import { CodeNameModel } from 'components/common/types';
import { InputType } from 'components/common/types/Input.types';
import { Store } from 'components/common/types/Store.types';
import { CodeName } from 'components/common/types/CodeName.types';
import { DuplicationPanelProps } from './DuplicationPanel.types';

const DuplicationPanel: FC<DuplicationPanelProps> = ({ organisations, setOrganisations }) => {
  const dispatch = useDispatch();

  const organizationOptions = useSelector((state: Store) => state.userOptions.advertiserOptions);

  const [tmpOrganisations, setTmpOrganisations] = useState<CodeName[]>(organisations);

  const advertiserOptionsWithCategory = useMemo(() => {
    return { advertisers: organizationOptions.map((organisation) => ({ ...organisation, category: 'advertisers' })) };
  }, [organizationOptions]);

  const organizationsWithGroup = useMemo(() => {
    return tmpOrganisations.map((organisation) => ({ ...organisation, category: 'advertisers' }));
  }, [tmpOrganisations]);

  const {
    searchText,
    selectedOptions: selectedOrganisations,
    onCategoryDirectionSort,
    filteredOptions,
    getNumberOfFilteredOptions,
    sortDirection,
    includedOptions,
    excludedOptions,
    isOptionSelected,
    onFilterTextChange,
    onOptionIncludeExcludeChange,
  } = useCategoryOptions(advertiserOptionsWithCategory, organizationsWithGroup);

  const isChange = useMemo(
    () => !isEqual(organizationsWithGroup, selectedOrganisations),
    [organizationsWithGroup, selectedOrganisations],
  );

  const onSubmit = (): void => {
    setOrganisations(excludedOptions.map(({ code, name }) => ({ code, name })));
    dispatch(changePlannerSecondaryPanelType(PlannerSecondaryPanelType.EXCLUSION_CRITERIA));
  };

  useEffect(() => {
    setTmpOrganisations(organisations);
  }, [organisations]);

  useEffect(() => {
    setTmpOrganisations(excludedOptions);
  }, [isChange]);

  return (
    <PlannerSecondaryPanel
      title="Add duplication advertisers"
      customStyles={{ left: '430px' }}
      isLineLevel={false}
      confirmButtonLabel="Save"
      isChange={isChange}
      onSubmit={onSubmit}
      onCancel={() => dispatch(changePlannerSecondaryPanelType(PlannerSecondaryPanelType.EXCLUSION_CRITERIA))}
    >
      <div className="mb-2">
        <Input
          type={InputType.SEARCH}
          value={searchText}
          placeholder="Search advertisers"
          onChange={onFilterTextChange}
        />
      </div>

      {searchText.length > 2 && canDisplayOptionResults(filteredOptions) ? (
        <>
          <CollapsibleList
            label="Advertisers"
            isSortVisible
            onSort={onCategoryDirectionSort}
            sortDir={sortDirection}
            isOpen
            isClearAllVisible={false}
            key="group-advertisers"
            headerComponent={
              <CollapsibleListStatsHeader
                available={filteredOptions.advertisers.length}
                included={getNumberOfFilteredOptions('advertisers', includedOptions)}
                excluded={getNumberOfFilteredOptions('advertisers', excludedOptions)}
              />
            }
          >
            {filteredOptions.advertisers.map(({ code, name, category }) => {
              return (
                <SelectOption
                  value={{ code, name }}
                  key={`select-category-${code}`}
                  isSelected={isOptionSelected(code, 'advertisers')}
                  isDisabled={false}
                  isMultiple
                  hasCheckbox
                  isIncludeExcludeAlwaysVisible
                  classNames="!m-0"
                  onClick={(value: CodeNameModel) =>
                    onOptionIncludeExcludeChange({
                      ...value,
                      category,
                    })
                  }
                >
                  <p className="max-w-[250px] truncate">{name}</p>
                </SelectOption>
              );
            })}
          </CollapsibleList>
        </>
      ) : (
        <p>{searchText.length > 2 ? 'There are no results matching your search' : 'Start searching'}</p>
      )}
    </PlannerSecondaryPanel>
  );
};

export default DuplicationPanel;
