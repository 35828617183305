import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SelectOption from 'lib/SelectOption';
import { Label } from 'lib/Label';
import { hideSecondaryPanel } from 'store/productManagement/reducer';
import { Environment } from 'components/common/types/Deal.types';
import { Store } from 'components/common/types/Store.types';
import PlannerSecondaryPanel from 'components/pages/Planner/PlannerSecondaryPanel';
import { isValueRequired } from 'components/pages/PCM/Products/utils/isValueRequired';
import SectionDivider from 'components/pages/Planner/SectionDivider';
import SelectionPermissionDropdown from '../SelectionPermissionDropdown/SelectionPermissionDropdown';
import { requiredFieldPermissionOptions } from '../../consts';
import { isPanelConfirmDisabled } from '../../utils/isPanelConfirmDisabled';
import { ProductChannelsPanelProps } from './ProductChannels.types';
import { secondaryPanelLayoutStyles } from '../../styles';

const ProductChannelsPanel: React.FC<ProductChannelsPanelProps> = ({ onConfirm }) => {
  const dispatch = useDispatch();
  const allChannels = useSelector((state: Store) => state.userOptions.channels);
  const defaultChannels = useSelector((state: Store) => state.productManagement.newProduct.channels);
  const defaultSelectedPermission =
    requiredFieldPermissionOptions.find(({ code }) => code === defaultChannels?.selectionPermission) ??
    requiredFieldPermissionOptions[0];

  const [selectedPermission, setSelectedPermission] = useState(defaultSelectedPermission);
  const [chosenChannels, setChosenChannels] = useState(defaultChannels?.value || []);

  const isChannelSelected = (optionCode: string): boolean => chosenChannels.some((item) => item.code === optionCode);

  const handleOptionClick = (option: Environment): void => {
    setChosenChannels((prev) => {
      if (isChannelSelected(option.code)) {
        return chosenChannels.filter(({ code }) => code !== option.code);
      }
      return [...prev, option];
    });
  };

  return (
    <PlannerSecondaryPanel
      title="Channel"
      onSubmit={() => onConfirm({ value: chosenChannels, selectedPermission })}
      onCancel={() => dispatch(hideSecondaryPanel())}
      isDisabled={isPanelConfirmDisabled({
        field: defaultChannels,
        selectedPermission,
        newFieldValue: chosenChannels?.length ? chosenChannels : undefined,
      })}
      customStyles={secondaryPanelLayoutStyles}
    >
      <SelectionPermissionDropdown
        selectedPermission={selectedPermission}
        setSelectedPermission={setSelectedPermission}
        isReadOnly={false}
        permissionOptions={requiredFieldPermissionOptions}
      />
      {isValueRequired(selectedPermission.code) ? (
        <>
          <div className="my-4">
            <SectionDivider />
          </div>

          <Label label="Select channels" />

          <div className="flex flex-col space-y-1">
            {allChannels.map((option) => (
              <SelectOption
                key={option.code}
                value={option}
                isSelected={isChannelSelected(option.code)}
                onClick={() => handleOptionClick(option)}
              >
                {option.name}
              </SelectOption>
            ))}
          </div>
        </>
      ) : null}
    </PlannerSecondaryPanel>
  );
};

export default ProductChannelsPanel;
