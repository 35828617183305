import SVG from 'react-inlinesvg';
import cx from 'classnames';
import Chip from 'lib/Chip';
import arrowRight from 'assets/icons/arrow_light_right.svg';
import update from 'assets/icons/check-alloc.svg';
import { Width } from 'lib/Chip/Chip.types';
import { defaultButtonLabel, EditableChipProps } from './EditableChip.types';

const EditableChip: React.FC<EditableChipProps> = ({
  buttonLabel = defaultButtonLabel,
  chipColor,
  chipLabel,
  chipSubLabel,
  dataTestId,
  isActive,
  isDisabled,
  onClick,
}) => {
  return (
    <button
      className={cx(
        'p-1 flex items-center justify-between w-full border-2 rounded hover:bg-primary-600-opacity-5 cursor-pointer group disabled:cursor-not-allowed',
        {
          'border-primary-600 bg-primary-600-opacity-5': isActive,
          'border-white': !isActive,
        },
      )}
      data-test-id={dataTestId}
      disabled={isDisabled}
      onClick={onClick}
      name="Editable chip"
      type="button"
    >
      <Chip dataTestId="chip" color={chipColor} width={Width.SMALL} label={chipLabel} subLabel={chipSubLabel} />
      <div className="flex items-center pr-[8px]">
        {isActive ? (
          <SVG src={update} className="text-neutral-900" id="update-icon" />
        ) : (
          <div className="flex items-center space-x-2 opacity-0 group-hover:opacity-100">
            <div
              className={cx('sub-header-base', {
                'text-neutral-400': isDisabled,
                'text-primary-600': !isDisabled,
              })}
            >
              {buttonLabel}
            </div>
            <SVG
              src={arrowRight}
              className={cx('', {
                'text-neutral-400': isDisabled,
                'text-primary-600': !isDisabled,
              })}
            />
          </div>
        )}
      </div>
    </button>
  );
};

export default EditableChip;
