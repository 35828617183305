import { BannerState } from 'store/banner/reducer';

export enum CampaignVersioningEditingStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  CANCEL = 'CANCEL',
}

export enum CampaignVersioningErrors {
  TECHNICAL_ERROR = 'TECHNICAL_ERROR',
  TECHNICAL_ERROR_REALLOCATE = 'TECHNICAL_ERROR_REALLOCATE',
  ERROR_LIVE = 'ERROR_LIVE',
  DRAFT_FAILED_PRICING = 'DRAFT_FAILED_PRICING',
  CAMPAIGN_CORRUPTED = 'CAMPAIGN_CORRUPTED',
  OPTION_DATE_REQUIRED = 'OPTION_DATE_REQUIRED',
  STATUS_REQUIRED = 'STATUS_REQUIRED',
  FAILURE_SOLVE = 'FAILURE_SOLVE',
  FAILURE_PRICE = 'FAILURE_PRICE',
}

export type CampaignVersioningMessageExtendedMapping = {
  [key in CampaignVersioningEditingStatus | CampaignVersioningErrors]: BannerState;
};
