import { useState } from 'react';
import checkSvg from 'assets/icons/check.svg';
import copySvg from 'assets/icons/copy.svg';
import Button from 'lib/Button';
import ButtonProps from 'lib/Button/Button.types';
import Tooltip, { TooltipDirection, TooltipSize, TooltipTheme } from 'components/patterns/Tooltip';
import { copyToClipboard } from 'utils/copyToClipboard';

type Props = Pick<ButtonProps, 'label' | 'color' | 'isDisabled'> & {
  textToCopy: string;
};

export const CopyToClipboardButton: React.FC<Props> = ({ label, color, isDisabled, textToCopy }) => {
  const [wasCopied, setWasCopied] = useState(false);
  const [copyResult, setCopyResult] = useState<string | null>(null);

  const onClick = async (): Promise<void> => {
    const copyToClipboardResult = await copyToClipboard(textToCopy);
    setCopyResult(copyToClipboardResult);

    if (!copyToClipboardResult && !wasCopied) setWasCopied(true);
  };

  const onMouseLeave = (): void => {
    if (wasCopied) {
      setWasCopied(false);
      setCopyResult(null);
    }
  };

  const getTooltipMessage = (): string | undefined => {
    if (copyResult) return copyResult;
    if (wasCopied) return 'Copied to clipboard';

    return undefined;
  };

  return (
    <Tooltip
      contentSize={TooltipSize.MAX}
      direction={TooltipDirection.BOTTOM}
      theme={TooltipTheme.DARK}
      tooltip={getTooltipMessage()}
    >
      <Button
        color={color}
        svg={wasCopied ? checkSvg : copySvg}
        label={label}
        isDisabled={isDisabled}
        onClick={onClick}
        onMouseLeave={onMouseLeave}
      />
    </Tooltip>
  );
};
