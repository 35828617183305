import { ProductCreationInterface, ProductCreationStatus } from '../ProductsTable/ProductsTable.types';

export const getProductsToDisplayForDisabledToggle = ({
  isHideDisabled,
  products,
}: {
  isHideDisabled: boolean;
  products: ProductCreationInterface[];
}): ProductCreationInterface[] => {
  return isHideDisabled ? products.filter(({ status }) => status === ProductCreationStatus.ACTIVE) : products;
};
