import { SelectionPermission } from 'store/productManagement/reducer.types';
import { formatChipDate } from 'utils/formatChipDate';
import { SectionSelectedType } from 'lib/SectionButton/SectionButton.types';
import { CodeNameModel } from 'components/common/types';
import { isValueRequired } from '../../utils/isValueRequired';

export const getSelectedValue = ({
  selectedPermission,
  value,
}: {
  selectedPermission?: string;
  value: {
    startDate?: Date;
    endDate?: Date;
  };
}): SectionSelectedType | undefined => {
  if (selectedPermission === SelectionPermission.ALL_OPTIONS) {
    return ['No restrictions'];
  }

  if (isValueRequired(selectedPermission) && !!value.endDate && !!value.startDate) {
    return {
      [formatChipDate({ startDate: value.startDate, endDate: value.endDate })]: [
        {
          code: 'schedule',
          name: '',
        },
      ],
    };
  }

  return undefined;
};

export const isProductScheduleRangeConfirmDisabled = ({
  selectedPermission,
  startDate,
  endDate,
  previouslySelectedScheduleRangeValues,
  previouslySelectedPermission,
}: {
  selectedPermission: CodeNameModel;
  startDate?: Date;
  endDate?: Date;
  previouslySelectedScheduleRangeValues: { startDate?: Date; endDate?: Date };
  previouslySelectedPermission?: SelectionPermission;
}): boolean => {
  const unchangedStartDate = startDate === previouslySelectedScheduleRangeValues.startDate;
  const unchangedEndDate = endDate === previouslySelectedScheduleRangeValues.endDate;
  const unchangedPermission = selectedPermission.code === previouslySelectedPermission;

  if (isValueRequired(selectedPermission.code)) {
    if (!startDate || !endDate) {
      return true;
    }

    if (unchangedStartDate && unchangedEndDate) {
      return true;
    }
  }

  if (!isValueRequired(selectedPermission.code) && unchangedPermission) {
    return true;
  }

  return false;
};
