import React from 'react';
import Chip from 'lib/Chip';
import { Size } from 'lib/Chip/Chip.types';
import { bookingStatusStyles, defaultBookingStatusStyle } from './consts';
import { ChipBookingStatusProps } from './ChipBookingStatus.types';

export const ChipBookingStatus: React.FC<ChipBookingStatusProps> = ({ status, dataTestId = 'chip-booking-status' }) => {
  const styles = bookingStatusStyles[status] ?? defaultBookingStatusStyle;

  return <Chip label={styles.text} color={styles.style} size={Size.SMALL} dataTestId={dataTestId} />;
};
