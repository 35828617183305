import { useSelector } from 'react-redux';
import { Store } from 'components/common/types/Store.types';
import EditableChip from 'lib/EditableChip';
import { PlannerSecondaryPanelType } from 'store/dealManagement/reducer';
import { useOnChangePanelVisibility } from 'components/pages/Planner/hooks/useOnChangePanelVisibility';
import { isValid } from 'date-fns';
import { formatDate } from 'modules/I18N';
import { getNoonTimeFromDate } from 'utils/dateFormatUtil';
import { DirectSalesStatusOption } from 'components/common/types/DirectSalesCampaign.types';
import { STATUS_SETTINGS } from 'components/pages/Planner/PlannerLineActions/DirectSalesPlannerLineActions/Status/StatusPanel/StatusPanel.types';
import { isEmpty } from 'lodash';
import useDirectSalesCampaignStatus from './useDirectSalesCampaignStatus';
import DirectSalesCampaignStatusPanel from './DirectSalesCampaignStatusPanel';

const DirectSalesCampaignStatus: React.FC = () => {
  const onChangePanelVisibility = useOnChangePanelVisibility();
  const { campaignState, campaignStateOptions, isDisabled } = useDirectSalesCampaignStatus();

  const plannerSecondaryPanelType = useSelector((state: Store) => state.dealManagement.plannerSecondaryPanelType);

  const isValidOptionStatusValue =
    campaignState.status === DirectSalesStatusOption.OPTION && isValid(campaignState.expires);

  const isStatusPanelOpen = plannerSecondaryPanelType === PlannerSecondaryPanelType.DIRECT_SALES_STATUS;

  return (
    <>
      <EditableChip
        buttonLabel="Update status"
        chipLabel={STATUS_SETTINGS[campaignState.status].text}
        chipSubLabel={
          campaignState.expires && isValidOptionStatusValue
            ? `${formatDate(campaignState.expires)} ${getNoonTimeFromDate(campaignState.expires)}`
            : undefined
        }
        chipColor={STATUS_SETTINGS[campaignState.status].style}
        dataTestId="direct-sales-campaign-booking-status"
        isActive={isStatusPanelOpen}
        isDisabled={isDisabled}
        onClick={() => onChangePanelVisibility(PlannerSecondaryPanelType.DIRECT_SALES_STATUS)}
      />
      {isStatusPanelOpen && !isEmpty(campaignStateOptions) && (
        <DirectSalesCampaignStatusPanel options={campaignStateOptions} campaignState={campaignState} />
      )}
    </>
  );
};

export default DirectSalesCampaignStatus;
