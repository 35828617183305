import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, isEqual } from 'lodash';
import { RootState } from 'store';
import { notifyError, notifyInfo, notifySuccess } from 'store/notification/reducer';
import { NOTIFICATION_TIMEOUT } from 'consts/notifications';
import { hideBanner, showBanner } from 'store/banner/reducer';
import {
  acceptOrDiscardChanges,
  createDirectSalesCampaign,
  deleteDirectSalesDealLine,
  editDirectSalesCampaign,
  getDirectSalesCampaign,
  getDirectSalesSolution,
  pollCampaignState,
  pollDealLineState,
  pollDealSalesCampaign,
  updateCampaignState,
  updateDealLineState,
  updateDirectSalesDealLine,
} from 'modules/api/directSalesCampaign';
import { CampaignVersioningEditingStatus, CampaignVersioningErrors } from 'consts/directSalesCampaignVersioning';
import { getCampaignVersioningMessageExtended } from 'components/pages/Planner/hooks/utils/getCampaignVersioningMessagesExtended';
import { Store } from 'components/common/types/Store.types';
import {
  addDirectSalesLineId,
  changeDealCurrentLineData,
  changeDealData,
  changeDirectSalesData,
  changeFormParams,
  changeLineIsSaving,
  clearDirectSalesAllocation,
  clearDirectSalesAssetList,
  clearDirectSalesPrices,
  clearTransientLineStates,
  generateUniqueDealLineName,
  hidePlannerSecondaryPanel,
  removeDirectSalesLine,
  setDirectSalesStates,
  setLoadedDirectSalesData,
  updateBackupFormDataLine,
  updateDirectSalesSessionLock,
  updateTransientLineStates,
} from 'store/dealManagement/reducer';
import { Advertiser, Brand, Line } from 'components/common/types/Deal.types';
import { CodeNameModel } from 'components/common/types';
import { STATUS_PROCESS_END_STATUSES } from 'consts/directSalesProcessEndStatuses';
import {
  DirectSales,
  DirectSalesCampaignDefinitionExclusions,
  DirectSalesLine,
  DirectSalesLineAllocation,
  DirectSalesState,
  DirectSalesStatusOption,
  DirectSalesStatusOptionEvent,
  DirectSalesTransientStates,
} from 'components/common/types/DirectSalesCampaign.types';
import { getDateInRequestFormat, getTimeInDate } from 'utils/dateUtil';
import {
  DirectSalesCampaignDealLineStateResponse,
  DirectSalesCampaignResponse,
} from 'components/common/types/DirectSalesCampaignResponse.types';
import { clearActiveObjective } from 'store/objective/reducer';
import {
  isTransientCommitDisabled,
  validateAttemptToConfirmChanges,
  ValidationResult,
} from 'components/pages/Planner/hooks/utils/directSalesTransientLineUtils';
import useDirectSalesCampaignStatus from 'components/common/Deal/CampaignHeader/CampaignDrawer/Overview/DirectSalesCampaignStatus/useDirectSalesCampaignStatus';
import usePrevious from 'customHooks/usePrevious';
import {
  getEveryLineStatus,
  getIsAnyLineFetchingAvailability,
  getIsDirectSalesSessionLockedAndReadOnly,
} from 'store/dealManagement/selectors';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import { CampaignDuplicationModalMessages } from 'components/common/CampaignDuplicationModal/consts';
import { useHasFeatureAccess } from 'customHooks/useHasFeatureAccess';
import { FeatureFlags } from 'components/common/types/Features.types';
import { useCheckDirectSalesLineLevelChange } from './useCheckDirectSalesLineLevelChange';
import { useCheckDirectSalesMandatoryFields } from './useCheckDirectSalesMandatoryFields';
import {
  mergeCurrentLineToAllLines,
  transformAllLineData,
  transformDirectSalesBookingResponse,
  transformDirectSalesDealLineRequest,
  transformDirectSalesWithLineFormRequest,
  TransformedDirectSalesBookingResponse,
} from './utils/transformDirectSalesCampaign';
import { useCheckDirectSalesCampaignLevelChange } from './useCheckDirectSalesCampaignLevelChanges';
import { isDirectSalesCampaignCloned } from './utils/isDirectSalesCampaignCloned';
import { transformCampaignStatusResponse } from './utils/transformCampaignStatusResponse';
import { PollCompleteHandlerResult, useDirectSalesDraftErrorHandler } from './useDirectSalesDraftErrorHandler';
import {
  getDirectSalesFailedLines,
  isDirectSalesFailedStatus,
  isSomeDirectSalesStatusFailed,
} from './utils/directSalesFailedStatus';
import { OnPollComplete } from './useOnPollComplete.types';
import { transformTargetStatusesToTransientDealLinesState } from './utils/transformTargetStatusesToTransientDealLinesState';
import { useDirectSalesPricedSolution } from './useDirectSalesPricedSolution';
import { updateDatesForDirectSalesLines } from './utils/updateDatesForDirectSalesLines';
import transformDirectSalesLineAllocation from './utils/transformDirectSalesLineAllocation';

export type GetUpdatedDirectSalesCampaignResult = Promise<
  DirectSalesCampaignResponse & TransformedDirectSalesBookingResponse
>;

export interface UseDirectSalesPlannerActions {
  checkAllocateHandler: () => void;
  getUpdatedDirectSalesCampaign: (customCampaignId?: string) => GetUpdatedDirectSalesCampaignResult;
  statusConditions: {
    showSave: boolean;
    showConfirm: boolean;
    showSendForApproval: boolean;
  };
  isSavingDisabled: boolean;
  saveDeal: (
    campaignDefinitionData?: Partial<DirectSalesCampaignDefinitionExclusions>,
    callback?: VoidFunction,
  ) => Promise<void>;
  getSolution: (pollCompleteHandler: () => Promise<PollCompleteHandlerResult>) => Promise<void>;
  editDeal: (data?: {
    currentLineData?: Partial<Line>;
    directSalesData?: Partial<DirectSales>;
    campaignDefinitionData?: Partial<DirectSalesCampaignDefinitionExclusions>;
  }) => Promise<void>;
  editDealLine: (data?: { currentLineData?: Partial<Line>; directSalesData?: Partial<DirectSales> }) => Promise<void>;
  saveCampaignState: (
    newState: DirectSalesState,
    oldState: DirectSalesState,
    { onPollCompleteWithSuccess, onPollCompleteWithFailure }: OnPollComplete,
  ) => Promise<void>;
  saveDealLineState: (
    newState: DirectSalesState,
    oldState: DirectSalesState,
    { onPollCompleteWithSuccess, onPollCompleteWithFailure }: OnPollComplete,
  ) => Promise<void>;
  deleteDealLine: (lineId: string, callback?: VoidFunction) => Promise<void>;
  discardChanges: (providedCampaignId: string) => Promise<void>;
  showBannerInformation: (
    bannerInformation: CampaignVersioningErrors | CampaignVersioningEditingStatus,
    providedIsCommitDisabled?: boolean,
    providedCommitTransientDeal?: (campaignId: string) => Promise<void>,
  ) => void;
  submitNewDeal: (data?: {
    campaignDefinitionData?: Partial<DirectSalesCampaignDefinitionExclusions>;
  }) => Promise<void>;
  duplicateCampaign: (data?: {
    campaignStartDate: Date;
    areCancelledLinesIncluded: boolean;
    campaignDefinitionData?: Partial<DirectSalesCampaignDefinitionExclusions>;
    linesData?: Partial<DirectSalesLine>[];
  }) => Promise<void>;
  confirmChanges: () => void;
}

const useDirectSalesPlannerActions = (): UseDirectSalesPlannerActions => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLineLevelChange = useCheckDirectSalesLineLevelChange();
  const isCampaignLevelChange = useCheckDirectSalesCampaignLevelChange();
  const { campaignState } = useDirectSalesCampaignStatus();
  const { areAllMandatoryFieldsFilled } = useCheckDirectSalesMandatoryFields();
  const { handleDraftFail, handleDraftFailedLines, handlePollCompleteDraftFail } = useDirectSalesDraftErrorHandler();
  const cancelFunctions = useCancelRequest();

  const isLineLevelStatusDisabled = useHasFeatureAccess(FeatureFlags.DISABLED_DIRECT_LINE_STATUS);
  const isActionsDisabled = useSelector((state: Store) => state.dealManagement.isActionsDisabled);
  const isFetchingAvailability = useSelector(getIsAnyLineFetchingAvailability);
  const directSales = useSelector((state: Store) => state.dealManagement.directSales);
  const lines = useSelector((state: Store) => state.dealManagement.backupFormData.lines) as DirectSalesLine[];
  const linesStatuses = useSelector(getEveryLineStatus);
  const campaignId = useSelector((state: Store) => state.dealManagement.commonDeal.dealId);
  const dealLineId = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.lineId);
  const advertiser = useSelector((state: Store) => state.dealManagement.commonDeal.advertiser);
  const brand = useSelector((state: Store) => state.dealManagement.commonDeal.brand);
  const productCategory = useSelector((state: Store) => state.dealManagement.commonDeal.productCategory);
  const agency = useSelector((state: Store) => state.dealManagement.commonDeal.agency);
  const specialist = useSelector((state: Store) => state.dealManagement.commonDeal.specialist);
  const salesPerson = useSelector((state: Store) => state.dealManagement.commonDeal.salesPerson);
  const salesTeam = useSelector((state: Store) => state.dealManagement.commonDeal.salesTeam);
  const adminPerson = useSelector((state: Store) => state.dealManagement.commonDeal.adminPerson);
  const dealName = useSelector((state: Store) => state.dealManagement.commonDeal.dealName);
  const currentLine = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine);
  const marketId = useSelector((state: RootState) => state.publisher.configuration.marketId);
  const currentLineStatus = useSelector((state: Store) => state.dealManagement.directSales.state);
  const transientState = useSelector((state: Store) => state.dealManagement.directSales.transientState);
  const transientStateDealLines = useSelector((state: Store) => state.dealManagement.directSales.transientLineStates);
  const productCategoryExclusivity = useSelector(
    (state: Store) => state.dealManagement.commonDeal.productCategoryExclusivity,
  );
  const duplicationExclusion = useSelector((state: Store) => state.dealManagement.commonDeal.duplicationExclusion);
  const juxtapositionExclusion = useSelector((state: Store) => state.dealManagement.commonDeal.juxtapositionExclusion);
  const exclusions = useSelector((state: Store) => state.dealManagement.commonDeal.exclusions);
  const advertiserBlockExclusion = useSelector(
    (state: Store) => state.dealManagement.commonDeal.advertiserBlockExclusion,
  );
  const agencyBlockExclusion = useSelector((state: Store) => state.dealManagement.commonDeal.agencyBlockExclusion);
  const specialistBlockExclusion = useSelector(
    (state: Store) => state.dealManagement.commonDeal.specialistBlockExclusion,
  );
  const { isDirectSalesSessionLockedAndReadOnly } = useSelector(getIsDirectSalesSessionLockedAndReadOnly);

  const previousLines = usePrevious(lines);
  const previousTransientLines = usePrevious(transientStateDealLines);

  const handlePricedSolution = useDirectSalesPricedSolution();
  const { campaignId: originalCampaignId } = useParams();

  const checkAllocateHandler = (): void => {};

  const getUpdatedDirectSalesCampaign = async (
    customCampaignId: string = campaignId,
  ): GetUpdatedDirectSalesCampaignResult => {
    const result = await getDirectSalesCampaign(customCampaignId, marketId, cancelFunctions);
    const transformResult = transformDirectSalesBookingResponse(result);

    if (
      isDirectSalesCampaignCloned(result.id) &&
      isEmpty(transientStateDealLines) &&
      result.campaign.body.campaignDefinition.dealLines.length >= 1
    ) {
      const targetStatuses = transformTargetStatusesToTransientDealLinesState(
        result.campaign.body.campaignDefinition.dealLines,
      );

      if (!isEmpty(targetStatuses)) {
        dispatch(updateTransientLineStates(targetStatuses));
      }
    }

    dispatch(updateDirectSalesSessionLock(transformResult.sessionLockState));

    return {
      ...result,
      ...transformResult,
    };
  };

  const showSave = isLineLevelChange || isCampaignLevelChange;
  const showConfirm = areAllMandatoryFieldsFilled;
  const showSendForApproval = areAllMandatoryFieldsFilled;
  const isSavingDisabled =
    isActionsDisabled ||
    isFetchingAvailability ||
    !areAllMandatoryFieldsFilled ||
    !currentLine.availability.assets.length;

  const isCommitDisabled = useMemo(() => {
    return isTransientCommitDisabled({
      areAllMandatoryFieldsFilled,
      transientStateDealLines,
      campaignState,
      linesStatuses,
      isLineLevelStatusDisabled,
    });
  }, [
    currentLineStatus.status,
    isEqual(previousLines, lines),
    isEqual(previousTransientLines, transientStateDealLines),
    campaignId,
    dealLineId,
  ]);

  const isCampaignCloned = useMemo(() => isDirectSalesCampaignCloned(campaignId), [campaignId]);

  const discardChanges = useCallback(
    async (providedCampaignId: string): Promise<void> => {
      dispatch(changeFormParams({ isEditingDisabled: true }));

      try {
        await acceptOrDiscardChanges(
          { transientAction: DirectSalesTransientStates.CANCEL },
          marketId,
          providedCampaignId,
          cancelFunctions,
        );

        if (!originalCampaignId) return;

        const {
          campaign: {
            body: { pricingSolution },
          },
          deal: commonDeal,
          lines: updatedLines,
        } = await getUpdatedDirectSalesCampaign(originalCampaignId);

        dispatch(
          setLoadedDirectSalesData({
            commonDeal,
            lines: updatedLines,
          }),
        );

        if (commonDeal.dealId) {
          await handlePricedSolution({
            campaignId: commonDeal.dealId,
            marketId,
            pricingSolution,
            lines: updatedLines,
          });
        }

        dispatch(changeFormParams({ isEditingDisabled: false }));
        dispatch(changeDealData({ dealId: originalCampaignId }));
        dispatch(clearTransientLineStates());
        dispatch(hideBanner());
      } catch (error) {
        dispatch(notifyError({ message: error.message }));
        dispatch(changeFormParams({ isEditingDisabled: false }));
      }
    },
    [marketId, campaignId],
  );

  /* eslint-disable sonarjs/cognitive-complexity */
  const confirmChanges = useCallback(async (): Promise<void> => {
    dispatch(changeFormParams({ isEditingDisabled: true }));

    try {
      await acceptOrDiscardChanges(
        {
          transientAction: DirectSalesTransientStates.ACCEPT,
        },
        marketId,
        campaignId,
        cancelFunctions,
      );

      if (!originalCampaignId) return;

      const pollCompleteHandler = async (campaignResponse: DirectSalesCampaignResponse): Promise<void> => {
        const { deal, lines: updatedLines, sessionLockState } = transformDirectSalesBookingResponse(campaignResponse);

        if (deal.dealId) {
          await handlePricedSolution({
            campaignId: deal.dealId,
            marketId,
            pricingSolution: campaignResponse.campaign.body.pricingSolution,
            lines: updatedLines,
          });
        }

        dispatch(updateDirectSalesSessionLock(sessionLockState));

        dispatch(
          setLoadedDirectSalesData({
            commonDeal: deal,
            lines: updatedLines,
          }),
        );

        dispatch(changeDealData({ dealId: originalCampaignId }));
        dispatch(clearTransientLineStates());
        dispatch(hideBanner());
      };

      const poll = pollDealSalesCampaign(marketId, originalCampaignId, cancelFunctions);

      poll(({ data }: { data: DirectSalesCampaignResponse }): boolean => {
        const {
          id,
          campaign: {
            body: {
              campaignDefinition: { dealLines },
            },
          },
        } = data;

        const isFailedSolveOrFailedPriceLine = isSomeDirectSalesStatusFailed(dealLines);

        if (isDirectSalesCampaignCloned(id) && !isFailedSolveOrFailedPriceLine) return true;
        if (!isDirectSalesCampaignCloned(id)) pollCompleteHandler(data);
        if (isFailedSolveOrFailedPriceLine) handleDraftFailedLines(getDirectSalesFailedLines(dealLines));

        dispatch(changeFormParams({ isEditingDisabled: false }));
        return false;
      }, 1000);
    } catch (error) {
      dispatch(notifyError({ message: error.message }));
      dispatch(changeFormParams({ isEditingDisabled: false }));
    }
  }, [marketId, campaignId]);
  /* eslint-enable sonarjs/cognitive-complexity */

  /* eslint-disable @typescript-eslint/no-use-before-define */
  const commitTransientDeal = useCallback(async (): Promise<void> => {
    if (!isDirectSalesCampaignCloned(campaignId)) return;

    if (!transientStateDealLines || isEmpty(transientStateDealLines)) return;

    const confirmChangesValidationResult: ValidationResult = validateAttemptToConfirmChanges({
      transientStateDealLines,
      lines,
    });

    if (!confirmChangesValidationResult.isValid && confirmChangesValidationResult.errorStatus) {
      showBannerInformation(confirmChangesValidationResult.errorStatus);
    } else {
      confirmChanges();
    }
  }, [
    campaignId,
    isEqual(previousTransientLines, transientStateDealLines),
    isEqual(previousLines, lines),
    confirmChanges,
  ]);

  const showBannerInformation = useCallback(
    (
      bannerInformation: CampaignVersioningErrors | CampaignVersioningEditingStatus,
      providedIsCommitDisabled?: boolean,
      providedCommitTransientDeal?: (campaignId: string) => Promise<void>,
    ): void => {
      const bannerDisplayInformation = getCampaignVersioningMessageExtended(
        navigate,
        discardChanges,
        providedCommitTransientDeal ?? commitTransientDeal,
        editDeal,
        providedIsCommitDisabled ?? isCommitDisabled,
        isCampaignCloned,
      )[bannerInformation];

      dispatch(showBanner(bannerDisplayInformation));
    },
    [
      navigate,
      discardChanges,
      commitTransientDeal,
      isCommitDisabled,
      isEqual(transientStateDealLines, previousTransientLines),
    ],
  );
  /* eslint-enable @typescript-eslint/no-use-before-define */

  const editDeal = async ({
    currentLineData,
    directSalesData,
    campaignDefinitionData,
  }: {
    currentLineData?: Partial<Line>;
    directSalesData?: Partial<DirectSales>;
    campaignDefinitionData?: Partial<DirectSalesCampaignDefinitionExclusions>;
    // eslint-disable-next-line sonarjs/cognitive-complexity
  } = {}): Promise<void> => {
    const dealLines = mergeCurrentLineToAllLines(lines as DirectSalesLine[], {
      ...currentLine,
      ...directSales,
      ...currentLineData,
      ...directSalesData,
    });

    const payload = {
      deal: {
        name: dealName,
        advertiser: advertiser as Advertiser,
        brand: brand as Brand,
        productCategory: productCategory as CodeNameModel,
        agency,
        specialist,
        salesPerson,
        salesTeam,
        adminPerson,
        productCategoryExclusivity: campaignDefinitionData?.productCategoryExclusivity || productCategoryExclusivity,
        duplicationExclusion: campaignDefinitionData?.duplicationExclusion || duplicationExclusion,
        juxtapositionExclusion: campaignDefinitionData?.juxtapositionExclusion || juxtapositionExclusion,
        exclusions: campaignDefinitionData?.exclusions || exclusions,
        advertiserBlockExclusion: campaignDefinitionData?.advertiserBlockExclusion || advertiserBlockExclusion,
        agencyBlockExclusion: campaignDefinitionData?.agencyBlockExclusion || agencyBlockExclusion,
        specialistBlockExclusion: campaignDefinitionData?.specialistBlockExclusion || specialistBlockExclusion,
      },
      lines: transformAllLineData(dealLines),
    };

    const formData = transformDirectSalesWithLineFormRequest(payload);

    dispatch(changeFormParams({ isEditingDisabled: true }));

    dispatch(
      notifyInfo({
        message: 'Saving your line changes',
        timeout: NOTIFICATION_TIMEOUT.DEFAULT,
      }),
    );

    try {
      const { id } = await editDirectSalesCampaign(formData, marketId, campaignId, cancelFunctions);

      const {
        campaign: {
          header,
          body: {
            campaignDefinition: { dealLines: savedDealLines },
            allocationSolution,
          },
        },
      } = await getUpdatedDirectSalesCampaign();

      if (campaignId !== id) {
        dispatch(changeDealData({ dealId: id }));

        if (isDirectSalesCampaignCloned(id)) {
          showBannerInformation(CampaignVersioningEditingStatus.IN_PROGRESS);
        }
      }

      dispatch(
        changeDealData({
          salesPerson: header.salesPerson || null,
          salesTeam: header.salesTeam || null,
          adminPerson: header.adminPerson || null,
        }),
      );

      const savedLine = savedDealLines.find(({ name }) => name === currentLine.name || name === currentLineData?.name);

      if (savedLine && savedLine.id) {
        if (!currentLine.lineId) {
          dispatch(addDirectSalesLineId(savedLine.id));
        } else {
          const isDirectSalesChange = !isEmpty(directSalesData);
          const isCurrentLineChange = !isEmpty(currentLineData);

          if (!isDirectSalesChange && !isCurrentLineChange) return;

          if (isDirectSalesChange) dispatch(changeDirectSalesData(directSalesData));

          if (isCurrentLineChange) dispatch(changeDealCurrentLineData(currentLineData));

          dispatch(
            updateBackupFormDataLine({
              lineId: currentLine.lineId,
              newBackupLineData: { ...currentLineData, ...directSalesData },
            }),
          );
          dispatch(clearDirectSalesPrices());
          dispatch(clearDirectSalesAssetList());
          dispatch(hidePlannerSecondaryPanel());
        }

        const linesStatesWithAllocation: Record<
          string,
          { state: DirectSalesState; allocation: DirectSalesLineAllocation }
        > = Object.fromEntries(
          savedDealLines.map((line) => {
            const lineAllocation = transformDirectSalesLineAllocation(allocationSolution, line.state.status, line.id);
            return [line.id, { state: line.state, allocation: lineAllocation }];
          }),
        );
        dispatch(setDirectSalesStates(linesStatesWithAllocation));

        dispatch(
          notifySuccess({
            message: 'Successfully saved the campaign changes',
            timeout: NOTIFICATION_TIMEOUT.DEFAULT,
          }),
        );
      }
    } catch (error) {
      handleDraftFail();
      dispatch(notifyError({ message: error.message }));
    } finally {
      dispatch(changeFormParams({ isEditingDisabled: false }));
    }
  };

  useEffect(() => {
    if (isDirectSalesSessionLockedAndReadOnly || !transientState) return;

    showBannerInformation(transientState, isCommitDisabled, commitTransientDeal);
  }, [isDirectSalesSessionLockedAndReadOnly, isCommitDisabled, transientState, commitTransientDeal]);

  const getSolution = useCallback(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    async (pollCompleteHandler: () => Promise<PollCompleteHandlerResult>): Promise<void> => {
      dispatch(
        changeFormParams({
          isEditingDisabled: true,
          isLoading: true,
          isFetchingAllocation: true,
        }),
      );

      let isPollingAllowed = false;

      try {
        await getDirectSalesSolution(campaignId, marketId, cancelFunctions);
        isPollingAllowed = true;
      } catch (error) {
        if (error.response.status === 503) {
          isPollingAllowed = true;
        } else {
          dispatch(
            changeFormParams({
              isEditingDisabled: false,
              isLoading: false,
              isFetchingAllocation: false,
            }),
          );
          dispatch(notifyError({ message: error.message }));
        }
      }

      if (isPollingAllowed) {
        dispatch(notifyInfo({ message: 'The solution is getting generated' }));

        try {
          const poll = pollDealLineState(marketId, campaignId, dealLineId, cancelFunctions);

          poll(({ data: { states } }: { data: DirectSalesCampaignDealLineStateResponse }): boolean => {
            const lineStatus = states[dealLineId].state.status;

            if (
              lineStatus === DirectSalesStatusOption.DRAFT_SOLVING ||
              lineStatus === DirectSalesStatusOption.DRAFT_SOLVED
            ) {
              return true;
            }

            if (isDirectSalesFailedStatus(lineStatus)) {
              handlePollCompleteDraftFail(pollCompleteHandler(), { status: lineStatus });
              dispatch(
                changeFormParams({
                  isEditingDisabled: false,
                  isLoading: false,
                  isFetchingAllocation: false,
                }),
              );

              return false;
            }

            if (lineStatus === DirectSalesStatusOption.DRAFT_PRICED) {
              pollCompleteHandler();

              return false;
            }

            return true;
          }, 1000);
        } catch (error) {
          dispatch(
            changeFormParams({
              isEditingDisabled: false,
              isLoading: false,
              isFetchingAllocation: false,
            }),
          );
          dispatch(notifyError({ message: error.message }));
        }
      }
    },
    [campaignId, marketId, dealLineId],
  );

  const saveDealLineState = async (
    newState: DirectSalesState,
    oldState: DirectSalesState,
    { onPollCompleteWithSuccess, onPollCompleteWithFailure }: OnPollComplete,
    // eslint-disable-next-line sonarjs/cognitive-complexity
  ): Promise<void> => {
    if (!campaignId || !dealLineId) {
      return;
    }

    dispatch(changeFormParams({ isEditingDisabled: true, isLoading: true }));

    const isOptionStatus = newState.status === DirectSalesStatusOption.OPTION;

    if (isOptionStatus && !newState.expires) {
      dispatch(
        notifyError({
          message: 'Please add date for OPTION status',
          timeout: NOTIFICATION_TIMEOUT.DEFAULT,
        }),
      );
      return;
    }

    const event =
      oldState.status === DirectSalesStatusOption.OPTION && newState.status === DirectSalesStatusOption.CONFIRMED
        ? DirectSalesStatusOptionEvent.CONFIRM_OPTION
        : DirectSalesStatusOptionEvent[newState.status];

    const expires =
      newState.expires && isOptionStatus
        ? { expires: getDateInRequestFormat(newState.expires, getTimeInDate(newState.expires)) }
        : {};

    const data = JSON.stringify({
      event,
      ...expires,
    });

    dispatch(
      notifyInfo({
        message: 'Saving your line changes',
        timeout: NOTIFICATION_TIMEOUT.DEFAULT,
      }),
    );

    try {
      await updateDealLineState(data, marketId, originalCampaignId ?? campaignId, dealLineId, cancelFunctions);

      const poll = pollCampaignState(marketId, originalCampaignId ?? campaignId, cancelFunctions);

      poll(({ data: statesData }: { data: DirectSalesCampaignDealLineStateResponse }): boolean => {
        const isUnfinishedStatusChange = Object.values(statesData.states).some(
          (value) => !STATUS_PROCESS_END_STATUSES.includes(value.state.status),
        );

        if (isUnfinishedStatusChange) return true;

        const draftFailedStatusChange = Object.values(statesData.states).some((value) =>
          [DirectSalesStatusOption.DRAFT_FAILED_PRICE, DirectSalesStatusOption.DRAFT_FAILED_SOLVE].includes(
            value.state.status,
          ),
        );

        if (draftFailedStatusChange) {
          onPollCompleteWithFailure();
          return false;
        }

        if (!isDirectSalesCampaignCloned(campaignId)) {
          dispatch(setDirectSalesStates(transformCampaignStatusResponse(statesData)));
        }

        onPollCompleteWithSuccess('Successfully updated deal line state');
        return false;
      }, 1000);
    } catch (error) {
      dispatch(
        changeFormParams({
          isEditingDisabled: false,
          isLoading: false,
        }),
      );

      dispatch(
        notifyError({
          message: 'Unable to update deal line state',
          timeout: NOTIFICATION_TIMEOUT.DEFAULT,
        }),
      );
    }
  };

  const saveCampaignState = async (
    newState: DirectSalesState,
    oldState: DirectSalesState,
    { onPollCompleteWithSuccess, onPollCompleteWithFailure }: OnPollComplete,
  ): Promise<void> => {
    if (!campaignId || !dealLineId) {
      return;
    }

    dispatch(changeFormParams({ isEditingDisabled: true, isLoading: true }));

    const event =
      oldState.status === DirectSalesStatusOption.OPTION && newState.status === DirectSalesStatusOption.CONFIRMED
        ? DirectSalesStatusOptionEvent.CONFIRM_OPTION
        : DirectSalesStatusOptionEvent[newState.status];

    const expires =
      newState.expires && newState.status === DirectSalesStatusOption.OPTION
        ? { expires: getDateInRequestFormat(newState.expires, getTimeInDate(newState.expires)) }
        : {};

    const data = JSON.stringify({
      event,
      ...expires,
    });

    dispatch(
      notifyInfo({
        message: 'Saving your campaign changes',
        timeout: NOTIFICATION_TIMEOUT.DEFAULT,
      }),
    );

    try {
      await updateCampaignState(data, marketId, originalCampaignId ?? campaignId, cancelFunctions);
      const poll = pollCampaignState(marketId, originalCampaignId ?? campaignId, cancelFunctions);

      poll(({ data: statesData }: { data: DirectSalesCampaignDealLineStateResponse }): boolean => {
        const isUnfinishedStatusChange = Object.values(statesData.states).some(
          (value) => !STATUS_PROCESS_END_STATUSES.includes(value.state.status),
        );

        if (isUnfinishedStatusChange) return true;

        // eslint-disable-next-line sonarjs/no-identical-functions
        const draftFailedStatusChange = isSomeDirectSalesStatusFailed(Object.values(statesData.states));

        if (draftFailedStatusChange) {
          onPollCompleteWithFailure();
          return false;
        }

        if (!isDirectSalesCampaignCloned(campaignId)) {
          dispatch(setDirectSalesStates(transformCampaignStatusResponse(statesData)));
        }

        onPollCompleteWithSuccess('Successfully updated campaign state');
        return false;
      }, 1000);
    } catch (error) {
      dispatch(
        changeFormParams({
          isEditingDisabled: false,
          isLoading: false,
        }),
      );

      dispatch(
        notifyError({
          message: 'Unable to update campaign state',
          timeout: NOTIFICATION_TIMEOUT.DEFAULT,
        }),
      );
    }
  };

  const createCampaign = async ({
    campaignDefinitionData,
    linesData,
    infoMessage = 'Saving your campaign changes',
    errorMessage = 'Saving your campaign failed',
    successMessage = 'Campaign creation successful',
  }: {
    campaignDefinitionData?: Partial<DirectSalesCampaignDefinitionExclusions>;
    linesData?: Partial<DirectSalesLine>[];
    infoMessage?: string;
    errorMessage?: string;
    successMessage?: string;
    // eslint-disable-next-line sonarjs/cognitive-complexity
  }): Promise<void> => {
    const payload = {
      deal: {
        name: campaignDefinitionData?.name || dealName,
        advertiser: campaignDefinitionData?.advertiserId
          ? { code: campaignDefinitionData.advertiserId, name: '' }
          : (advertiser as Advertiser),
        brand: campaignDefinitionData?.brandId ? { code: campaignDefinitionData.brandId, name: '' } : (brand as Brand),
        productCategory: campaignDefinitionData?.productCategory
          ? { code: campaignDefinitionData.productCategory, name: '' }
          : (productCategory as CodeNameModel),
        agency: campaignDefinitionData?.agencyId ? { code: campaignDefinitionData?.agencyId, name: '' } : agency,
        specialist: campaignDefinitionData?.specialistId
          ? { code: campaignDefinitionData?.specialistId, name: '' }
          : specialist,
        salesPerson: campaignDefinitionData?.salesPerson || salesPerson,
        salesTeam: campaignDefinitionData?.salesTeam || salesTeam,
        adminPerson: campaignDefinitionData?.adminPerson || adminPerson,
        productCategoryExclusivity: campaignDefinitionData?.productCategoryExclusivity || productCategoryExclusivity,
        duplicationExclusion: campaignDefinitionData?.duplicationExclusion || duplicationExclusion,
        juxtapositionExclusion: campaignDefinitionData?.juxtapositionExclusion || juxtapositionExclusion,
        exclusions: campaignDefinitionData?.exclusions || exclusions,
        advertiserBlockExclusion: campaignDefinitionData?.advertiserBlockExclusion || advertiserBlockExclusion,
        agencyBlockExclusion: campaignDefinitionData?.agencyBlockExclusion || agencyBlockExclusion,
        specialistBlockExclusion: campaignDefinitionData?.specialistBlockExclusion || specialistBlockExclusion,
      },
      lines: transformAllLineData(linesData || lines),
    };

    const formData = transformDirectSalesWithLineFormRequest(payload);

    dispatch(changeFormParams({ isEditingDisabled: true }));
    dispatch(notifyInfo({ message: infoMessage }));

    try {
      const { id } = await createDirectSalesCampaign(formData, marketId, cancelFunctions);

      if (id) {
        dispatch(notifySuccess({ message: successMessage }));
        navigate(`/planner/direct-sales/${id}`);
      }
    } catch (error) {
      dispatch(
        notifyError({
          message: `${error.message} - ${errorMessage}`,
          timeout: NOTIFICATION_TIMEOUT.LONG,
        }),
      );
    } finally {
      dispatch(changeFormParams({ isEditingDisabled: false }));
    }
  };

  const submitNewDeal = async ({
    campaignDefinitionData,
  }: {
    campaignDefinitionData?: Partial<DirectSalesCampaignDefinitionExclusions>;
  } = {}): Promise<void> => {
    await createCampaign({
      campaignDefinitionData,
      linesData: [{ name: generateUniqueDealLineName([], 1), ...directSales }],
    });
  };

  const duplicateCampaign = async ({
    campaignStartDate,
    campaignDefinitionData,
    linesData = lines,
    areCancelledLinesIncluded,
  }: {
    campaignStartDate: Date;
    areCancelledLinesIncluded: boolean;
    campaignDefinitionData?: Partial<DirectSalesCampaignDefinitionExclusions>;
    linesData?: Partial<DirectSalesLine>[];
  }): Promise<void> => {
    const linesToDuplicate = areCancelledLinesIncluded
      ? linesData
      : linesData.filter((line: DirectSalesLine) => line.state.status !== DirectSalesStatusOption.CANCELLED);
    const linesWithUpdatedDates = updateDatesForDirectSalesLines(linesToDuplicate, campaignStartDate);

    await createCampaign({
      campaignDefinitionData,
      linesData: linesWithUpdatedDates,
      infoMessage: CampaignDuplicationModalMessages.DUPLICATION_INFO_MESSAGE,
      errorMessage: CampaignDuplicationModalMessages.DUPLICATION_ERROR_MESSAGE,
      successMessage: CampaignDuplicationModalMessages.DUPLICATION_SUCCESS_MESSAGE,
    });
  };

  const editDealLine = async ({
    currentLineData,
    directSalesData,
  }: {
    currentLineData?: Partial<Line>;
    directSalesData?: Partial<DirectSales>;

    // eslint-disable-next-line sonarjs/cognitive-complexity
  } = {}): Promise<void> => {
    const currentLineId = currentLine.lineId;

    const dealLine = {
      ...currentLine,
      ...directSales,
      ...currentLineData,
      ...directSalesData,
    };

    const [payload] = transformAllLineData([dealLine]);
    const formData = transformDirectSalesDealLineRequest(payload);

    dispatch(changeLineIsSaving({ lineId: dealLine.lineId, isSaving: true }));

    dispatch(
      notifyInfo({
        message: 'Saving your line changes',
        timeout: NOTIFICATION_TIMEOUT.DEFAULT,
      }),
    );

    try {
      const { id } = await updateDirectSalesDealLine(formData, marketId, campaignId, currentLineId, cancelFunctions);

      if (campaignId !== id) dispatch(changeDealData({ dealId: id }));

      const {
        campaign: {
          header,
          body: {
            campaignDefinition: { dealLines: savedDealLines },
            allocationSolution,
          },
        },
      } = await getUpdatedDirectSalesCampaign();

      dispatch(
        changeDealData({
          salesPerson: header.salesPerson || null,
          salesTeam: header.salesTeam || null,
          adminPerson: header.adminPerson || null,
        }),
      );

      const savedLine = savedDealLines.find(({ name }) => name === currentLine.name || name === currentLineData?.name);

      if (savedLine && savedLine.id) {
        if (!currentLine.lineId) {
          dispatch(addDirectSalesLineId(savedLine.id));
        } else {
          const isDirectSalesChange = !isEmpty(directSalesData);
          const isCurrentLineChange = !isEmpty(currentLineData);

          if (!isDirectSalesChange && !isCurrentLineChange) return;

          if (isDirectSalesChange) dispatch(changeDirectSalesData(directSalesData));

          if (isCurrentLineChange) dispatch(changeDealCurrentLineData(currentLineData));
          dispatch(
            updateBackupFormDataLine({
              lineId: currentLine.lineId,
              newBackupLineData: { ...currentLineData, ...directSalesData },
            }),
          );
          dispatch(clearDirectSalesPrices());
          dispatch(clearDirectSalesAssetList());
          dispatch(clearActiveObjective());
          dispatch(hidePlannerSecondaryPanel());
        }

        const linesStatesWithAllocation: Record<
          string,
          { state: DirectSalesState; allocation: DirectSalesLineAllocation }
        > = Object.fromEntries(
          savedDealLines.map((line) => {
            const lineAllocation = transformDirectSalesLineAllocation(allocationSolution, line.state.status, line.id);
            return [line.id, { state: line.state, allocation: lineAllocation }];
          }),
        );
        dispatch(setDirectSalesStates(linesStatesWithAllocation));

        dispatch(
          notifySuccess({
            message: 'Successfully saved the line changes',
            timeout: NOTIFICATION_TIMEOUT.DEFAULT,
          }),
        );
      }
    } catch (error) {
      dispatch(notifyError({ message: error.message }));
    } finally {
      dispatch(
        changeLineIsSaving({
          lineId: dealLine.lineId,
          isSaving: false,
        }),
      );
    }
  };

  const saveDeal = async (
    campaignDefinitionData?: DirectSalesCampaignDefinitionExclusions,
    callback?: VoidFunction,
  ): Promise<void> => {
    if (!campaignId) {
      await submitNewDeal({ campaignDefinitionData });
    } else {
      await editDeal({ campaignDefinitionData });
    }
    callback?.();
  };

  const deleteDealLine = useCallback(
    async (lineId: string): Promise<void> => {
      dispatch(changeFormParams({ isEditingDisabled: true }));

      dispatch(
        notifyInfo({
          message: 'Saving your line changes',
          timeout: NOTIFICATION_TIMEOUT.DEFAULT,
        }),
      );

      try {
        const { id } = await deleteDirectSalesDealLine(marketId, campaignId, lineId, cancelFunctions);

        if (id) {
          dispatch(removeDirectSalesLine());
          dispatch(clearDirectSalesAllocation());
          dispatch(clearActiveObjective());
          dispatch(changeDirectSalesData({ state: { status: DirectSalesStatusOption.DRAFT_UPDATING } }));

          dispatch(
            notifySuccess({
              message: 'Successfully saved the line changes',
              timeout: NOTIFICATION_TIMEOUT.DEFAULT,
            }),
          );
        }

        dispatch(changeFormParams({ isEditingDisabled: false }));
      } catch (error) {
        dispatch(notifyError({ message: error.message }));
        dispatch(changeFormParams({ isEditingDisabled: false }));
      }
    },
    [marketId, campaignId],
  );

  return {
    checkAllocateHandler,
    getUpdatedDirectSalesCampaign,
    statusConditions: {
      showConfirm,
      showSendForApproval,
      showSave,
    },
    isSavingDisabled,
    saveDeal,
    submitNewDeal,
    editDeal,
    editDealLine,
    deleteDealLine,
    getSolution,
    saveDealLineState,
    saveCampaignState,
    discardChanges,
    showBannerInformation,
    duplicateCampaign,
    confirmChanges,
  };
};

export default useDirectSalesPlannerActions;
