import { DealStatus, ProgrammaticAndDirectSalesBookingStatus } from 'components/common/types/Deal.types';
import { STATUS_SETTINGS } from 'components/pages/Planner/PlannerLineActions/DirectSalesPlannerLineActions/Status/StatusPanel/StatusPanel.types';
import { Color } from 'lib/Chip/Chip.types';

export const bookingStatusStyles: Record<ProgrammaticAndDirectSalesBookingStatus, { text: string; style: Color }> = {
  ...STATUS_SETTINGS,
  [DealStatus.PENDING_APPROVAL]: {
    text: 'Pending',
    style: Color.YELLOW,
  },
  [DealStatus.APPROVED]: {
    text: 'Approved',
    style: Color.PRIMARY,
  },
  [DealStatus.CANCELLED]: {
    text: 'Cancelled',
    style: Color.RED,
  },
  [DealStatus.REJECTED]: {
    text: 'Rejected',
    style: Color.RED,
  },
  [DealStatus.TERMINATED]: {
    text: 'Terminated',
    style: Color.RED,
  },
  [DealStatus.LIVE]: {
    text: 'Live',
    style: Color.SKY_BLUE,
  },
  [DealStatus.ENDED]: {
    text: 'Ended',
    style: Color.GREY,
  },
  [DealStatus.FAILED]: {
    text: 'Failed',
    style: Color.RED,
  },
  [DealStatus.PENDING_RESERVATION]: {
    text: 'Pending reservation',
    style: Color.GREY,
  },
  [DealStatus.RESERVED]: {
    text: 'Reserved',
    style: Color.GREY,
  },
};

export const defaultBookingStatusStyle = { text: 'New', style: Color.GREY };
