import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import formatsSvg from 'assets/icons/formats.svg';
import { Store } from 'components/common/types/Store.types';
import { useOnChangePanelVisibility } from 'components/pages/PCM/hooks/useOnChangePanelVisibility';
import SectionButton from 'lib/SectionButton';
import { SectionButtonTheme } from 'lib/SectionButton/SectionButton.types';
import { PlannerSecondaryPanelType } from 'store/dealManagement/reducer';
import { DirectSalesCodeNameModel } from 'components/common/types/DirectSalesCampaign.types';
import { getNoRestrictionsDisplayValue } from 'components/pages/PCM/Products/utils/getNoRestrictionsDisplayValue';
import { sortByCategoryIncludeExclude } from 'components/pages/Planner/PlannerSections/FiltersContent/utils';
import { changeNewProduct, clearItemInNewProduct, hideSecondaryPanel } from 'store/productManagement/reducer';
import { ProductFieldName } from 'store/productManagement/reducer.types';
import { CodeNameModel } from 'components/common/types';
import { isValueRequired } from '../../utils/isValueRequired';
import { ProductFormatsPanel } from './ProductFormatsPanel';

const fieldName = ProductFieldName.FORMATS;

export const ProductFormats: React.FC = () => {
  const dispatch = useDispatch();
  const onChangePanelVisibility = useOnChangePanelVisibility();
  const secondaryPanelType = useSelector((state: Store) => state.productManagement.secondaryPanel);
  const selectedFormats = useSelector((state: Store) => state.productManagement.newProduct.formats?.value);
  const formatSelectedPermission = useSelector(
    (state: Store) => state.productManagement.newProduct.formats?.selectionPermission,
  );
  const isFormatPanelOpen = secondaryPanelType === PlannerSecondaryPanelType.FORMAT;

  const categorizedValues = useMemo(() => {
    return selectedFormats ? sortByCategoryIncludeExclude(selectedFormats) : [];
  }, [selectedFormats]);

  const handleConfirm = ({
    selectedPermission,
    formats,
  }: {
    selectedPermission: CodeNameModel;
    formats: DirectSalesCodeNameModel[];
  }): void => {
    const value = formats.length ? formats : undefined;

    dispatch(changeNewProduct({ fieldName, selectedPermission, value }));
    dispatch(hideSecondaryPanel());
  };

  return (
    <>
      <SectionButton
        isActive={isFormatPanelOpen}
        dataTestId="section-button-formats"
        theme={formatSelectedPermission ? SectionButtonTheme.SOLID : SectionButtonTheme.DASHED}
        label="Format"
        icon={formatsSvg}
        onClick={() => onChangePanelVisibility(PlannerSecondaryPanelType.FORMAT)}
        selected={
          isValueRequired(formatSelectedPermission)
            ? categorizedValues
            : getNoRestrictionsDisplayValue(formatSelectedPermission)
        }
        onClear={() => {
          dispatch(clearItemInNewProduct({ fieldName }));
          dispatch(hideSecondaryPanel());
        }}
      />
      {isFormatPanelOpen && (
        <ProductFormatsPanel onConfirm={handleConfirm} onCancel={() => dispatch(hideSecondaryPanel())} />
      )}
    </>
  );
};
