import SVG from 'react-inlinesvg';
import Button from 'lib/Button';
import vioohTextLogoSvg from 'assets/icons/viooh_text_logo.svg';
import smallLogoSvg from 'assets/icons/small_logo.svg';
import arrowRightSvg from 'assets/icons/arrow_right.svg';
import { VIOOH_WEBSITE_HOME } from 'consts/viooh';

export const Header: React.FC = () => {
  const onButtonClick = (): void => {
    window.open(VIOOH_WEBSITE_HOME, '_blank');
  };

  return (
    <header
      className="w-full pb-6 md:px-6 md:pt-6 text-primary-50 sub-header-base relative top-0"
      data-test-id="public-campaign-plan-header"
    >
      <div className="bg-primary-950 md:rounded-2xl md:px-7 py-6">
        <div className="max-w-7xl mx-auto px-8 flex flex-row justify-between items-center">
          <SVG src={vioohTextLogoSvg} className="h-8 flex-shrink-0" />
          <Button onClick={onButtonClick} classNames="py-2 px-3 max-h-10 flex flex-row items-center space-x-1">
            <SVG src={smallLogoSvg} className="w-[11px] h-3.5" />
            <p>viooh.com</p>
            <SVG src={arrowRightSvg} />
          </Button>
        </div>
      </div>
    </header>
  );
};
