import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import {
  canDisplayOptionResults,
  filterOptions,
  isCategoryEqual,
  sortOptions,
  updateSelectedOptions,
} from 'components/pages/Planner/PlannerSecondaryPanel/utils';
import CollapsibleList from 'lib/CollapsibleList';
import CollapsibleListStatsHeader from 'lib/CollapsibleList/CollapsibleListStatsHeader';
import { shouldDisplayCategoryClearAll } from 'components/pages/Planner/PlannerSections/FiltersContent/utils';
import SelectOption from 'lib/SelectOption';

import { SortSettings } from 'lib/CollapsibleList/CollapsibleList.types';
import { SortDir } from '../../../../common/types/SortDir.types';
import { CodeNameModel } from '../../../../common/types';

type PlannerCategoryOptionsProps = {
  searchText?: string;
  labelMap?: Record<string, string>;
  options: Record<string, CodeNameModel[]>;
  selectedOptions: CodeNameModel[];
  localStateSetter: (selectedOptions: CodeNameModel[]) => void;
};

const PlannerCategoryOptions: FC<PlannerCategoryOptionsProps> = ({
  searchText = '',
  labelMap,
  options,
  selectedOptions,
  localStateSetter,
}) => {
  const [filteredOptions, setFilteredOptions] = useState<Record<string, CodeNameModel[]>>(options);
  const [sortDirection, setSortDirection] = useState(SortDir.ASC);
  const [openCategory, setOpenCategory] = useState<string>('');

  useEffect(() => {
    const newOptions = filterOptions(options, searchText);
    setFilteredOptions(sortOptions(newOptions, sortDirection));
  }, [options, sortDirection, searchText]);

  const isOptionSelected = useCallback(
    (code: string, category: string) =>
      selectedOptions.some((option) => option.code === code && isCategoryEqual(category, option.category)),
    [selectedOptions],
  );

  const onOptionIncludeExcludeChange = (option: CodeNameModel): void => {
    localStateSetter(updateSelectedOptions(selectedOptions, option));
  };

  const onClearCategory = (clearedCategory: string): void => {
    localStateSetter(selectedOptions.filter(({ category }) => category !== clearedCategory));
  };

  const isOptionIncluded = (code: string, category: string): boolean =>
    selectedOptions?.find((option) => option.code === code && isCategoryEqual(category, option.category))?.include ||
    false;

  const includedOptions = useMemo(() => selectedOptions.filter((option) => option.include), [selectedOptions]);
  const excludedOptions = useMemo(() => selectedOptions.filter((option) => !option.include), [selectedOptions]);

  const getNumberOfFilteredOptions = (option: string, _selectedOptions: CodeNameModel[]): number => {
    return _selectedOptions.filter((selectedOption) =>
      options[option].some(
        (formatOption: CodeNameModel) =>
          formatOption.code === selectedOption.code && isCategoryEqual(option, selectedOption.category),
      ),
    ).length;
  };

  const onCategoryDirectionSort = useCallback((): void => {
    setSortDirection(SortSettings[sortDirection].nextDir);
  }, [sortDirection]);

  const onToggleCategory = useCallback(
    (category: string) => {
      if (category === openCategory) setOpenCategory('');
      else setOpenCategory(category);
    },
    [openCategory],
  );

  return (
    <>
      {canDisplayOptionResults(filteredOptions) ? (
        <>
          {!!filteredOptions &&
            Object.keys(filteredOptions).map((category) => {
              const label = labelMap ? labelMap[category] : category;

              return (
                <CollapsibleList
                  label={label}
                  isSortVisible
                  onSort={onCategoryDirectionSort}
                  sortDir={sortDirection}
                  isClearAllVisible={shouldDisplayCategoryClearAll(selectedOptions, label)}
                  isOpen={category === openCategory}
                  onClearAll={() => onClearCategory(label)}
                  onToggle={() => onToggleCategory(label)}
                  key={`location-category-${category}`}
                  headerComponent={
                    <CollapsibleListStatsHeader
                      available={filteredOptions?.[category].length}
                      included={getNumberOfFilteredOptions(category, includedOptions)}
                      excluded={getNumberOfFilteredOptions(category, excludedOptions)}
                    />
                  }
                >
                  {filteredOptions?.[category].map((subOption) => {
                    return (
                      <SelectOption
                        value={{
                          code: subOption.code,
                          name: subOption.name,
                          include: isOptionIncluded(subOption.code, category),
                        }}
                        key={`select-category-${subOption.code}`}
                        isSelected={isOptionSelected(subOption.code, category)}
                        isDisabled={false}
                        isMultiple
                        hasCheckbox={false}
                        isIncludeExcludeAlwaysVisible
                        onIncludeExcludeChange={(value) =>
                          onOptionIncludeExcludeChange({
                            ...value,
                            category: label,
                          })
                        }
                        classNames="!m-0"
                      >
                        <p className="max-w-[250px] truncate">{subOption.name}</p>
                      </SelectOption>
                    );
                  })}
                </CollapsibleList>
              );
            })}
        </>
      ) : (
        <p>There are no results matching your search</p>
      )}
    </>
  );
};

export default PlannerCategoryOptions;
