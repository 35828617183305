import { AxiosResponse } from 'axios';
import isAfter from 'date-fns/isAfter';
import endOfDay from 'date-fns/endOfDay';
import {
  CampaignPlanDetails,
  CampaignPlanErrorEvent,
  CampaignPlanErrorTitle,
  CampaignPlanResponse,
} from 'components/common/types/CampaignPlan.types';
import { CancelFunctions } from 'components/common/types';
import { HTTP_METHODS, URLS } from './constants';
import createRequest from './request';

export const validateCampaignPlanResponse = (response: AxiosResponse<CampaignPlanResponse>): void => {
  const isCampaignPlanIsCancelled = (): boolean => response.status === 204;

  const isCampaignPlanSuccessResponse = (data: CampaignPlanResponse): data is CampaignPlanDetails => {
    return 'campaignId' in data && 'endDate' in data;
  };

  const isCampaignPlanIsExpired = (data: CampaignPlanDetails): boolean =>
    isAfter(new Date(), endOfDay(new Date(data.endDate)));

  if (isCampaignPlanIsCancelled())
    throw new CampaignPlanErrorEvent({
      message: 'You cannot access this campaign plan due to its current status.',
      title: CampaignPlanErrorTitle.UNAUTHORIZED,
    });

  if (!isCampaignPlanSuccessResponse(response.data)) throw new CampaignPlanErrorEvent(response.data);

  if (isCampaignPlanIsExpired(response.data))
    throw new CampaignPlanErrorEvent({
      message: 'You cannot access this campaign plan as it has expired.',
      title: CampaignPlanErrorTitle.UNAUTHORIZED,
    });
};

export const getCampaignPlan = async (
  token: string,
  cancelFunctions: CancelFunctions,
): Promise<CampaignPlanDetails> => {
  const { send, cancel } = createRequest({
    url: URLS().FETCH_CAMPAIGN_PLAN(token),
    method: HTTP_METHODS.GET,
    ignoreHeaders: true,
  });

  if (cancelFunctions.FETCH_CAMPAIGN_PLAN) cancelFunctions.FETCH_CAMPAIGN_PLAN();
  cancelFunctions.FETCH_CAMPAIGN_PLAN = cancel;

  try {
    const result: AxiosResponse<CampaignPlanResponse> = await send();

    validateCampaignPlanResponse(result);

    return result.data as CampaignPlanDetails;
  } catch (error) {
    if (error.response?.data) {
      throw new CampaignPlanErrorEvent(error.response.data);
    }
    throw error;
  }
};
