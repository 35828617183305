import React from 'react';
import cx from 'classnames';
import ShowMoreMenu from 'lib/ShowMoreMenu';
import SVG from 'react-inlinesvg';
import eraseSvg from 'assets/icons/erase.svg';
import cloneSvg from 'assets/icons/clone.svg';
import editSvg from 'assets/icons/edit.svg';
import SectionDivider from 'components/pages/Planner/SectionDivider';
import { ProductCreationStatus } from 'components/pages/PCM/Products/ProductsTable/ProductsTable.types';
import { ProductOptionsMenuProps } from './ProductOptionsMenu.types';

const ProductOptionsMenu: React.FC<ProductOptionsMenuProps> = ({
  isOpen,
  onToggle,
  onKeepAndDuplicate,
  onDisableAndDuplicate,
  status,
  onChangeOfStatus,
}) => {
  return (
    <ShowMoreMenu
      isOpen={isOpen}
      onToggle={onToggle}
      svgClassName="rotate-90 cursor-pointer"
      contentWidth="min-w-[285px]"
    >
      <button
        className="py-2 px-2 w-full flex justify-start items-center text-essential-secondary body-base space-x-2 enabled:hover:bg-primary-50"
        type="button"
        onClick={onKeepAndDuplicate}
      >
        <p className="w-10/12 text-left body-base truncate whitespace-nowrap overflow-hidden">
          Duplicate & set original as active
        </p>
        <SVG src={editSvg} className="w-4 h-4" />
      </button>
      <button
        className="py-2 px-2 w-full flex justify-start items-center text-essential-secondary body-base space-x-2 enabled:hover:bg-primary-50"
        type="button"
        onClick={onDisableAndDuplicate}
      >
        <p className="w-10/12 text-left body-base truncate whitespace-nowrap overflow-hidden">
          Duplicate & set original as disabled
        </p>
        <SVG src={cloneSvg} className="w-4 h-4" />
      </button>
      <SectionDivider />
      <button
        className={cx(
          'py-2 px-2 w-full flex justify-start items-center body-base space-x-2 enabled:hover:bg-primary-50',
          {
            'text-red-900': status === ProductCreationStatus.ACTIVE,
            'text-green-700': status !== ProductCreationStatus.ACTIVE,
          },
        )}
        type="button"
        onClick={onChangeOfStatus}
      >
        <p className="w-10/12 text-left body-base truncate whitespace-nowrap overflow-hidden">
          {status === ProductCreationStatus.ACTIVE ? 'Disable' : 'Activate'}
        </p>
        <SVG src={eraseSvg} className="w-4 h-4" />
      </button>
    </ShowMoreMenu>
  );
};

export default ProductOptionsMenu;
