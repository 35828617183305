import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNetworksData } from 'modules/api/lookups';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import { changeFormParams } from 'store/dealManagement/reducer';
import { changeNetworkReferenceData } from 'store/userOptions/reducer';
import { NetworkReferenceResponse } from 'store/userOptions/reducer.types';
import { Store } from 'components/common/types/Store.types';
import { DirectSalesMediaType } from 'components/common/types/DirectSalesCampaign.types';
import { AssetTypes } from 'components/common/types/AssetList.types';
import { UseReferenceDataProps } from './UseReferenceData.types';

const useNetworkReferenceData = ({ environments, mediaType, startDate, endDate }: UseReferenceDataProps): void => {
  const cancelFunctions = useCancelRequest();
  const dispatch = useDispatch();
  const market = useSelector((state: Store) => state.publisher.configuration.marketId);

  useEffect(() => {
    if (!environments.length || !mediaType || !startDate || !endDate) return;

    const loadNetworkReferenceData = async (): Promise<void> => {
      dispatch(changeFormParams({ isLoading: true, isFetchingNetworks: true }));

      try {
        const { networkGroup }: { networkGroup: NetworkReferenceResponse[] } = await getNetworksData(cancelFunctions, {
          market,
          assetTypes: [mediaType === DirectSalesMediaType.PAPER ? AssetTypes.STATIC : mediaType],
          channelCodes: environments.map(({ code }) => code),
          startDate,
          endDate,
        });

        dispatch(changeNetworkReferenceData(networkGroup));
        dispatch(changeFormParams({ isLoading: false, isFetchingNetworks: false }));
      } catch (e) {
        dispatch(changeFormParams({ isLoading: false, isFetchingNetworks: false }));
      }
    };

    loadNetworkReferenceData();
  }, [environments, mediaType, startDate, endDate]);
};

export default useNetworkReferenceData;
