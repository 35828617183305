export enum BorderStyle {
  NONE = 'border-none',
  BOTTOM = 'border-b border-b-1 border-b-neutral-300',
}

export interface AccordionProps {
  dataTestId: string;
  label: string;
  border?: BorderStyle;
  onClose?: VoidFunction;
  onOpen?: VoidFunction;
  isBorderHiddenWhenOpen?: boolean;
  isDisabled?: boolean;
  isOpenOnInit?: boolean;
  isExternalOpen?: boolean;
  icon?: string;
  labelClassNames?: string;
  extendedChildren?: JSX.Element;
  toggleIconPosition?: 'left' | 'right';
  wrapperClassNames?: string;
  toggleIconClassNames?: string;
}
