import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import {
  ColGroupDef,
  ColumnApi,
  ColumnGroupShowType,
  GridOptions,
  GridReadyEvent,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import { parseISO } from 'date-fns';
import {
  getProgrammaticSavedAllocationReport,
  getProgrammaticProposalAllocationReport,
  getDirectSalesAllocationReport,
  getPublicAllocationReport,
} from 'modules/api/allocationReport';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import { TableHeader } from 'components/common/TableHeader';
import { HeaderBackgroundColor, TextColor } from 'components/common/TableHeader/TableHeader.types';
import useCampaignType from 'components/pages/Planner/hooks/useCampaignType';
import { useParams } from 'react-router-dom';
import { Store } from '../types/Store.types';
import {
  AggregationFunction,
  AllocationReportData,
  AllocationWeek,
  Frame,
  MetricOption,
  ReportType,
  RowGroupColumn,
  SelectedLine,
} from './AllocationReport.types';
import { HOURS, defaultMetricOption, rowGroupColumns } from './consts';
import { HeaderGroupComponent } from './Table/CustomComponents/HeaderGroupComponent';
import { ReportCreationDateChip } from './Table/CustomComponents/ReportCreationDateChip';
import {
  generateTimeBreakdownColumnHeaders,
  redrawGroupedRowsWhenSortChanged,
  togglePinnedColumnOnColumnGroupOpen,
} from './Table/utils';
import { valueFormatters } from './utils';
import RowGroupRenderer from './Table/CustomComponents/RowGroupRenderer';
import { useExportAllocationReport } from './useExportAllocationReport';

export type ChangeLineHandler = (fieldName: string, selectedLine: SelectedLine) => void;
export type ChangeMetricTypeHandler = (selectedMetricType: MetricOption) => void;

export interface AllocationReportTableParams {
  gridOptions: GridOptions;
  onGridReady: (event: GridReadyEvent<Frame[]>) => void;
  rowData: Frame[];
}

export type UseAllocationReport = ({
  dealLines,
  defaultSelectedLine,
  publicToken,
  publicLocaleCode,
}: {
  dealLines: SelectedLine[];
  defaultSelectedLine: SelectedLine;
  publicToken?: string;
  publicLocaleCode?: string;
}) => {
  activeReportType: ReportType;
  allocationReportData?: AllocationReportData;
  allocationReportTableParams: AllocationReportTableParams;
  handleChangeLine: ChangeLineHandler;
  handleChangeMetricType: ChangeMetricTypeHandler;
  handleExportReport: () => void;
  handleFetchAllocationReport: (reportType: ReportType) => void;
  isLoading: boolean;
  isDirectSalesCampaignType: boolean;
  selectedLine: SelectedLine;
  selectedMetricType: MetricOption;
  selectedRowGroupColumns: RowGroupColumn[];
  handleToggleRowGroupColumn: (rowGroupColumns: RowGroupColumn[]) => void;
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const useAllocationReport: UseAllocationReport = ({ defaultSelectedLine, publicToken, publicLocaleCode }) => {
  const { campaignId: originalCampaignId } = useParams();
  const cancelFunctions = useCancelRequest();
  const { isDirectSalesCampaignType, isProgrammaticCampaignType } = useCampaignType();

  const marketId = useSelector((state: Store) => state.publisher.configuration.marketId);
  const publisherLocaleCode = useSelector((state: Store) => state.publisher.configuration.localeCode);
  const localeCode = publicLocaleCode || publisherLocaleCode;
  const campaignType = useSelector((state: Store) => state.dealManagement.campaignType);
  const dealId = useSelector((state: Store) => state.dealManagement.commonDeal.dealId);
  const proposalCampaignId = useSelector((state: Store) => state.dealManagement.temporaryDealId);

  const [isLoading, setIsLoading] = useState(false);
  const [allocationReportData, setAllocationReportData] = useState<AllocationReportData>();
  const [selectedLine, setSelectedLine] = useState<SelectedLine>(defaultSelectedLine);
  const [selectedMetricType, setSelectedMetricType] = useState(defaultMetricOption);
  const [activeReportType, setActiveReportType] = useState(selectedLine.lineDefaultReportType);
  const [rowData, setRowData] = useState<Frame[]>([]);
  const [totalAllocations, setTotalAllocations] = useState<AllocationWeek[]>([]);
  const [startOfLoadingTimeInMs, setStartOfLoadingTimeInMs] = useState<number>();
  const [selectedRowGroupColumns, setSelectedRowGroupColumns] = useState<RowGroupColumn[]>(rowGroupColumns);
  const [columnApi, setColumnApi] = useState<ColumnApi | null>(null);

  const { exportDirectSalesAllocationReport, exportProgrammaticAllocationReport } = useExportAllocationReport({
    activeReportType,
    allocationReportData,
    selectedLine,
    selectedMetricType,
  });

  const fetchAllocationReport = async (reportType: ReportType): Promise<void> => {
    try {
      setIsLoading(true);
      setStartOfLoadingTimeInMs(performance.now());

      let allocationReportResponse;

      if (publicToken) {
        allocationReportResponse = await getPublicAllocationReport(
          publicToken,
          selectedLine.lineId,
          selectedMetricType.code,
          cancelFunctions,
        );
      } else if (isDirectSalesCampaignType) {
        const campaignId = reportType === ReportType.SAVED && originalCampaignId ? originalCampaignId : dealId;

        allocationReportResponse = await getDirectSalesAllocationReport(
          marketId,
          campaignId,
          selectedLine.lineId,
          selectedMetricType.code,
          cancelFunctions,
        );
      } else if (isProgrammaticCampaignType && reportType === ReportType.NOT_SAVED) {
        allocationReportResponse = await getProgrammaticProposalAllocationReport(
          proposalCampaignId,
          selectedMetricType.code,
          cancelFunctions,
        );
      } else if (isProgrammaticCampaignType && reportType === ReportType.SAVED) {
        allocationReportResponse = await getProgrammaticSavedAllocationReport(
          dealId,
          selectedLine.lineId,
          selectedMetricType.code,
          cancelFunctions,
        );
      } else {
        return;
      }

      setAllocationReportData(allocationReportResponse);
      setRowData(allocationReportResponse.frames);
      setActiveReportType(reportType);

      if (allocationReportResponse?.totalAllocations?.length > 0) {
        setTotalAllocations(allocationReportResponse.totalAllocations);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Allocation Report fetch failed:', error);
      datadogLogs.logger.error('Allocation Report fetch failed', { tradingType: campaignType, error });
      setAllocationReportData(undefined);
      setRowData([]);
    } finally {
      setIsLoading(false);
    }
  };

  const updateRowGroupColumnState = (colApi: ColumnApi | null, selectedGroups: RowGroupColumn[]): void => {
    if (!colApi) return;

    const rowGroupColumnState = selectedGroups.map(({ colId, isSelected, groupOrder }) => {
      return {
        colId,
        rowGroup: isSelected,
        rowGroupIndex: groupOrder,
      };
    });

    colApi.applyColumnState({
      state: rowGroupColumnState,
      applyOrder: true,
    });
  };

  const updateSelectedRowGroupColumns = (colApi: ColumnApi): void => {
    const activeRowGroupColumnsIds = colApi.getRowGroupColumns().map((rowGroupColumn) => rowGroupColumn.getColId());

    const updatedRowGroupColumns = rowGroupColumns.map((rowGroupColumn) => {
      const isSelected = activeRowGroupColumnsIds.includes(rowGroupColumn.colId);

      return {
        ...rowGroupColumn,
        isSelected,
        groupOrder: isSelected ? activeRowGroupColumnsIds.indexOf(rowGroupColumn.colId) : undefined,
      };
    });

    setSelectedRowGroupColumns(updatedRowGroupColumns);
  };

  const handleFormatValue = (params: ValueFormatterParams): string => {
    if (typeof params.value === 'number') {
      return valueFormatters[selectedMetricType.code](params.value, localeCode);
    }

    if (params.colDef.aggFunc === AggregationFunction.AVG && params?.value?.value != null) {
      return valueFormatters[selectedMetricType.code](params.value.value, localeCode);
    }

    return '';
  };

  const timeBreakdownColumnHeaders = useMemo(() => {
    if (allocationReportData?.startDate && allocationReportData.endDate) {
      return generateTimeBreakdownColumnHeaders(
        parseISO(allocationReportData.startDate),
        parseISO(allocationReportData.endDate),
        localeCode,
      );
    }

    return null;
  }, [allocationReportData?.startDate, allocationReportData?.endDate]);

  const onGridReady = useCallback<AllocationReportTableParams['onGridReady']>(
    (onGridReadyEvent) => {
      if (!allocationReportData || !timeBreakdownColumnHeaders) {
        datadogLogs.logger.error('Allocation Report load failed', { tradingType: campaignType });
        onGridReadyEvent.api.showNoRowsOverlay();

        return;
      }

      const aggFunc = allocationReportData.rowGroupAggFunction;

      const columnDefinitions: ColGroupDef[] = [
        {
          headerGroupComponent: () =>
            !!allocationReportData.metaData &&
            ReportCreationDateChip({
              creationDateTime: allocationReportData.metaData.report.creationDateTime,
            }),
          groupId: 'timestamp',
          children: [
            {
              cellClass: 'frame-cell',
              cellClassRules: {
                'show-line': (params) => !params.node.firstChild,
              },
              colId: 'frame-id',
              field: 'frameId',
              headerComponent: TableHeader,
              headerComponentParams: {
                backgroundColor: HeaderBackgroundColor.WHITE,
                label: 'Frame ID',
                sortable: true,
                textColor: TextColor.SECONDARY,
              },
              cellRenderer: RowGroupRenderer,
              width: 170,
              pinned: 'left',
              sortable: true,
              showRowGroup: true,
            },
          ],
        },
        {
          groupId: 'total-duration',
          headerGroupComponent: HeaderGroupComponent,
          headerGroupComponentParams: {
            backgroundColor: HeaderBackgroundColor.WHITE,
            label: timeBreakdownColumnHeaders.label,
            hideDuplicateWhenPinned: true,
            headerLevel: 'Total line duration',
          },
          openByDefault: false,
          children: [
            {
              aggFunc,
              cellClass: 'data-cell',
              colId: 'frame-total',
              field: 'total',
              headerComponent: TableHeader,
              headerComponentParams: {
                backgroundColor: HeaderBackgroundColor.WHITE,
                label: 'Total',
                textColor: TextColor.SECONDARY,
              },
              openByDefault: true,
              width: 170,
              valueGetter: (params: ValueGetterParams) => params.data?.total,
              valueFormatter: handleFormatValue,
            },
            ...rowGroupColumns.map(({ colId, field, name }) => ({
              colId,
              field,
              headerValueGetter: () => name,
              hide: true,
              sortable: true,
            })),
            ...timeBreakdownColumnHeaders.weeks.map((week) => ({
              aggFunc,
              columnGroupShow: 'open' as ColumnGroupShowType,
              headerGroupComponent: HeaderGroupComponent,
              headerGroupComponentParams: {
                label: week.label,
                headerLevel: 'Week',
              },
              children: [
                {
                  aggFunc,
                  cellClass: 'data-cell',
                  columnGroupShow: 'closed',
                  field: `allocations.${week.allocationWeek}.total`,
                  headerName: '',
                  valueGetter: (params: ValueGetterParams) =>
                    params.data?.allocations?.[week.allocationWeek].total || 0,
                  valueFormatter: handleFormatValue,
                },
                {
                  aggFunc,
                  cellClass: 'data-cell',
                  columnGroupShow: 'open',
                  field: `allocations.${week.allocationWeek}.total`,
                  headerGroupComponent: TableHeader,
                  headerGroupComponentParams: {
                    backgroundColor: HeaderBackgroundColor.SECONDARY,
                    label: 'Total',
                  },
                  valueGetter: (params: ValueGetterParams) =>
                    params.data?.allocations?.[week.allocationWeek].total || 0,
                  valueFormatter: handleFormatValue,
                  children: [
                    {
                      aggFunc,
                      cellClass: 'data-cell',
                      columnGroupShow: 'open',
                      headerName: '',
                      field: `allocations.${week.allocationWeek}.total`,
                      valueGetter: (params: ValueGetterParams) =>
                        params.data?.allocations?.[week.allocationWeek].total || 0,
                      valueFormatter: handleFormatValue,
                    },
                  ],
                },
                ...week.days.map((day, dayIndex) => ({
                  aggFunc,
                  columnGroupShow: 'open',
                  field: `allocations.${week.allocationWeek}.days.${dayIndex}.total`,
                  headerGroupComponent: HeaderGroupComponent,
                  headerGroupComponentParams: {
                    label: day.label,
                    headerLevel: 'Day',
                  },
                  valueGetter: (params: ValueGetterParams) =>
                    params.data?.allocations[week.allocationWeek].days[dayIndex].total || 0,
                  valueFormatter: handleFormatValue,
                  children: [
                    {
                      aggFunc,
                      cellClass: 'data-cell',
                      columnGroupShow: 'closed',
                      field: `allocations.${week.allocationWeek}.days.${dayIndex}.total`,
                      headerName: '',
                      valueGetter: (params: ValueGetterParams) =>
                        params.data?.allocations[week.allocationWeek].days[dayIndex].total || 0,
                      valueFormatter: handleFormatValue,
                    },
                    {
                      aggFunc,
                      cellClass: 'data-cell',
                      columnGroupShow: 'open',
                      field: `allocations.${week.allocationWeek}.days.${dayIndex}.total`,
                      headerComponent: TableHeader,
                      headerComponentParams: {
                        backgroundColor: HeaderBackgroundColor.SECONDARY,
                        label: 'Total',
                      },
                      valueGetter: (params: ValueGetterParams) =>
                        params.data?.allocations[week.allocationWeek].days[dayIndex].total || 0,
                      valueFormatter: handleFormatValue,
                    },
                    ...HOURS.map((hour, hourIndex) => ({
                      aggFunc,
                      cellClass: 'data-cell',
                      columnGroupShow: 'open',
                      field: `allocations.${week.allocationWeek}.days.${dayIndex}.hours.${hourIndex}`,
                      headerComponent: TableHeader,
                      headerComponentParams: {
                        backgroundColor: HeaderBackgroundColor.PRIMARY,
                        label: hour.label,
                        headerLevel: 'Hour',
                      },
                      valueGetter: (params: ValueGetterParams) =>
                        params.data?.allocations?.[week.allocationWeek].days[dayIndex].hours?.[hourIndex],
                      valueFormatter: handleFormatValue,
                    })),
                  ],
                })),
              ],
            })),
          ],
        },
      ];

      onGridReadyEvent.api.setColumnDefs(columnDefinitions);
      setColumnApi(onGridReadyEvent.columnApi);
      updateRowGroupColumnState(onGridReadyEvent.columnApi, selectedRowGroupColumns);

      const loadingEndTime = performance.now();

      datadogLogs.logger.info('Allocation Report loaded', {
        tradingType: campaignType,
        allocationReportLoadTimeInMs: Math.trunc(loadingEndTime - startOfLoadingTimeInMs!),
        networkSpeedInMbps: navigator.connection?.downlink || null, // Chromium's hard limit is 10 Mbps,
      });
    },
    [allocationReportData, timeBreakdownColumnHeaders],
  );

  const gridOptions: GridOptions = {
    onGridReady,
    onColumnGroupOpened: (params) =>
      togglePinnedColumnOnColumnGroupOpen(params, 'total-duration', 'frame-total', 'right'),
    suppressAsyncEvents: !publicToken,
    defaultColDef: {
      width: 120,
      resizable: true,
      suppressMenu: true,
      minWidth: 100,
    },
    onColumnRowGroupChanged: (params) => updateSelectedRowGroupColumns(params.columnApi),
    rowGroupPanelShow: 'onlyWhenGrouping',
    onSortChanged: redrawGroupedRowsWhenSortChanged,
    suppressMakeColumnVisibleAfterUnGroup: true,
    groupDisplayType: 'custom',
    suppressAggFuncInHeader: true,
    suppressMovableColumns: true,
    alwaysShowHorizontalScroll: true,
    pinnedBottomRowData:
      totalAllocations.length > 0
        ? [
            {
              allocations: totalAllocations,
              frameId: 'Total',
              total: allocationReportData?.total,
            },
          ]
        : undefined,
  };

  const handleChangeLine: ChangeLineHandler = (_, line): void => {
    setSelectedLine(line);
    setActiveReportType(line.lineDefaultReportType);
  };

  const handleChangeMetricType: ChangeMetricTypeHandler = (metricType): void => {
    datadogRum.addAction(`click on Allocation report / ${metricType.name} metric type`);
    setSelectedMetricType(metricType);
  };

  const handleToggleRowGroupColumn = (selectedGroups: RowGroupColumn[]): void => {
    const selectedRowGroupColumnsIds = selectedGroups.map(({ colId }) => colId);

    const updatedRowGroupColumns = rowGroupColumns.map((rowGroup) => {
      const isSelected = selectedRowGroupColumnsIds.includes(rowGroup.colId);

      return {
        ...rowGroup,
        isSelected,
        groupOrder: isSelected ? selectedRowGroupColumnsIds.indexOf(rowGroup.colId) : undefined,
      };
    });

    setSelectedRowGroupColumns(updatedRowGroupColumns);
    updateRowGroupColumnState(columnApi, updatedRowGroupColumns);
  };

  useEffect(() => {
    if (selectedLine && selectedMetricType && !isLoading) fetchAllocationReport(activeReportType);
  }, [selectedLine, selectedMetricType]);

  return {
    activeReportType,
    allocationReportTableParams: {
      gridOptions,
      onGridReady,
      rowData,
    },
    allocationReportData,
    handleChangeLine,
    handleChangeMetricType,
    handleExportReport: isDirectSalesCampaignType
      ? exportDirectSalesAllocationReport
      : exportProgrammaticAllocationReport,
    handleFetchAllocationReport: fetchAllocationReport,
    isLoading,
    isDirectSalesCampaignType,
    selectedLine,
    selectedMetricType,
    selectedRowGroupColumns,
    handleToggleRowGroupColumn,
  };
};
