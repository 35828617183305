import { RefObject } from 'react';

export type ToasterProps = { toasts: ToastProps[]; onToastRemove: (id: string) => void };

export type ToastProps = {
  id: string;
  header: string;
  description: string;
};

export type ToastElementProps = {
  index: number;
  toast: ToastProps;
  maxVisibleToasts: number;
  onRemove: (toastId: string) => void;
};

export type UseToasterScroller = (
  containerRef: RefObject<HTMLDivElement>,
  totalToasts: number,
  maxVisibleToasts: number,
) => {
  numOfScrolledToasts: number;
  decreaseScrolledToasts: () => void;
};

export const Z_INDEX = 1000;
export const SPACING = 166;
export const MIN_VISIBLE_TOASTS = 1;
export const DEFAULT_MAX_VISIBLE_TOASTS = 5;
export const NUM_OF_FADING_TOAST = 2;
export const LAST_TOAST_OPACITY = 0.15;
export const MOUSE_WHEEL_SCROLL_TICK = 12;
export const MIN_MOUSE_WHEEL_DISTANCE_TO_SCROLL_NOTIFICATION = MOUSE_WHEEL_SCROLL_TICK;
export const MIN_TOUCHPAD_WHEEL_DISTANCE_TO_SCROLL_NOTIFICATION = 60;
