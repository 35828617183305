import React from 'react';

type SectionWrapperProps = {
  title: string;
  children?: React.ReactNode;
};

export const SectionWrapper: React.FC<SectionWrapperProps> = ({ title, children }) => {
  return (
    <div className="w-full grid grid-cols-[2fr_3fr] border-b border-neutral-950-opacity-10 py-4">
      <div>
        <p className="body-sm text-essential-tertiary">{title}</p>
      </div>
      <div className="flex flex-col gap-1">{children}</div>
    </div>
  );
};
