import { DirectSalesMediaType } from './DirectSalesCampaign.types';

export enum DirectSalesProductTemplateStatus {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
}

export enum DirectSalesProductTemplateRuleType {
  PRICING_MODE = 'pricingMode',
  RANGE = 'range',
  MEDIA_TYPE = 'mediaTypeFilter',
  CHANNEL = 'channelFilter',
  FORMAT = 'productFormatFilter',
}

export enum DirectSalesProductTemplateBehaviour {
  FIXED = 'FIXED',
  SET = 'SET',
  ANY = 'ANY',
}

export const productRuleMap: Record<string, string> = {
  channels: 'ChannelFilter',
  mediaType: 'MediaTypeFilter',
  range: 'Range',
  pricingMode: 'PricingMode',
  budget: 'BudgetTarget',
  frames: 'FrameTarget',
  impressions: 'VolumeTarget',
  networks: 'NetworkTarget',
};

export type DirectSalesProductTemplateMediaFilter = {
  type: DirectSalesProductTemplateRuleType;
  mediaType: DirectSalesMediaType;
};

export type DirectSalesProductTemplateChannelFilter = {
  type: DirectSalesProductTemplateRuleType;
  name: string;
  id: string;
};

export type DirectSalesProductTemplateTargetFilter = {
  target: number;
  defaultGreenTolerance: number;
  defaultOrangeTolerance: number;
  defaultPriority: number;
};

export type DirectSalesProductTemplateNetworkFilter = DirectSalesProductTemplateTargetFilter & {
  networkId: string;
};

export type DirectSalesProductFormatsFilter = {
  type: string;
  selectionType: 'Include' | 'Exclude';
  name: string;
  id: string;
};

export type DirectSalesProductTemplateChannelsFilter = DirectSalesProductTemplateChannelFilter[];

export type DirectSalesFixedSet =
  | string[]
  | DirectSalesProductTemplateMediaFilter[]
  | DirectSalesProductTemplateChannelFilter[]
  | DirectSalesProductTemplateTargetFilter[]
  | DirectSalesProductFormatsFilter[];

export type DirectSalesAllowedSet = { startDate: string; endDate: string }[];

export type DirectSalesProductRuleFixedSet = DirectSalesProductRules & {
  fixedSet: DirectSalesFixedSet;
};

export type DirectSalesProductRuleAllowedSet = DirectSalesProductRules & {
  allowedSet: DirectSalesAllowedSet;
};

export type DirectSalesProductRules = {
  ruleType: DirectSalesProductTemplateRuleType;
  behaviour: DirectSalesProductTemplateBehaviour;
};

export type DirectSalesProductTemplateRequest = {
  name: string;
  folder: string;
  status: DirectSalesProductTemplateStatus;
  rules: DirectSalesProductRules[];
};

export type DirectSalesProductTemplateResponse = {
  id: string;
};
