import { CancelFunctions } from 'components/common/types';
import { ShareCampaignPlanResponse } from 'components/common/types/DirectSalesCampaignResponse.types';
import createRequest from './request';
import { HTTP_METHODS, URLS } from './constants';

export const createShareCampaignPlanToken = async (
  cancelFunctions: CancelFunctions,
  marketId: string,
  campaignId: string,
): Promise<ShareCampaignPlanResponse> => {
  const { send, cancel } = createRequest({
    url: URLS().CAMPAIGN_REPORTING_TOKEN(marketId, campaignId),
    showLoader: true,
    method: HTTP_METHODS.POST,
    data: {},
  });

  if (cancelFunctions.CAMPAIGN_REPORTING_TOKEN) cancelFunctions.CAMPAIGN_REPORTING_TOKEN();
  cancelFunctions.CAMPAIGN_REPORTING_TOKEN = cancel;

  const result = await send();
  return result.data;
};
