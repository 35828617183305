import { PropsWithChildren, RefObject } from 'react';
import { IconSize, InputShape, InputSize, InputTheme, InputType } from 'components/common/types/Input.types';

interface RenderElementProps {
  isDisabled?: boolean;
}

export enum InputAlign {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export interface BaseInputProps
  extends PropsWithChildren<Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type' | 'size'>> {
  id?: string;
  dataTestId?: string;
  size?: InputSize;
  iconSize?: IconSize;
  value?: string | number;
  label?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  type?: InputType;
  placeholder?: string;
  theme?: InputTheme;
  infoMessage?: string;
  errorMessage?: string;
  startIconSvg?: string;
  endIconSvg?: string;
  textSymbol?: string;
  align?: InputAlign;
  shape?: InputShape;
  endComponent?: React.ReactNode;
  renderEndComponent?: (props: RenderElementProps) => React.ReactElement<RenderElementProps>;
  dataPendoId?: string | null;
  inputRef?: RefObject<HTMLInputElement>;
}

export interface InputBorderStyleProps {
  isDisabled?: boolean;
  errorMessage?: string;
  shape?: InputShape;
  classNames?: string;
  theme?: InputTheme;
}

export interface InputStyleProps {
  size?: InputSize;
  theme?: InputTheme;
  classNames?: string;
  align?: InputAlign;
  shape?: InputShape;
}
