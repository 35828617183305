import BaseSelect from 'lib/BaseSelect';
import { Label } from 'lib/Label';

import { codeNameProductCategoryExclusivity } from 'consts/productCategoryExclusivityOptions';
import {
  DirectSalesProductCategoryExclusivityLabel,
  DirectSalesProductCategoryExclusivityType,
} from 'components/common/types/DirectSalesCampaign.types';

import { InputSize } from 'components/common/types/Input.types';
import { Padding as BaseSelectPadding } from 'lib/BaseSelect/BaseSelect.types';
import { CodeNameModel } from 'components/common/types';
import ProductCategoryExclusionProps from './ProductCategoryExclusivity.types';

const ProductCategoryExclusivity: React.FC<ProductCategoryExclusionProps> = ({ onSelect, selectedValue }) => {
  return (
    <div className="flex justify-between items-center">
      <Label id="product-category-exclusivity" label="Product category exclusivity" />
      <div className="w-[167px]">
        <BaseSelect
          name="productCategoryExclusivity"
          dataTestId="product-category-exclusivity-select"
          options={codeNameProductCategoryExclusivity}
          selectedValue={{
            code: selectedValue,
            name: DirectSalesProductCategoryExclusivityLabel[selectedValue],
          }}
          size={InputSize.SMALL}
          onSelect={(_, value: CodeNameModel) => onSelect(value.code as DirectSalesProductCategoryExclusivityType)}
          padding={BaseSelectPadding.NONE}
        />
      </div>
    </div>
  );
};

export default ProductCategoryExclusivity;
