import { formatNumber, formatPercent } from 'modules/I18N';
import { MetricOption, MetricType, SelectedLine, ValueFormatter } from './AllocationReport.types';
import TIMEZONES from '../Header/Timezone/timezones';

export const generateFileName = ({
  activeReportType,
  selectedMetricType,
  selectedLine,
  timezone,
}: {
  activeReportType: string;
  selectedMetricType: MetricOption;
  selectedLine: SelectedLine;
  timezone: string;
}): string => {
  const reportType = activeReportType.replaceAll(' ', '_');
  const reportMetric = selectedMetricType.name.slice(0, 3);
  const dealLineName = selectedLine.lineName.slice(0, 35).replaceAll(' ', '_');

  const localeCodeThatIsInYYYYMMDDFormat = 'en-CA';
  const currentDate = new Date();
  const reportExportDate = currentDate
    .toLocaleDateString(localeCodeThatIsInYYYYMMDDFormat, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
    .replaceAll('-', '');

  const localeCodeThatIsInHHMMFormat = 'en-GB';
  const reportExportTime = currentDate
    .toLocaleTimeString(localeCodeThatIsInHHMMFormat, {
      hour: '2-digit',
      minute: '2-digit',
    })
    .replaceAll(':', '')
    .slice(0, 4);

  const timezoneAbbreviation = TIMEZONES.find((config) => config.code === timezone)?.tzAbr ?? '';

  return `${reportType}_Report_${reportMetric}_${dealLineName}_${reportExportDate}_${reportExportTime}_${timezoneAbbreviation}`;
};

export const formatToPercentage = (value: number, decimalPlaces?: number): string => {
  return `${(value * 100).toFixed(decimalPlaces ?? 0)}%`;
};

export const valueFormatters: Record<MetricType, ValueFormatter> = {
  [MetricType.IMPRESSIONS]: (value, localeCode) => formatNumber({ value, localeCode, digits: 0 }),
  [MetricType.SOT]: (value, localeCode) => formatPercent({ value, localeCode, maximumFractionDigits: 2 }),
  [MetricType.FRAMES]: (value, localeCode) => formatNumber({ value, localeCode, digits: 0 }),
};
