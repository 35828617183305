import { DirectSalesFilteredCampaignsStatusTableResponse } from 'components/common/types/DirectSalesCampaign.types';
import Tooltip from 'components/patterns/Tooltip';
import Chip from 'lib/Chip';
import { Color } from 'lib/Chip/Chip.types';
import React from 'react';
import { capitalize } from 'lodash';

export interface StatusRendererProps {
  campaignStatus: DirectSalesFilteredCampaignsStatusTableResponse | null;
}

const CampaignStatusColors: Record<DirectSalesFilteredCampaignsStatusTableResponse, Color> = {
  DRAFT: Color.GREY,
  PROPOSAL: Color.YELLOW,
  CONFIRMED: Color.LIME,
  OPTION: Color.PURPLE,
  CANCELLED: Color.RED,
  MIXED: Color.ORANGE,
};

export const StatusRenderer: React.FC<StatusRendererProps> = ({ campaignStatus }) => {
  if (!campaignStatus) return null;

  const initialCampaignStatusLetter = campaignStatus.charAt(0);
  const chipColor = CampaignStatusColors[campaignStatus];

  return (
    <Tooltip tooltip={capitalize(campaignStatus)}>
      <Chip
        isDisabled
        subLabel={initialCampaignStatusLetter}
        color={chipColor}
        dataTestId={`campaign-status-chip-${campaignStatus}`}
        subLabelClassName="px-[13px]"
      />
    </Tooltip>
  );
};
