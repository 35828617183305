// eslint-disable-next-line sonarjs/cognitive-complexity
import { useCallback, useMemo, useState } from 'react';
import { DirectSalesStatusOption } from 'components/common/types/DirectSalesCampaign.types';
import {
  hidePlannerSecondaryPanel,
  setDirectSalesStates,
  updateTransientLineStates,
} from 'store/dealManagement/reducer';
import { useDispatch, useSelector } from 'react-redux';
import warningSvg from 'assets/icons/warning.svg';
import Button, { Color, Size } from 'lib/Button';
import Modal from 'lib/Modal';
import useTransientDealLineStates from 'components/pages/Planner/hooks/useTransientDealLineStates';
import PlannerSecondaryPanel from 'components/pages/Planner/PlannerSecondaryPanel';
import StatusOption from 'components/pages/Planner/PlannerLineActions/DirectSalesPlannerLineActions/Status/StatusPanel/StatusOption';
import DateTimePicker from 'components/common/DateTimePickerPopover';
import {
  assignStateToMultipleLines,
  assignStateToMultipleTransientLines,
  getStatusOptionLabel,
} from 'components/pages/Planner/PlannerLineActions/DirectSalesPlannerLineActions/Status/StatusPanel/utils';
import {
  getCampaignOptionDropDateDisabledDays,
  getEveryLineStateGroupedByLineId,
} from 'store/dealManagement/selectors';
import useDirectSalesPlannerActions from 'components/pages/Planner/hooks/useDirectSalesPlannerActions';
import { isDirectSalesCampaignCloned } from 'components/pages/Planner/hooks/utils/isDirectSalesCampaignCloned';
import { Store } from 'components/common/types/Store.types';
import { isValidStatusChange } from 'components/pages/Planner/PlannerLineActions/DirectSalesPlannerLineActions/utils';
import useOnPollComplete from 'components/pages/Planner/hooks/useOnPollComplete';
import { DirectSalesCampaignStatusProps } from './DirectSalesCampaignStatus.types';

// eslint-disable-next-line sonarjs/cognitive-complexity
const DirectSalesCampaignStatusPanel: React.FC<DirectSalesCampaignStatusProps> = ({ campaignState, options }) => {
  const dispatch = useDispatch();

  const disabledDays = useSelector(getCampaignOptionDropDateDisabledDays);
  const lineStates = useSelector(getEveryLineStateGroupedByLineId);
  const campaignId = useSelector((state: Store) => state.dealManagement.commonDeal.dealId);

  const [expires, setExpires] = useState(campaignState.expires ?? '');
  const [status, setStatus] = useState(campaignState.status);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const { saveTransientDealLineState } = useTransientDealLineStates();
  const { saveCampaignState } = useDirectSalesPlannerActions();
  const { onPollCompleteWithSuccess, onPollCompleteWithFailure } = useOnPollComplete();

  const onStatusChange = (newStatus: DirectSalesStatusOption): void => setStatus(newStatus);
  const onDateChange = (value: Date): void => setExpires(value);

  const isStatusChange = useMemo(() => {
    return isValidStatusChange(status, expires);
  }, [campaignState, status, expires]);

  const submitStatusChange = async (): Promise<void> => {
    const newCampaignState = {
      status,
      ...(expires && status === DirectSalesStatusOption.OPTION ? { expires } : {}),
    };

    await saveCampaignState(newCampaignState, campaignState, {
      onPollCompleteWithSuccess,
      onPollCompleteWithFailure,
    });
  };

  const handleSubmit = useCallback(async (): Promise<void> => {
    if (isDirectSalesCampaignCloned(campaignId)) {
      const currentLineExpiry = status === DirectSalesStatusOption.OPTION && expires ? { expires } : {};
      const newTransientLineStates = assignStateToMultipleTransientLines(lineStates, status, currentLineExpiry);
      const newCampaignLineStates = assignStateToMultipleLines(lineStates, status, currentLineExpiry);

      dispatch(hidePlannerSecondaryPanel());

      try {
        await saveTransientDealLineState(newTransientLineStates);

        dispatch(updateTransientLineStates(newTransientLineStates));
        dispatch(setDirectSalesStates(newCampaignLineStates));
      } catch {} // eslint-disable-line no-empty

      return;
    }

    if (status === DirectSalesStatusOption.CANCELLED) {
      setIsCancelModalOpen(true);
      return;
    }

    await submitStatusChange();
  }, [campaignId, status, expires]);

  return (
    <>
      <PlannerSecondaryPanel
        title="Campaign status"
        onSubmit={handleSubmit}
        onCancel={() => {
          dispatch(hidePlannerSecondaryPanel());
          onStatusChange(campaignState.status);
        }}
        customStyles={{ left: '430px' }}
        isChange={isStatusChange}
        isDisabled={!isStatusChange}
      >
        <div className="flex flex-col space-y-1">
          {options.map((option) => (
            <StatusOption
              onClick={() => setStatus(option)}
              isSelected={status === option}
              text={getStatusOptionLabel(option)}
              key={option}
            >
              {option === DirectSalesStatusOption.OPTION && (
                <DateTimePicker
                  value={new Date(expires)}
                  onChange={onDateChange}
                  disabledDays={disabledDays}
                  seconds={59}
                  milliseconds={999}
                />
              )}
            </StatusOption>
          ))}
        </div>
      </PlannerSecondaryPanel>
      <Modal
        dataTestId="planner-cancel-campaign-lines-modal"
        title="Cancel deal lines"
        text="Are you sure you want to cancel all the deal lines? This action cannot be undone."
        isOpen={isCancelModalOpen}
        onClose={() => setIsCancelModalOpen(false)}
        icon={warningSvg}
        actionButtons={
          <>
            <Button
              dataTestId="planner-cancel-campaign-lines-modal-cancel"
              label="Cancel"
              size={Size.MEDIUM}
              color={Color.SECONDARY}
              onClick={() => setIsCancelModalOpen(false)}
            />
            <Button
              dataTestId="planner-cancel-campaign-lines-modal-confirm"
              label="Cancel campaign lines"
              size={Size.MEDIUM}
              color={Color.DANGER}
              onClick={() => {
                setIsCancelModalOpen(false);
                submitStatusChange();
              }}
            />
          </>
        }
      />
    </>
  );
};

export default DirectSalesCampaignStatusPanel;
