import { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import cx from 'classnames';

import FiltersSvg from 'assets/icons/filter-thin.svg';
import InfoSvg from 'assets/icons/info-thin.svg';
import Button, { Color, Size, TextSize } from 'lib/Button';
import InputNumber from 'lib/InputNumber';
import { InputAlign } from 'lib/BaseInput';
import { InputSize } from 'components/common/types/Input.types';
import { INVALID_VALUES, TargetToleranceProps } from './TargetTolerance.types';

const TargetTolerance: React.FC<TargetToleranceProps> = ({
  updateGreenTolerance,
  updateOrangeTolerance,
  initialGreen,
  initialOrange,
  dataTestId = 'target-tolerance',
}) => {
  const [greenValue, setGreenValue] = useState(initialGreen);
  const [orangeValue, setOrangeValue] = useState(initialOrange);
  const [isToleranceOpen, setIsToleranceOpen] = useState(false);
  const [toleranceError, setToleranceError] = useState('');

  useEffect(() => {
    if (greenValue > orangeValue) setToleranceError(INVALID_VALUES);
    else setToleranceError('');
  }, [greenValue, orangeValue]);

  const handleGreenChange = (value: number): void => {
    setGreenValue(value);
    updateGreenTolerance(value);
  };

  const handleOrangeChange = (value: number): void => {
    setOrangeValue(value);
    updateOrangeTolerance(value);
  };

  return (
    <div
      data-test-id={dataTestId}
      className={cx('flex flex-col w-full justify-center items-end border-t', {
        'border-neutral-100': isToleranceOpen,
        'border-transparent': !isToleranceOpen,
      })}
    >
      <Button
        onClick={() => setIsToleranceOpen(!isToleranceOpen)}
        classNames="my-1"
        size={Size.SMALL}
        color={Color.TRANSPARENT}
        label="Adjust tolerance"
        textSize={TextSize.SMALL}
        svg={FiltersSvg}
      />
      {isToleranceOpen ? (
        <div className="flex flex-col items-end w-full gap-2 my-2">
          <div className="flex gap-2">
            <div className="flex gap-1 items-center">
              <span className="body-sm text-essential-secondary">Green</span>
              <div className="w-20">
                <InputNumber
                  value={greenValue}
                  max={99}
                  onChange={handleGreenChange}
                  id="tolerance-green-input"
                  dataTestId="tolerance-green-input"
                  size={InputSize.SMALL}
                  textSymbol="%"
                  align={InputAlign.RIGHT}
                />
              </div>
            </div>
            <div className="flex gap-1 items-center">
              <span className="body-sm text-essential-secondary">Orange</span>
              <div className="w-20">
                <InputNumber
                  value={orangeValue}
                  max={99}
                  onChange={handleOrangeChange}
                  id="tolerance-orange-input"
                  dataTestId="tolerance-orange-input"
                  size={InputSize.SMALL}
                  textSymbol="%"
                  align={InputAlign.RIGHT}
                />
              </div>
            </div>
          </div>
          {toleranceError.length ? (
            <span className="flex text-pinkRed-800 body-sm gap-1 py-1">
              <SVG className="w-3 h-3 mt-[4px]" src={InfoSvg} />
              {toleranceError}
            </span>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default TargetTolerance;
