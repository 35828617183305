import { Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import { Store } from 'components/common/types/Store.types';
import Overlay from 'lib/Overlay';
import { thoughtspot } from 'config';
import LiveboardVisuals from 'components/patterns/LiveboardVisuals';
import { getIsReseller } from 'store/publisher/selectors';
import { hideMarketFilterStyle, filterByMarket, labsReportVisibleActions } from '../utils';
import { getUpdateParametersForTimezone } from '../../utils';

interface DealHealthLiveboardProps {
  showLiveboard: boolean;
  setShowLiveboard: Dispatch<SetStateAction<boolean>>;
}

export const DealHealthLiveboard: React.FC<DealHealthLiveboardProps> = ({ showLiveboard, setShowLiveboard }) => {
  const { marketId, timezone } = useSelector((state: Store) => state.publisher.configuration);
  const isReseller = useSelector(getIsReseller);

  const dealHealthLiveboardId = isReseller
    ? thoughtspot.resellerDealHealthDashboardId
    : thoughtspot.dealHealthDashboardId;

  if (!showLiveboard) return null;
  return (
    <Overlay
      onClose={() => setShowLiveboard(false)}
      isOpen={showLiveboard}
      overflowYScroll
      header={
        <header>
          <h3 className="sub-header-lg text-neutral-900">Deal Health</h3>
        </header>
      }
    >
      <LiveboardVisuals
        liveboardId={dealHealthLiveboardId}
        customizations={hideMarketFilterStyle}
        visibleActions={labsReportVisibleActions}
        filterByOptions={[filterByMarket(marketId)]}
        updateParameters={getUpdateParametersForTimezone(timezone)}
      />
    </Overlay>
  );
};
