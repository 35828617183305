import DayPicker from 'components/patterns/DayPicker';
import { DATE_FORMAT } from 'modules/api/constants';
import { FilterDateProps } from './FilterDate.types';

const FilterDate: React.FC<FilterDateProps> = ({
  changeFilters,
  keyName,
  selectedDate,
  placeholder = 'Search by timeframe',
  isDisabled = false,
}) => {
  const handleDateChange = (value: Date): void => {
    changeFilters({ [keyName]: value });
  };

  return (
    <DayPicker
      dataTestId="filter-date-picker"
      onDayChange={handleDateChange}
      placeholder={placeholder}
      format={DATE_FORMAT}
      selectedDay={selectedDate}
      isDisabled={isDisabled}
      isClearable
    />
  );
};

export default FilterDate;
