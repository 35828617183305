module.exports = {
  app: {
    name: 'Programmatic Platform',
  },
  auth0: {
    domain: 'viooh-lab.eu.auth0.com',
    clientID: 'cbkR497qchuon73ZVWxBweWCHJIh1HaF',
    audience: 'https://auth-demo.viooh.com',
    responseType: 'token id_token',
    scope: 'openid profile email',
  },
  authProvider: 'Auth0',
  apiKeys: {
    googleMap: 'AIzaSyDl84nE7cL3C9gVZJeLUXsXc8N0VqI8MPg',
  },
  dataDog: {
    applicationId: '964f9e51-f2c9-4394-a9c5-a64bc6b09a77',
    clientToken: 'pubd5a5e3010d47232addac501f77d89396',
    site: 'datadoghq.eu',
    service: 'trading-manager-ui',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  },
  pendo: {
    subscriptionKey: '2f9402fe-931d-4b16-6e49-533b5dad4546',
  },
  isPublisherActive: true,
  thoughtspot: {
    host: 'https://viooh.thoughtspot.cloud/',
    identifier: 'development',
    allocationReportId: '33afad77-5327-44b5-a66d-594de0e61f35',
    labsId: '2557f287-a5ef-4d0f-acbd-a947f79154cd',
    pacingBoardId: '1fa0192a-70fa-4b04-8c98-0d36de1173c5',
    resellerDealHealthDashboardId: '17a46a4d-598b-44fe-b4d8-d6042e69ab5d',
    dealHealthDashboardId: '276282e7-c60a-4c18-b4e7-13746616aad7',
    proofOfPlayLiveboardId: '5c3f305e-96cb-4f9e-89e3-ac5a1b3b16ab',
    yieldInsightsLiveboardId: '119cba27-3e47-49ce-9a68-620818a51103',
  },
  agGrid: {
    enterpriseKey:
      'Using_this_{AG_Grid}_Enterprise_key_{AG-064387}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{VIOOH_Limited}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{TMUI}_only_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{TMUI}_need_to_be_licensed___{TMUI}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{31_July_2025}____[v3]_[01]_MTc1MzkxNjQwMDAwMA==461dbf31b83bffa84ca695abd4163267',
  },
  URLS: (BASE_URL, getAutomationUrlPart, plannerUrl, DSM_BASE_URL) => ({
    GET_DEAL_CONFIG: () => `${BASE_URL}${getAutomationUrlPart()}/programmatic/v2/config`,
    SETUPLIST_DATA_URL: (query) => `${BASE_URL}agl/api/v1/setup/list/?updateStamp=0${query}`,
    SETUP_DOWNLOAD_URL: (fileId) => `${BASE_URL}agl/api/v1/setup/download/${fileId}`,
    SETUP_UPLOAD_FILE: () => `${BASE_URL}agl/api/v1/setup/create`,
    FETCH_FILE_ASSETS: (fileId) => `${BASE_URL}agl/api/v1/setup/detail/${fileId}`,
    LOOKUP_API: (lookupName) => `${BASE_URL}ag/api/v1/lookup/${lookupName}`,
    DEALID_LOOKUP_API: (isAdServerMarket) =>
      isAdServerMarket
        ? `${BASE_URL}ad/programmatic/v4/deal/lookup/`
        : `${BASE_URL}${getAutomationUrlPart()}/DirectService/programmatic/v1/deal/lookup/`,
    CONTENT_DEALID_LOOKUP_API: (query) => `${BASE_URL}mod/api/v1/moderation/deal?${query}`,
    CREATIVE_ADMIN_DEALID_LOOKUP_API: (query) => `${BASE_URL}mod/api/v1/moderation/admin/deal?${query}`,
    META_DATA: () => `${BASE_URL}ag/api/v1/meta`,
    FETCH_CREATIVES: (query) => `${BASE_URL}mod/api/v1/moderation/creative?${query}`,
    CREATE_CREATIVE: () => `${BASE_URL}mod/api/v1/moderation/creative`,
    FETCH_CREATIVES_DETAILS: (creativeId) => `${BASE_URL}mod/api/v1/moderation/creative/${creativeId}`,
    APPROVE_CREATIVE: (marketId) => `${BASE_URL}mod/api/v1/moderation/market/${marketId}/approve`,
    FLAG_CREATIVE: (marketId) => `${BASE_URL}mod/api/v1/moderation/market/${marketId}/flag`,
    PENDING_APPROVE_CREATIVE: (marketId) => `${BASE_URL}mod/api/v1/moderation/market/${marketId}/pending`,
    SEND_CREATIVE_FOR_APPROVAL: (marketId) => `${BASE_URL}mod/api/v1/moderation/market/${marketId}/send-for-approval`,
    FETCH_ELIGIBLE_RESELLER_MEDIA_OWNERS: (marketId) =>
      `${BASE_URL}mod/api/v1/moderation/market/${marketId}/eligible-media-owners`,
    REJECT_CREATIVE: (marketId) => `${BASE_URL}mod/api/v1/moderation/market/${marketId}/reject`,
    ADD_CREATIVE_DEALID: (marketId) => `${BASE_URL}mod/api/v1/moderation/market/${marketId}/deal`,
    DELETE_CREATIVE_DEALID: (dealId) => `${BASE_URL}mod/api/v1/moderation/market/deal/${dealId}`,
    CREATIVE_FRAME_SUMMARY: (creativeId, environment) =>
      `${BASE_URL}mod/api/v1/moderation/creative/${creativeId}/frame/count?environment=${environment}`,
    CREATIVE_EXPORT_FRAME_SUMMARY: (creativeId, environment) =>
      `${BASE_URL}mod/api/v1/moderation/creative/${creativeId}/frame/export?environment=${environment}`,
    DEAL_LIST_DATA_URL: (query, isAdServerMarket) => {
      if (isAdServerMarket) return `${BASE_URL}ad/programmatic/v1/deal/list/v2?${query}`;

      return `${BASE_URL}${getAutomationUrlPart()}/DirectService/programmatic/v1/deal/list?${query}`;
    },
    DEAL_LIST_BIDS_DATA_URL: () => `${BASE_URL}api/v1/deal/list`,
    SAVE_MAIN_DEAL: () => `${BASE_URL}${getAutomationUrlPart()}/programmatic/v2/deal`,
    LINE: () => `${BASE_URL}${getAutomationUrlPart()}/programmatic/v2/line`,
    UPDATE_LIVE_LINE: () => `${BASE_URL}${getAutomationUrlPart()}/programmatic/v2/line/terminateAndCreateNew`,
    TERMINATE_LINE: () => `${BASE_URL}${getAutomationUrlPart()}/programmatic/v2/line/terminate`,
    CHECK_DEAL_AVAILABILITY: () => `${BASE_URL}${getAutomationUrlPart()}/programmatic/v2/deal/availability`,
    CHECK_LINE_AVAILABILITY: () => `${BASE_URL}${getAutomationUrlPart()}/programmatic/v2/line/availability`,
    DEAL_LINES_DETAIL: (internalId) => `${BASE_URL}${getAutomationUrlPart()}/programmatic/v2/deal/${internalId}`,
    BRAND_LOOKUP: () => `${BASE_URL}ag/api/sspui/v1/lookup/brand`,
    POI_LOOKUP: () => `${BASE_URL}ag/api/v1/lookup/poi`,
    POI_LIST: () => `${BASE_URL}carto/v1/carto3/poiOptions`,
    POI_DATA: () => `${BASE_URL}carto/v1/carto3/poidata`,
    GET_USER_LIST: (qryText, pageNu, pageSize) => {
      let url = `${BASE_URL}um/api/v1/user/searchUser?page=${pageNu}&perPage=${pageSize}`;
      // due to auth0 limitation on free text search, check: https://auth0.com/docs/users/search/v3/query-syntax#wildcards
      if (qryText && qryText.length > 2) {
        url = `${url}&query=email:*${qryText}* OR name=*${qryText}*`;
      }
      return url;
    },
    GET_USER_DETAIL: (userId) => `${BASE_URL}um/api/v1/user/getUserDetailsById/${userId}`,
    CHANGE_USER_STATUS: (userId) => `${BASE_URL}um/api/v1/user/updateStatus/${userId}`,
    CREATE_USER: () => `${BASE_URL}um/api/v1/user/createUser`,
    UPDATE_USER: (userId) => `${BASE_URL}um/api/v1/user/updateUser/${userId}`,
    DELETE_USER: (userId) => `${BASE_URL}um/api/v1/user/deleteUser/${userId}`,
    GET_ROLE_LIST: () => `${BASE_URL}um/api/v1/role/getRoles`,
    CREATE_ROLE: () => `${BASE_URL}um/api/v1/role/createRole`,
    UPDATE_ROLE: (roleId) => `${BASE_URL}um/api/v1/role/updateRole/${roleId}`,
    DELETE_ROLE: (roleId) => `${BASE_URL}um/api/v1/role/delete/${roleId}`,
    GET_PERMISSIONS_LIST: () => `${BASE_URL}um/api/v1/permission/getPermissions`,
    CREATIVE_CATEGORIES: (query) => `${BASE_URL}mod/api/v1/moderation/category?${query}`,
    UPDATE_CREATIVE_CATEGORY: (marketId, categoryId) =>
      `${BASE_URL}mod/api/v1/moderation/market/${marketId}/category/${categoryId}`,
    UPDATE_CREATIVE_MOVEMENT: (creativeId) => `${BASE_URL}mod/api/v1/moderation/creative/${creativeId}/movement`,
    CREATIVE_ID_LOOKUP_API: (query) => `${BASE_URL}mod/api/v1/moderation/creative/externalId?${query}`,
    DSP_LOOKUP_API: (query) => `${BASE_URL}mod/api/v1/moderation/dsp?${query}`,
    MARKET_LOOKUP_API: (query) => `${BASE_URL}mod/api/v1/moderation/environment?${query}`,
    UPDATE_CREATIVE_MARKETS: () => `${BASE_URL}mod/api/v1/moderation/admin/creative/market`,
    FETCH_ADVERTISERS: (marketId, name) =>
      `${BASE_URL}mod/api/v1/moderation/advertiser?environment=${marketId}&name=${name}`,
    FETCH_AGENCY: (marketId, name) => `${BASE_URL}mod/api/v1/moderation/agency?environment=${marketId}&name=${name}`,
    UPDATE_AGENCY: (marketId) => `${BASE_URL}mod/api/v1/moderation/market/${marketId}/agency`,
    FETCH_SPECIALIST: (marketId, name) =>
      `${BASE_URL}mod/api/v1/moderation/specialist?environment=${marketId}&name=${name}`,
    UPDATE_SPECIALIST: (marketId) => `${BASE_URL}mod/api/v1/moderation/market/${marketId}/specialist`,
    DELETE_CREATIVE_COMMERCIAL: (commercialId) => `${BASE_URL}mod/api/v1/moderation/market/commercial/${commercialId}`,
    CREATE_CREATIVE_COMMERCIAL: (marketId) => `${BASE_URL}mod/api/v1/moderation/market/${marketId}/commercial`,
    UPDATE_CREATIVE_COMMERCIAL: (commercialId) => `${BASE_URL}mod/api/v1/moderation/market/commercial/${commercialId}`,
    FETCH_CREATIVE_FRAME_TAGS: (environment, query, marketId) => {
      if (marketId) {
        return `${BASE_URL}mod/api/v1/moderation/tag?environment=${environment}&query=${query}&marketId=${marketId}`;
      }

      return `${BASE_URL}mod/api/v1/moderation/tag?environment=${environment}&query=${query}`;
    },
    CREATE_CREATIVE_FRAME_TAG: (marketId) => `${BASE_URL}mod/api/v1/moderation/market/${marketId}/tag`,
    FETCH_ALL_CREATIVE_FRAME_TAGS: (marketId) => `${BASE_URL}mod/api/v1/moderation/market/${marketId}/tag`,
    DELETE_CREATIVE_FRAME_TAG: (marketTagId) => `${BASE_URL}mod/api/v1/moderation/market/tag/${marketTagId}`,
    UPDATE_CREATIVE_FRAME_TAG: (marketTagId) => `${BASE_URL}mod/api/v1/moderation/market/tag/${marketTagId}`,
    FETCH_CREATIVE_TAG_FRAME_DETAILS: (tagId) => `${BASE_URL}mod/api/v1/moderation/frame?tagId=${tagId}`,
    GET_CREATIVE_PRODUCT_FORMAT: (environment, query) =>
      `${BASE_URL}mod/api/v1/moderation/format?environment=${environment}&query=${query}`,
    ADD_CREATIVE_PRODUCT_FORMAT: (idMarket, idProductFormat) =>
      `${BASE_URL}mod/api/v1/moderation/market/${idMarket}/format/${idProductFormat}`,
    REMOVE_CREATIVE_PRODUCT_FORMAT: (idMarket, idProductFormat) =>
      `${BASE_URL}mod/api/v1/moderation/market/${idMarket}/format/${idProductFormat}`,
    EXPORT_DEALS_REPORT: (query) => `${BASE_URL}api/v2/rawDataExport?${query}`,
    EXPORT_REVENUE_REPORT: (query) => `${BASE_URL}api/v2/exportReports?${query}`,
    FETCH_REVENUE_CREATIVE_CHART: (query) => `${BASE_URL}api/v2/getRevenueChartCreative?${query}`,
    FETCH_REVENUE_CHART: (query) => `${BASE_URL}api/v2/getRevenueChart?${query}`,
    FETCH_BID_CHART: (query) => `${BASE_URL}api/v2/getBidChart?${query}`,
    FETCH_TRADED_WIN_CHART: (query) => `${BASE_URL}api/v2/getTradedWinRateChart?${query}`,
    FETCH_WEIGHTED_WIN_CHART: (query) => `${BASE_URL}api/v2/getWeightedWinRateChart?${query}`,
    FETCH_FILL_RATE_CHART: (query) => `${BASE_URL}api/v2/getFillRateChart?${query}`,
    FETCH_DASHBOARD_TABLE: (query) => `${BASE_URL}api/v2/getDealList?${query}`,
    FETCH_PERFORMANCE_TRADING_BEHAVIOUR_CHART: (query) => `${BASE_URL}api/v2/getTradingBehaviourChart?${query}`,
    FETCH_PERFORMANCE_IMPRESSIONS_TRADED_CHART: (query) => `${BASE_URL}api/v2/getImpressionsTradedChart?${query}`,
    FETCH_PERFORMANCE_REVENUE_CHART: (query) => `${BASE_URL}api/v2/getRevenuePerformanceChart?${query}`,
    FETCH_PERFORMANCE_DATA: (query) => `${BASE_URL}api/v2/getDealOverview?${query}`,
    FETCH_PERFORMANCE_TABLE: (query) => `${BASE_URL}api/v2/getPerformanceDealList?${query}`,
    FETCH_DIAGNOSTICS_TABLE: (query) => `${BASE_URL}api/v2/getDiagnosticDealList?${query}`,
    FETCH_TOTAL_LOST_IMPRESSIONS: (query) => `${BASE_URL}api/v2/getLostImpressionByReason?${query}`,
    FETCH_DEAL_TRADING_IMPACTS: (query) => `${BASE_URL}api/v2/getDealTradingImpacts?${query}`,
    FETCH_TOTAL_IMPACT_LOSS: (query) => `${BASE_URL}api/v2/getTotalImpactByLossType?${query}`,
    FETCH_TOTAL_IMPACT_CREATIVE: (query) => `${BASE_URL}api/v2/getLostImpressionByCreative?${query}`,
    FETCH_SUMMERY_OVERVIEW: (query) => `${BASE_URL}api/v2/getLostImpressionByCreative?${query}`,
    FETCH_STATS: (query) => `${BASE_URL}api/v2/getDailyWeeklySummary?${query}`,
    FETCH_DSP_REVENUE_MONTH_CHART: (query) => `${BASE_URL}api/v2/getDSPMonthlyRevenueGraph?${query}`,
    FETCH_DSP_REVENUE_DAY_CHART: (query) => `${BASE_URL}api/v2/getDSPDailyRevenueGraph?${query}`,
    FETCH_REVENUE_MONTH_ON_MONTH_DAY_CHART: (query) => `${BASE_URL}api/v2/getMonthlyRevenueGraph?${query}`,
    FETCH_REVENUE_CPM_CHART: (query) => `${BASE_URL}api/v2/getMonthlyCPMGraph?${query}`,
    FETCH_REVENUE_TABLE: (query) => `${BASE_URL}api/v2/getRevenueSummaryList?${query}`,
    DELETE_CREATIVE: (marketId) => `${BASE_URL}mod/api/v1/moderation/market/${marketId}`,
    RESTORE_CREATIVE: (marketId) => `${BASE_URL}mod/api/v1/moderation/market/${marketId}/restore`,
    FETCH_PRODUCT_FORMATS: () => `${BASE_URL}ag/api/sspui/v1/im/lookup/productFormat`,
    FETCH_CHANNELS: () => `${BASE_URL}ag/api/sspui/v1/im/lookup/channel`,
    FETCH_BUSINESS_AREAS: () => `${BASE_URL}ag/api/sspui/v1/im/lookup/businessArea`,
    FETCH_VISUAL_UNIT_CODES: () => `${BASE_URL}ag/api/v1/lookup/visualunitcodes`,
    FETCH_TAGS: () => `${BASE_URL}ag/api/sspui/v1/im/lookup/tag`,
    FETCH_NETWORKS: () => `${BASE_URL}ag/api/sspui/v1/im/lookup/network`,
    FETCH_ASSET_TYPES: () => `${BASE_URL}ag/api/sspui/v1/im/lookup/assetTypes`,
    FETCH_ASSET_TABLE: (query) => `${BASE_URL}ag/api/sspui/v1/im/filter/assets?${query}`,
    FETCH_ASSET_BY_ID: (id) => `${BASE_URL}ag/api/fs/v1/assets/${id}`,
    FETCH_CREATIVE_SETS_TABLE: (query) => `${BASE_URL}mod/api/v1/moderation/creative-sets?${query}`,
    FETCH_VISUAL_UNIT_GROUPS: (query) => `${BASE_URL}mod/api/v1/moderation/visual-units/groups?${query}`,
    CREATIVE_SET: (id) => `${BASE_URL}mod/api/v1/moderation/creative-sets/${id || ''}`,
    CREATE_DEAL: () => `${BASE_URL}ad/programmatic/v4/campaigns`,
    CREATE_LINE: (dealId) => `${BASE_URL}ad/programmatic/v4/campaigns/${dealId}/deals`,
    FETCH_DEAL_CONFIG: (marketId) => `${BASE_URL}ad/programmatic/v1/publisher/${marketId}/config`,
    FETCH_DEAL_PROCESS_STATUS: (dealId) => `${BASE_URL}ad/programmatic/v4/campaigns/${dealId}/status`,
    FETCH_DEAL: (dealId) => `${BASE_URL}ad/programmatic/v4/campaigns/${dealId}`,
    FETCH_LINE: (dealId, lineId) => `${BASE_URL}ad/programmatic/v4/campaigns/${dealId}/deals/${lineId}`,
    STOP_LINE: (dealId, lineId) => `${BASE_URL}ad/programmatic/v4/campaigns/${dealId}/deals/${lineId}/stop`,
    DELETE_LINE: (dealId, lineId) => `${BASE_URL}ad/programmatic/v4/campaigns/${dealId}/deals/${lineId}`,
    UPDATE_LINE: (dealId, lineId) => `${BASE_URL}ad/programmatic/v4/campaigns/${dealId}/deals/${lineId}`,
    EDIT_DEAL: (dealId) => `${BASE_URL}ad/programmatic/v4/campaigns/${dealId}`,
    CHECK_ADS_AVAILABILITY: () => `${BASE_URL}ad/programmatic/v4/assets/availability`,
    APPROVE_DEAL: (dealId) => `${BASE_URL}ad/programmatic/v4/campaigns/${dealId}/approve`,
    REJECT_DEAL: (dealId) => `${BASE_URL}ad/programmatic/v4/campaigns/${dealId}/reject`,
    CANCEL_DEAL: (dealId) => `${BASE_URL}ad/programmatic/v4/campaigns/${dealId}/cancel`,
    GET_DEALS: (query) => `${BASE_URL}ad/programmatic/v4/campaigns?${query}`,
    CHECK_ALLOCATE: () => `${BASE_URL}ad/programmatic/v4/campaigns/proposals`,
    FETCH_ALLOCATED_DEAL: (dealId) => `${BASE_URL}ad/programmatic/v4/campaigns/${dealId}/proposal`,
    FETCH_NETWORK_GROUPS_TABLE: (query) => `${BASE_URL}ag/api/sspui/v1/im/filter/networkgroups?${query}`,
    FETCH_NETWORK_GROUPS: () => `${BASE_URL}ag/api/sspui/v1/im/lookup/networkGroup`,
    FETCH_NETWORKS_TABLE: (query) => `${BASE_URL}ag/api/sspui/v1/im/filter/networks?${query}`,
    FETCH_NETWORK_PRICE_DEFINITIONS_TABLE: (query) =>
      `${BASE_URL}ag/api/sspui/v1/im/filter/networkpricedefinitions?${query}`,
    FETCH_NETWORK_PRICE_DEFINITION: () => `${BASE_URL}ag/api/sspui/v1/im/lookup/networkPriceDefinition`,
    FETCH_COMMERCIAL_ENTITIES_TABLE: (query) => `${BASE_URL}ag/api/sspui/v1/im/filter/persons?${query}`,
    FETCH_PERSON_TYPES: () => `${BASE_URL}ag/api/sspui/v1/im/lookup/personTypes`,
    GET_PUBLISHER_CONFIGURATION: (externalPublisherId) =>
      `${BASE_URL}ps/api/v1/publishers/${externalPublisherId}/programmatic-platform`,
    GET_PUBLISHER_FEATURES: (externalPublisherId) =>
      `${BASE_URL}ps/api/v1/publishers/${externalPublisherId}/programmatic-platform-features`,
    GET_PUBLISHER_DIRECT_SALES_PRICING: (externalPublisherId) =>
      `${BASE_URL}ps/api/v1/publishers/${externalPublisherId}/dsm-pricing-service`,
    BULK_EDIT_CREATIVES_META: () => `${BASE_URL}mod/api/v1/moderation/market/batch/metadata`,
    BULK_EDIT_STATUS_CHANGE: () => `${BASE_URL}mod/api/v1/moderation/market/batch/status`,
    BULK_RESTORE_CREATIVES: () => `${BASE_URL}mod/api/v1/moderation/market/batch/restore`,
    BULK_EDIT_DELETE_SELECTED: () => `${BASE_URL}mod/api/v1/moderation/market/batch/delete`,
    FETCH_PRODUCT_CATEGORIES: (query) => `${BASE_URL}mod/api/v1/moderation/category?${query}`,
    FETCH_BRANDS: (query) => `${BASE_URL}mod/api/v1/moderation/brand?${query}`,
    SALES_PERSON_LOOKUP_API: (query) => `${BASE_URL}ag/api/fs/v1/persons?${query}`,
    SALES_TEAM_LOOKUP_API: (query) => `${BASE_URL}ag/api/fs/v1/salesTeams?${query}`,
    GET_AUTHENTICATION_TOKEN: (query) => `${BASE_URL}eaauth/api/v1/auth/token?${query}`,
    PLANNER: () => plannerUrl,
    FETCH_PERMISSIONS: () => `https://keycloak.devel.viooh.net.cn/realms/dev-cn/protocol/openid-connect/token`,
    OPEN_STREET_MAP_OVERPASS: () => 'https://overpass-api.de/api/interpreter',
    FETCH_SECONDARY_AUDIENCE_AVERAGE_BY_SEGMENT_AND_HOUR: () =>
      `${BASE_URL}carto/v1/carto3/secondary-audience-options/scores/frameAverageByHour`,
    FETCH_SECONDARY_AUDIENCE_OPTIONS: () => `${BASE_URL}carto/v1/carto3/secondary-audience-options`,
    FETCH_AVERAGE_SEGMENT_SCORES_PER_FRAME: () =>
      `${BASE_URL}carto/v1/carto3/secondary-audience-options/scores/hourAverageByFrame`,
    MTB_EXPORT: (campaignId) => `${BASE_URL}ad/programmatic/export/${campaignId}/mtb`,
    CHECK_DIRECT_SALES_AVAILABILITY: (marketId) => `${DSM_BASE_URL}api/v1/${marketId}/assets/availability`,
    DIRECT_SALES_CAMPAIGN: (marketId, campaignId) => `${DSM_BASE_URL}api/v1/${marketId}/booking/${campaignId}`,
    CREATE_DIRECT_SALES_CAMPAIGN: (marketId) => `${DSM_BASE_URL}api/v1/${marketId}/booking`,
    FETCH_DIRECT_SALES_SOLUTION: (marketId, campaignId) => `${DSM_BASE_URL}api/v1/${marketId}/solution/${campaignId}`,
    FETCH_RESELLER_MEDIA_OWNERS: (reseller) => `${BASE_URL}vcl/api/v1/reseller/${reseller}/metadata`,
    FETCH_RESELLER_MEDIA_OWNERS_CREATIVE_STATUS: (marketId) =>
      `${BASE_URL}mod/api/v1/moderation/market/${marketId}/reseller-media-owners`,
    FETCH_FINAL_ALLOCATION_REPORT: (campaignId, lineId, metricType) =>
      `${BASE_URL}ad/programmatic/v3/allocation-report/${campaignId}/deal/${lineId}/metric/${metricType}`,
    FETCH_PROPOSAL_ALLOCATION_REPORT: (proposalCampaignId, metricType) =>
      `${BASE_URL}ad/programmatic/v3/allocation-report/proposals/${proposalCampaignId}/metric/${metricType}`,
    FETCH_ALLOWED_STATE_TRANSITIONS_METADATA: (marketId) =>
      `${DSM_BASE_URL}api/v1/${marketId}/booking/dealLine/allowedTransitions`,
    FETCH_DIRECT_SALES_CAMPAIGN_STATE: (marketId, campaignId) =>
      `${DSM_BASE_URL}api/v1/${marketId}/booking/${campaignId}/state`,
    FETCH_DIRECT_SALES_DEAL_LINE_STATE: (marketId, campaignId, dealLineId) =>
      `${DSM_BASE_URL}api/v1/${marketId}/booking/${campaignId}/state/${dealLineId}`,
    UPDATE_DIRECT_SALES_DEAL_LINE_STATE: (marketId, campaignId, dealLineId) =>
      `${DSM_BASE_URL}api/v1/${marketId}/booking/${campaignId}/state/${dealLineId}`,
    UPDATE_DIRECT_SALES_CAMPAIGN_STATE: (marketId, campaignId) =>
      `${DSM_BASE_URL}api/v1/${marketId}/booking/${campaignId}/state`,
    DELETE_DIRECT_SALES_DEAL_LINE_STATE: (marketId, campaignId, dealLineId) =>
      `${DSM_BASE_URL}api/v1/${marketId}/booking/${campaignId}/state/${dealLineId}`,
    DELETE_DIRECT_SALES_CAMPAIGN_STATE: (marketId, campaignId) =>
      `${DSM_BASE_URL}api/v1/${marketId}/booking/${campaignId}/state`,
    FETCH_DIRECT_SALES_REFERENCE_DATA: () => `${BASE_URL}vcl/api/v1/assets/reference-data`,
    FETCH_LINE_IMPRESSION_METRICS: (lineId) => `${BASE_URL}sps/api/summary-panel/v1/impressionMetrics/deal/${lineId}`,
    FETCH_CAMPAIGN_IMPRESSION_METRICS: (campaignId) =>
      `${BASE_URL}sps/api/summary-panel/v1/impressionMetrics/campaign/${campaignId}`,
    FETCH_DIRECT_SALES_NETWORKS_REFERENCE_DATA: () => `${BASE_URL}vcl/api/v1/assets/network-reference-data`,
    UPDATE_DIRECT_SALES_DEAL_LINE: (marketId, campaignId, dealLineId) =>
      `${DSM_BASE_URL}api/v1/${marketId}/booking/${campaignId}/dealline/${dealLineId}`,
    DELETE_DIRECT_SALES_DEAL_LINE: (marketId, campaignId, dealLineId) =>
      `${DSM_BASE_URL}api/v1/${marketId}/booking/${campaignId}/dealline/${dealLineId}`,
    UPDATE_TRANSIENT_DEAL_STATE: (marketId, campaignId) => `${DSM_BASE_URL}api/v1/${marketId}/transient/${campaignId}`,
    TERMINATE_DIRECT_SALES_CAMPAIGN_SESSION: (externalPublisherId, campaignId) =>
      `${DSM_BASE_URL}api/v1/${externalPublisherId}/booking/${campaignId}/unlock`,
    EXTEND_DIRECT_SALES_CAMPAIGN_SESSION_LOCK: (externalPublisherId, campaignId) =>
      `${DSM_BASE_URL}api/v1/${externalPublisherId}/booking/${campaignId}/extend-lock`,
    FETCH_DIRECT_SALES_ALLOCATION_REPORT: (publisherId, campaignId, dealLineId, metricType) =>
      `${DSM_BASE_URL}api/v1/${publisherId}/allocation-report/campaign/${campaignId}/deal-line/${dealLineId}/metric/${metricType}`,
    FETCH_DIRECT_SALES_FILTERED_CAMPAIGNS_TABLE: (externalPublisherId, query) =>
      `${DSM_BASE_URL}api/v1/${externalPublisherId}/filter/bookings/full?${query}`,
    FETCH_DIRECT_SALES_ASSET_LIST: (publisherId, campaignId) =>
      `${DSM_BASE_URL}api/v1/${publisherId}/booking/${campaignId}/frame-list`,
    CREATE_DIRECT_SALES_PRODUCT_TEMPLATE: (marketId) => `${DSM_BASE_URL}api/v1/${marketId}/template`,
    UPDATE_DIRECT_SALES_TRANSIENT_DEAL_LINE_STATE: (marketId, campaignId) =>
      `${DSM_BASE_URL}api/v1/${marketId}/booking/${campaignId}/targetStates`,
    FETCH_DIRECT_SALES_PRODUCT_FOLDER: (marketId) => `${DSM_BASE_URL}api/v1/${marketId}/template/folders`,
    CAMPAIGN_REPORTING_TOKEN: (marketId, campaignId) =>
      `${DSM_BASE_URL}api/v1/${marketId}/reporting/token/${campaignId}`,
    FETCH_CAMPAIGN_PLAN: (token) => `${DSM_BASE_URL}api/v1/token/${token}/campaign-plan`,
    FETCH_PUBLIC_ALLOCATION_REPORT: (token, dealLine, metric) =>
      `${DSM_BASE_URL}api/v1/token/${token}/allocation-report/deal-line/${dealLine}/metric/${metric}`,
    ENABLE_DIRECT_SALES_SHARED_CAMPAIGN: (marketId, campaignId) =>
      `${DSM_BASE_URL}api/v1/${marketId}/booking/${campaignId}/share`,
    DISABLE_DIRECT_SALES_SHARED_CAMPAIGN: (marketId, campaignId) =>
      `${DSM_BASE_URL}api/v1/${marketId}/booking/${campaignId}/share`,
  }),
  HTTP_METHODS: {
    POST: 'POST',
    GET: 'GET',
    PUT: 'PUT',
    PATCH: 'PATCH',
    DELETE: 'DELETE',
  },
  features: () => {
    return {
      ADS_DEAL_LEVEL_CPM: true,
      OVERRIDE_DEAL_ID: false,
    };
  },
};
