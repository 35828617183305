/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

import { fetchDirectSalesProductFolder } from 'modules/api/directSalesProductTemplate';
import { InputContainer } from 'components/common/Deal/CampaignHeader/CampaignDrawer/Details/utils';
import { Label } from 'lib/Label';

import { useCancelRequest } from 'customHooks/useCancelRequest';

import { InputTheme } from 'components/common/types/Input.types';
import { Store } from 'components/common/types/Store.types';
import { ArrowContainer, Popover } from 'react-tiny-popover';
import Button, { Color } from 'lib/Button';
import Input from 'lib/Input';
import { changeSelectedFolder } from 'store/productManagement/reducer';

export const FolderInput: React.FC = () => {
  const dispatch = useDispatch();
  const marketId = useSelector((state: Store) => state.publisher.configuration.marketId);
  const selectedFolderId = useSelector((state: Store) => state.productManagement.newProduct.folder);

  const [folders, setFolders] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [filterText, setFilterText] = useState('');

  const parentRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);
  const activeLineRef = useRef<HTMLDivElement>(null);

  const cancelFunctions = useCancelRequest();

  const filteredFolders = useMemo(() => {
    if (filterText.length < 1) return folders;
    return folders.filter((folder) => folder.toLowerCase().includes(filterText.toLowerCase()));
  }, [folders, filterText]);

  const displayCreateBtn = filterText.length > 0 && filteredFolders.length === 0;

  const onFolderSelect = (selectedFolder: string): void => {
    if (selectedFolder !== selectedFolderId) dispatch(changeSelectedFolder(selectedFolder));
    setIsOpen(false);
  };

  const handleTextChange = (textInput: string): void => {
    if (selectedFolderId && textInput !== selectedFolderId) {
      dispatch(changeSelectedFolder(''));
    }
    setFilterText(textInput);
  };

  useEffect(() => {
    let isMounted = true;
    (async () => {
      try {
        const response = await fetchDirectSalesProductFolder(marketId, cancelFunctions);
        if (isMounted) {
          setFolders(response.map(({ folder }) => folder).filter((folder) => !!folder));
        }
        // eslint-disable-next-line no-empty
      } catch {}
    })();
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    setFilterText(selectedFolderId);
  }, [selectedFolderId]);

  return (
    <InputContainer>
      <Label id="folder" theme={InputTheme.FLAT_GRAY} label="Folder" />
      <div ref={parentRef} data-test-id="folder-select" className="relative">
        <Popover
          containerClassName="z-20 py-1"
          parentElement={parentRef.current as HTMLElement}
          align="end"
          positions={['bottom']}
          isOpen={isOpen}
          onClickOutside={() => setIsOpen(false)}
          content={({ position, childRect, popoverRect }) => (
            <ArrowContainer
              position={position}
              childRect={childRect}
              popoverRect={popoverRect}
              arrowColor="var(--transparent-color)"
              arrowSize={0}
            >
              <div className="relative w-80 bg-neutral-50 shadow-md border border-neutral-300 rounded-md">
                <div className="flex flex-col">
                  <div
                    data-test-id="line-select-list"
                    ref={listRef}
                    className="max-h-[500px] overflow-y-auto py-1 px-2.5"
                  >
                    <div className="px-2.5 pt-4 pb-2.5 body-base text-essential-tertiary">
                      Please select a folder or create a new one:
                    </div>
                    {filteredFolders.map((folder) => {
                      return (
                        <div
                          ref={folder === selectedFolderId ? activeLineRef : null}
                          data-test-id={`option-${folder}`}
                          key={`option-${folder}`}
                          className={cx('w-full flex justify-start items-center space-x-2 px-2.5 rounded-md', {
                            'bg-neutral-950-opacity-5 sub-header-base text-primary': folder === selectedFolderId,
                            'hover:bg-primary-600-opacity-5 hover:text-primary': folder !== selectedFolderId,
                          })}
                        >
                          <button
                            className="py-2 w-full flex justify-between items-center body-base space-x-2 [&_svg]:fill-current [&_svg]:text-neutral-700"
                            type="button"
                            onClick={() => onFolderSelect(folder)}
                          >
                            <p className="w-10/12 text-left body-base truncate whitespace-nowrap overflow-hidden">
                              {folder}
                            </p>
                          </button>
                        </div>
                      );
                    })}
                    <div className="px-2.5 pt-4 body-base text-primary-600">Create new folder:</div>
                    <div className="w-full flex justify-start items-center space-x-2 py-4 px-4">
                      <div className="w-full flex items-center body-base mx-1">
                        <Button
                          classNames="text-primary shadow-inner-sm border border-neutral-300 w-full"
                          dataTestId="line-select-new-line-button"
                          label="Save"
                          color={Color.TRANSPARENT}
                          onClick={() => onFolderSelect(filterText)}
                          isDisabled={!displayCreateBtn}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ArrowContainer>
          )}
        >
          <div role="button" onClick={() => setIsOpen(true)}>
            <Input
              className={cx(
                'bg-neutral-950-opacity-5 sub-header-base text-primary h-[24px] border rounded-md hover:bg-neutral-950-opacity-10',
                {
                  'border-neutral-400': isOpen,
                  'border-transparent': !isOpen,
                },
              )}
              placeholder="Select folder"
              dataTestId="select-folder"
              name=""
              value={filterText}
              onChange={(value: string) => handleTextChange(value)}
            />
          </div>
        </Popover>
      </div>
    </InputContainer>
  );
};
/* eslint-enable jsx-a11y/interactive-supports-focus */
/* eslint-enable jsx-a11y/click-events-have-key-events */
