import Auth from 'modules/Auth';
import { PermissionsEnum } from 'consts/permissions';
import { formatDateObjToIsoDateString } from 'utils/dateFormatUtil';
import { getHours, getMinutes, getSeconds, getMilliseconds, isValid } from 'date-fns';

type DateType = Date | string;

type Options = {
  compareTime?: boolean;
};

const getAsDate = (date: DateType): Date => {
  return typeof date === 'string' ? new Date(date) : date;
};

const isSameDay = (date1: Date, date2: Date): boolean =>
  date1.getFullYear() === date2.getFullYear() &&
  date1.getMonth() === date2.getMonth() &&
  date1.getDate() === date2.getDate();

export const areDatesEqual = (
  date1: DateType | undefined | null,
  date2: DateType | undefined | null,
  options: Options = {
    compareTime: false,
  },
): boolean => {
  if (!date1 || !date2) return false;

  if (options.compareTime) {
    return getAsDate(date1).getTime() === getAsDate(date2).getTime();
  }

  return isSameDay(getAsDate(date1), getAsDate(date2));
};

export const isFutureDate = (date: Date | string): boolean => {
  return new Date(date).getTime() > new Date().getTime();
};

export const getDateInRequestFormat = (date: Date | string, hrs: number | number[] = []): string => {
  const newDate = new Date(date);

  if (Array.isArray(hrs)) {
    const [hour = 0, minute = 0, second = 0, ms = 0] = hrs;
    newDate.setHours(hour, minute, second, ms);
  } else {
    newDate.setHours(hrs, 0, 0, 0);
  }

  return formatDateObjToIsoDateString(newDate, Auth.hasPermission(PermissionsEnum.FEATURE_FLAG_TIMEZONE_UTC));
};

export const getTimeInDate = (date: Date | string): number[] => {
  const dateAndTime = typeof date === 'string' ? new Date(date) : date;

  if (!isValid(dateAndTime)) {
    return [0, 0, 0, 0];
  }

  const hours = getHours(dateAndTime);
  const minutes = getMinutes(dateAndTime);
  const seconds = getSeconds(dateAndTime);
  const milliseconds = getMilliseconds(dateAndTime);

  return [hours, minutes, seconds, milliseconds];
};

export const isValidDate = (date: Date): boolean => {
  return date instanceof Date && !Number.isNaN(date.getTime());
};
