import SVG from 'react-inlinesvg';
import { format } from 'date-fns-tz';
import { formatDateCustom, formatNumber } from 'modules/I18N';
import { CampaignPlanDetails } from 'components/common/types/CampaignPlan.types';
import { DirectSalesStatusOption } from 'components/common/types/DirectSalesCampaign.types';
import { ChipBookingStatus } from 'components/common/Deal/CampaignHeader/CampaignDrawer/ChipBookingStatus/ChipBookingStatus';
import arrowIcon from 'assets/icons/arrow_right.svg';
import calendarIcon from 'assets/icons/calendar-thin.svg';
import eyeIcon from 'assets/icons/eye_empty.svg';
import frameIcon from 'assets/icons/frame.svg';
import { isValidDate } from 'utils/dateUtil';
import { DATE_RANGE_FORMAT, FORMAT_DATE_FALLBACK, LAST_UPDATED_DATE_TIME_FORMAT } from './consts';

const PublicCampaignPlanContent: React.FC<{
  campaignPlanDetails: CampaignPlanDetails;
  campaignStatus: DirectSalesStatusOption;
}> = ({ campaignPlanDetails, campaignStatus }) => {
  const {
    campaignId,
    campaignName,
    startDate,
    endDate,
    lastUpdatedAt,
    metaData: { isoCountryCode, timeZone },
    totalCampaignFrames,
    totalCampaignImpressions,
  } = campaignPlanDetails;

  const lastUpdatedDateFormatWithCampaignTimezone = { ...LAST_UPDATED_DATE_TIME_FORMAT, timeZone };

  const formattedStartDate = formatDateCustom(startDate, DATE_RANGE_FORMAT, isoCountryCode);
  const formattedEndDate = formatDateCustom(endDate, DATE_RANGE_FORMAT, isoCountryCode);
  const formattedLastUpdatedAt = formatDateCustom(
    lastUpdatedAt,
    lastUpdatedDateFormatWithCampaignTimezone,
    isoCountryCode,
    FORMAT_DATE_FALLBACK,
  ).replace(/,/, ' at');
  const formattedTimeZone = isValidDate(new Date(lastUpdatedAt))
    ? format(new Date(lastUpdatedAt), 'zzz', { timeZone })
    : '';
  const formattedLastUpdatedAtWithTimeZone = `${formattedLastUpdatedAt} ${formattedLastUpdatedAt === FORMAT_DATE_FALLBACK ? '' : formattedTimeZone}`;

  const formattedCampaignImpressions = formatNumber({
    value: totalCampaignImpressions,
    localeCode: isoCountryCode,
  });
  const formattedCampaignFrames = formatNumber({
    value: totalCampaignFrames,
    localeCode: isoCountryCode,
  });

  const renderInfoBox = (icon: string, label: string, value: string | number | JSX.Element): JSX.Element => (
    <div className="p-5 border rounded-xl min-w-[305px] flex flex-1 items-start gap-4">
      <div className="p-2 border rounded-lg">
        <SVG src={icon} className="h-5 w-5" />
      </div>
      <div>
        <p className="sub-header-base text-essential-secondary">{label}</p>
        <div className="header-2xl flex items-center gap-2">{value}</div>
      </div>
    </div>
  );

  return (
    <>
      <div className="flex items-center justify-between py-4">
        <div className="flex items-center p-2.5 gap-4">
          <h2 className="header-2xl">{campaignName || campaignId}</h2>
          <div className="hidden sm:block">
            <ChipBookingStatus status={campaignStatus} />
          </div>
        </div>
        <div className="hidden sm:flex gap-8">
          <div className="p-2.5 hidden sm:block">
            <p className="sub-header-base text-essential-secondary">Campaign ID</p>
            <p className="header-xl">{campaignId}</p>
          </div>
          {formattedLastUpdatedAt && (
            <div className="p-2.5 hidden md:block">
              <p className="sub-header-base text-essential-secondary">Last updated</p>
              <p className="header-xl">{formattedLastUpdatedAtWithTimeZone}</p>
            </div>
          )}
        </div>
      </div>
      <div className="flex gap-4 w-full my-9 overflow-x-auto scrollbar-hidden">
        {renderInfoBox(
          calendarIcon,
          'Campaign date range',
          <div className="flex flex-wrap items-center gap-2" data-test-id="date-range">
            {!!startDate && formattedStartDate}
            <SVG src={arrowIcon} />
            {!!endDate && formattedEndDate}
          </div>,
        )}
        {renderInfoBox(eyeIcon, 'Campaign impressions', formattedCampaignImpressions)}
        {renderInfoBox(frameIcon, 'Campaign frames', formattedCampaignFrames)}
      </div>
    </>
  );
};

export default PublicCampaignPlanContent;
