import { useEffect, useRef, useState } from 'react';
import { DEFAULT_MAX_VISIBLE_TOASTS, MIN_VISIBLE_TOASTS, SPACING, ToasterProps } from './Toaster.types';
import { ToasterElement } from './ToasterElement';
import { getElementBoundingBox } from '../../utils/getElementBoundingBox';
import { useToasterScroller } from './useToasterScroller';

export const Toaster: React.FC<ToasterProps> = ({ toasts, onToastRemove }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [maxVisibleToasts, setMaxVisibleToasts] = useState(DEFAULT_MAX_VISIBLE_TOASTS);
  const { numOfScrolledToasts, decreaseScrolledToasts } = useToasterScroller(
    containerRef,
    toasts.length,
    maxVisibleToasts,
  );

  useEffect(() => {
    if (!containerRef.current) return;

    const calculateMaxVisibleToasts = (): void => {
      const availableHeight = getElementBoundingBox(containerRef.current).height;
      const maxToasts = Math.floor(availableHeight / SPACING);
      setMaxVisibleToasts(Math.max(MIN_VISIBLE_TOASTS, maxToasts));
    };

    calculateMaxVisibleToasts();

    const resizeObserver = new ResizeObserver(() => {
      calculateMaxVisibleToasts();
    });

    resizeObserver.observe(document.body);

    // eslint-disable-next-line consistent-return
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const removeToast = (toastId: string): void => {
    onToastRemove?.(toastId);
    decreaseScrolledToasts();
  };

  return (
    <div className="fixed right-4 bottom-4 top-4 w-125 pointer-events-none" ref={containerRef}>
      <div className="pointer-events-auto">
        {toasts.map((toast, index) => (
          <ToasterElement
            key={`container_${toast.id}`}
            index={index - numOfScrolledToasts}
            toast={toast}
            maxVisibleToasts={maxVisibleToasts}
            onRemove={removeToast}
          />
        ))}
      </div>
    </div>
  );
};
