import { CodeNameModel } from 'components/common/types';
import { ProductCreationInterface } from '../ProductsTable/ProductsTable.types';
import { defaultAllFoldersProductOption } from './getProductsByFolderName';

export const mappedToCodeNameModel = (folderNames: string[]): CodeNameModel[] => {
  const folderNamesMapped = folderNames.map((folderName) => ({ code: folderName, name: folderName }));
  const allFolders = [defaultAllFoldersProductOption];

  return [...allFolders, ...folderNamesMapped];
};

export const getFolderNames = (products: ProductCreationInterface[]): CodeNameModel[] => {
  const uniqueFolderNames = [...new Set(products.map(({ folder }) => folder))] as string[];

  return mappedToCodeNameModel(uniqueFolderNames);
};
