import BaseSelect from 'lib/BaseSelect';
import { CodeNameModel } from 'components/common/types';
import { useMemo } from 'react';
import { findOption } from 'lib/BaseSelect/utils';
import { FilterStatusProps } from './FilterStatus.types';

const FilterStatus: React.FC<FilterStatusProps> = ({
  status = '',
  changeFilters,
  statusesList,
  keyName = 'status',
}) => {
  const selectedStatus = useMemo(() => findOption(statusesList, 'code', status), [status, statusesList]);

  return (
    <BaseSelect
      dataTestId="status-select"
      name={keyName}
      options={statusesList}
      selectedValue={selectedStatus}
      onSelect={(_, value: CodeNameModel) => changeFilters({ [keyName]: value?.code })}
      placeholder="Select status"
      isClearable
    />
  );
};

export default FilterStatus;
