import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import cx from 'classnames';

import {
  changeCurrentLine,
  changeDealData,
  createNewLine,
  duplicateLine,
  removeTemporaryDealId,
} from 'store/dealManagement/reducer';

import plusIcon from 'assets/icons/plus.svg';
import copyIcon from 'assets/icons/copy.svg';
import arrowDownSvg from 'assets/icons/arrow_down.svg';
import downUpSvg from 'assets/icons/arrow_up.svg';

import { warningDealStatuses } from 'consts/ooc';
import { getFormattedDateTime } from 'modules/I18N';
import { DealStatus } from 'components/common/types/Deal.types';
import { getActionConditions } from 'components/pages/DealWithLines/common/getActionConditions';
import DealLinesIcon from 'components/common/Deal/Panel/LineDetails/DealLines/DealLinesIcon';
import { DealLinesStatus } from 'components/common/Deal/Panel/LineDetails/DealLines/DealLinesIcon/DealLinesIcon.types';
import { useHasFeatureAccess } from 'customHooks/useHasFeatureAccess';
import { FeatureFlags } from 'components/common/types/Features.types';
import { getIsAdServer, getIsReseller } from 'store/publisher/selectors';
import { getIsAnyLineFetchingAvailability } from 'store/dealManagement/selectors';

const sortLines = (lines, sortOrder) =>
  [...lines].sort((a, b) => {
    const firstValue = sortOrder === 'asc' ? a : b;
    const secondValue = sortOrder === 'asc' ? b : a;
    return firstValue.createdDate.getTime() - secondValue.createdDate.getTime();
  });

// eslint-disable-next-line sonarjs/cognitive-complexity
const DealLines = ({ handleMouseEnter, closePopup }) => {
  const dispatch = useDispatch();
  const dealManagementState = useSelector((state) => state.dealManagement);
  const {
    backupFormData: {
      lines,
      commonDeal: { summary: backupDealSummary },
    },
    commonDeal: { currentLine, bookingStatusCode },
  } = dealManagementState;

  const [sortOrder, setSortOrder] = useState('asc');
  const isAdServerMarket = useSelector(getIsAdServer);
  const dealConfig = useSelector((state) => state.dealConfig);
  const isReseller = useSelector(getIsReseller);
  const isFetchingAvailability = useSelector(getIsAnyLineFetchingAvailability);

  const actionConditions = getActionConditions(
    dealManagementState,
    isAdServerMarket,
    dealConfig,
    isReseller,
    isFetchingAvailability,
  );

  const handleCreateLine = () => {
    if (actionConditions.createLine.disabled) return;
    dispatch(createNewLine());
    closePopup();
  };

  const handleDuplicateLine = () => {
    if (actionConditions.duplicateLine.disabled) return;
    dispatch(duplicateLine(currentLine.id));
    closePopup();
  };

  const handleLineSorting = (e) => {
    e.stopPropagation();
    setSortOrder((prevState) => (prevState === 'asc' ? 'desc' : 'asc'));
  };

  const isBookingStatusFailed = bookingStatusCode === DealStatus.FAILED;

  const getDealLinesIconStatus = (line) => {
    if (!useHasFeatureAccess(FeatureFlags.OUT_OF_CHARGE))
      return {
        dealLinesIconStatus: null,
        isLineWithOutOfChargedFrames: false,
      };

    const isLineWithOutOfChargedFrames = line?.dischargedFrameIds?.length > 0;
    const showOOCWarning = warningDealStatuses.includes(bookingStatusCode) && isLineWithOutOfChargedFrames;
    const dealLinesIconStatus =
      ((isBookingStatusFailed || line.bookingStatusCode === DealStatus.FAILED) &&
        DealLinesStatus.BOOKING_STATUS_FAILED) ||
      (showOOCWarning && DealLinesStatus.LINE_WITH_OUT_OF_CHARGE_FRAMES);

    return {
      showOOCWarning,
      dealLinesIconStatus,
    };
  };

  return (
    <div className="divide-y-2 divide-neutral-300 border-r-2 border-neutral-300">
      <div className="bg-neutral-100 px-4 py-7 space-y-6 text-primary">
        <div className="flex justify-between items-center space-x-1.5">
          <p className="sub-header-lg">
            {currentLine.id ? (
              <>
                {currentLine.sortOrderNumber} <span>{currentLine.name}</span>
              </>
            ) : (
              <span>New line</span>
            )}
          </p>
          <p className="body-base">
            {getFormattedDateTime(new Date(currentLine.createdDate), {
              dateStyle: 'medium',
            })}
          </p>
        </div>
        <div className="flex justify-around">
          {actionConditions.createLine.show ? (
            <button
              type="button"
              onClick={handleCreateLine}
              disabled={actionConditions.createLine.disabled}
              className="disabled:opacity-25 disabled:text-neutral-900"
            >
              <SVG src={plusIcon} />
            </button>
          ) : null}
          {actionConditions.duplicateLine.show ? (
            <button
              type="button"
              onClick={handleDuplicateLine}
              disabled={actionConditions.duplicateLine.disabled}
              className="disabled:opacity-25 disabled:text-neutral-900"
            >
              <SVG src={copyIcon} />
            </button>
          ) : null}
          <button type="button" onClick={handleLineSorting}>
            <SVG src={sortOrder === 'asc' ? arrowDownSvg : downUpSvg} />
          </button>
        </div>
      </div>

      <div className="p-2 pb-0 max-h-80 overflow-y-scroll">
        {sortLines(lines, sortOrder).map((line) => {
          const { dealLinesIconStatus, showOOCWarning } = getDealLinesIconStatus(line);
          const lineFailed = lines.length > 1 ? line.bookingStatusCode === DealStatus.FAILED : isBookingStatusFailed;

          return (
            <div
              key={line.id}
              data-test-id="deal-line"
              className={cx('group flex justify-between items-center w-full space-x-2 rounded-md', {
                'text-neutral': line.terminated,
                'bg-pinkRed-opacity-20 border border-pinkRed-500': lineFailed,
                'border border-transparent hover:bg-neutral-100 hover:border-neutral-300': !lineFailed,
                'bg-yellow-opacity-20 border border-yellow-300 hover:bg-yellow-opacity-20 hover:border-yellow-300':
                  showOOCWarning,
              })}
              onMouseEnter={() => handleMouseEnter(line.id)}
            >
              <button
                data-test-id="line"
                type="button"
                className="flex items-baseline p-2"
                onClick={() => {
                  dispatch(changeCurrentLine(line.id));
                  dispatch(removeTemporaryDealId());
                  dispatch(changeDealData({ summary: backupDealSummary }));
                  closePopup();
                }}
              >
                <p className="mr-2 w-7 sub-header-base">{line.sortOrderNumber}</p>
                <p className="w-24 mr-2 text-left truncate sub-header-base">{line.name}</p>
                <p className="body-base">
                  {getFormattedDateTime(new Date(line.createdDate), {
                    dateStyle: 'medium',
                  })}
                </p>
              </button>
              {line.terminated ? <p className="body-base text-neutral-900">T</p> : null}
              {dealLinesIconStatus ? <DealLinesIcon status={dealLinesIconStatus} /> : null}
              <div className="p-2 invisible group-hover:visible">
                <button
                  type="button"
                  onClick={() => {
                    dispatch(duplicateLine(line.id));
                    closePopup();
                  }}
                  disabled={actionConditions.duplicateLine.disabled}
                  className="disabled:opacity-25 disabled:text-neutral-900"
                >
                  <SVG src={copyIcon} className="text-primary" />
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

DealLines.propTypes = {
  handleMouseEnter: PropTypes.func,
  closePopup: PropTypes.func.isRequired,
};

DealLines.defaultProps = {
  handleMouseEnter: () => null,
};

export default DealLines;
