import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PlannerSecondaryPanelType } from 'store/dealManagement/reducer';
import { changeSecondaryPanel } from 'store/productManagement/reducer';
import { Store } from 'components/common/types/Store.types';

type UseOnChangePanelVisibilityReturnType = (nextPanelType: PlannerSecondaryPanelType) => ReturnType<VoidFunction>;

export const useOnChangePanelVisibility = (): UseOnChangePanelVisibilityReturnType => {
  const dispatch = useDispatch();
  const currentPanelType = useSelector((state: Store) => state.productManagement.secondaryPanel);

  return useCallback(
    (nextPanelType: PlannerSecondaryPanelType) => {
      dispatch(changeSecondaryPanel(nextPanelType));
    },
    [currentPanelType],
  );
};
