import React from 'react';
import { differenceInDays } from 'date-fns';
import SVG from 'react-inlinesvg';
import cx from 'classnames';
import infoSvg from 'assets/icons/info.svg';
import shareArrowSvg from 'assets/icons/shareArrow.svg';
import { Color } from 'lib/Button';
import Modal from 'lib/Modal';
import { CircularLoadingSpinner, SpinnerSize } from 'lib/CircularLoadingSpinner/CircularLoadingSpinner';
import ToggleSwitch from 'components/patterns/ToggleSwitch';
import { CopyToClipboardButton } from './CopyToClipboardButton';
import { Error } from './Error';
import { ShareCampaignPlanModalProps } from './ShareCampaignPlanModal.types';

export const ShareCampaignPlanModal: React.FC<ShareCampaignPlanModalProps> = ({
  shareCampaignPlan: { error: shareError, isLoading: isShareLoading, url: shareUrl, expiresAt },
  toggleCampaignPlan: {
    isLoading: isToggleLoading,
    isChecked: isToggleChecked,
    isDisabled: isToggleDisabled,
    error: toggleError,
  },
  onClose,
  onToggleSwitchChange,
}) => {
  return (
    <Modal icon={shareArrowSvg} isOpen title="Share campaign plan" onClose={onClose}>
      <div className="flex flex-col gap-y-4 max-w-lg">
        <div className="body-lg leading-6 text-neutral-900">
          The campaign plan is read-only and is accessible to anyone. Any committed changes to this campaign will be
          automatically reflected in the plan.
        </div>

        {shareError && <Error header="Unable to generate campaign plan link" errorMessage={shareError} />}

        {isShareLoading && (
          <div className="h-40">
            <CircularLoadingSpinner />
          </div>
        )}

        {!isShareLoading && !shareError && (
          <>
            <div className="flex flex-row items-center gap-x-2">
              <div
                className={cx('w-full max-w-[432px] border rounded-md border-neutral-950-opacity-10 px-3 py-2', {
                  'bg-neutral-950-opacity-5 pointer-events-none select-none selection:bg-transparent': isToggleChecked,
                })}
              >
                <div className="whitespace-nowrap text-neutral-600 body-base overflow-scroll scrollbar-hidden">
                  {shareUrl}
                </div>
              </div>
              <div>
                <CopyToClipboardButton
                  color={Color.PRIMARY}
                  label="Copy"
                  textToCopy={shareUrl}
                  isDisabled={isToggleChecked}
                />
              </div>
            </div>
            <div className="flex flex-row items-center gap-x-2 body-lg leading-6 text-neutral-900">
              <SVG src={infoSvg} className="fill-current text-neutral-700" />
              <p>The campaign plan link will expire in {differenceInDays(new Date(expiresAt), new Date())} days.</p>
            </div>
            <div
              className="flex flex-row border rounded-md border-neutral-950-opacity-10 p-3 space-x-3"
              aria-disabled={isToggleDisabled}
            >
              {isToggleLoading ? (
                <div className="relative h-8 w-8">
                  <CircularLoadingSpinner size={SpinnerSize.SMALL} />
                </div>
              ) : (
                <ToggleSwitch
                  isChecked={isToggleChecked}
                  isDisabled={isToggleDisabled}
                  onChange={onToggleSwitchChange}
                  dataTestId="share-campaign-plan-modal-disable-switch"
                />
              )}

              <div className="flex flex-col space-y-1">
                <div>Disable campaign plan access</div>
                <div className="body-lg leading-6 text-neutral-900">
                  Turning this on will deactivate the campaign plan link.
                </div>

                {toggleError && (
                  <Error
                    header={`Unable to ${isToggleChecked ? 'enable' : 'disable'} campaign plan`}
                    errorMessage={toggleError}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};
