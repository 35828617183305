import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useDealFormReadOnly } from 'customHooks/useDealFormReadOnly';
import { SOURCE_SYSTEM } from 'consts/sourceSystem';
import { Label } from 'lib/Label';
import { CustomBaseSelectProps } from 'lib/BaseSelect/BaseSelect.types';
import { changeDealInfo } from 'store/dealManagement/actions';
import { InputTheme } from 'components/common/types/Input.types';
import AsyncSelect from 'components/patterns/AsyncSelect';
import { Store } from 'components/common/types/Store.types';
import { useLoadLookup } from 'components/common/FilterArea/Filters/useLoadLookup';
import { Advertiser, BrandWithProductCategory, DealStatus } from 'components/common/types/Deal.types';
import usePrevious from 'customHooks/usePrevious';
import useCampaignType from 'components/pages/Planner/hooks/useCampaignType';
import useIsReadOnly from 'components/pages/Planner/useIsReadOnly';
import { updateOrganisations } from '../utils';

interface SelectAdvertiserProps extends Pick<CustomBaseSelectProps, 'theme' | 'size' | 'padding' | 'shadow' | 'shape'> {
  advertisers: Advertiser[];
  setAdvertisers: (value: React.SetStateAction<Advertiser[]>) => void;
  setBrands: (value: React.SetStateAction<BrandWithProductCategory[]>) => void;
  dataTestId?: string;
  placeholder?: string;
  withLabel?: boolean;
}

export const SelectAdvertiser: React.FC<SelectAdvertiserProps> = ({
  advertisers,
  setAdvertisers,
  setBrands,
  dataTestId,
  placeholder,
  theme,
  size,
  padding,
  shadow,
  shape,
  withLabel = false,
}) => {
  const advertiser = useSelector((state: Store) => state.dealManagement.commonDeal.advertiser);
  const bookingStatusCode = useSelector((state: Store) => state.dealManagement.commonDeal.bookingStatusCode);
  const sourceSystem = useSelector((state: Store) => state.dealManagement.commonDeal.sourceSystem);
  const exclusions = useSelector((state: Store) => state.dealManagement.commonDeal.exclusions);

  const dispatch = useDispatch();
  const { onLoadLookup } = useLoadLookup(0);
  const readOnly = useDealFormReadOnly();
  const { isCampaignReadOnly } = useIsReadOnly();
  const { isDirectSalesCampaignType } = useCampaignType();
  const previousAdvertiser = usePrevious(advertiser);
  const isPendingReservation = bookingStatusCode === DealStatus.PENDING_RESERVATION;

  const onAdvertiserChange = (selectedAdvertiser: Advertiser | null): void => {
    if (!selectedAdvertiser) {
      dispatch(changeDealInfo({ brand: null, productCategory: null }));
      setAdvertisers([]);
      setBrands([]);
    } else {
      const { brands: selectedBrands } = selectedAdvertiser;
      if (selectedBrands && selectedBrands.length === 1) {
        dispatch(
          changeDealInfo({
            brand: selectedBrands[0],
            productCategory: selectedBrands[0].productCategory,
          }),
        );
      } else {
        dispatch(changeDealInfo({ brand: null, productCategory: null }));
      }

      setBrands(selectedBrands || []);
    }
    if (isDirectSalesCampaignType) {
      const { organisations } = exclusions;
      dispatch(
        changeDealInfo({
          exclusions: {
            ...exclusions,
            organisations: updateOrganisations(organisations, selectedAdvertiser, previousAdvertiser || null),
          },
        }),
      );
    }
  };

  return (
    <>
      {withLabel ? (
        <Label
          id="advertiser"
          theme={InputTheme.FLAT_GRAY}
          label="Advertiser"
          isRequired={sourceSystem !== SOURCE_SYSTEM.DV360}
        />
      ) : null}
      <AsyncSelect
        dataTestId={dataTestId}
        name="advertiser"
        placeholder={placeholder}
        selectedValue={advertiser}
        loadData={onLoadLookup}
        onSelect={(dropdownName: string, selectedAdvertiser: Advertiser | null) => {
          dispatch(changeDealInfo({ [dropdownName]: selectedAdvertiser }));
          onAdvertiserChange(selectedAdvertiser);
        }}
        defaultOptions={advertisers}
        isDisabled={(readOnly && !isPendingReservation) || isCampaignReadOnly}
        theme={theme}
        size={size}
        padding={padding}
        shadow={shadow}
        shape={shape}
      />
    </>
  );
};
