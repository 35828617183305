import { useSelector } from 'react-redux';
import { Store } from 'components/common/types/Store.types';
import { FormatTypeEnum } from 'modules/I18N';
import { handleValueFormat } from 'components/pages/DealWithLines/common/RightSidebar/utils';
import { DealStatus, DealType } from 'components/common/types/Deal.types';
import reportSvg from 'assets/icons/report.svg';
import listSvg from 'assets/icons/list.svg';
import Button, { Color, Size } from 'lib/Button';
import { CardTheme } from 'lib/Card/Card.types';
import { LevelType } from 'consts/thoughtspot';
import { ReportType } from 'components/common/AllocationReport/AllocationReport.types';
import { DealSummaryCard } from '../../DealSummary/DealSummaryCard/DealSummaryCard';
import DealSummaryCardSection from '../../DealSummary/DealSummaryCardSection';
import { DealSummaryCardSectionLineType } from '../../DealSummary/DealSummaryCardSection/DealSummaryCardSection.types';
import { DealSummaryAllocationReport } from '../../DealSummaryAllocationReport/DealSummaryAllocationReport';
import { DealSummaryFrameList } from '../../DealSummaryFrameList/DealSummaryFrameList';
import { AllocationCardProps } from './AllocationCard.types';
import { getDealSummaryCardVisibility } from '../selectors';

const AllocationCard: React.FC<AllocationCardProps> = ({
  allocatedFrames,
  allocatedImpressions,
  assets,
  defaultAllocationReportLevelType = LevelType.LINE,
  totalCost,
}) => {
  const currencyCode = useSelector((state: Store) => state.publisher.configuration.currencyCode);
  const localeCode = useSelector((state: Store) => state.publisher.configuration.localeCode);
  const isForecastedAllocation = useSelector((state: Store) => state.dealManagement.isForecastedAllocation);
  const isNewDeal = useSelector((state: Store) => state.dealManagement.isNewDeal);
  const dealType = useSelector((state: Store) => state.dealManagement.commonDeal.dealType);
  const bookingStatusCode = useSelector((state: Store) => state.dealManagement.commonDeal.bookingStatusCode);
  const { isAllocationCardVisible } = useSelector(getDealSummaryCardVisibility);
  const currentLinebookingStatusCode = useSelector(
    (state: Store) => state.dealManagement.commonDeal.currentLine.bookingStatusCode,
  );
  const isGuaranteed = dealType === DealType.GUARANTEED;

  const allocationCardTheme =
    dealType === DealType.GUARANTEED &&
    (isNewDeal || bookingStatusCode === DealStatus.APPROVED || isForecastedAllocation)
      ? CardTheme.PRIMARY
      : CardTheme.DEFAULT;

  const isReadOnly =
    isGuaranteed &&
    currentLinebookingStatusCode &&
    [DealStatus.TERMINATED, DealStatus.ENDED].includes(currentLinebookingStatusCode);

  const buttonsVisibility = {
    frameList: defaultAllocationReportLevelType === LevelType.LINE,
    report: isGuaranteed && bookingStatusCode !== DealStatus.CANCELLED,
  };
  const showButtonsContainer = Object.values(buttonsVisibility).some((isButtonVisible) => isButtonVisible);

  return (
    <DealSummaryCard header="ALLOCATION" isVisible={isAllocationCardVisible} theme={allocationCardTheme}>
      <DealSummaryCardSection
        lines={[
          {
            label: currencyCode,
            value: handleValueFormat({
              valueType: FormatTypeEnum.NUMBER,
              value: totalCost,
              currencyCode,
              localeCode,
            }),
            lineType: DealSummaryCardSectionLineType.HEADER,
          },
        ]}
        isReadOnly={isReadOnly}
      />
      <DealSummaryCardSection
        lines={[
          {
            label: 'Impressions',
            value: handleValueFormat({
              valueType: FormatTypeEnum.ROUNDED_NUMBER,
              value: allocatedImpressions,
              currencyCode,
              localeCode,
            }),
            lineType: DealSummaryCardSectionLineType.HEADER,
          },
        ]}
        isReadOnly={isReadOnly}
      />
      <DealSummaryCardSection
        lines={[
          {
            label: 'Frames',
            value: handleValueFormat({
              valueType: FormatTypeEnum.ROUNDED_NUMBER,
              value: allocatedFrames,
              currencyCode,
              localeCode,
            }),
            lineType: DealSummaryCardSectionLineType.HEADER,
          },
        ]}
        isReadOnly={isReadOnly}
      >
        {showButtonsContainer && (
          <div className="flex flex-row gap-x-2 h-9">
            {buttonsVisibility.frameList && !!assets?.length && (
              <DealSummaryFrameList
                assets={assets}
                actionButton={(onClick) => (
                  <Button color={Color.TERTIARY} label="Frame list" size={Size.WIDE} svg={listSvg} onClick={onClick} />
                )}
              />
            )}
            {buttonsVisibility.report && (
              <DealSummaryAllocationReport
                buttonLabel="Report"
                actionButton={(onClick) => (
                  <Button
                    color={Color.TERTIARY}
                    label="Report"
                    size={Size.WIDE}
                    svg={reportSvg}
                    onClick={onClick}
                    ddActionName="Allocation report / Allocation card report button"
                  />
                )}
                reportType={ReportType.SAVED}
                defaultLevelType={defaultAllocationReportLevelType}
              />
            )}
          </div>
        )}
      </DealSummaryCardSection>
    </DealSummaryCard>
  );
};

export default AllocationCard;
