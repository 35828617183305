import YearMonthForm from 'components/patterns/YearMonthForm';
import { isValid } from 'date-fns';
import { getInputBorderStyle } from 'lib/BaseInput';
import Button, { Color, Size } from 'lib/Button';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import DayPicker, { CaptionElementProps, NavbarElementProps } from 'react-day-picker';
import cx from 'classnames';

import './DateTimePicker.pcss';
import arrowLightLeft from 'assets/icons/arrow_light_left.svg';
import arrowLightRight from 'assets/icons/arrow_light_right.svg';
import { DATE_TIME_PICKER_YEARS_AFTER, DATE_TIME_PICKER_YEARS_BEFORE } from 'consts/dateTimePicker';
import { DateTimePickerProps } from './DateTimePicker.types';

const DateTimePicker: React.FC<DateTimePickerProps> = ({
  value,
  isOpen,
  onChange,
  disabledDays,
  isDisabled = false,
  firstDayOfTheWeek = 1,
  yearsBefore = DATE_TIME_PICKER_YEARS_BEFORE,
  yearsAfter = DATE_TIME_PICKER_YEARS_AFTER,
  seconds = 0,
  milliseconds = 0,
  isTimePickerEnabled = true,
}) => {
  const dayPickerCalendarRef = useRef<HTMLDivElement>(null);
  const dayPickerRef = useRef<DayPicker>(null);

  const [month, setMonth] = useState(new Date());

  const isValidDate = isValid(value);

  useEffect(() => {
    if (isValidDate) setMonth(value);
  }, [isOpen, isValidDate]);

  const time = useMemo((): string => {
    if (!isValidDate) return '';

    const hours = value.getHours().toString().padStart(2, '0');
    const minutes = value.getMinutes().toString().padStart(2, '0');

    return `${hours}:${minutes}`;
  }, [value, isValidDate]);

  const handleYearMonthChange = (newDate: Date): void => setMonth(newDate);

  const handleDateChange = useCallback(
    (newDate: Date): void => {
      newDate.setHours(
        isValidDate ? value.getHours() : 23,
        isValidDate ? value.getMinutes() : 59,
        seconds,
        milliseconds,
      );

      onChange(newDate);
    },
    [value, isValidDate],
  );

  const handleTimeChange = useCallback(
    (dateTime: string): void => {
      const newDate = new Date(value);

      const [hours, minutes] = dateTime.split(':');

      newDate.setHours(+hours, +minutes, seconds, milliseconds);

      onChange(newDate);
    },
    [value],
  );

  return (
    <div
      className="DateTimePicker flex flex-col items-center w-[320px]"
      data-test-id="date-time-picker-calendar"
      ref={dayPickerCalendarRef}
    >
      <DayPicker
        ref={dayPickerRef}
        showOutsideDays
        firstDayOfWeek={firstDayOfTheWeek}
        selectedDays={value}
        disabledDays={disabledDays}
        month={month}
        onDayClick={(day) => handleDateChange(day)}
        captionElement={(props: CaptionElementProps) => (
          <YearMonthForm
            date={month}
            onChange={handleYearMonthChange}
            localeUtils={props.localeUtils}
            yearsBefore={yearsBefore}
            yearsAfter={yearsAfter}
          />
        )}
        navbarElement={(props: NavbarElementProps) => (
          <div className="absolute w-[296px] flex justify-between">
            <Button
              onClick={() => {
                props.onPreviousClick();
                setMonth(props.previousMonth);
              }}
              svg={arrowLightLeft}
              color={Color.SECONDARY}
              classNames="w-10 h-10 flex items-center justify-center z-20"
              svgClassnames="text-primary w-6 h-6"
            />
            <Button
              onClick={() => {
                props.onNextClick();
                setMonth(props.nextMonth);
              }}
              size={Size.MEDIUM}
              svg={arrowLightRight}
              color={Color.SECONDARY}
              classNames="w-10 h-10 flex items-center justify-center z-20"
              svgClassnames="text-primary w-6 h-6"
            />
          </div>
        )}
      />
      {isTimePickerEnabled ? (
        <div className="my-4">
          <input
            className={cx(getInputBorderStyle({ isDisabled }), 'text-sm text-center p-1 outline-none bg-neutral-50')}
            type="time"
            data-test-id="date-time-picker-time-input"
            onChange={(e) => handleTimeChange(e.target.value)}
            value={time}
            disabled={!isValidDate}
          />
        </div>
      ) : null}
    </div>
  );
};

export default DateTimePicker;
