import { createSelector } from '@reduxjs/toolkit';
import { DealStatus, DealType } from 'components/common/types/Deal.types';
import { Store } from 'components/common/types/Store.types';
import { isToday } from 'date-fns';
import {
  getIsAnyLineFetchingAvailability,
  getIsNewAvailabilityFetched,
  getIsValidAvailabilityRequest,
} from 'store/dealManagement/selectors';
import { isPastDate } from 'utils/isPastDate';

export const getDealSummaryCardVisibility = createSelector(
  (state: Store) => state.dealManagement.commonDeal.bookingStatusCode,
  getIsValidAvailabilityRequest,
  getIsNewAvailabilityFetched,
  getIsAnyLineFetchingAvailability,
  (state: Store) => state.dealManagement.commonDeal.currentLine.isCurrentLineWithProposalAllocation,
  (state: Store) => state.dealManagement.isNewDeal,
  (state: Store) => state.dealManagement.commonDeal.dealType,
  (state: Store) => Boolean(state.dealManagement.commonDeal.currentLine.id),
  (state: Store) => state.dealManagement.commonDeal.currentLine.bookingStatusCode,
  (
    bookingStatusCode,
    isValidAvailabilityRequest,
    isNewAvailabilityFetched,
    isFetchingAvailability,
    isCurrentLineWithProposalAllocation,
    isNewDeal,
    dealType,
    isExistingLine,
    currentLineBookingStatusCode,
  ) => {
    const isGuaranteed = dealType === DealType.GUARANTEED;
    const isNonGuaranteed = dealType !== DealType.GUARANTEED;

    const isAvailabilityCardVisible = Boolean(
      (isNonGuaranteed && isValidAvailabilityRequest) ||
        (isGuaranteed &&
          isValidAvailabilityRequest &&
          isNewAvailabilityFetched &&
          currentLineBookingStatusCode &&
          ![DealStatus.CANCELLED, DealStatus.TERMINATED].includes(bookingStatusCode) &&
          ![DealStatus.CANCELLED, DealStatus.TERMINATED].includes(currentLineBookingStatusCode)) ||
        (isGuaranteed &&
          isValidAvailabilityRequest &&
          isNewAvailabilityFetched &&
          (isNewDeal || !isExistingLine) &&
          ![DealStatus.CANCELLED, DealStatus.TERMINATED].includes(bookingStatusCode)),
    );

    const isDeliveryCardVisible =
      isGuaranteed && [DealStatus.LIVE, DealStatus.TERMINATED, DealStatus.ENDED].includes(bookingStatusCode);

    const isProposalCardVisible =
      isGuaranteed &&
      ((bookingStatusCode === DealStatus.PENDING_APPROVAL &&
        !isFetchingAvailability &&
        !isNewAvailabilityFetched &&
        isExistingLine) ||
        (isCurrentLineWithProposalAllocation &&
          (isNewDeal ||
            [DealStatus.PENDING_APPROVAL, DealStatus.APPROVED, DealStatus.LIVE].includes(bookingStatusCode))) ||
        bookingStatusCode === DealStatus.CANCELLED);

    const isCheckAllocationButtonVisible =
      isGuaranteed && ![DealStatus.CANCELLED, DealStatus.TERMINATED, DealStatus.ENDED].includes(bookingStatusCode);

    const isAllocationCardVisible =
      isGuaranteed &&
      ((isExistingLine && [DealStatus.APPROVED, DealStatus.LIVE].includes(bookingStatusCode)) ||
        [DealStatus.TERMINATED, DealStatus.ENDED].includes(bookingStatusCode));

    return {
      isAllocationCardVisible,
      isAvailabilityCardVisible,
      isDeliveryCardVisible,
      isProposalCardVisible,
      isCheckAllocationButtonVisible,
    };
  },
);

export const getLineSummaryCardVisibility = createSelector(
  getDealSummaryCardVisibility,
  (state: Store) => Boolean(state.dealManagement.commonDeal.currentLine.id),
  (state: Store) => state.dealManagement.commonDeal.currentLine.startDate,
  (state: Store) => state.dealManagement.commonDeal.currentLine.bookingStatusCode,
  (dealCardsVisibility, isExistingLine, currentDealLineStartDate) => {
    const isCurrentLineStartDateTodayOrPast =
      currentDealLineStartDate !== null &&
      (isPastDate(new Date(currentDealLineStartDate)) || isToday(new Date(currentDealLineStartDate)));

    const { isAvailabilityCardVisible, isProposalCardVisible, isDeliveryCardVisible } = dealCardsVisibility;
    const isLineDeliveryCardVisible = isDeliveryCardVisible && isExistingLine && isCurrentLineStartDateTodayOrPast;

    return {
      isLineProposalCardVisible: isProposalCardVisible,
      isLineDeliveryCardVisible,
      isLineAvailabilityCardVisible: isAvailabilityCardVisible,
    };
  },
);
