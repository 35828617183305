import { isEmpty } from 'lodash';
import {
  DirectSalesTransientDealLineState,
  DirectSalesState,
  DirectSalesStatusLabels,
  DirectSalesStatusOption,
} from 'components/common/types/DirectSalesCampaign.types';

export const getStatusOptionLabel = (option: DirectSalesStatusOption): string => {
  return option in DirectSalesStatusLabels ? DirectSalesStatusLabels[option] : '';
};

export const generateNewTransientStates = (
  transientLineStates: DirectSalesTransientDealLineState,
  currentLineId: string,
  status: DirectSalesStatusOption,
  expires: Date | string,
): DirectSalesTransientDealLineState => {
  const currentLineExpiry = status === DirectSalesStatusOption.OPTION && expires ? { expires } : {};
  const currentLineState = isEmpty(currentLineExpiry) ? { status } : { status, ...currentLineExpiry };

  return {
    ...transientLineStates,
    [currentLineId]: currentLineState,
  };
};

export const assignStateToMultipleTransientLines = (
  lineStates: Record<string, DirectSalesState>,
  newStatus: DirectSalesStatusOption,
  currentLineExpiry: { expires?: string | Date },
): DirectSalesTransientDealLineState => {
  const currentCampaignStatus = isEmpty(currentLineExpiry)
    ? { status: newStatus }
    : { status: newStatus, ...currentLineExpiry };

  const newLineStates: DirectSalesTransientDealLineState = {};

  Object.keys(lineStates).forEach((key) => {
    newLineStates[key] = currentCampaignStatus;
  });

  return newLineStates;
};

export const assignStateToMultipleLines = (
  lineStates: Record<string, DirectSalesState>,
  newStatus: DirectSalesStatusOption,
  currentLineExpiry: { expires?: string | Date },
): Record<string, { state: DirectSalesState }> => {
  const currentCampaignStatus = isEmpty(currentLineExpiry)
    ? { state: { status: newStatus } }
    : { state: { status: newStatus, ...currentLineExpiry } };

  const newLineStates: Record<string, { state: DirectSalesState }> = {};

  Object.keys(lineStates).forEach((key) => {
    newLineStates[key] = currentCampaignStatus;
  });

  return newLineStates;
};
