import { ReactElement } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Auth from 'modules/Auth';
import { PermissionsEnum } from 'consts/permissions';
import { URLS } from 'modules/api/constants';
import Header from 'components/common/Header';
import SubNavigation from 'components/common/SubNavigation';
import { FeatureFlags } from 'components/common/types/Features.types';
import { useHasFeatureAccess } from 'customHooks/useHasFeatureAccess';
import Button, { Size, TextOverflow } from 'lib/Button';
import editIcon from 'assets/icons/edit.svg';
import blankIcon from 'assets/icons/blank.svg';
import PlannerLabsButton from './PlannerLabsButton';
import DealFormButton from './DealFormButton';
import DealFormDropdown from './DealFormDropdown';

const DealsHeader: React.FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const openPlannerNative = (): void => navigate('/planner/programmatic');
  const openPlannerDirectSales = (): void => navigate('/planner/direct-sales');
  const openPlannerLabs = (): Window | null => window.open(URLS().PLANNER(), '_blank');
  const openProductCreationManager = (): void => navigate('/product');
  const openDealForm = (): void => navigate('/deal');
  const hasPlannerEnabled = useHasFeatureAccess(FeatureFlags.PLANNER);
  const hasPlannerNativeEnabled = useHasFeatureAccess(FeatureFlags.PLANNER_NATIVE);
  const hasDirectSalesPlannerEnabled = useHasFeatureAccess(FeatureFlags.DIRECT_SALES_PLANNER);

  const createDealButton = (): ReactElement | null => {
    if (!Auth.hasPermission(PermissionsEnum.DEAL_CREATE)) {
      return null;
    }

    if (hasDirectSalesPlannerEnabled && pathname.includes('product-management')) {
      return (
        <Button
          label="Create new product"
          size={Size.MEDIUM}
          svg={blankIcon}
          svgClassnames="mr-[4px]"
          onClick={openProductCreationManager}
          dataPendoId="/deals | Create new product"
        />
      );
    }
    if (hasDirectSalesPlannerEnabled && pathname.includes('direct-sales')) {
      return (
        <Button
          label="Create campaign"
          size={Size.LARGE}
          svg={editIcon}
          svgClassnames="mr-[4px]"
          onClick={openPlannerDirectSales}
          textOverflow={TextOverflow.VISIBLE}
          dataPendoId="/deals | Create direct sales planner deal"
        />
      );
    }

    if (hasPlannerNativeEnabled) {
      return <DealFormDropdown openDealForm={openDealForm} openPlanner={openPlannerNative} />;
    }

    if (hasPlannerEnabled) {
      return (
        <>
          <PlannerLabsButton onClick={openPlannerLabs} />
          <DealFormButton onClick={openDealForm} />
        </>
      );
    }

    return <DealFormButton onClick={openDealForm} />;
  };

  const dealsTabs = [
    {
      id: 1,
      path: '/deals/programmatic',
      isVisible: true,
      name: 'Programmatic',
    },
    {
      id: 2,
      path: '/deals/direct-sales',
      isVisible: hasDirectSalesPlannerEnabled,
      name: 'Direct Sales',
    },
    {
      id: 3,
      path: '/deals/product-management',
      isVisible: hasDirectSalesPlannerEnabled,
      name: 'Products',
    },
  ];

  return (
    <Header
      title="Deal management"
      subNavigation={<SubNavigation tabsConfig={dealsTabs} />}
      actionButton={createDealButton()}
    />
  );
};

export default DealsHeader;
