import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import cx from 'classnames';
import { Store } from 'components/common/types/Store.types';
import IconButton from 'lib/IconButton';
import sidebarSimpleLeftIcon from 'assets/icons/sidebar-simple-left.svg';
import saveIcon from 'assets/icons/save.svg';
import copyIcon from 'assets/icons/copy.svg';
import { toggleCampaignDrawerOpen } from 'store/dealManagement/reducer';
import AdvertiserInfoConfirmationModal from 'components/common/Modals/AdvertiserInfoConfirmationModal';
import useIsReadOnly from 'components/pages/Planner/useIsReadOnly';
import { isDirectSalesCampaignCloned } from 'components/pages/Planner/hooks/utils/isDirectSalesCampaignCloned';
import usePendoTracking from 'customHooks/usePendoTracking';
import { useDuplicateDeal } from 'customHooks/useDuplicateDeal';
import { DealStatus } from 'components/common/types/Deal.types';
import { VALID_CAMPAIGN_SHARE_PLAN_STATES } from 'components/common/types/DirectSalesCampaign.types';
import useHandleSaveButton from 'customHooks/useHandleSaveButton';
import useCampaignType from 'components/pages/Planner/hooks/useCampaignType';
import CampaignDuplicateModal from 'components/common/CampaignDuplicationModal/CampaignDuplicationModal';
import { useToggle } from 'customHooks/useToggle';
import { getEveryLineStatus } from 'store/dealManagement/selectors';
import { ChipBookingStatus } from '../ChipBookingStatus/ChipBookingStatus';
import { IconTradingType } from '../IconTradingType/IconTradingType';
import { SelectTradingType } from '../SelectTradingType/SelectTradingType';
import { ShareCampaignPlan } from '../ShareCampaignPlan/ShareCampaignPlan';

export const CampaignNameAndStatus: React.FC<{
  isPlannerHeader?: boolean;
}> = ({ isPlannerHeader }) => {
  const dispatch = useDispatch();

  const { getPendoDataId } = usePendoTracking();

  const {
    handleSave,
    handleUndoChanges,
    handleConfirmChanges,
    showSaveButton,
    isSaveDisabled,
    showAdvertiserInfoConfirmationModal,
  } = useHandleSaveButton();

  const { handleDuplicateDeal } = useDuplicateDeal();
  const { campaignId: originalCampaignId } = useParams();

  const dealName = useSelector((state: Store) => state.dealManagement.commonDeal.dealName);
  const dealId = useSelector((state: Store) => state.dealManagement.commonDeal.dealId);
  const isCampaignDrawerOpen = useSelector((state: Store) => state.dealManagement.isCampaignDrawerOpen);
  const bookingStatusCode = useSelector((state: Store) => state.dealManagement.commonDeal.bookingStatusCode);
  const isLoading = useSelector((state: Store) => state.dealManagement.isLoading);
  const linesStates = useSelector(getEveryLineStatus);
  const bookingStatus = useSelector((state: Store) => state.dealManagement.commonDeal.bookingStatusCode);

  const { isCampaignReadOnly } = useIsReadOnly();
  const { isDirectSalesCampaignType } = useCampaignType();
  const {
    isOpen: isCampaignDuplicationModalOpen,
    open: openCampaignDuplicationModal,
    close: closeCampaignDuplicationModal,
  } = useToggle();

  const isShareCampaignPlanButtonEnabled =
    linesStates.length && linesStates.every((lineState) => VALID_CAMPAIGN_SHARE_PLAN_STATES.includes(lineState));

  const handleToggleCampaignDrawer = (): void => {
    dispatch(toggleCampaignDrawerOpen(!isCampaignDrawerOpen));
  };

  return (
    <div
      className={cx('flex flex-shrink-0 gap-2 items-center px-[8px]', {
        'border-r border-neutral-950-opacity-10': !isCampaignDrawerOpen,
        'w-[380px]': isPlannerHeader && !isCampaignDrawerOpen,
        'w-[348px]': !isPlannerHeader || isCampaignDrawerOpen,
      })}
    >
      {isCampaignDrawerOpen ? (
        <SelectTradingType />
      ) : (
        <>
          <IconTradingType />
          <button
            data-test-id="deal-header-deal-name"
            className={cx('header-base w-[271px] truncate text-left', {
              'text-essential-tertiary italic': !dealName && !dealId,
              'text-essential-primary': !!dealName || !!dealId,
            })}
            type="button"
            onClick={handleToggleCampaignDrawer}
            disabled={isCampaignReadOnly}
          >
            {dealName || (isDirectSalesCampaignCloned(dealId) ? originalCampaignId : dealId) || 'New campaign'}
          </button>
          <ChipBookingStatus status={bookingStatus} />
        </>
      )}

      <div className="flex items-center justify-center gap-1">
        {!!bookingStatusCode && isCampaignDrawerOpen && (
          <IconButton
            icon={copyIcon}
            name="Duplicate campaign"
            handleOnClick={() => {
              handleDuplicateDeal(dealId);
            }}
            isDisabled={isLoading || bookingStatusCode === DealStatus.FAILED}
          />
        )}

        {showSaveButton && (
          <IconButton
            icon={saveIcon}
            name="Save campaign data"
            handleOnClick={handleSave}
            isDisabled={isSaveDisabled}
            dataPendoId={getPendoDataId('Top Save Campaign')}
          />
        )}

        {isDirectSalesCampaignType && (
          <IconButton
            icon={copyIcon}
            name="Duplicate campaign"
            handleOnClick={openCampaignDuplicationModal}
            isDisabled={!dealId}
          />
        )}

        <ShareCampaignPlan isDisabled={!isShareCampaignPlanButtonEnabled} />

        <IconButton
          icon={sidebarSimpleLeftIcon}
          name="Toggle campaign drawer"
          handleOnClick={handleToggleCampaignDrawer}
        />
      </div>
      <AdvertiserInfoConfirmationModal
        isVisible={showAdvertiserInfoConfirmationModal}
        onUndoChanges={handleUndoChanges}
        onConfirm={handleConfirmChanges}
      />
      {isDirectSalesCampaignType && (
        <CampaignDuplicateModal
          isOpen={isCampaignDuplicationModalOpen}
          onClose={closeCampaignDuplicationModal}
          dealLineStates={linesStates}
        />
      )}
    </div>
  );
};
