import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Label } from 'lib/Label';
import { hideSecondaryPanel } from 'store/productManagement/reducer';
import { Store } from 'components/common/types/Store.types';
import { SelectionPermission } from 'store/productManagement/reducer.types';
import { DirectSalesPricingMode } from 'components/common/types/DirectSalesCampaign.types';
import PlannerSecondaryPanel from 'components/pages/Planner/PlannerSecondaryPanel';
import SectionDivider from 'components/pages/Planner/SectionDivider';
import { requiredFieldPermissionOptions } from 'components/pages/PCM/Products/consts';
import PricingModeOptions from 'components/common/PricingMode/PricingModeOptions/PricingModeOptions';
import SelectionPermissionDropdown from 'components/pages/PCM/Products/ProductFields/SelectionPermissionDropdown/SelectionPermissionDropdown';
import { isValueRequired } from 'components/pages/PCM/Products/utils/isValueRequired';
import { isPanelConfirmDisabled } from '../../utils/isPanelConfirmDisabled';
import { ProductPricingModePanelProps } from './ProductPricingModePanel.types';
import { secondaryPanelLayoutStyles } from '../../styles';

const ProductPricingModePanel: React.FC<ProductPricingModePanelProps> = ({ onConfirm, isReadOnly }) => {
  const dispatch = useDispatch();
  const pricingMode = useSelector((state: Store) => state.productManagement.newProduct.pricingMode);
  const isNetworkPricingMode = pricingMode?.value === DirectSalesPricingMode.NETWORK;

  const [newPricingMode, setNewPricingMode] = useState<DirectSalesPricingMode | undefined>(pricingMode?.value);
  const defaultSelectedPermission =
    requiredFieldPermissionOptions.find(({ code }) => code === pricingMode?.selectionPermission) ??
    requiredFieldPermissionOptions[0];
  const [selectedPermission, setSelectedPermission] = useState<{
    code: SelectionPermission;
    name: string;
  }>(defaultSelectedPermission);

  return (
    <PlannerSecondaryPanel
      title="Pricing mode"
      onSubmit={() =>
        onConfirm({
          pricingMode: isValueRequired(selectedPermission.code) ? newPricingMode : undefined,
          selectedPermission,
        })
      }
      onCancel={() => dispatch(hideSecondaryPanel())}
      isDisabled={isPanelConfirmDisabled({ field: pricingMode, selectedPermission, newFieldValue: newPricingMode })}
      customStyles={secondaryPanelLayoutStyles}
    >
      <SelectionPermissionDropdown
        selectedPermission={selectedPermission}
        setSelectedPermission={setSelectedPermission}
        isReadOnly={isReadOnly}
        permissionOptions={requiredFieldPermissionOptions}
      />

      {isValueRequired(selectedPermission.code) ? (
        <div className="flex flex-col space-y-2 mt-4">
          <div className="my-2">
            <SectionDivider />
          </div>

          <Label label="Select pricing mode" />

          <PricingModeOptions
            newPricingMode={newPricingMode}
            isNetworkPricingMode={isNetworkPricingMode}
            setNewPricingMode={setNewPricingMode}
          />
        </div>
      ) : null}
    </PlannerSecondaryPanel>
  );
};

export default ProductPricingModePanel;
