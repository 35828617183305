import { ProductCreationInterface } from '../ProductsTable/ProductsTable.types';

export const searchProductNameOrId = ({
  products,
  searchTerm,
}: {
  products: ProductCreationInterface[];
  searchTerm: string;
}): ProductCreationInterface[] => {
  const normalizedSearchTerm = searchTerm.toLowerCase();

  return products.filter(
    ({ productName, id }) =>
      productName.toLowerCase().includes(normalizedSearchTerm) || id.toLowerCase().includes(normalizedSearchTerm),
  );
};
