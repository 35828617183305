import { useState } from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import { useDispatch, useSelector } from 'react-redux';

import closeSquareSvg from 'assets/icons/close_square.svg';
import binSvg from 'assets/icons/bin.svg';
import rubberSvg from 'assets/icons/rubber.svg';
import plusSvg from 'assets/icons/plus.svg';
import copyIcon from 'assets/icons/copy.svg';
import saveSvg from 'assets/icons/save.svg';

import { DealStatus } from 'components/common/types/Deal.types';
import Modal from 'components/patterns/Modal';
import {
  getActionConditions,
  hasLineStarted,
  hasLineEnded,
} from 'components/pages/DealWithLines/common/getActionConditions';
import Button, { ButtonShape, ButtonType } from 'components/patterns/Button';

import { clearForm, createNewLine, duplicateLine, resetForm } from 'store/dealManagement/reducer';
import { getIsAdServer, getIsReseller } from 'store/publisher/selectors';
import { getIsAnyLineFetchingAvailability } from 'store/dealManagement/selectors';

import TerminateDialog from './TerminateDialog';
import LiveLineDialog from './LiveLineDialog';
import IconButton from '../IconButton';

const getIsLiveLine = (line, bookingStatusCode) =>
  hasLineStarted(line) && !hasLineEnded(line) && bookingStatusCode === DealStatus.LIVE;

const shouldRenderButton = (condition, element) => (condition ? element : null);

const LineActions = ({ createLine, updateLine, updateLiveLine, deleteLine, terminateLine }) => {
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showTerminateModal, setShowTerminateModal] = useState(false);
  const [showLiveLineModal, setShowLiveLineModal] = useState(false);
  const dealManagementState = useSelector((state) => state.dealManagement);
  const {
    commonDeal: { currentLine, bookingStatusCode },
    backupFormData: { lines },
  } = dealManagementState;
  const isAdServerMarket = useSelector(getIsAdServer);
  const dealConfig = useSelector((state) => state.dealConfig);
  const isReseller = useSelector(getIsReseller);
  const isFetchingAvailability = useSelector(getIsAnyLineFetchingAvailability);
  const actionConditions = getActionConditions(
    dealManagementState,
    isAdServerMarket,
    dealConfig,
    isReseller,
    isFetchingAvailability,
  );

  const handleTerminateLine = () => {
    setShowTerminateModal(false);
    terminateLine();
  };

  const handleDeleteLine = () => {
    setShowDeleteModal(false);
    deleteLine();
  };

  const handleSaveLine = () => {
    const backupLine = lines.find(({ id }) => id === currentLine.id);

    if (getIsLiveLine(backupLine, bookingStatusCode) && !isAdServerMarket) {
      setShowLiveLineModal(true);
      return;
    }

    if (currentLine.id) {
      updateLine();
      return;
    }

    createLine();
  };

  const handleUpdateLiveLine = () => {
    setShowLiveLineModal(false);
    updateLiveLine();
  };

  const lineDesc = isAdServerMarket ? currentLine.name : `line ${currentLine.sortOrderNumber}`;

  return (
    <>
      {actionConditions.terminateLine.show || actionConditions.deleteLine.show ? (
        <div className="px-6 flex items-center">
          {shouldRenderButton(
            actionConditions.terminateLine.show,
            <IconButton
              icon={closeSquareSvg}
              dataTestId="terminate-line-button"
              tooltipText="Terminate line"
              disabled={actionConditions.terminateLine.disabled}
              onClick={() => setShowTerminateModal(true)}
            />,
          )}
          {shouldRenderButton(
            actionConditions.deleteLine.show,
            <IconButton
              dataTestId="delete-line-button"
              icon={binSvg}
              tooltipText="Delete line"
              disabled={actionConditions.deleteLine.disabled}
              onClick={() => setShowDeleteModal(true)}
            />,
          )}
        </div>
      ) : null}
      <div className="px-6 space-x-3 flex items-center">
        {shouldRenderButton(
          actionConditions.clearForm.show,
          <IconButton
            dataTestId="clear-form-button"
            icon={rubberSvg}
            tooltipText="Clear form"
            disabled={actionConditions.clearForm.disabled}
            onClick={() => dispatch(clearForm())}
          />,
        )}
        {shouldRenderButton(
          actionConditions.resetForm.show,
          <IconButton
            dataTestId="reset-form-button"
            icon={rubberSvg}
            tooltipText="Reset form"
            disabled={actionConditions.resetForm.disabled}
            onClick={() => dispatch(resetForm())}
          />,
        )}
        {shouldRenderButton(
          actionConditions.createLine.show,
          <IconButton
            dataTestId="create-line-button"
            icon={plusSvg}
            tooltipText="Create line"
            disabled={actionConditions.createLine.disabled}
            onClick={() => dispatch(createNewLine())}
          />,
        )}
        {shouldRenderButton(
          actionConditions.duplicateLine.show,
          <IconButton
            dataTestId="duplicate-line-button"
            icon={copyIcon}
            tooltipText={`Duplicate line ${currentLine.sortOrderNumber}`}
            disabled={actionConditions.duplicateLine.disabled}
            onClick={() => dispatch(duplicateLine(currentLine.id))}
          />,
        )}
        {shouldRenderButton(
          actionConditions.saveLine.show,
          <IconButton
            dataTestId="line-save-button"
            icon={saveSvg}
            tooltipText="Save line"
            disabled={actionConditions.saveLine.disabled}
            onClick={handleSaveLine}
          />,
        )}
      </div>
      <TerminateDialog
        showTerminateModal={showTerminateModal}
        setShowTerminateModal={setShowTerminateModal}
        handleTerminateLine={handleTerminateLine}
      />
      <LiveLineDialog
        showLiveLineModal={showLiveLineModal}
        setShowLiveLineModal={setShowLiveLineModal}
        handleUpdateLiveLine={handleUpdateLiveLine}
      />
      <Modal
        isOpen={showDeleteModal}
        icon={<SVG src={binSvg} className="text-pinkRed-500" />}
        title={`Delete ${lineDesc}?`}
        actionButtons={
          <>
            <Button
              btnType={ButtonType.PRIMARY}
              btnShape={ButtonShape.NORMAL}
              onClick={() => setShowDeleteModal(false)}
            >
              Cancel
            </Button>
            <Button btnType={ButtonType.DANGER} btnShape={ButtonShape.NORMAL} onClick={handleDeleteLine}>
              Delete
            </Button>
          </>
        }
      >
        <div className="ml-8 body-base text-neutral-950-opacity-60">
          <p className="mb-2">
            Deleting <strong>{lineDesc}</strong> will remove it permanently and you will be unable to undo this change.
          </p>
          <p>
            Are you sure you want to delete <strong>{lineDesc}</strong>?
          </p>
        </div>
      </Modal>
    </>
  );
};

LineActions.propTypes = {
  createLine: PropTypes.func,
  updateLine: PropTypes.func,
  updateLiveLine: PropTypes.func,
  deleteLine: PropTypes.func,
  terminateLine: PropTypes.func,
};

LineActions.defaultProps = {
  createLine: () => {},
  updateLine: () => {},
  updateLiveLine: () => {},
  deleteLine: () => {},
  terminateLine: () => {},
};

export default LineActions;
