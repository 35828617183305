import { URLS, HTTP_METHODS, HEADERS_CONTENT_TYPE } from 'modules/api/constants';
import handleRequest from 'modules/api/common';
import { CancelFunctions } from 'components/common/types';
import {
  DirectSalesFilteredCampaignsRequestResponse,
  GetFiltersRequestData,
} from 'components/common/types/DirectSalesCampaign.types';
import { DirectSalesDealManagementFilters } from 'components/pages/Deals/DirectSalesDeals/Filters/Filters.types';
import { endOfDay, format, startOfDay } from 'date-fns';

const getFiltersRequestData = (
  publisherId: string,
  filters?: DirectSalesDealManagementFilters,
): GetFiltersRequestData => {
  const filtersRequestData: GetFiltersRequestData['search'] = [
    {
      field: 'PUBLISHER',
      operation: 'EQUAL',
      value: publisherId,
    },
  ];

  if (filters?.startDate) {
    filtersRequestData.push({
      field: 'START_DATE',
      operation: 'GREATER_OR_EQUAL',
      value: startOfDay(filters.startDate),
    });
  }

  if (filters?.endDate) {
    filtersRequestData.push({
      field: 'END_DATE',
      operation: 'LESS_OR_EQUAL',
      value: endOfDay(filters.endDate),
    });
  }

  if (filters?.advertiser) {
    filtersRequestData.push({
      field: 'ADVERTISER',
      operation: 'EQUAL',
      value: filters.advertiser.name,
    });
  }

  if (filters?.brand) {
    filtersRequestData.push({
      field: 'BRAND',
      operation: 'EQUAL',
      value: filters.brand.name,
    });
  }

  if (filters?.productCategory) {
    filtersRequestData.push({
      field: 'PRODUCT_CATEGORY',
      operation: 'EQUAL',
      value: filters.productCategory.name,
    });
  }

  if (filters?.specialist) {
    filtersRequestData.push({
      field: 'SPECIALIST',
      operation: 'EQUAL',
      value: filters.specialist.name,
    });
  }

  if (filters?.agency) {
    filtersRequestData.push({
      field: 'AGENCY',
      operation: 'EQUAL',
      value: filters.agency.name,
    });
  }

  if (filters?.createdAt) {
    filtersRequestData.push({
      field: 'CREATED_AT',
      operation: 'EQUAL',
      value: format(filters.createdAt, 'yyyy-MM-dd'),
    });
  }

  if (filters?.dropDate) {
    filtersRequestData.push({
      field: 'DROP_DATE',
      operation: 'EQUAL',
      value: format(filters.dropDate, 'yyyy-MM-dd'),
    });
  }

  if (filters?.updatedBy) {
    filtersRequestData.push({
      field: 'UPDATED_BY',
      operation: 'CONTAINS',
      value: filters.updatedBy,
    });
  }

  if (filters?.createdBy) {
    filtersRequestData.push({
      field: 'CREATED_BY',
      operation: 'CONTAINS',
      value: filters.createdBy,
    });
  }

  if (filters?.campaignName) {
    filtersRequestData.push({
      field: 'CAMPAIGN_NAME',
      operation: 'CONTAINS',
      value: filters.campaignName,
    });
  }

  if (filters?.campaignReference) {
    filtersRequestData.push({
      field: 'REFERENCE',
      operation: 'CONTAINS',
      value: filters.campaignReference,
    });
  }

  if (filters?.campaignStatus) {
    filtersRequestData.push({
      field: 'CAMPAIGN_STATUS',
      operation: 'EQUAL',
      value: [filters.campaignStatus],
    });
  }

  return {
    search: filtersRequestData,
  };
};

export const getDirectSalesFilteredCampaignsTable = ({
  externalPublisherId,
  cancelFunctions,
  filters,
  page,
  size,
}: {
  externalPublisherId: string;
  cancelFunctions: CancelFunctions;
  page: number;
  size: number;
  filters?: DirectSalesDealManagementFilters;
}): Promise<DirectSalesFilteredCampaignsRequestResponse> => {
  const requestData = getFiltersRequestData(externalPublisherId, filters);

  const query = `page=${page}&size=${size}`;

  const reqOptions = {
    url: URLS().FETCH_DIRECT_SALES_FILTERED_CAMPAIGNS_TABLE(externalPublisherId, query),
    method: HTTP_METHODS.POST,
    headers: {
      'Content-Type': HEADERS_CONTENT_TYPE.APPLICATION_JSON,
    },
    showLoader: true,
    data: requestData,
  };

  return handleRequest(reqOptions, cancelFunctions, 'FETCH_DIRECT_SALES_FILTERED_CAMPAIGNS_TABLE');
};
