import { TargetLabel } from 'consts/targetLabel';
import { NetworkCriteriaType } from 'components/common/types/DirectSalesNetworks.types';
import { UseNetworkTarget } from 'components/common/types/UseNetworkTarget.types';
import { UseTarget } from '../AllocationDirectSalesContent/Targets/TargetsPanel/TargetsPanel.types';

export enum TargetType {
  BUDGET = 'budget',
  FRAMES = 'frames',
  IMPRESSIONS = 'impressions',
  NETWORK = 'networks',
}

export interface TargetSectionProps {
  label?: TargetLabel;
  hasBorder?: boolean;
  target: UseTarget | UseNetworkTarget;
  targetType: TargetType;
  icon?: string;
  currentOption?: NetworkCriteriaType;
  isTargetValueRequired?: boolean;
}
