import React from 'react';
import { DealStatus } from 'components/common/types/Deal.types';
import { useSelector } from 'react-redux';
import { Store } from 'components/common/types/Store.types';
import { ReportTypeButton, ProgrammaticReportTypeButtonsProps } from './ReportTypeButtons.types';
import { ReportType } from '../AllocationReport.types';
import { ReportTypeButtons } from './ReportTypeButtons';

export const ProgrammaticReportTypeButtons: React.FC<ProgrammaticReportTypeButtonsProps> = ({
  activeReportType,
  handleFetchAllocationReport,
  selectedLine,
}) => {
  const currentLineId = useSelector((state: Store) => state.dealManagement.commonDeal.currentLine.lineId);
  const proposalCampaignId = useSelector((state: Store) => state.dealManagement.temporaryDealId);

  const isNewLine = !selectedLine.lineId;
  const isSelectedLineWithNewProposal = selectedLine.lineId === currentLineId && !!proposalCampaignId;

  const showNewProposalButton = isNewLine || isSelectedLineWithNewProposal;
  const showProposalButton =
    !isNewLine &&
    !!selectedLine.lineStatus &&
    [DealStatus.PENDING_APPROVAL, DealStatus.CANCELLED].includes(selectedLine.lineStatus as DealStatus);
  const showAllocationButton =
    !isNewLine &&
    !!selectedLine.lineStatus &&
    [DealStatus.APPROVED, DealStatus.LIVE, DealStatus.TERMINATED, DealStatus.ENDED].includes(
      selectedLine.lineStatus as DealStatus,
    );
  const allowButtonClick = showNewProposalButton && (showProposalButton || showAllocationButton);

  const reportTypeButtons: ReportTypeButton[] = [
    {
      id: 'new-proposal',
      isActive: activeReportType === ReportType.NOT_SAVED,
      isOnClickEnabled: allowButtonClick && activeReportType !== ReportType.NOT_SAVED,
      isVisible: showNewProposalButton,
      label: 'New Proposal',
      onClick: () => handleFetchAllocationReport(ReportType.NOT_SAVED),
    },
    {
      id: 'proposal',
      isActive: activeReportType === ReportType.SAVED,
      isOnClickEnabled: allowButtonClick && activeReportType !== ReportType.SAVED,
      isVisible: showProposalButton,
      label: 'Proposal',
      onClick: () => handleFetchAllocationReport(ReportType.SAVED),
    },
    {
      id: 'allocation',
      isActive: activeReportType === ReportType.SAVED,
      isOnClickEnabled: allowButtonClick && activeReportType !== ReportType.SAVED,
      isVisible: showAllocationButton,
      label: 'Allocation',
      onClick: () => handleFetchAllocationReport(ReportType.SAVED),
    },
  ];

  return <ReportTypeButtons reportTypeButtons={reportTypeButtons} />;
};
