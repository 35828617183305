import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { Store } from 'components/common/types/Store.types';
import { isDirectSalesCampaignCloned } from 'components/pages/Planner/hooks/utils/isDirectSalesCampaignCloned';
import { ReportTypeButton, DirectSalesReportTypeButtonsProps } from './ReportTypeButtons.types';
import { ReportType } from '../AllocationReport.types';
import { ReportTypeButtons } from './ReportTypeButtons';

export const DirectSalesReportTypeButtons: React.FC<DirectSalesReportTypeButtonsProps> = ({
  activeReportType,
  handleFetchDirectSalesAllocationReport,
  selectedLine,
}) => {
  const campaignId = useSelector((state: Store) => state.dealManagement.commonDeal.dealId);
  const transientLineStates = useSelector((state: Store) => state.dealManagement.directSales.transientLineStates);

  const isCampaignCloned = isDirectSalesCampaignCloned(campaignId);
  const isSelectedLineInTransientState =
    !isEmpty(transientLineStates) && Object.keys(transientLineStates).includes(selectedLine.lineId);
  const showExistingSavedAllocationButton = isCampaignCloned && isSelectedLineInTransientState;

  const reportTypeButtons: ReportTypeButton[] = useMemo(
    () => [
      {
        id: 'allocation',
        isActive:
          !showExistingSavedAllocationButton ||
          (showExistingSavedAllocationButton && activeReportType === ReportType.NOT_SAVED),
        isOnClickEnabled: showExistingSavedAllocationButton && activeReportType !== ReportType.NOT_SAVED,
        isVisible: true,
        label: 'Allocation',
        onClick: () =>
          handleFetchDirectSalesAllocationReport(
            showExistingSavedAllocationButton ? ReportType.NOT_SAVED : ReportType.SAVED,
          ),
      },
      {
        id: 'existing-allocation',
        isActive: activeReportType === ReportType.SAVED,
        isOnClickEnabled: activeReportType !== ReportType.SAVED,
        isVisible: showExistingSavedAllocationButton,
        label: 'Existing Saved Allocation',
        onClick: () => handleFetchDirectSalesAllocationReport(ReportType.SAVED),
      },
    ],
    [activeReportType, showExistingSavedAllocationButton],
  );

  return <ReportTypeButtons reportTypeButtons={reportTypeButtons} />;
};
