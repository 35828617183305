import cx from 'classnames';
import { FrontEndType } from 'components/common/types/Deal.types';
import { useDealAndLineActions } from 'components/pages/DealWithLines/AdsDealLines/hooks/useDealAndLineActions';
import useProgrammaticPlannerActions from 'components/pages/Planner/hooks/useProgrammaticPlannerActions';
import useCampaignType from 'components/pages/Planner/hooks/useCampaignType';
import { Color } from 'lib/Button';
import { useSelector } from 'react-redux';
import { Store } from 'components/common/types/Store.types';
import { CampaignPlannerMode } from 'store/dealManagement/reducer';
import Overview from './Overview';
import CampaignSettings from './CampaignSettings';
import Details from './Details';
import SaveCampaignButton from './SaveCampaignButton';
import ExclusionCriteria from './ExclusionCriteria/ExclusionCriteria';

interface CampaignDrawerProps {
  campaignView: FrontEndType;
}

export const CampaignDrawer: React.FC<CampaignDrawerProps> = ({ campaignView }) => {
  const isOverviewMode =
    useSelector((state: Store) => state.dealManagement.campaignPlannerMode) === CampaignPlannerMode.OVERVIEW;

  const { saveDeal: saveDealProgrammaticDealForm } = useDealAndLineActions();
  const { saveDeal: saveDealProgrammaticPlanner } = useProgrammaticPlannerActions();

  const { isProgrammaticCampaignType, isDirectSalesCampaignType } = useCampaignType();

  return (
    <div
      className={cx(
        'w-[var(--campaign-drawer-width)] shrink-0 bg-white h-full border-x border-neutral-950-opacity-10 flex flex-col overflow-auto',
        { 'pb-[var(--overview-mode-footer-height)]': isOverviewMode },
      )}
      data-test-id="campaign-drawer-open"
    >
      <Overview
        saveDeal={campaignView === FrontEndType.STANDARD ? saveDealProgrammaticDealForm : saveDealProgrammaticPlanner}
      />
      <Details />
      {isDirectSalesCampaignType && <ExclusionCriteria />}
      {isProgrammaticCampaignType && <CampaignSettings showLineCPMSwitch={campaignView === FrontEndType.STANDARD} />}
      <SaveCampaignButton color={isProgrammaticCampaignType ? Color.TRANSPARENT : Color.PRIMARY} />
    </div>
  );
};
