import SVG from 'react-inlinesvg';
import excludeSvg from 'assets/icons/exclude.svg';
import includeSvg from 'assets/icons/include.svg';

import { CollapsibleListStatsHeaderProps, CollapsibleListStatsStyle } from './CollapsibleListStatsHeader.types';

const CollapsibleListStatsHeader: React.FC<CollapsibleListStatsHeaderProps> = ({
  excluded,
  included,
  available = 0,
  availableLengthSuffix = <p>results</p>,
  isDisabled = false,
  includedStyle = CollapsibleListStatsStyle.GREEN,
  excludedStyle = CollapsibleListStatsStyle.PINK_RED,
}) => {
  return (
    <div data-test-id="collapsible-list-header" className="flex flex-1 gap-1 justify-start p-2">
      <div
        data-test-id="collapsible-list-header-available"
        className={isDisabled ? CollapsibleListStatsStyle.NEUTRAL : CollapsibleListStatsStyle.PRIMARY}
      >
        {available} {availableLengthSuffix}
      </div>

      {excluded ? (
        <div data-test-id="collapsible-list-header-excluded" className={excludedStyle}>
          <SVG src={excludeSvg} className="w-4 h-4" /> {excluded}
        </div>
      ) : null}
      {included ? (
        <div data-test-id="collapsible-list-header-included" className={includedStyle}>
          <SVG src={includeSvg} className="w-4 h-4" /> {included}
        </div>
      ) : null}
    </div>
  );
};

export default CollapsibleListStatsHeader;
