import React from 'react';
import PricingMode from 'components/common/PricingMode';
import { Store } from 'components/common/types/Store.types';
import { useDispatch, useSelector } from 'react-redux';
import { PlannerSecondaryPanelType } from 'store/dealManagement/reducer';
import { ProductFieldName } from 'store/productManagement/reducer.types';
import { changeNewProduct, hideSecondaryPanel, clearItemInNewProduct } from 'store/productManagement/reducer';
import { useOnChangePanelVisibility } from 'components/pages/PCM/hooks/useOnChangePanelVisibility';
import { DIRECT_SALES_PRICING_MODES } from 'components/common/types/DirectSalesCampaign.types';
import { isValueRequired } from 'components/pages/PCM/Products/utils/isValueRequired';
import { getNoRestrictionsDisplayValue } from 'components/pages/PCM/Products/utils/getNoRestrictionsDisplayValue';
import ProductPricingModePanel from './ProductPricingModePanel';

const fieldName = ProductFieldName.PRICING_MODE;

const ProductPricingMode: React.FC = () => {
  const dispatch = useDispatch();
  const secondaryPanelType = useSelector((state: Store) => state.productManagement.secondaryPanel);
  const selectedPricingMode = useSelector((state: Store) => state.productManagement.newProduct.pricingMode?.value);
  const selectedNetworksPermission = useSelector(
    (state: Store) => state.productManagement.newProduct.networks?.selectionPermission,
  );
  const isNetworksPricing = isValueRequired(selectedNetworksPermission);
  const pricingModeSelectedPermission = useSelector(
    (state: Store) => state.productManagement.newProduct.pricingMode?.selectionPermission,
  );
  const onChangePanelVisibility = useOnChangePanelVisibility();

  const isPricingModeOpen = secondaryPanelType === PlannerSecondaryPanelType.PRICING_MODE;
  const isReadOnly = false;

  const sectionButtonDisplayValue =
    isValueRequired(pricingModeSelectedPermission) && selectedPricingMode
      ? [DIRECT_SALES_PRICING_MODES[selectedPricingMode]]
      : getNoRestrictionsDisplayValue(pricingModeSelectedPermission);

  return (
    <PricingMode
      isActive={isPricingModeOpen}
      onClear={() => {
        dispatch(clearItemInNewProduct({ fieldName }));
        dispatch(hideSecondaryPanel());
      }}
      isClearAllDisabled={isNetworksPricing}
      isReadOnly={isReadOnly}
      selectedValue={sectionButtonDisplayValue}
      onClick={() => onChangePanelVisibility(PlannerSecondaryPanelType.PRICING_MODE)}
      panel={
        <ProductPricingModePanel
          onConfirm={({ pricingMode, selectedPermission }) => {
            dispatch(changeNewProduct({ fieldName, value: pricingMode, selectedPermission }));
            dispatch(hideSecondaryPanel());
          }}
          isReadOnly={isReadOnly}
        />
      }
      isRequired
    />
  );
};

export default ProductPricingMode;
