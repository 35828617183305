import SVG from 'react-inlinesvg';
import diamondImage from 'assets/img/diamond.png';
import linkedinSvg from 'assets/icons/linkedin.svg';
import Button, { Color, Size } from 'lib/Button';
import * as VioohURL from 'consts/viooh';

export const Footer: React.FC = () => {
  const onLearnMoreButtonClick = (): void => {
    window.open(VioohURL.VIOOH_WEBSITE_ABOUT, '_blank');
  };

  const onContactUsButtonClick = (): void => {
    window.open(VioohURL.VIOOH_WEBSITE_CONTACT, '_blank');
  };

  const onLinkedInButtonClick = (): void => {
    window.open(VioohURL.VIOOH_LINKEDIN_PROFILE, '_blank');
  };

  return (
    <footer className="p-6 relative mt-auto" data-test-id="public-campaign-plan-footer">
      <div className="md:px-12 pb-12 pt-16 space-y-16 flex flex-col justify-between items-center bg-neutral-200 rounded-2xl">
        <div className="max-w-7xl mx-auto flex flex-col md:flex-row space-y-12 md:space-y-0 w-full px-7 pb-16">
          <div className="flex flex-col space-y-4 w-full md:w-3/5">
            <div className="self-start">
              <img className="mx-auto" src={diamondImage} alt="VIOOH" width="24px" />
            </div>
            <div className="header-3xl text-purple-700">Transforming OOH for media owners and buyers</div>
            <div className="flex flex-col flex-grow justify-between space-y-7 header-xl text-primary-900 font-normal">
              <p>
                VIOOH Trading Manager boosts programmatic revenue for OOH inventory owners and connects them with our
                powerful network of DSP partners.
              </p>
              <p>
                Explore our innovative solutions to maximise your digital strategy.{' '}
                <a href={VioohURL.VIOOH_WEBSITE_HOME} rel="noreferrer" target="_blank" className="underline">
                  Visit our website
                </a>{' '}
                to learn more.
              </p>
            </div>
          </div>
          <div className="flex flex-col w-2/5">
            <div className="flex flex-row md:justify-end space-x-3 w-full">
              <Button
                onClick={onLearnMoreButtonClick}
                label="Learn more"
                color={Color.BLACK_AND_WHITE}
                classNames="text-primary-600"
              />
              <Button onClick={onContactUsButtonClick} label="Contact us" color={Color.PURPLE_LIGHT} />
            </div>
          </div>
        </div>
        <div className="max-w-7xl mx-auto flex flex-col md:flex-row items-start md:items-center md:justify-between w-full px-7 pt-8 body-lg text-neutral-600 border-t border-neutral-950-opacity-10">
          <p>© 2025 VIOOH. All rights reserved.</p>
          <Button onClick={onLinkedInButtonClick} color={Color.TRANSPARENT} size={Size.EXTRA_SMALL}>
            <SVG src={linkedinSvg} />
          </Button>
        </div>
      </div>
    </footer>
  );
};
