import { PricingModeProps } from './PricingMode.types';
import PricingModeButton from './PricingModeButton/PricingModeButton';

const PricingMode: React.FC<PricingModeProps> = ({
  isActive,
  onClear,
  panel,
  onClick,
  selectedValue,
  isReadOnly,
  isRequired = false,
  isClearAllDisabled = false,
  onShowMoreMenuClick,
}) => {
  return (
    <>
      <PricingModeButton
        isActive={isActive}
        onClear={onClear}
        onClick={onClick}
        selectedValue={selectedValue}
        isReadOnly={isReadOnly}
        isRequired={isRequired}
        isClearAllDisabled={isClearAllDisabled}
        onShowMoreMenuClick={onShowMoreMenuClick}
      />
      {isActive && panel}
    </>
  );
};

export default PricingMode;
