import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideSecondaryPanel } from 'store/productManagement/reducer';
import { SelectionPermission } from 'store/productManagement/reducer.types';
import { DirectSalesCodeNameModel } from 'components/common/types/DirectSalesCampaign.types';
import PlannerSecondaryPanel from 'components/pages/Planner/PlannerSecondaryPanel';
import { Store } from 'components/common/types/Store.types';
import SectionDivider from 'components/pages/Planner/SectionDivider';
import TargetSection from 'components/pages/Planner/PlannerSections/TargetSection';
import { TargetType } from 'components/pages/Planner/PlannerSections/TargetSection/TargetSection.types';
import { UseTarget } from 'components/pages/Planner/PlannerSections/AllocationDirectSalesContent/Targets/TargetsPanel/TargetsPanel.types';
import { TargetLabel } from 'consts/targetLabel';
import { permissionOptions } from '../../consts';
import { isValueRequired } from '../../utils/isValueRequired';
import SelectionPermissionDropdown from '../SelectionPermissionDropdown/SelectionPermissionDropdown';
import { ProductTargetsPanelProps } from './ProductTargetsPanel.types';
import { isSaveDisabled } from './utils';
import { secondaryPanelLayoutStyles } from '../../styles';

const ProductTargetsPanel: React.FC<ProductTargetsPanelProps> = ({ onConfirm }) => {
  const dispatch = useDispatch();
  const budget = useSelector((state: Store) => state.productManagement.newProduct?.budget);
  const frames = useSelector((state: Store) => state.productManagement.newProduct?.frames);
  const impressions = useSelector((state: Store) => state.productManagement.newProduct?.impressions);

  const defaultSelectedPriority = {
    code: '1',
    name: '1',
  };

  const defaultSelectedBudgetPermission =
    permissionOptions.find(({ code }) => code === budget?.selectionPermission) ?? permissionOptions[0];
  const defaultSelectedFramesPermission =
    permissionOptions.find(({ code }) => code === frames?.selectionPermission) ?? permissionOptions[0];
  const defaultSelectedImpressionsPermission =
    permissionOptions.find(({ code }) => code === impressions?.selectionPermission) ?? permissionOptions[0];

  const [selectedBudgetPermission, setSelectedBudgetPermission] = useState<{
    code: SelectionPermission;
    name: string;
  }>(defaultSelectedBudgetPermission);

  const [budgetValue, setBudgetValue] = useState<number | undefined>(budget?.value);
  const [budgetGreenTolerance, setBudgetGreenTolerance] = useState<number | undefined>(budget?.greenTolerance ?? 5);
  const [budgetOrangeTolerance, setBudgetOrangeTolerance] = useState<number | undefined>(budget?.orangeTolerance ?? 10);
  const [budgetPriority, setBudgetPriority] = useState<DirectSalesCodeNameModel | undefined>(
    budget?.priority ?? defaultSelectedPriority,
  );

  const [selectedFramesPermission, setSelectedFramesPermission] = useState<{
    code: SelectionPermission;
    name: string;
  }>(defaultSelectedFramesPermission);

  const [framesValue, setFramesValue] = useState<number | undefined>(frames?.value);
  const [framesGreenTolerance, setFramesGreenTolerance] = useState<number | undefined>(frames?.greenTolerance ?? 5);
  const [framesOrangeTolerance, setFramesOrangeTolerance] = useState<number | undefined>(frames?.orangeTolerance ?? 10);
  const [framesPriority, setFramesPriority] = useState<DirectSalesCodeNameModel | undefined>(
    frames?.priority ?? defaultSelectedPriority,
  );

  const [selectedImpressionsPermission, setSelectedImpressionsPermission] = useState<{
    code: SelectionPermission;
    name: string;
  }>(defaultSelectedImpressionsPermission);

  const [impressionsValue, setImpressionsValue] = useState<number | undefined>(impressions?.value);
  const [impressionsGreenTolerance, setImpressionsGreenTolerance] = useState<number | undefined>(
    impressions?.greenTolerance ?? 5,
  );
  const [impressionsOrangeTolerance, setImpressionsOrangeTolerance] = useState<number | undefined>(
    impressions?.orangeTolerance ?? 10,
  );
  const [impressionsPriority, setImpressionsPriority] = useState<DirectSalesCodeNameModel | undefined>(
    impressions?.priority ?? defaultSelectedPriority,
  );

  const budgetTarget = {
    value: budgetValue,
    priority: budgetPriority,
    greenTolerance: budgetGreenTolerance,
    orangeTolerance: budgetOrangeTolerance,
    handleValueChange: setBudgetValue,
    handlePriorityChange: setBudgetPriority,
    handleGreenToleranceChange: setBudgetGreenTolerance,
    handleOrangeToleranceChange: setBudgetOrangeTolerance,
    isToleranceError: false,
    isChange: false,
  } as UseTarget;

  const framesTarget = {
    value: framesValue,
    priority: framesPriority,
    greenTolerance: framesGreenTolerance,
    orangeTolerance: framesOrangeTolerance,
    handleValueChange: setFramesValue,
    handlePriorityChange: setFramesPriority,
    handleGreenToleranceChange: setFramesGreenTolerance,
    handleOrangeToleranceChange: setFramesOrangeTolerance,
    isToleranceError: false,
    isChange: false,
  } as UseTarget;

  const impressionsTarget = {
    value: impressionsValue,
    priority: impressionsPriority,
    greenTolerance: impressionsGreenTolerance,
    orangeTolerance: impressionsOrangeTolerance,
    handleValueChange: setImpressionsValue,
    handlePriorityChange: setImpressionsPriority,
    handleGreenToleranceChange: setImpressionsGreenTolerance,
    handleOrangeToleranceChange: setImpressionsOrangeTolerance,
    isToleranceError: false,
    isChange: false,
  } as UseTarget;

  return (
    <PlannerSecondaryPanel
      title="Target"
      onSubmit={() =>
        onConfirm({
          budget:
            selectedBudgetPermission.code === SelectionPermission.FIXED_VALUE
              ? {
                  selectionPermission: selectedBudgetPermission.code,
                  value: budgetValue,
                  priority: budgetPriority,
                  greenTolerance: budgetGreenTolerance,
                  orangeTolerance: budgetOrangeTolerance,
                }
              : {
                  selectionPermission: selectedBudgetPermission.code,
                },
          impressions:
            selectedImpressionsPermission.code === SelectionPermission.FIXED_VALUE
              ? {
                  selectionPermission: selectedImpressionsPermission.code,
                  value: impressionsValue,
                  priority: impressionsPriority,
                  greenTolerance: impressionsGreenTolerance,
                  orangeTolerance: impressionsOrangeTolerance,
                }
              : { selectionPermission: selectedImpressionsPermission.code },
          frames:
            selectedFramesPermission.code === SelectionPermission.FIXED_VALUE
              ? {
                  selectionPermission: selectedFramesPermission.code,
                  value: framesValue,
                  priority: framesPriority,
                  greenTolerance: framesGreenTolerance,
                  orangeTolerance: framesOrangeTolerance,
                }
              : { selectionPermission: selectedFramesPermission.code },
        })
      }
      onCancel={() => dispatch(hideSecondaryPanel())}
      customStyles={secondaryPanelLayoutStyles}
      isDisabled={isSaveDisabled({
        budget: budgetValue,
        frames: framesValue,
        impressions: impressionsValue,
        selectedBudgetPermission: selectedBudgetPermission.code,
        selectedFramesPermission: selectedFramesPermission.code,
        selectedImpressionsPermission: selectedImpressionsPermission.code,
      })}
    >
      <div className="border border-solid border-neutral-300 p-2 mb-2 rounded-md">
        <SelectionPermissionDropdown
          selectedPermission={selectedBudgetPermission}
          setSelectedPermission={setSelectedBudgetPermission}
          permissionOptions={permissionOptions}
          label="Budget selection permissions"
          dataTestId="product-budget-selection-permission"
        />
        {isValueRequired(selectedBudgetPermission.code) && (
          <>
            <div className="my-4">
              <SectionDivider />
            </div>

            <TargetSection label={TargetLabel.BUDGET} target={budgetTarget} targetType={TargetType.BUDGET} />
          </>
        )}
      </div>
      <div className="border border-solid border-neutral-300 p-2 mb-2 rounded-md">
        <SelectionPermissionDropdown
          selectedPermission={selectedFramesPermission}
          setSelectedPermission={setSelectedFramesPermission}
          permissionOptions={permissionOptions}
          label="Frames selection permissions"
          dataTestId="product-frames-selection-permission"
        />
        {isValueRequired(selectedFramesPermission.code) && (
          <>
            <div className="my-4">
              <SectionDivider />
            </div>

            <TargetSection label={TargetLabel.FRAMES} target={framesTarget} targetType={TargetType.FRAMES} />
          </>
        )}
      </div>
      <div className="border border-solid border-neutral-300 p-2 mb-2 rounded-md">
        <SelectionPermissionDropdown
          selectedPermission={selectedImpressionsPermission}
          setSelectedPermission={setSelectedImpressionsPermission}
          permissionOptions={permissionOptions}
          label="Impressions selection permissions"
          dataTestId="product-impressions-selection-permission"
        />
        {isValueRequired(selectedImpressionsPermission.code) && (
          <>
            <div className="my-4">
              <SectionDivider />
            </div>

            <TargetSection
              label={TargetLabel.IMPRESSIONS}
              target={impressionsTarget}
              targetType={TargetType.IMPRESSIONS}
            />
          </>
        )}
      </div>
    </PlannerSecondaryPanel>
  );
};

export default ProductTargetsPanel;
