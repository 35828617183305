import { GridApi, ICellRendererParams, IDatasource } from 'ag-grid-community';
import NumberRenderer from 'components/common/NumberRenderer';
import StatusRenderer from 'components/common/StatusRenderer';
import { DealItemData } from 'components/common/types/DealItem';
import { Store } from 'components/common/types/Store.types';
import { ColumnDef } from 'components/common/types/Table.types';
import { BadgeSize } from 'components/patterns/Badge';
import PageSize from 'components/patterns/PageSize';
import Table, { TableHeight, TableRowHeight } from 'components/patterns/Table';
import { TooltipDirection } from 'components/patterns/Tooltip';
import { DealFilters } from 'components/common/types/Deal.types';
import { PerformanceFilters } from 'components/pages/Insights/Performance/PerformanceContent/Filters/Filters.types';
import { DEAL_STATUS_BADGE } from 'consts/deal';
import { PermissionsEnum } from 'consts/permissions';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import { usePageFilters } from 'customHooks/usePageFilters';
import { isEmpty } from 'lodash';
import Auth from 'modules/Auth';
import Session from 'modules/Session';
import { getDealsWithBids } from 'modules/api/deals';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isAdServer } from 'store/publisher/utils';
import { percentageCellRenderer } from 'utils/cellRenderer';
import { getDealTypeLabel } from 'utils/getDealTypeLabel';
import { getValidPageSize } from 'utils/pageSize';
import ActionRenderer from './ActionRenderer';
import BidsRenderer from './BidsRenderer';
import { DealsTableProps } from './DealsTable.types';
import ImpressionsRenderer from './ImpressionsRenderer';
import OutOfChargeRenderer from './OutOfChargeRenderer';
import ScheduledRenderer from './ScheduledRenderer';
import { ColumnIds, sortColumnsBy } from './utils/utils';
import CampaignNameAndRef from '../../common/CampaignNameAndRef';

const pageKey = 'deal';

const DealsTable: React.FC<DealsTableProps> = ({ hasOutOfChargeEnabled }) => {
  const localeCode = useSelector((state: Store) => state.publisher.configuration.localeCode);
  const backEndSystem = useSelector((state: Store) => state.publisher.configuration.backEndSystem);
  const publisherFeatures = useSelector((state: Store) => state.publisher.publisherFeatures);
  const environmentId = useSelector((state: Store) => state.environment.environmentId);

  const [pageSize, setPageSize] = useState(getValidPageSize(Session.getPageSizeByKey(pageKey)));

  const navigate = useNavigate();
  const tableApiRef: MutableRefObject<GridApi | undefined> = useRef();

  const { filters, changeFilters } = usePageFilters<
    DealFilters | Pick<PerformanceFilters, 'deal' | 'startDate' | 'endDate'>
  >('insights/dashboard');
  const cancelFunctions = useCancelRequest();

  const isAdServerMarket = isAdServer({ backEndSystem, publisherFeatures, environmentId });

  const hasReferenceViewPermission = Auth.hasPermission(PermissionsEnum.DEAL_MANAGEMENT_AUTOMATION_REFERENCE_VIEW);
  const hasRevenueViewPermission = Auth.hasPermission(PermissionsEnum.DEAL_MANAGEMENT_REVENUE_VIEW);
  const hasPerformancePageAccess = Auth.hasPermission(PermissionsEnum.DEAL_LEVEL_PERFORMANCE_PAGE_ACCESS);

  const onPieChartClick = (data: DealItemData): void => {
    if (hasPerformancePageAccess) {
      navigate('/insights/deal-performance');
      changeFilters({
        deal: { code: data.dealId ?? '', internalId: data.internalId?.toString() ?? '', name: data.dealName ?? '' },
        startDate: undefined,
        endDate: undefined,
      });
    }
  };

  const getDataSource = (): IDatasource => ({
    getRows: async (params) => {
      const [sortingColumn] = params.sortModel;

      try {
        const { content, totalElements } = await getDealsWithBids({
          cancelFunctions,
          filters,
          page: params.startRow / pageSize,
          size: pageSize,
          sort: sortingColumn
            ? {
                sortBy: sortColumnsBy(sortingColumn.colId as keyof ColumnIds),
                sortDir: sortingColumn.sort.toUpperCase(),
              }
            : {},
          isAdServerMarket,
        });

        if (totalElements === 0) {
          tableApiRef.current?.showNoRowsOverlay();
        } else {
          tableApiRef.current?.hideOverlay();
        }

        params.successCallback(content, totalElements);
      } catch {
        params.failCallback();
      }
    },
  });

  const onPageSizeChange = (size: number): void => {
    setPageSize(size);
    Session.setPageSizeByKey(pageKey, size);
    tableApiRef.current?.paginationSetPageSize(size);
    tableApiRef.current?.setDatasource(getDataSource());
  };

  const getTableApi = (tableApi: GridApi): void => {
    tableApiRef.current = tableApi;
    tableApi.setDatasource(getDataSource());
  };

  useEffect(() => {
    if (!isEmpty(filters) && tableApiRef.current) {
      tableApiRef.current?.setDatasource(getDataSource());
    }
  }, [filters]);

  const columnDefs: ColumnDef[] = [
    {
      headerName: '',
      field: 'dealId',
      minWidth: 90,
      headerTooltip: '',
      cellRenderer: ActionRenderer,
      resizable: false,
      sortable: false,
    },
    {
      headerName: '',
      field: 'includesDischargedFrames',
      minWidth: 40,
      headerTooltip: '',
      cellRenderer: OutOfChargeRenderer,
      resizable: false,
      hide: !hasOutOfChargeEnabled || !isAdServerMarket,
      colId: 'includesDischargedFrames',
      sortable: false,
    },
    {
      headerName: 'Status',
      field: 'bookingStatusCode',
      minWidth: 90,
      headerTooltip: 'Status',
      cellRenderer: (params: ICellRendererParams) =>
        StatusRenderer({
          value: params.value,
          statuses: DEAL_STATUS_BADGE,
          badgeSize: BadgeSize.SMALL,
          direction: TooltipDirection.RIGHT,
        }),
      colId: 'status',
      sortable: false,
    },
    {
      headerName: 'Deal name / ID',
      field: 'dealId',
      minWidth: 350,
      headerTooltip: 'Deal name / ID',
      cellRenderer: (params: ICellRendererParams) =>
        CampaignNameAndRef({ campaignName: params.data?.dealName, campaignRef: params.data?.dealId }),
      sortable: true,
    },
    {
      headerName: 'Deal type',
      field: 'dealTypeCode',
      minWidth: 125,
      headerTooltip: 'Deal types',
      sortable: true,
      cellRenderer: (params: ICellRendererParams) => getDealTypeLabel(params.value),
    },
    {
      headerName: 'Scheduled',
      field: 'startDate',
      minWidth: 200,
      headerTooltip: 'Scheduled',
      cellRenderer: ScheduledRenderer,
      sortable: true,
    },
    {
      headerName: 'Internal Ref.',
      field: 'internalId',
      minWidth: 120,
      headerTooltip: 'Internal Ref',
      sortable: true,
      hide: !hasReferenceViewPermission || isAdServerMarket,
      cellRenderer: (params: ICellRendererParams) => params.value ?? '',
    },
    {
      headerName: 'DSP name',
      field: 'dspName',
      minWidth: 150,
      headerTooltip: 'DSP name',
      sortable: true,
      cellRenderer: (params: ICellRendererParams) => params.value ?? '',
    },
    {
      headerName: 'Bids',
      field: 'bids',
      headerTooltip: 'Bids',
      minWidth: 430,
      cellRenderer: (params: ICellRendererParams) => BidsRenderer({ ...params, onClick: onPieChartClick }),
      sortable: false,
    },
    {
      headerName: 'Fill rate',
      field: 'bids.fillRate',
      headerTooltip: 'Fill rate',
      minWidth: 100,
      cellRenderer: (params: ICellRendererParams) => percentageCellRenderer(params, localeCode),
      sortable: false,
    },
    {
      headerName: 'IMPRESSIONS',
      field: 'allocatedImpressions',
      headerTooltip: 'IMPRESSIONS',
      minWidth: 420,
      cellRenderer: (params: ICellRendererParams) => ImpressionsRenderer({ ...params, onClick: onPieChartClick }),
      hide: true,
      sortable: false,
    },
    {
      headerName: 'Remaining Impressions',
      field: 'remainingImpressions',
      headerTooltip: 'Remaining Impressions',
      minWidth: 180,
      cellRenderer: ({ value }: ICellRendererParams) => NumberRenderer({ value, localeCode }),
      hide: true,
      sortable: false,
    },
    {
      headerName: 'CPM',
      field: 'cpm',
      minWidth: 85,
      headerTooltip: 'CPM',
      cellRenderer: ({ value }: ICellRendererParams) => NumberRenderer({ value, localeCode }),
      sortable: true,
    },
    {
      headerName: 'Revenue',
      field: 'bids.revenue',
      minWidth: 100,
      headerTooltip: 'Revenue',
      cellRenderer: ({ value }: ICellRendererParams) => NumberRenderer({ value, localeCode }),
      hide: !hasRevenueViewPermission,
      sortable: false,
    },
    {
      headerName: 'Category',
      field: 'productCategoryName',
      minWidth: 150,
      headerTooltip: 'Category',
      sortable: true,
      cellRenderer: (params: ICellRendererParams) => params.value ?? '',
    },
    {
      headerName: 'Advertiser',
      field: 'advertiserName',
      minWidth: 150,
      headerTooltip: 'Advertiser',
      sortable: true,
      cellRenderer: (params: ICellRendererParams) => params.value ?? '',
    },
    {
      headerName: 'Brand',
      field: 'brandName',
      minWidth: 150,
      headerTooltip: 'Brand',
      sortable: true,
      cellRenderer: (params: ICellRendererParams) => params.value ?? '',
    },
    {
      headerName: 'Agency',
      field: 'agencyName',
      minWidth: 350,
      headerTooltip: 'Agency',
      resizable: false,
      sortable: true,
      cellRenderer: (params: ICellRendererParams) => params.value ?? '',
    },
  ];

  return (
    <>
      <div className="mb-3">
        <PageSize
          dataTestId="page-size-selection"
          onPageSizeChange={onPageSizeChange}
          pageSize={pageSize}
          pageInfoText="deals per page"
        />
      </div>
      <Table
        dataTestId="deals-table"
        key={pageSize}
        columnDefs={columnDefs}
        getTableApi={getTableApi}
        extraGridOptions={{
          rowModelType: 'infinite',
          rowHeight: TableRowHeight.NORMAL,
          pagination: true,
          paginationPageSize: pageSize,
          cacheBlockSize: pageSize,
          enableCellTextSelection: true,
          domLayout: 'autoHeight',
        }}
        tableSize={{ tableHeight: TableHeight.AUTO }}
      />
    </>
  );
};

export default DealsTable;
