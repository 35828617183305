import { useSelector } from 'react-redux';
import { thoughtspot } from 'config';
import { initialiseThoughtspot } from 'utils/thoughtspotInit';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import { getIsReseller } from 'store/publisher/selectors';
import { Store } from 'components/common/types/Store.types';
import LiveboardVisuals from 'components/patterns/LiveboardVisuals';
import { filterByMarket, hideMarketFilterStyle, labsReportVisibleActions } from '../LabsReportDropdown/utils';
import { getUpdateParametersForTimezone } from '../utils';

const DealHealth: React.FC = () => {
  const { localeCode, marketId, timezone } = useSelector((state: Store) => state.publisher.configuration);
  const isReseller = useSelector(getIsReseller);
  const cancelFunctions = useCancelRequest();

  initialiseThoughtspot(cancelFunctions, localeCode);

  const dealHealthLiveboardId = isReseller
    ? thoughtspot.resellerDealHealthDashboardId
    : thoughtspot.dealHealthDashboardId;

  return (
    <div className="pt-4 h-full">
      <LiveboardVisuals
        liveboardId={dealHealthLiveboardId}
        customizations={hideMarketFilterStyle}
        visibleActions={labsReportVisibleActions}
        filterByOptions={[filterByMarket(marketId)]}
        updateParameters={getUpdateParametersForTimezone(timezone)}
      />
    </div>
  );
};

export default DealHealth;
