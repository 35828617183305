import { ReactElement, ReactNode } from 'react';
import switchSvg from 'assets/icons/switch.svg';
import arrowUp from 'assets/icons/arrow_up.svg';
import arrowDown from 'assets/icons/arrow_down.svg';
import { SortDir } from 'components/common/types/SortDir.types';

export const SortSettings: Record<SortDir, { nextDir: SortDir; icon: string }> = {
  [SortDir.NONE]: {
    nextDir: SortDir.ASC,
    icon: switchSvg,
  },
  [SortDir.DESC]: {
    nextDir: SortDir.NONE,
    icon: arrowDown,
  },
  [SortDir.ASC]: {
    nextDir: SortDir.DESC,
    icon: arrowUp,
  },
};

export interface CollapsibleListProps {
  label?: string | ReactElement;
  icon?: string;
  headerComponent?: ReactNode;
  isOpen?: boolean;
  sortDir?: SortDir;
  onSort?: (newSortDir: SortDir) => void;
  onClearAll?: VoidFunction;
  onToggle?: VoidFunction;
  isSortVisible?: boolean;
  isClearAllVisible?: boolean;
}
