import { FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PlannerSecondaryPanelType } from 'store/dealManagement/reducer';
import { clearItemInNewProduct, hideSecondaryPanel } from 'store/productManagement/reducer';
import { useOnChangePanelVisibility } from 'components/pages/PCM/hooks/useOnChangePanelVisibility';
import locationSvg from 'assets/icons/locations.svg';
import SectionButton, { SectionButtonTheme } from 'lib/SectionButton';

import { Store } from 'components/common/types/Store.types';
import { ProductFieldName } from 'store/productManagement/reducer.types';
import { sortByCategoryIncludeExclude } from 'components/pages/Planner/PlannerSections/FiltersContent/utils';
import ProductLocationsPanel from './ProductLocationsPanel';
import { isValueRequired } from '../../utils/isValueRequired';
import { getNoRestrictionsDisplayValue } from '../../utils/getNoRestrictionsDisplayValue';

const ProductLocation: FC = () => {
  const dispatch = useDispatch();

  const secondaryPanelType = useSelector((state: Store) => state.productManagement.secondaryPanel);
  const selectedLocations = useSelector((state: Store) => state.productManagement.newProduct.locations?.value);
  const selectedPermission = useSelector(
    (state: Store) => state.productManagement.newProduct.locations?.selectionPermission,
  );

  const onChangePanelVisibility = useOnChangePanelVisibility();
  const fieldName = ProductFieldName.LOCATIONS;
  const isLocationPanelOpen = secondaryPanelType === PlannerSecondaryPanelType.LOCATION;

  const categorizedValues = useMemo(() => {
    return selectedLocations ? sortByCategoryIncludeExclude(selectedLocations) : [];
  }, [selectedLocations]);

  return (
    <>
      <SectionButton
        dataTestId="product-button-locations"
        label="Location"
        icon={locationSvg}
        theme={selectedPermission ? SectionButtonTheme.SOLID : SectionButtonTheme.DASHED}
        isActive={isLocationPanelOpen}
        selected={
          isValueRequired(selectedPermission) ? categorizedValues : getNoRestrictionsDisplayValue(selectedPermission)
        }
        onClick={() => onChangePanelVisibility(PlannerSecondaryPanelType.LOCATION)}
        onClear={() => {
          dispatch(clearItemInNewProduct({ fieldName }));
          dispatch(hideSecondaryPanel());
        }}
      />
      {isLocationPanelOpen && <ProductLocationsPanel onCancel={() => dispatch(hideSecondaryPanel())} />}
    </>
  );
};

export default ProductLocation;
