import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from 'components/common/types/Store.types';
import { getLookupData } from 'modules/api/lookups';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import { changeChannels } from 'store/userOptions/reducer';
import { getResellerDealMediaOwners } from 'store/reseller/selectors';

const useChannels = (): void => {
  const dispatch = useDispatch();
  const cancelFunctions = useCancelRequest();
  const channels = useSelector((state: Store) => state.userOptions.channels);
  const marketId = useSelector((state: Store) => state.publisher.configuration.marketId);
  const resellerMediaOwners = useSelector(getResellerDealMediaOwners);

  useEffect(() => {
    if (channels.length >= 1) return;

    const getEnvironments = async (): Promise<void> => {
      try {
        const result = await getLookupData({
          cancelFunctions,
          lookupURLName: 'environment',
          query: '',
        });

        dispatch(changeChannels(result));
      } catch {} // eslint-disable-line no-empty
    };

    getEnvironments();
  }, [marketId, resellerMediaOwners]);
};

export default useChannels;
