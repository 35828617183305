import { useState } from 'react';
import ToggleSwitch from 'components/patterns/ToggleSwitch';
import BaseSelect from 'lib/BaseSelect';
import Input from 'lib/Input';
import { InputType } from 'components/common/types/Input.types';
import { CodeNameModel } from 'components/common/types';
import { PCMTableFiltersProps } from '../ProductsTable.types';

const ProductsTableFilters: React.FC<PCMTableFiltersProps> = ({
  folders,
  selectedFolderGroup,
  onChangeSelectedFolderGroup,
  onFilterTextChange,
  searchText,
  onToggle,
}: PCMTableFiltersProps) => {
  const [hideDisabled, setHideDisabled] = useState<boolean>(false);

  return (
    <div className="flex p-2 space-x-2 justify-content items-center max-w-[50%]">
      <div className="flex-[0.5]">
        <BaseSelect
          dataTestId="folder-name-filter"
          onSelect={(_, value: CodeNameModel) => onChangeSelectedFolderGroup(value.code)}
          name="folder"
          options={folders}
          selectedValue={{
            code: selectedFolderGroup,
            name: selectedFolderGroup,
          }}
          classNameWrapper="bg-neutral-50 border !border-solid border-neutral-300"
          isDisabled={!folders.length}
        />
      </div>

      <div className="flex-1">
        <Input type={InputType.SEARCH} value={searchText} placeholder="Search product" onChange={onFilterTextChange} />
      </div>

      <ToggleSwitch
        isChecked={hideDisabled}
        onChange={() => {
          onToggle(!hideDisabled);
          setHideDisabled(!hideDisabled);
        }}
        label="Hide disabled"
      />
    </div>
  );
};

export default ProductsTableFilters;
