import { SelectionPermission } from 'store/productManagement/reducer.types';

export const getNoRestrictionsDisplayValue = (selectedPermission?: string): string[] => {
  if (selectedPermission === SelectionPermission.ALL_OPTIONS) {
    return ['No restrictions'];
  }

  if (selectedPermission === SelectionPermission.DISABLED) {
    return ['Not in product'];
  }

  return [];
};
