import { useDispatch, useSelector } from 'react-redux';
import { Store } from 'components/common/types/Store.types';
import Input from 'lib/Input';
import { IconSize, InputShape, InputSize, InputTheme } from 'components/common/types/Input.types';
import editSvg from 'assets/icons/edit.svg';
import { changeNewProductName } from 'store/productManagement/reducer';

const PLACEHOLDER_PRODUCT_NAME = 'New product';

export const CreateProductHeader: React.FC = () => {
  const dispatch = useDispatch();
  const name = useSelector((state: Store) => state.productManagement.newProduct.name);
  return (
    <div className="pl-[2px]" data-test-id="create-product-header">
      <Input
        value={name}
        dataTestId="product-name-input"
        theme={InputTheme.ITALIC_WHITE}
        placeholder={PLACEHOLDER_PRODUCT_NAME}
        onChange={(value: string) => dispatch(changeNewProductName(value))}
        endIconSvg={editSvg}
        iconSize={IconSize.EXTRA_SMALL}
        size={InputSize.EXTRA_SMALL}
        shape={InputShape.ROUNDED_MEDIUM}
        className="py-1"
        name="Product name"
      />
    </div>
  );
};
