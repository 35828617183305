import MARKET_WISE_LOCALE from 'consts/locale';
import Session from 'modules/Session';

import { PatternOption } from 'components/common/DaypartTargeting/DaypartTargeting.types';
import { Distances, FrontEndType } from 'components/common/types/Deal.types';
import {
  DirectSalesLockStatusOption,
  DirectSalesPricingMode,
  DirectSalesStatusOption,
} from 'components/common/types/DirectSalesCampaign.types';
import { DEFAULT_CRITERIA } from 'consts/targetCriteria';

const timezone = MARKET_WISE_LOCALE[Session.getEnvironmentId() as keyof typeof MARKET_WISE_LOCALE]?.timezone || '';

export const defaultLine = {
  budget: undefined,
  ceilingSot: undefined,
  cities: [],
  counties: [],
  countries: [],
  cpm: '',
  createdDate: new Date(),
  dischargedFrameIds: [],
  earliestFrameStartDate: new Date(),
  earliestFrameTimezone: timezone,
  endDate: new Date(new Date().setHours(23, 59, 59, 999)),
  environments: [],
  floorSot: undefined,
  id: '',
  impressions: undefined,
  impressionMetrics: undefined,
  indexOptimisation: [],
  isCurrentLineWithProposalAllocation: false,
  isFetchingAvailability: false,
  isSaving: false,
  deliveredImpressions: 0,
  latestFrameEndDate: new Date(),
  latestFrameTimezone: timezone,
  lineId: '',
  frontEndType: FrontEndType.STANDARD,
  listFiles: [],
  uploadedFrameLists: [],
  maxCPM: undefined,
  mediaOwners: [],
  name: '',
  postCodes: [],
  routeFrameCodes: [],
  sortOrderNumber: '',
  startDate: new Date(new Date().setHours(0, 0, 0, 0)),
  streets: [],
  terminated: false,
  visualUnitCodes: [],
  visualUnitFiles: [],
  tags: [],
  sweep: '',
  frames: undefined,
  sot: undefined,
  adjustedSot: 0,
  sotFloor: 0,
  sotCeiling: 0,
  selectedDays: {},
  patternRepeatType: PatternOption.NO_REPEAT,
  patternLength: 1,
  productFormats: [],
  venueTaxonomies: [],
  availability: {
    allocatedFrames: 0,
    allocatedImpressions: 0,
    assets: [],
    availableImpressions: 0,
    availableFrames: 0,
    assetCpmDistribution: {
      max: 0,
      min: 0,
      weightedMean: 0,
    },
    totalCost: 0,
  },
  preservedAvailability: {
    allocatedImpressions: 0,
    totalCost: 0,
    totalSot: 0,
    totalSpans: 0,
  },
  preservedAllocation: {
    allocatedFrames: 0,
    allocatedImpressions: 0,
    availableFrames: 0,
    availableImpressions: 0,
    budget: 0,
    deliveredImpressions: 0,
    frames: 0,
    impressions: 0,
    totalCost: 0,
  },
  proximity: {
    openStreetMapPoi: [],
    plannerPoi: [],
    poi: {
      attributeCode: '',
      dataSourceCode: '',
      distance: '',
      include: true,
      selectedCodes: [],
      tags: [],
      unit: Distances.Metres,
    },
    points: {
      distance: '',
      files: [],
      include: true,
      isFileUploadActive: false,
      latitude: '',
      longitude: '',
      unit: Distances.Metres,
    },
    postCode: {
      distance: '',
      files: [],
      include: true,
      isFileUploadActive: false,
      tags: [],
      unit: Distances.Metres,
    },
  },
  segment: {
    selectedSegmentDataProvider: '',
    selectedSegments: {},
  },
};

export const defaultDirectSales = {
  state: {
    status: DirectSalesStatusOption.DRAFT_UPDATING,
  },
  lockState: {
    status: DirectSalesLockStatusOption.UNLOCKED,
  },
  transientState: undefined,
  mediaType: undefined,
  budgetCriteria: DEFAULT_CRITERIA,
  framesCriteria: DEFAULT_CRITERIA,
  impressionsCriteria: DEFAULT_CRITERIA,
  networkCriteria: [],
  pricingMode: DirectSalesPricingMode.CPM,
  dealLineFormats: [],
  locations: [],
  poi: [],
  allocation: {},
  objectives: [],
  transientLineStates: {},
  draftFailuresToasts: [],
  productTemplate: {
    id: '',
    name: '',
  },
  floatingRange: null,
  enabledForSharing: undefined,
};
