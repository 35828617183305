import { Routes, Route } from 'react-router-dom';
import { PageWrapper } from 'components/common/layout/PageWrapper/PageWrapper';
import PrivateRoute from 'components/common/Auth/Private';
import { ChildRoute } from 'components/common/types/Route.types';
import withLoader from 'components/hocs/withLoader';
import ProductsTable from './ProductsTable';

const Products: React.FC = () => {
  const childRoutes: ChildRoute[] = [
    {
      id: 1,
      path: 'create',
      main: ProductsTable,
      pageAccessPermission: undefined,
    },
  ];

  return (
    <PageWrapper>
      <ProductsTable />
      <Routes>
        {childRoutes.map((route) => (
          <Route
            key={route.id}
            path={route.path}
            element={<PrivateRoute component={route.main} pageAccessPermission={route.pageAccessPermission} />}
          />
        ))}
      </Routes>
    </PageWrapper>
  );
};

export default withLoader(Products);
