import { PoiOption, PoiOptionListResponse, PoiSubOptionResponse } from 'components/common/types/Planner.types';

export const removeEmptyChildren = (option: PoiOption): PoiOption => {
  return option.children?.length
    ? {
        ...option,
        children: option.children.map(removeEmptyChildren),
      }
    : { ...option, children: undefined };
};

export const splitAndTrimCategories = (categoryType: string): string[] => {
  return categoryType.split('/').map((category) => category.trim());
};

export const createRootCategory = (categoryType: string): PoiOption => {
  const [rootCategoryType] = splitAndTrimCategories(categoryType);

  return {
    categoryType: rootCategoryType,
    categoryPath: rootCategoryType,
    code: rootCategoryType,
    name: rootCategoryType,
    selectable: false,
    children: [],
  };
};

export const buildSubCategoriesHierarchy = (categoryType: string, rootCategory: PoiOption): PoiOption => {
  const categories = splitAndTrimCategories(categoryType);
  let currentParent = rootCategory;

  categories.slice(1).forEach((categoryName, index) => {
    const subCategories = categories.slice(0, index + 2);
    const subCategoryType = subCategories.join('/');
    const subCategoryCode = subCategories.join('-');

    let subCategory = currentParent.children?.find((child) => child.categoryType === subCategoryType);

    if (!subCategory) {
      subCategory = {
        categoryType: subCategoryType,
        categoryPath: subCategoryType,
        code: subCategoryCode,
        name: categoryName,
        selectable: false,
        parentCode: subCategories[subCategories.length - 2],
        children: [],
      };

      currentParent.children?.push(subCategory);
    }

    currentParent = subCategory;
  });

  return currentParent;
};

const createCategoryTypeTree = (
  subOptions: PoiSubOptionResponse[],
  categoryType: string,
  rootCategory: PoiOption,
): void => {
  const lastParent = buildSubCategoriesHierarchy(categoryType, rootCategory);

  lastParent.children = subOptions.map(({ categoryName }) => ({
    code: `${categoryName}-${categoryType}`,
    name: categoryName,
    categoryName,
    categoryType,
    categoryPath: `${categoryType} / ${categoryName}`,
    selectable: true,
    parentCode: lastParent.name,
  }));
};

export const transformPoiOptions = (poiOptionListResponses: PoiOptionListResponse[]): PoiOption[] => {
  const categoryMap = poiOptionListResponses.reduce((map, response) => {
    response.options.forEach(({ categoryType, suboptions }) => {
      const categories = splitAndTrimCategories(categoryType);
      const rootCategoryType = categories[0];

      let rootCategory = map.get(rootCategoryType);

      if (!rootCategory) {
        rootCategory = createRootCategory(categoryType);
        map.set(rootCategoryType, rootCategory);
      }

      createCategoryTypeTree(suboptions, categoryType, rootCategory);
    });

    return map;
  }, new Map<string, PoiOption>());

  return Array.from(categoryMap.values()).map(removeEmptyChildren);
};
