import { CodeNameModel } from 'components/common/types';
import { Environment, Network } from 'components/common/types/Deal.types';
import { NetworkCriteriaType } from 'components/common/types/DirectSalesNetworks.types';
import { DirectSalesOption } from 'components/common/types/DirectSalesCampaign.types';
import { PoiOption } from 'components/common/types/Planner.types';
import { SegmentsSubOptions } from 'components/common/types/Segment.types';
import { CodeName } from 'components/common/types/CodeName.types';

export interface ReferenceOption extends CodeNameModel {
  assets: number;
}

export enum LocationOption {
  ADDRESS = 'address',
  CONURBATION = 'conurbation',
  COUNTRY = 'country',
  COUNTY = 'county',
  DISTRICT = 'district',
  GEOGRAPHICAL_AREA_LEVEL_1 = 'geographicalAreaLevel1',
  GEOGRAPHICAL_AREA_LEVEL_2 = 'geographicalAreaLevel2',
  GEOGRAPHICAL_AREA_LEVEL_3 = 'geographicalAreaLevel3',
  POSTCODE = 'postcode',
  ROUTE_TOWN = 'routeTown',
  TOWN = 'town',
  TV_AREA = 'tvArea',
}

export const LocationLabel = {
  [LocationOption.ADDRESS]: 'Address',
  [LocationOption.CONURBATION]: 'Conurbation',
  [LocationOption.COUNTRY]: 'Country',
  [LocationOption.COUNTY]: 'County',
  [LocationOption.DISTRICT]: 'District',
  [LocationOption.GEOGRAPHICAL_AREA_LEVEL_1]: 'Geographical Area Level 1',
  [LocationOption.GEOGRAPHICAL_AREA_LEVEL_2]: 'Geographical Area Level 2',
  [LocationOption.GEOGRAPHICAL_AREA_LEVEL_3]: 'Geographical Area Level 3',
  [LocationOption.POSTCODE]: 'Postcode',
  [LocationOption.ROUTE_TOWN]: 'Route Town',
  [LocationOption.TOWN]: 'Town',
  [LocationOption.TV_AREA]: 'TV Area',
};

export const LOCATION_OPTIONS = [
  {
    code: LocationOption.ADDRESS,
    name: LocationLabel[LocationOption.ADDRESS],
  },
  {
    code: LocationOption.CONURBATION,
    name: LocationLabel[LocationOption.CONURBATION],
  },
  {
    code: LocationOption.COUNTRY,
    name: LocationLabel[LocationOption.COUNTRY],
  },
  {
    code: LocationOption.COUNTY,
    name: LocationLabel[LocationOption.COUNTY],
  },
  {
    code: LocationOption.DISTRICT,
    name: LocationLabel[LocationOption.DISTRICT],
  },
  {
    code: LocationOption.GEOGRAPHICAL_AREA_LEVEL_1,
    name: LocationLabel[LocationOption.GEOGRAPHICAL_AREA_LEVEL_1],
  },
  {
    code: LocationOption.GEOGRAPHICAL_AREA_LEVEL_2,
    name: LocationLabel[LocationOption.GEOGRAPHICAL_AREA_LEVEL_2],
  },
  {
    code: LocationOption.GEOGRAPHICAL_AREA_LEVEL_3,
    name: LocationLabel[LocationOption.GEOGRAPHICAL_AREA_LEVEL_3],
  },
  {
    code: LocationOption.POSTCODE,
    name: LocationLabel[LocationOption.POSTCODE],
  },
  {
    code: LocationOption.ROUTE_TOWN,
    name: LocationLabel[LocationOption.ROUTE_TOWN],
  },
  {
    code: LocationOption.TOWN,
    name: LocationLabel[LocationOption.TOWN],
  },
  {
    code: LocationOption.TV_AREA,
    name: LocationLabel[LocationOption.TV_AREA],
  },
];

interface BaseLocation {
  address?: ReferenceOption[];
  conurbation: ReferenceOption[];
  geographicalAreaLevel1?: ReferenceOption[];
  geographicalAreaLevel2?: ReferenceOption[];
  geographicalAreaLevel3?: ReferenceOption[];
  postcode: ReferenceOption[];
  routeTown: ReferenceOption[];
  tvArea: ReferenceOption[];
}

export type DistrictResponse = ReferenceOption;
export type TownResponse = ReferenceOption & Record<LocationOption.DISTRICT, DistrictResponse[]>;
export type CountyResponse = ReferenceOption & Record<LocationOption.TOWN, TownResponse[]>;
export type CountryResponse = ReferenceOption & Record<LocationOption.COUNTY, CountyResponse[]>;

export interface ReferenceLocationResponse extends BaseLocation {
  country: CountryResponse[];
}

export type District = ReferenceOption & { town: string };
export type Town = ReferenceOption & { county: string };
export type County = ReferenceOption & { country: string };
export type Country = ReferenceOption;

export interface ReferenceLocation extends BaseLocation {
  country: Country[];
  county: County[];
  town: Town[];
  district: District[];
}

export enum FormatOption {
  BUSINESS_AREA = 'businessArea',
  FRAME_SIZE_FAMILY = 'frameSizeFamily',
  PRODUCT_FORMAT_MARKETING = 'productFormatMarketing',
  REVENUE_STREAM = 'revenueStream',
  PRODUCT_FORMAT = 'productFormat',
}

export const FormatLabel = {
  [FormatOption.BUSINESS_AREA]: 'Business Area',
  [FormatOption.FRAME_SIZE_FAMILY]: 'Size Code',
  [FormatOption.PRODUCT_FORMAT_MARKETING]: 'Marketing Name',
  [FormatOption.REVENUE_STREAM]: 'Revenue Stream',
  [FormatOption.PRODUCT_FORMAT]: 'Product Format',
};

export const FORMAT_OPTIONS = [
  {
    code: FormatOption.BUSINESS_AREA,
    name: FormatLabel[FormatOption.BUSINESS_AREA],
  },
  {
    code: FormatOption.FRAME_SIZE_FAMILY,
    name: FormatLabel[FormatOption.FRAME_SIZE_FAMILY],
  },
  {
    code: FormatOption.PRODUCT_FORMAT_MARKETING,
    name: FormatLabel[FormatOption.PRODUCT_FORMAT_MARKETING],
  },
  {
    code: FormatOption.REVENUE_STREAM,
    name: FormatLabel[FormatOption.REVENUE_STREAM],
  },
  {
    code: FormatOption.PRODUCT_FORMAT,
    name: FormatLabel[FormatOption.PRODUCT_FORMAT],
  },
];

export type BusinessAreaResponse = ReferenceOption & Record<FormatOption.REVENUE_STREAM, ReferenceOption[]>;

export type RevenueStream = ReferenceOption & { businessArea: string };

interface BaseReferenceFormat {
  frameSizeFamily: ReferenceOption[];
  productFormatMarketing: ReferenceOption[];
  productFormat: ReferenceOption[];
}

export interface ReferenceFormat extends BaseReferenceFormat {
  businessArea: ReferenceOption[];
  revenueStream: RevenueStream[];
}

export interface ReferenceFormatResponse extends BaseReferenceFormat {
  businessArea: BusinessAreaResponse[];
}

export interface ReferenceOptions {
  location: ReferenceLocation;
  format: ReferenceFormat;
  tags: Record<string, CodeNameModel[]>;
}

export interface NetworkReferenceOptions {
  [key: string]: NetworkCriteriaType[];
}

export interface NetworkReferenceResponse extends CodeNameModel {
  networks: Network[];
}

export interface TagGroup extends ReferenceOption {
  tag: ReferenceOption[];
}

export type ReferenceTagResponse = TagGroup[];

export interface ReferenceOptionsResponse {
  location: ReferenceLocationResponse;
  format: ReferenceFormatResponse;
  tagGroup: ReferenceTagResponse;
}

export type DataProvider = string;
export type DataProvidersWithPoiOptions = {
  [key: DataProvider]: PoiOption[];
};

export interface UserOptions {
  advertiserOptions: CodeName[];
  referenceOptions: ReferenceOptions;
  segmentOptions: SegmentsSubOptions[];
  locationOptions: DirectSalesOption[];
  poiOptions: PoiOption[];
  dataProvidersWithPoiOptions: DataProvidersWithPoiOptions;
  networks: NetworkReferenceResponse[];
  channels: Environment[];
}
