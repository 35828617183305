import { CodeNameModel } from 'components/common/types';
import { TagCategory } from 'components/common/types/DirectSalesCampaign.types';
import {
  ReferenceFormat,
  ReferenceLocation,
  ReferenceFormatResponse,
  BusinessAreaResponse,
  ReferenceLocationResponse,
  CountryResponse,
  ReferenceTagResponse,
} from 'store/userOptions/reducer.types';

export const transformFormat = (formats: ReferenceFormatResponse): ReferenceFormat => {
  return formats.businessArea.reduce(
    (acc: ReferenceFormat, current: BusinessAreaResponse): ReferenceFormat => {
      const newRevenueStream = current.revenueStream.map((stream) => ({ ...stream, businessArea: current.code }));
      acc.revenueStream.push(...newRevenueStream);

      const { revenueStream, ...areaWithoutRevenue } = current;
      acc.businessArea.push(areaWithoutRevenue);

      return acc;
    },
    { ...formats, businessArea: [], revenueStream: [] },
  );
};

export const transformLocation = (location: ReferenceLocationResponse): ReferenceLocation => {
  return location.country.reduce(
    (acc: ReferenceLocation, current: CountryResponse): ReferenceLocation => {
      const { county, ...countryWithoutCounty } = current;
      acc.country.push(countryWithoutCounty);

      county.forEach((currentCounty) => {
        const { town, ...countyWithoutTown } = currentCounty;
        acc.county.push({ ...countyWithoutTown, country: current.code });

        town.forEach((currentTown) => {
          const { district, ...townWithoutDistrict } = currentTown;
          acc.town.push({ ...townWithoutDistrict, county: currentCounty.code });

          district.forEach((currentDistrict) => {
            acc.district.push({ ...currentDistrict, town: currentTown.code });
          });
        });
      });

      return acc;
    },
    { ...location, country: [], county: [], town: [], district: [] },
  );
};

export const sortByCategory = (data: CodeNameModel[]): Record<string, CodeNameModel[]> => {
  return data
    .filter((value) => value.include)
    .reduce(
      (
        acc: Record<string, CodeNameModel[]>,
        { code, name, category }: { code: string; name: string; category: string },
      ) => {
        if (!acc[category]) {
          acc[category] = [{ code, name }];
        } else {
          acc[category].push({ code, name });
        }
        return acc;
      },
      {},
    );
};

export const sortByCategoryIncludeExclude = (
  data: CodeNameModel[],
): Record<string, { included?: CodeNameModel[]; excluded?: CodeNameModel[] }> => {
  return data.reduce(
    (
      acc: Record<string, { included?: CodeNameModel[]; excluded?: CodeNameModel[] }>,
      { code, name, category, include }: { code: string; name: string; category: string; include: boolean },
    ) => {
      if (!acc[category]) {
        if (include) acc[category] = { included: [{ code, name }] };
        else acc[category] = { excluded: [{ code, name }] };
      } else if (include) {
        const { included } = acc[category];

        if (included) included.push({ code, name });
        else acc[category].included = [{ code, name }];
      } else {
        const { excluded } = acc[category];

        if (excluded) excluded.push({ code, name });
        else acc[category].excluded = [{ code, name }];
      }
      return acc;
    },
    {},
  );
};

export const transformTags = <T>(
  tagsResponse: T[],
  getTags: (curr: T) => { code: string; name: string }[],
  getKey: (curr: T) => string,
): Record<string, CodeNameModel[]> => {
  return tagsResponse.reduce((acc: Record<string, CodeNameModel[]>, curr) => {
    acc[getKey(curr)] = getTags(curr).map((subTag) => ({
      code: subTag.code,
      name: subTag.name,
      include: undefined,
    }));
    return acc;
  }, {});
};

export const transformProgrammaticTags = (tagsResponse: TagCategory[]): Record<string, CodeNameModel[]> => {
  return transformTags(
    tagsResponse,
    (curr) => curr.tags,
    (curr) => curr.name,
  );
};

export const transformDirectSalesTags = (tagsResponse: ReferenceTagResponse): Record<string, CodeNameModel[]> => {
  return transformTags(
    tagsResponse,
    (curr) => curr.tag,
    (curr) => curr.name,
  );
};

export const removeCategoryFromSelectedOptions = (selectedOptions: CodeNameModel[]): string[] => {
  return selectedOptions.filter((selectedOption) => selectedOption.include).map(({ name }) => name);
};

export const shouldDisplayCategoryClearAll = (allSelectedOptions: CodeNameModel[], optionCategory: string): boolean => {
  return allSelectedOptions.some((selectedOption) => selectedOption.category === optionCategory);
};
