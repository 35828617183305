import { Store } from 'components/common/types/Store.types';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isOneFilterSelected, isOneTargetSelected } from 'utils/objectives';
import { PlannerSecondaryPanelType } from 'store/dealManagement/reducer';
import { updateIsDiscardModalOpen, updateObjectiveToCancel } from 'store/objective/reducer';
import { CampaignType } from '../PlannerSections/types';

type UseUnsavedChange = (callback: VoidFunction) => void;

const useUnsavedChange = (): UseUnsavedChange => {
  const dispatch = useDispatch();

  const currentPanelType = useSelector((state: Store) => state.dealManagement.plannerSecondaryPanelType);
  const campaignType = useSelector((state: Store) => state.dealManagement.campaignType);
  const objectives = useSelector((state: Store) => state.dealManagement.directSales?.objectives);
  const {
    data: { objectiveGroupId: activeObjectiveGroupId },
    settings: { isChange: isUnsavedObjectiveChange, isSubsectionChange: isUnsavedObjectiveSubsectionChange },
  } = useSelector((state: Store) => state.objective);

  return useCallback(
    (callback: VoidFunction) => {
      if (campaignType === CampaignType.DIRECT && currentPanelType.includes(PlannerSecondaryPanelType.OBJECTIVE)) {
        const initialObjectiveState = objectives.find(
          (objective) => objective.objectiveGroupId === activeObjectiveGroupId,
        );

        const isUnsavedObjective =
          initialObjectiveState &&
          !(isOneTargetSelected(initialObjectiveState) && isOneFilterSelected(initialObjectiveState));

        const isExistingObjectiveWithUnsavedChanges = isUnsavedObjectiveChange || isUnsavedObjectiveSubsectionChange;

        if (isUnsavedObjective) {
          dispatch(
            updateObjectiveToCancel({
              code: initialObjectiveState.objectiveGroupId.toString(),
              name: initialObjectiveState.name,
            }),
          );
          return;
        }

        if (isExistingObjectiveWithUnsavedChanges) {
          dispatch(updateIsDiscardModalOpen(true));
          return;
        }
      }

      callback();
    },
    [objectives, campaignType, currentPanelType, isUnsavedObjectiveChange, isUnsavedObjectiveSubsectionChange],
  );
};

export default useUnsavedChange;
