import { useState, PropsWithChildren } from 'react';
import cx from 'classnames';
import Checkbox from 'lib/Checkbox';
import IncludeExcludeToggle from 'lib/IncludeExcludeToggle/IncludeExcludeToggle';
import { CodeNameModel } from 'components/common/types';
import { SelectOptionProps } from './SelectOption.types';

const SelectOption: React.FC<PropsWithChildren<SelectOptionProps>> = ({
  isSelected,
  onClick,
  children,
  value,
  onIncludeExcludeChange,
  hasCheckbox = true,
  isIncludeExcludeAlwaysVisible = false,
  isDisabled = false,
  isMultiple = false,
  classNames,
  dataTestId = 'select-option',
}: SelectOptionProps) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const onHover = (newState: boolean): void => {
    if (isIncludeExcludeAlwaysVisible) return;
    setIsHovered(newState);
  };

  const onExcludeIncludeClick = (newValue: CodeNameModel): void => {
    if (!onIncludeExcludeChange) return;

    if (!isSelected || value.include !== newValue.include) {
      onIncludeExcludeChange(newValue);
    } else {
      onClick?.();
    }
  };

  return (
    <div
      data-test-id={dataTestId}
      className={cx(
        'flex rounded-sm p-2 mx-1 hover:bg-neutral-100',
        {
          'cursor-not-allowed': isDisabled && !isSelected,
        },
        classNames,
      )}
      onMouseEnter={() => onHover(true)}
      onMouseLeave={() => onHover(false)}
    >
      <div
        className={cx('flex w-full items-center py-1', {
          'cursor-not-allowed': isDisabled && !isSelected,
          'pl-1': hasCheckbox,
        })}
        onClick={() => {
          if (!onClick) return;

          if (!isDisabled && isMultiple) {
            onClick(value);
            return;
          }

          if (!isDisabled || isSelected) {
            onClick(value);
          }
        }}
        role="presentation"
      >
        {hasCheckbox && <Checkbox isSelected={isSelected} />}
        <div
          className={cx('truncate', {
            'pl-2': hasCheckbox,
          })}
        >
          {children}
        </div>
      </div>
      {onIncludeExcludeChange && (
        <IncludeExcludeToggle
          option={value}
          onIncludeExcludeChange={onExcludeIncludeClick}
          isHovered={isHovered}
          isSelected={isSelected}
          isAlwaysVisible={isIncludeExcludeAlwaysVisible}
          isOptionNameSelectable={hasCheckbox}
        />
      )}
    </div>
  );
};

export default SelectOption;
