import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import SVG from 'react-inlinesvg';
import infoSvg from 'assets/icons/info.svg';
import warningSvg from 'assets/icons/warning.svg';
import diamondWarningSvg from 'assets/icons/diamond_warning.svg';
import { hideBanner } from 'store/banner/reducer';
import { Store } from 'components/common/types/Store.types';
import Modal from 'lib/Modal';
import { useToggle } from 'customHooks/useToggle';
import Button, { Color } from '../Button';
import { BannerType, backgroundColorStyle, confirmButtonStyle, fontColorStyle } from './Banner.types';

const iconSvgMap: { [key in Exclude<BannerType, BannerType.NONE>]: string } = {
  [BannerType.INFO]: infoSvg,
  [BannerType.WARNING]: warningSvg,
  [BannerType.ERROR]: diamondWarningSvg,
};

export const Banner: React.FC = () => {
  const dispatch = useDispatch();
  const bannerType = useSelector((state: Store) => state.banner?.type);
  const headerInfo = useSelector((state: Store) => state.banner?.headerInfo);
  const contentInfo = useSelector((state: Store) => state.banner?.contentInfo);
  const discardButton = useSelector((state: Store) => state.banner?.discardButton);
  const confirmButton = useSelector((state: Store) => state.banner?.confirmButton);
  const exitButton = useSelector((state: Store) => state.banner?.exitButton);
  const campaignId = useSelector((state: Store) => state.dealManagement.commonDeal.dealId);

  const { isOpen: isDiscardModalOpen, open: openDiscardModal, close: closeDiscardModal } = useToggle();

  if (bannerType === BannerType.NONE) return null;

  return (
    <div
      className={cx(
        'w-full body-base pt-0.5 pb-[8px] px-2 flex flex-row space-x-2 items-center',
        fontColorStyle[bannerType],
        backgroundColorStyle[bannerType],
      )}
    >
      <div className="flex items-start justify-center shrink-0">
        <SVG src={iconSvgMap[bannerType]} className="fill-current" />
      </div>
      <div className="flex flex-row items-center space-x-4">
        {headerInfo && <span className="sub-header-base">{headerInfo}</span>}
        {contentInfo && <span className="body-base">{contentInfo}</span>}

        <div className="flex flex-row space-x-4">
          {discardButton && (
            <Button
              label={discardButton.buttonLabel ?? 'Discard'}
              color={Color.BLACK_AND_WHITE}
              onClick={openDiscardModal}
              classNames="!bg-transparent !p-0 border-none hover:bg-transparent"
            />
          )}
          {isDiscardModalOpen && (
            <Modal
              title="Discard changes?"
              text="Changes made will be permanently discarded."
              isOpen={isDiscardModalOpen}
              onClose={closeDiscardModal}
              icon={warningSvg}
              actionButtons={
                <>
                  <Button label="Cancel" color={Color.SECONDARY} onClick={closeDiscardModal} />
                  <Button
                    label="Confirm discard"
                    color={Color.DANGER}
                    onClick={() => {
                      discardButton?.actionCallback && discardButton.actionCallback(campaignId);
                      closeDiscardModal();
                    }}
                  />
                </>
              }
            />
          )}
          {exitButton && (
            <Button
              label={exitButton.buttonLabel ?? 'Exit'}
              color={Color.BLACK_AND_WHITE}
              onClick={() => {
                exitButton?.actionCallback && exitButton.actionCallback();
                dispatch(hideBanner());
              }}
              classNames="!bg-transparent !p-0 border-none hover:bg-transparent"
            />
          )}
          {confirmButton && (
            <Button
              label={confirmButton.buttonLabel ?? 'Confirm'}
              color={Color.BLACK_AND_WHITE}
              onClick={() => confirmButton?.actionCallback && confirmButton.actionCallback(campaignId)}
              classNames={confirmButtonStyle[bannerType]}
              isDisabled={confirmButton?.isDisabled}
            />
          )}
        </div>
      </div>
    </div>
  );
};
