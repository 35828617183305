import { HEADERS_CONTENT_TYPE, HTTP_METHODS, URLS } from 'modules/api/constants';
import handleRequest from 'modules/api/common';
import { CancelFunctions } from 'components/common/types';
import {
  DirectSalesAssetListResponse,
  DirectSalesAvailabilityResponse,
  DirectSalesCampaignDealLineStateResponse,
  DirectSalesCampaignResponse,
  DirectSalesStatusChangeResponse,
} from 'components/common/types/DirectSalesCampaignResponse.types';
import {
  DirectSalesTransformedDealLineRequest,
  DirectSalesUpdateTransientDealStateRequest,
} from 'components/common/types/DirectSalesCampaign.types';
import { DirectSalesCampaignRequest } from 'components/common/types/DirectSalesCampaignRequest.types';
import { TransformedAvailabilityRequest } from 'components/common/types/Availability';
import createRequest, { getRequestOptions } from './request';

export const getDirectSalesCampaign = (
  id: string,
  marketId: string,
  cancelFunctions: CancelFunctions,
): Promise<DirectSalesCampaignResponse> => {
  const reqOptions = {
    url: URLS().DIRECT_SALES_CAMPAIGN(marketId, id),
    method: HTTP_METHODS.GET,
    showLoader: true,
  };

  return handleRequest(reqOptions, cancelFunctions, 'DIRECT_SALES_CAMPAIGN');
};

export const editDirectSalesCampaign = (
  data: DirectSalesCampaignRequest,
  marketId: string,
  campaignId: string,
  cancelFunctions: CancelFunctions,
): Promise<DirectSalesCampaignResponse> => {
  const reqOptions = {
    url: URLS().DIRECT_SALES_CAMPAIGN(marketId, campaignId),
    method: HTTP_METHODS.PUT,
    data,
    headers: {
      'Content-Type': HEADERS_CONTENT_TYPE.APPLICATION_JSON,
    },
    notificationTimeout: 0,
  };

  return handleRequest(reqOptions, cancelFunctions, 'DIRECT_SALES_CAMPAIGN');
};

export const getDirectSalesSolution = (
  id: string,
  marketId: string,
  cancelFunctions: CancelFunctions,
): Promise<DirectSalesCampaignResponse> => {
  const reqOptions = {
    url: URLS().FETCH_DIRECT_SALES_SOLUTION(marketId, id),
    method: HTTP_METHODS.GET,
    notificationTimeout: 0,
  };

  return handleRequest(reqOptions, cancelFunctions, 'FETCH_DIRECT_SALES_ALLOCATION');
};

export const createDirectSalesCampaign = (
  data: DirectSalesCampaignRequest,
  marketId: string,
  cancelFunctions: CancelFunctions,
): Promise<DirectSalesCampaignResponse> => {
  const reqOptions = {
    url: URLS().CREATE_DIRECT_SALES_CAMPAIGN(marketId),
    method: HTTP_METHODS.POST,
    data,
    headers: {
      'Content-Type': HEADERS_CONTENT_TYPE.APPLICATION_JSON,
    },
    notificationTimeout: 0,
  };

  return handleRequest(reqOptions, cancelFunctions, 'CREATE_DIRECT_SALES_CAMPAIGN');
};

export const checkDirectSalesAvailability = (
  data: TransformedAvailabilityRequest,
  marketId: string,
  cancelFunctions: CancelFunctions,
): Promise<DirectSalesAvailabilityResponse> => {
  const reqOptions = {
    url: URLS().CHECK_DIRECT_SALES_AVAILABILITY(marketId),
    method: HTTP_METHODS.POST,
    data,
    showLoader: false,
    headers: {
      'Content-Type': HEADERS_CONTENT_TYPE.APPLICATION_JSON,
    },
    notificationTimeout: 0,
  };

  return handleRequest(reqOptions, cancelFunctions, 'CHECK_DIRECT_SALES_AVAILABILITY');
};

export const fetchCampaignState = (
  marketId: string,
  campaignId: string,
  cancelFunctions: CancelFunctions,
): Promise<DirectSalesCampaignDealLineStateResponse> => {
  const reqOptions = {
    url: URLS().FETCH_DIRECT_SALES_CAMPAIGN_STATE(marketId, campaignId),
    method: HTTP_METHODS.GET,
    notificationTimeout: 0,
  };

  return handleRequest(reqOptions, cancelFunctions, 'FETCH_DIRECT_SALES_CAMPAIGN_STATE');
};

export const fetchDealLineState = (
  marketId: string,
  campaignId: string,
  dealLineId: string,
  cancelFunctions: CancelFunctions,
): Promise<DirectSalesCampaignDealLineStateResponse> => {
  const reqOptions = {
    url: URLS().FETCH_DIRECT_SALES_DEAL_LINE_STATE(marketId, campaignId, dealLineId),
    method: HTTP_METHODS.GET,
    notificationTimeout: 0,
  };

  return handleRequest(reqOptions, cancelFunctions, 'FETCH_DIRECT_SALES_DEAL_LINE_STATE');
};

export const pollDealLineState = (
  marketId: string,
  campaignId: string,
  dealLineId: string,
  cancelFunctions: CancelFunctions,
): ((callback: unknown, interval?: number) => boolean) => {
  const reqOptions = {
    url: URLS().FETCH_DIRECT_SALES_DEAL_LINE_STATE(marketId, campaignId, dealLineId),
    method: HTTP_METHODS.GET,
    notificationTimeout: 0,
  };

  const { poll, cancel } = createRequest(reqOptions);

  if (cancelFunctions.FETCH_DIRECT_SALES_DEAL_LINE_STATE) cancelFunctions.FETCH_DIRECT_SALES_DEAL_LINE_STATE();
  cancelFunctions.FETCH_DIRECT_SALES_DEAL_LINE_STATE = cancel;

  return poll;
};

export const pollCampaignState = (
  marketId: string,
  campaignId: string,
  cancelFunctions: CancelFunctions,
): ((callback: unknown, interval?: number) => boolean) => {
  const reqOptions = {
    url: URLS().FETCH_DIRECT_SALES_CAMPAIGN_STATE(marketId, campaignId),
    method: HTTP_METHODS.GET,
    notificationTimeout: 0,
  };

  const { poll, cancel } = createRequest(reqOptions);

  if (cancelFunctions.FETCH_DIRECT_SALES_CAMPAIGN_STATE) cancelFunctions.FETCH_DIRECT_SALES_CAMPAIGN_STATE();
  cancelFunctions.FETCH_DIRECT_SALES_CAMPAIGN_STATE = cancel;

  return poll;
};

export const updateDirectSalesDealLine = (
  data: DirectSalesTransformedDealLineRequest,
  marketId: string,
  campaignId: string,
  dealLineId: string,
  cancelFunctions: CancelFunctions,
): Promise<{
  id: string;
  dealLines: [{ id: string; name: string }];
}> => {
  const reqOptions = {
    url: URLS().UPDATE_DIRECT_SALES_DEAL_LINE(marketId, campaignId, dealLineId),
    method: HTTP_METHODS.PUT,
    data,
    headers: {
      'Content-Type': HEADERS_CONTENT_TYPE.APPLICATION_JSON,
    },
    notificationTimeout: 0,
  };

  return handleRequest(reqOptions, cancelFunctions, 'UPDATE_DIRECT_SALES_DEAL_LINE');
};

export const deleteDirectSalesDealLine = (
  marketId: string,
  campaignId: string,
  lineId: string,
  cancelFunctions: CancelFunctions,
): Promise<{
  id: string;
  dealLines: [{ id: string; name: string }];
}> => {
  const reqOptions = {
    url: URLS().DELETE_DIRECT_SALES_DEAL_LINE(marketId, campaignId, lineId),
    method: HTTP_METHODS.DELETE,
    headers: {
      'Content-Type': HEADERS_CONTENT_TYPE.APPLICATION_JSON,
    },
    notificationTimeout: 0,
  };

  return handleRequest(reqOptions, cancelFunctions, 'DELETE_DIRECT_SALES_DEAL_LINE');
};

export const updateDealLineState = (
  data: string,
  marketId: string,
  campaignId: string,
  dealLineId: string,
  cancelFunctions: CancelFunctions,
): Promise<DirectSalesStatusChangeResponse> => {
  const reqOptions = {
    url: URLS().UPDATE_DIRECT_SALES_DEAL_LINE_STATE(marketId, campaignId, dealLineId),
    method: HTTP_METHODS.PUT,
    data,
    showLoader: false,
    headers: {
      'Content-Type': HEADERS_CONTENT_TYPE.APPLICATION_JSON,
    },
    notificationTimeout: 0,
  };

  return handleRequest(reqOptions, cancelFunctions, 'UPDATE_DIRECT_SALES_DEAL_LINE_STATE');
};

export const updateTransientDealLineState = (
  data: string,
  marketId: string,
  campaignId: string,
  cancelFunctions: CancelFunctions,
): Promise<DirectSalesStatusChangeResponse> => {
  const reqOptions = {
    url: URLS().UPDATE_DIRECT_SALES_TRANSIENT_DEAL_LINE_STATE(marketId, campaignId),
    method: HTTP_METHODS.PUT,
    data,
    showLoader: false,
    headers: {
      'Content-Type': HEADERS_CONTENT_TYPE.APPLICATION_JSON,
    },
    notificationTimeout: 0,
  };

  return handleRequest(reqOptions, cancelFunctions, 'UPDATE_DIRECT_SALES_TRANSIENT_DEAL_LINE_STATE');
};

export const updateCampaignState = (
  data: string,
  marketId: string,
  campaignId: string,
  cancelFunctions: CancelFunctions,
): Promise<void> => {
  const reqOptions = {
    url: URLS().UPDATE_DIRECT_SALES_CAMPAIGN_STATE(marketId, campaignId),
    method: HTTP_METHODS.PUT,
    data,
    showLoader: false,
    headers: {
      'Content-Type': HEADERS_CONTENT_TYPE.APPLICATION_JSON,
    },
    notificationTimeout: 0,
  };

  return handleRequest(reqOptions, cancelFunctions, 'UPDATE_DIRECT_SALES_CAMPAIGN_STATE');
};

export const acceptOrDiscardChanges = (
  data: DirectSalesUpdateTransientDealStateRequest,
  marketId: string,
  campaignId: string,
  cancelFunctions: CancelFunctions,
): Promise<void> => {
  const reqOptions = {
    url: URLS().UPDATE_TRANSIENT_DEAL_STATE(marketId, campaignId),
    method: HTTP_METHODS.PUT,
    data,
    headers: {
      'Content-Type': HEADERS_CONTENT_TYPE.APPLICATION_JSON,
    },
    notificationTimeout: 0,
  };

  return handleRequest(reqOptions, cancelFunctions, 'UPDATE_TRANSIENT_DEAL_STATE');
};

export const terminateCampaignSession = async (publisherId: string, campaignId: string): Promise<unknown> => {
  return fetch(URLS().TERMINATE_DIRECT_SALES_CAMPAIGN_SESSION(publisherId, campaignId), {
    keepalive: true,
    method: 'POST',
    headers: { ...getRequestOptions().headers, 'Content-Type': HEADERS_CONTENT_TYPE.APPLICATION_JSON },
  });
};

export const extendCampaignSessionLock = (
  publisherId: string,
  campaignId: string,
  cancelFunctions: CancelFunctions,
): Promise<void> => {
  const reqOptions = {
    url: URLS().EXTEND_DIRECT_SALES_CAMPAIGN_SESSION_LOCK(publisherId, campaignId),
    method: HTTP_METHODS.PUT,
    headers: {
      'Content-Type': HEADERS_CONTENT_TYPE.APPLICATION_JSON,
    },
  };

  return handleRequest(reqOptions, cancelFunctions, 'EXTEND_DIRECT_SALES_CAMPAIGN_SESSION_LOCK');
};

export const pollDealSalesCampaign = (
  marketId: string,
  campaignId: string,
  cancelFunctions: CancelFunctions,
): ((callback: unknown, interval?: number) => void) => {
  const reqOptions = {
    url: URLS().DIRECT_SALES_CAMPAIGN(marketId, campaignId),
    method: HTTP_METHODS.GET,
    showLoader: true,
    notificationTimeout: 0,
  };

  const { poll, cancel } = createRequest(reqOptions);

  if (cancelFunctions.DIRECT_SALES_CAMPAIGN) cancelFunctions.DIRECT_SALES_CAMPAIGN();
  cancelFunctions.DIRECT_SALES_CAMPAIGN = cancel;

  return poll;
};

export const getDirectSalesAssetList = (
  campaignId: string,
  marketId: string,
  cancelFunctions: CancelFunctions,
): Promise<DirectSalesAssetListResponse> => {
  const reqOptions = {
    url: URLS().FETCH_DIRECT_SALES_ASSET_LIST(marketId, campaignId),
    method: HTTP_METHODS.GET,
    showLoader: true,
  };

  return handleRequest(reqOptions, cancelFunctions, 'FETCH_DIRECT_SALES_ASSET_LIST');
};

export const enableDirectSalesSharedCampaign = (
  campaignId: string,
  marketId: string,
  cancelFunctions: CancelFunctions,
): Promise<DirectSalesCampaignResponse> => {
  const reqOptions = {
    url: URLS().ENABLE_DIRECT_SALES_SHARED_CAMPAIGN(marketId, campaignId),
    method: HTTP_METHODS.PUT,
    showLoader: true,
  };

  return handleRequest(reqOptions, cancelFunctions, 'ENABLE_DIRECT_SALES_SHARED_CAMPAIGN');
};

export const disableDirectSalesSharedCampaign = (
  campaignId: string,
  marketId: string,
  cancelFunctions: CancelFunctions,
): Promise<DirectSalesCampaignResponse> => {
  const reqOptions = {
    url: URLS().DISABLE_DIRECT_SALES_SHARED_CAMPAIGN(marketId, campaignId),
    method: HTTP_METHODS.DELETE,
    showLoader: true,
  };

  return handleRequest(reqOptions, cancelFunctions, 'DISABLE_DIRECT_SALES_SHARED_CAMPAIGN');
};
