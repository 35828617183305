import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from 'components/common/types/Store.types';
import { changeNewProduct, clearItemInNewProduct, hideSecondaryPanel } from 'store/productManagement/reducer';
import { ProductFieldName } from 'store/productManagement/reducer.types';
import { CodeNameModel } from 'components/common/types';
import calendarSvg from 'assets/icons/calendar-thin.svg';
import SectionButton from 'lib/SectionButton';
import { SectionButtonTheme } from 'lib/SectionButton/SectionButton.types';
import { PlannerSecondaryPanelType } from 'store/dealManagement/reducer';
import { useOnChangePanelVisibility } from 'components/pages/PCM/hooks/useOnChangePanelVisibility';
import { isValueRequired } from '../../utils/isValueRequired';
import { getSelectedValue } from './utils';
import ProductScheduleRangePanel from './ProductScheduleRangePanel';

const fieldName = ProductFieldName.RANGE;

const ProductScheduleRange: React.FC = () => {
  const dispatch = useDispatch();
  const secondaryPanelType = useSelector((state: Store) => state.productManagement.secondaryPanel);
  const selectedPermissionForRange = useSelector(
    (state: Store) => state.productManagement.newProduct.range?.selectionPermission,
  );
  const selectedRange = useSelector((state: Store) => state.productManagement.newProduct.range?.value);
  const onChangePanelVisibility = useOnChangePanelVisibility();

  const isProductScheduleRangePanelOpen = secondaryPanelType === PlannerSecondaryPanelType.SCHEDULE;

  const handleSubmit = ({
    startDate,
    endDate,
    selectedPermission,
  }: {
    startDate: Date;
    endDate: Date;
    selectedPermission: CodeNameModel;
  }): void => {
    const value = isValueRequired(selectedPermission.code)
      ? { startDate, endDate }
      : { startDate: undefined, endDate: undefined };

    dispatch(changeNewProduct({ fieldName, value, selectedPermission }));
    dispatch(hideSecondaryPanel());
  };

  return (
    <>
      <SectionButton
        dataTestId="section-button-deal-schedule"
        label="Range"
        isActive={isProductScheduleRangePanelOpen}
        theme={SectionButtonTheme.SOLID}
        onClick={() => onChangePanelVisibility(PlannerSecondaryPanelType.SCHEDULE)}
        icon={calendarSvg}
        onClear={() => {
          dispatch(clearItemInNewProduct({ fieldName }));
          dispatch(hideSecondaryPanel());
        }}
        selected={getSelectedValue({
          selectedPermission: selectedPermissionForRange,
          value: { startDate: selectedRange?.startDate, endDate: selectedRange?.endDate },
        })}
        isDisabled={false}
        isRequired
      />
      {isProductScheduleRangePanelOpen && <ProductScheduleRangePanel onConfirm={handleSubmit} isReadOnly={false} />}
    </>
  );
};

export default ProductScheduleRange;
