import createRequest from 'modules/api/request';
import { URLS, HTTP_METHODS } from 'modules/api/constants';
import { CancelFunctions } from 'components/common/types';
import {
  PublisherConfigurationResponse,
  PublisherDirectSalesPricing,
  PublisherFeatures,
} from 'components/common/types/Publisher.types';

export const getPublisherConfiguration = async ({
  envId,
  cancelFunctions,
  cancelable = true,
}: {
  envId: string;
  cancelFunctions: CancelFunctions;
  cancelable?: boolean;
}): Promise<PublisherConfigurationResponse> => {
  const { send, cancel } = createRequest({
    url: URLS().GET_PUBLISHER_CONFIGURATION(envId),
    method: HTTP_METHODS.GET,
    headers: { 'publisher-id': null, 'env-id': null },
    cancelable,
  });

  if (cancelFunctions.GET_PUBLISHER_CONFIGURATION) cancelFunctions.GET_PUBLISHER_CONFIGURATION();
  cancelFunctions.GET_PUBLISHER_CONFIGURATION = cancel;

  const result = await send();
  return result.data;
};

export const getPublisherFeatures = async ({
  envId,
  cancelFunctions,
  cancelable = true,
}: {
  envId: string;
  cancelFunctions: CancelFunctions;
  cancelable?: boolean;
}): Promise<PublisherFeatures> => {
  const { send, cancel } = createRequest({
    url: URLS().GET_PUBLISHER_FEATURES(envId),
    method: HTTP_METHODS.GET,
    headers: { 'publisher-id': null, 'env-id': null },
    cancelable,
  });

  if (cancelFunctions.GET_PUBLISHER_FEATURES) cancelFunctions.GET_PUBLISHER_FEATURES();
  cancelFunctions.GET_PUBLISHER_FEATURES = cancel;

  const result = await send();
  return result.data.applicationConfiguration;
};

export const getDirectSalesPublisherPricing = async ({
  envId,
  cancelFunctions,
  cancelable = true,
}: {
  envId: string;
  cancelFunctions: CancelFunctions;
  cancelable?: boolean;
}): Promise<PublisherDirectSalesPricing> => {
  const { send, cancel } = createRequest({
    url: URLS().GET_PUBLISHER_DIRECT_SALES_PRICING(envId),
    method: HTTP_METHODS.GET,
    headers: { 'publisher-id': null, 'env-id': null },
    cancelable,
  });

  if (cancelFunctions.GET_PUBLISHER_DIRECT_SALES_PRICING) cancelFunctions.GET_PUBLISHER_DIRECT_SALES_PRICING();
  cancelFunctions.GET_PUBLISHER_DIRECT_SALES_PRICING = cancel;

  const result = await send();
  return result.data.applicationConfiguration;
};
