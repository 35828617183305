export enum AssetTypes {
  STATIC = 'STATIC',
  SCROLLING = 'SCROLLING',
  DIGITAL = 'DIGITAL',
}

export interface CodeType<T = string> {
  code: T;
}
export interface CodeNameType extends CodeType {
  name: string;
}

export type ProductFormat = CodeNameType;

export interface AssetListFilters {
  assetType?: CodeType<AssetTypes>;
  businessArea?: CodeNameType;
  channel?: CodeNameType;
  endDate: Date;
  frameCode?: string;
  network?: CodeNameType;
  productFormat?: ProductFormat;
  routeFrameCode?: string;
  startDate: Date;
  tag?: CodeNameType;
  visualUnit?: CodeType;
}

export interface InventoryManagementAssetList {
  frameCode: string;
}
