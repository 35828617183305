import SVG from 'react-inlinesvg';
import lockedSvg from 'assets/icons/locked.svg';

export const DirectSalesLockedLineStatusIcon: React.FC = () => {
  return (
    <div data-test-id="lock-state-status-icon" title="Locked">
      <SVG src={lockedSvg} className="w-4 h-4" />
    </div>
  );
};
