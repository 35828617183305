import { useSelector } from 'react-redux';

import { RootState } from 'store';
import { FormatTypeEnum } from 'modules/I18N';

import { DealSummaryCardSectionLineType } from 'components/pages/DealWithLines/common/RightSidebar/DealSummary/DealSummaryCardSection/DealSummaryCardSection.types';
import { CardTheme } from 'lib/Card/Card.types';

import { handleValueFormat } from 'components/pages/DealWithLines/common/RightSidebar/utils';
import { DealSummaryCard } from 'components/pages/DealWithLines/common/RightSidebar/DealSummary/DealSummaryCard/DealSummaryCard';
import DealSummaryCardSection from 'components/pages/DealWithLines/common/RightSidebar/DealSummary/DealSummaryCardSection';
import Button, { Color, Size } from 'lib/Button';
import listSvg from 'assets/icons/list.svg';
import reportSvg from 'assets/icons/report.svg';

import { LevelType } from 'consts/thoughtspot';
import { DealStatus, DealType } from 'components/common/types/Deal.types';
import { Store } from 'components/common/types/Store.types';
import { ReportType } from 'components/common/AllocationReport/AllocationReport.types';
import { DealSummaryFrameList } from '../../DealSummaryFrameList/DealSummaryFrameList';
import { DealSummaryAllocationReport } from '../../DealSummaryAllocationReport/DealSummaryAllocationReport';
import { ProposalCardProps } from './ProposalCard.types';

export const ProposalCard: React.FC<ProposalCardProps> = ({
  assets,
  totalCost,
  allocatedImpressions,
  availableImpressions,
  allocatedFrames,
  availableFrames,
  defaultAllocationReportLevelType = LevelType.LINE,
  isAvailabilityHidden = false,
  isVisible,
}) => {
  const { currencyCode, localeCode } = useSelector((state: RootState) => state.publisher.configuration);
  const bookingStatusCode = useSelector((state: Store) => state.dealManagement.commonDeal.bookingStatusCode);
  const dealType = useSelector((state: Store) => state.dealManagement.commonDeal.dealType);
  const isGuaranteed = dealType === DealType.GUARANTEED;
  const isReadOnly = isGuaranteed && bookingStatusCode === DealStatus.CANCELLED;
  const buttonsVisibility = {
    frameList: defaultAllocationReportLevelType === LevelType.LINE,
    report: isGuaranteed && bookingStatusCode !== DealStatus.CANCELLED,
  };
  const showButtonsContainer = Object.values(buttonsVisibility).some((isButtonVisible) => isButtonVisible);

  return (
    <DealSummaryCard header="PROPOSAL" isVisible={isVisible} theme={CardTheme.SECONDARY}>
      <DealSummaryCardSection
        lines={[
          {
            label: currencyCode,
            value: handleValueFormat({
              valueType: FormatTypeEnum.NUMBER,
              value: totalCost,
              currencyCode,
              localeCode,
            }),
            lineType: DealSummaryCardSectionLineType.HEADER,
          },
        ]}
        isReadOnly={isReadOnly}
      />
      <DealSummaryCardSection
        lines={[
          {
            label: 'Impressions',
            value: handleValueFormat({
              valueType: FormatTypeEnum.ROUNDED_NUMBER,
              value: allocatedImpressions,
              currencyCode,
              localeCode,
            }),
            lineType: DealSummaryCardSectionLineType.HEADER,
          },
          ...(isAvailabilityHidden
            ? []
            : [
                {
                  label: 'Available',
                  value: handleValueFormat({
                    valueType: FormatTypeEnum.ROUNDED_NUMBER,
                    value: availableImpressions,
                    currencyCode,
                    localeCode,
                  }),
                  lineType: DealSummaryCardSectionLineType.SUBHEADER_SECONDARY,
                },
              ]),
        ]}
        isReadOnly={isReadOnly}
      />
      <DealSummaryCardSection
        lines={[
          {
            label: 'Frames',
            value: handleValueFormat({
              valueType: FormatTypeEnum.NUMBER,
              value: allocatedFrames,
              currencyCode,
              localeCode,
            }),
            lineType: DealSummaryCardSectionLineType.HEADER,
          },
          ...(isAvailabilityHidden
            ? []
            : [
                {
                  label: 'Available',
                  value: handleValueFormat({
                    valueType: FormatTypeEnum.NUMBER,
                    value: availableFrames,
                    currencyCode,
                    localeCode,
                  }),
                  lineType: DealSummaryCardSectionLineType.SUBHEADER_SECONDARY,
                },
              ]),
        ]}
        isReadOnly={isReadOnly}
      >
        {showButtonsContainer && (
          <div className="flex flex-row gap-x-2 h-9">
            {buttonsVisibility.frameList && (
              <DealSummaryFrameList
                assets={assets}
                actionButton={(onClick) => (
                  <Button color={Color.TERTIARY} label="Frame list" size={Size.WIDE} svg={listSvg} onClick={onClick} />
                )}
              />
            )}
            {buttonsVisibility.report && (
              <DealSummaryAllocationReport
                buttonLabel="Report"
                actionButton={(onClick) => (
                  <Button
                    color={Color.TERTIARY}
                    label="Report"
                    size={Size.WIDE}
                    svg={reportSvg}
                    onClick={onClick}
                    ddActionName="Allocation report / Proposal card report button"
                  />
                )}
                defaultLevelType={defaultAllocationReportLevelType}
                reportType={ReportType.NOT_SAVED}
              />
            )}
          </div>
        )}
      </DealSummaryCardSection>
    </DealSummaryCard>
  );
};
