import { FC, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { changePlannerSecondaryPanelType, PlannerSecondaryPanelType } from 'store/dealManagement/reducer';

import ToggleSwitch from 'components/patterns/ToggleSwitch';
import Button, { Color } from 'lib/Button';
import Chip from 'lib/Chip';

import plusSVG from 'assets/icons/plus.svg';

import { changeAdvertiserOptions } from 'store/userOptions/reducer';
import { getLookupData } from 'modules/api/lookups';
import { useCancelRequest } from 'customHooks/useCancelRequest';

import { CodeName } from 'components/common/types/CodeName.types';
import { DirectSalesExclusionFlag } from 'components/common/types/DirectSalesDeal.types';
import { Size, TextPriority } from 'lib/Chip/Chip.types';
import { Store } from 'components/common/types/Store.types';
import { DuplicationProps } from './Duplication.types';

const Duplication: FC<DuplicationProps> = ({
  organisations,
  duplicationExclusion,
  setDuplicationExclusion,
  onRemove,
}) => {
  const dispatch = useDispatch();
  const cancelFunctions = useCancelRequest();

  const advertisers = useSelector((state: Store) => state.userOptions.advertiserOptions);

  const isDuplicationOn = duplicationExclusion === DirectSalesExclusionFlag.ON;

  const onDuplicationChange = (): void => {
    setDuplicationExclusion(isDuplicationOn ? DirectSalesExclusionFlag.OFF : DirectSalesExclusionFlag.ON);
  };

  useEffect(() => {
    const getAdvertisers = async (): Promise<void> => {
      try {
        const advertisersLookup = await getLookupData({ cancelFunctions, lookupURLName: 'advertiser', query: '' });
        dispatch(changeAdvertiserOptions(advertisersLookup.map(({ code, name }: CodeName) => ({ code, name }))));
      } catch (error) {
        // eslint-disable-next-line no-console
        console.warn(`Error fetching meta data: ${error}`);
      }
    };
    if (!advertisers.length) {
      getAdvertisers();
    }
  }, [advertisers]);

  return (
    <div className="relative w-full gap-x-2 py-3 border-gray-200 border-b">
      <div className="relative flex w-full justify-between mb-2">
        <p className="body-base font-semibold">Duplication</p>
        <ToggleSwitch
          isChecked={isDuplicationOn}
          onChange={() => onDuplicationChange()}
          dataTestId="duplication-switch"
        />
      </div>
      {isDuplicationOn && (
        <div className="relative flex w-full justify-between align-top">
          <div>
            <Button
              color={Color.TRANSPARENT}
              label="Add"
              svg={plusSVG}
              onClick={() => dispatch(changePlannerSecondaryPanelType(PlannerSecondaryPanelType.DUPLICATION))}
            />
          </div>
          <div className="justify-items-end">
            {organisations.map(({ code, name }) => {
              return (
                <div className="pb-2" key={code}>
                  <Chip
                    label={name}
                    isRemovable
                    size={Size.MEDIUM}
                    textPriority={TextPriority.EQUAL}
                    onRemove={onRemove}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Duplication;
