import { CodeNameModel } from 'components/common/types';

export enum ProductCreationStatus {
  ACTIVE = 'Active',
  DISABLED = 'Disabled',
}

export interface ProductCreationInterface {
  id: string;
  productName: string;
  folder: string;
  status: ProductCreationStatus;
}

export type PCMTableFiltersProps = {
  folders: CodeNameModel[];
  selectedFolderGroup: string;
  onChangeSelectedFolderGroup: (value: string) => void;
  onFilterTextChange: (value: string) => void;
  searchText: string;
  onToggle: (isHideDisabled: boolean) => void;
};
