import { getDateInRequestFormat } from 'utils/dateUtil';
import {
  DirectSalesProductRules,
  DirectSalesProductTemplateBehaviour,
  DirectSalesProductRuleAllowedSet,
  DirectSalesProductRuleFixedSet,
  DirectSalesAllowedSet,
  DirectSalesFixedSet,
  productRuleMap,
  DirectSalesProductTemplateChannelFilter,
  DirectSalesProductTemplateRuleType,
  DirectSalesProductTemplateMediaFilter,
  DirectSalesProductTemplateTargetFilter,
  DirectSalesProductFormatsFilter,
  DirectSalesProductTemplateNetworkFilter,
} from 'components/common/types/DirectSalesProductTemplate.types';
import { ProductManagementFields, ProductTarget, SelectionPermission } from 'store/productManagement/reducer.types';
import { isEmpty } from 'lodash';
import { mapLocationFilter, mappedFormatFilterName } from 'components/pages/Planner/hooks/utils/transformFilters';
import { DirectSalesCodeNameModel } from 'components/common/types/DirectSalesCampaign.types';
import { FormatLabel } from 'store/userOptions/reducer.types';

export const filterBySelectionPermission = (
  productTemplate: ProductManagementFields,
  permission: SelectionPermission,
): ProductManagementFields => {
  return Object.fromEntries(
    Object.entries(productTemplate).filter(([, value]) => value?.selectionPermission === permission),
  );
};

export const filterAnyDisabledValues = (productTemplate: ProductManagementFields): ProductManagementFields => {
  return Object.fromEntries(
    Object.entries(productTemplate).filter(
      ([, value]) => value !== undefined && value.selectionPermission !== SelectionPermission.DISABLED,
    ),
  );
};

export const transformNoRestrictionOptions = (fields: ProductManagementFields): DirectSalesProductRules[] => {
  return Object.keys(fields).map((fieldName) => {
    return {
      ruleType: productRuleMap[fieldName] as DirectSalesProductTemplateRuleType,
      behaviour: DirectSalesProductTemplateBehaviour.ANY,
    };
  });
};

const transformChannel = (fields: ProductManagementFields): DirectSalesProductTemplateChannelFilter[] => {
  const fieldValue = fields.channels?.value;

  if (!fieldValue || !fieldValue?.length) {
    return [];
  }

  return fieldValue.map(({ code, name }) => ({
    type: productRuleMap.channels as DirectSalesProductTemplateRuleType,
    name,
    id: code,
  }));
};

const transformPricingMode = (fields: ProductManagementFields): string[] => {
  const fieldValue = fields.pricingMode?.value;

  if (!fieldValue) {
    return [];
  }

  return [fieldValue] as string[];
};

const transformMediaType = (fields: ProductManagementFields): DirectSalesProductTemplateMediaFilter[] => {
  const fieldValue = fields.mediaType?.value;

  if (!fieldValue) {
    return [];
  }

  return [
    {
      type: productRuleMap.mediaType as DirectSalesProductTemplateRuleType,
      mediaType: fieldValue,
    },
  ];
};

const transformTarget = (target: ProductTarget): DirectSalesProductTemplateTargetFilter[] => {
  if (!target.value || !target.greenTolerance || !target.orangeTolerance || !target.priority) {
    return [];
  }

  return [
    {
      target: target.value,
      defaultGreenTolerance: target.greenTolerance / 100,
      defaultOrangeTolerance: target.orangeTolerance / 100,
      defaultPriority: Number(target.priority.code),
    },
  ];
};

const transformBudget = (fields: ProductManagementFields): DirectSalesProductTemplateTargetFilter[] => {
  const fieldValue = fields?.budget;

  if (!fieldValue) {
    return [];
  }

  return transformTarget(fieldValue);
};

const transformFrames = (fields: ProductManagementFields): DirectSalesProductTemplateTargetFilter[] => {
  const fieldValue = fields?.frames;

  if (!fieldValue) {
    return [];
  }

  return transformTarget(fieldValue);
};

const transformImpressions = (fields: ProductManagementFields): DirectSalesProductTemplateTargetFilter[] => {
  const fieldValue = fields?.impressions;

  if (!fieldValue) {
    return [];
  }

  return transformTarget(fieldValue);
};

const transformNetworks = (fields: ProductManagementFields): DirectSalesProductTemplateNetworkFilter[] => {
  const fieldValue = fields?.networks?.value;

  if (!fieldValue) {
    return [];
  }

  return fieldValue.map(({ target, priority, code, greenTolerance, orangeTolerance }) => ({
    target: Number(target),
    defaultPriority: Number(priority.code),
    networkId: code,
    defaultGreenTolerance: greenTolerance / 100,
    defaultOrangeTolerance: orangeTolerance / 100,
  }));
};

const transformFormats = (uniqueCategoryValues: DirectSalesCodeNameModel[]): DirectSalesProductFormatsFilter[] => {
  return uniqueCategoryValues.map(({ category, include, name, code }) => ({
    type: category ? mappedFormatFilterName[category] : '',
    selectionType: include ? 'Include' : 'Exclude',
    name,
    id: code,
  }));
};

const transformLocations = (uniqueCategoryValues: DirectSalesCodeNameModel[]): DirectSalesProductFormatsFilter[] => {
  return uniqueCategoryValues.map(({ category, include, name, code }) => ({
    type: category ? mapLocationFilter[category] : '',
    selectionType: include ? 'Include' : 'Exclude',
    name,
    id: code,
  }));
};

export const transformFixedSetRuleBasedOnField = (
  fields: ProductManagementFields,
  fieldName: string,
): DirectSalesFixedSet => {
  if (isEmpty(fields)) return [];

  if (fieldName === 'channels') {
    return transformChannel(fields);
  }

  if (fieldName === 'pricingMode') {
    return transformPricingMode(fields);
  }

  if (fieldName === 'mediaType' && fields[fieldName]?.value) {
    return transformMediaType(fields);
  }

  if (fieldName === 'budget') {
    return transformBudget(fields);
  }

  if (fieldName === 'frames') {
    return transformFrames(fields);
  }

  if (fieldName === 'impressions') {
    return transformImpressions(fields);
  }

  if (fieldName === 'networks') {
    return transformNetworks(fields);
  }

  return [];
};

export const transformFixedValues = (fields: ProductManagementFields): DirectSalesProductRuleFixedSet[] => {
  return Object.keys(fields).map((fieldName) => {
    return {
      ruleType: productRuleMap[fieldName] as DirectSalesProductTemplateRuleType,
      behaviour: DirectSalesProductTemplateBehaviour.FIXED,
      fixedSet: transformFixedSetRuleBasedOnField(fields, fieldName),
    };
  });
};

export const transformFixedFormatValues = (fields: ProductManagementFields): DirectSalesProductRuleFixedSet[] => {
  if (fields.formats && fields.formats.selectionPermission === SelectionPermission.FIXED_VALUE) {
    const fieldValue = fields?.formats?.value;

    if (!fieldValue) {
      return [];
    }

    const uniqueCategories = [...new Set(fieldValue.map(({ category }) => category))];

    if (!uniqueCategories) return [];

    const uniqueCategoryValues = uniqueCategories.map((category) =>
      fieldValue.map((item) => (item.category === category ? item : null)).filter(Boolean),
    );

    return uniqueCategories.flatMap((uniqueCategory) => {
      const currentUniqueCategoryValues = uniqueCategoryValues.filter((formatCategory: DirectSalesCodeNameModel[]) => {
        if (!formatCategory?.length) return [];
        return formatCategory.find(({ category }) => category === uniqueCategory);
      })[0];

      return {
        ruleType: mappedFormatFilterName[uniqueCategory as string] as DirectSalesProductTemplateRuleType,
        behaviour: DirectSalesProductTemplateBehaviour.FIXED,
        fixedSet: transformFormats(currentUniqueCategoryValues as DirectSalesCodeNameModel[]),
      };
    });
  }

  return [];
};

export const transformFixedLocationValues = (fields: ProductManagementFields): DirectSalesProductRuleFixedSet[] => {
  if (fields.locations && fields.locations.selectionPermission === SelectionPermission.FIXED_VALUE) {
    const fieldValue = fields?.locations?.value;

    if (!fieldValue) {
      return [];
    }

    const uniqueCategories = [...new Set(fieldValue.map(({ category }) => category))];

    if (!uniqueCategories) return [];

    const uniqueCategoryValues = uniqueCategories.map((category) =>
      fieldValue.map((item) => (item.category === category ? item : null)).filter(Boolean),
    );

    return uniqueCategories.flatMap((uniqueCategory) => {
      const currentUniqueCategoryValues = uniqueCategoryValues.filter(
        (locationCategory: DirectSalesCodeNameModel[]) => {
          if (!locationCategory?.length) return [];
          return locationCategory.find(({ category }) => category === uniqueCategory);
        },
      )[0];

      return {
        ruleType: mapLocationFilter[uniqueCategory as string] as DirectSalesProductTemplateRuleType,
        behaviour: DirectSalesProductTemplateBehaviour.FIXED,
        fixedSet: transformLocations(currentUniqueCategoryValues as DirectSalesCodeNameModel[]),
      };
    });
  }

  return [];
};

export const transformAllowedSetRuleBasedOnField = (
  fields: ProductManagementFields,
  fieldName: string,
): DirectSalesAllowedSet => {
  if (fieldName === 'range' && fields[fieldName]?.value) {
    return [
      {
        startDate: getDateInRequestFormat(fields[fieldName]!.value?.startDate as Date, [0, 0, 0, 0]),
        endDate: getDateInRequestFormat(fields[fieldName]!.value?.endDate as Date, [23, 59, 59, 999]),
      },
    ];
  }

  return [];
};

export const transformAllowedValues = (fields: ProductManagementFields): DirectSalesProductRuleAllowedSet[] => {
  return Object.keys(fields).map((fieldName) => {
    return {
      ruleType: productRuleMap[fieldName] as DirectSalesProductTemplateRuleType,
      behaviour: DirectSalesProductTemplateBehaviour.SET,
      allowedSet: transformAllowedSetRuleBasedOnField(fields, fieldName),
    };
  });
};

export const transformNoRestrictionFormatOptions = (fields: ProductManagementFields): DirectSalesProductRules[] => {
  if (fields.formats) {
    const allCategories = Object.values(FormatLabel);

    return allCategories.flatMap((uniqueCategory) => ({
      ruleType: mappedFormatFilterName[uniqueCategory as string] as DirectSalesProductTemplateRuleType,
      behaviour: DirectSalesProductTemplateBehaviour.ANY,
    }));
  }

  return [];
};

export const transformNoRestrictionLocationOptions = (fields: ProductManagementFields): DirectSalesProductRules[] => {
  if (fields.locations) {
    const locationCategories = [
      'Conurbation',
      'Country',
      'County',
      'Route Town',
      'District',
      'Town',
      'TV Area',
      'Postcode',
    ];

    return locationCategories.flatMap((uniqueCategory) => ({
      ruleType: mapLocationFilter[uniqueCategory as string] as DirectSalesProductTemplateRuleType,
      behaviour: DirectSalesProductTemplateBehaviour.ANY,
    }));
  }

  return [];
};

export const transformDirectSalesProductToRules = (
  productTemplate: ProductManagementFields,
): (DirectSalesProductRules | DirectSalesProductRuleFixedSet | DirectSalesProductRuleAllowedSet)[] => {
  const enabledValues = filterAnyDisabledValues(productTemplate);

  const {
    formats: formatsAllOptions,
    locations: locationAllOptions,
    ...fixedAllOptions
  } = filterBySelectionPermission(enabledValues, SelectionPermission.ALL_OPTIONS);
  const allOptions = transformNoRestrictionOptions(fixedAllOptions);

  const {
    formats: formatsFixedOptions,
    locations: locationFixedOptions,
    ...fixedSelectionValues
  } = filterBySelectionPermission(enabledValues, SelectionPermission.FIXED_VALUE);
  const fixedValues = transformFixedValues(fixedSelectionValues);

  const allOptionsFormatValues = transformNoRestrictionFormatOptions(
    filterBySelectionPermission(enabledValues, SelectionPermission.ALL_OPTIONS),
  );
  const fixedFormatValues = transformFixedFormatValues(
    filterBySelectionPermission(enabledValues, SelectionPermission.FIXED_VALUE),
  );

  const allOptionsLocationValues = transformNoRestrictionLocationOptions(
    filterBySelectionPermission(enabledValues, SelectionPermission.ALL_OPTIONS),
  );
  const fixedLocationValues = transformFixedLocationValues(
    filterBySelectionPermission(enabledValues, SelectionPermission.FIXED_VALUE),
  );

  const allowedValues = transformAllowedValues(
    filterBySelectionPermission(enabledValues, SelectionPermission.ALLOWED_VALUES),
  );

  return [
    ...allOptions,
    ...fixedValues,
    ...allowedValues,
    ...fixedFormatValues,
    ...allOptionsFormatValues,
    ...fixedLocationValues,
    ...allOptionsLocationValues,
  ];
};
