import DealIdRenderer from 'components/common/DealIdRenderer';

export type CampaignNameAndRefType = { campaignName?: string; campaignRef: string };

const CampaignNameAndRef: React.FC<CampaignNameAndRefType> = ({ campaignName, campaignRef }) => {
  if (!campaignName && !campaignRef) return <></>;

  return (
    <>
      <p className="mt-0 mb-0 sub-header-base" data-test-id="CampaignNameAndRef-subHeader">
        <DealIdRenderer data={{ dealId: campaignName || campaignRef }} />
      </p>
      {!!campaignName && (
        <p className="mt-0 mb-0 text-neutral-600 body-base" data-test-id="CampaignNameAndRef-body">
          <DealIdRenderer data={{ dealId: campaignRef }} />
        </p>
      )}
    </>
  );
};

export default CampaignNameAndRef;
