import { FolderInput } from './FolderInput/FolderInput';

export const CreateProductSidebar: React.FC = () => {
  return (
    <div
      className="w-[var(--campaign-drawer-width)] shrink-0 bg-white h-full border-x border-neutral-950-opacity-10 flex flex-col overflow-auto"
      data-test-id="create-product-sidebar"
    >
      <div className="flex flex-col py-4" data-test-id="create-product-sidebar-content">
        <FolderInput />
      </div>
    </div>
  );
};
