import { CodeNameModel } from 'components/common/types';
import { Environment } from 'components/common/types/Deal.types';
import {
  DirectSalesCodeNameModel,
  DirectSalesMediaType,
  DirectSalesPricingMode,
} from 'components/common/types/DirectSalesCampaign.types';
import { NetworkCriteriaType } from 'components/common/types/DirectSalesNetworks.types';
import { ToastProps } from 'lib/Toaster';
import { PlannerSecondaryPanelType } from 'store/dealManagement/reducer';

export enum ProductFieldName {
  OBJECTIVES = 'objectives',
  NETWORKS = 'networks',
  PRICING_MODE = 'pricingMode',
  RANGE = 'range',
  FORMATS = 'formats',
  MEDIA_TYPE = 'mediaType',
  TARGETS = 'targets',
  CHANNELS = 'channels',
  LOCATIONS = 'locations',
}

export enum SelectionPermission {
  ALL_OPTIONS = 'ALL_OPTIONS',
  FIXED_VALUE = 'FIXED_VALUE',
  DEFAULT_VALUE = 'DEFAULT_VALUE',
  DISABLED = 'DISABLED',
  ALLOWED_VALUES = 'ALLOWED_VALUES',
}

export type SelectedProductTemplateFields = {
  id: string;
};

export type NewProductCreationPayload = {
  selectedPermission: CodeNameModel;
  fieldName: string;
  value?:
    | DirectSalesMediaType
    | DirectSalesPricingMode
    | Environment[]
    | DirectSalesCodeNameModel[]
    | ProductScheduleRange
    | NetworkCriteriaType[];
};

export type ClearNewProductCreationPayload = {
  fieldName: string;
};

export type ClearSingleTargetPayload = {
  targetName: string;
};

export type ProductScheduleRange = {
  startDate?: Date;
  endDate?: Date;
};

export type ProductTarget = {
  selectionPermission: SelectionPermission;
  value?: number;
  priority?: DirectSalesCodeNameModel;
  greenTolerance?: number;
  orangeTolerance?: number;
};

export type ProductTargets = {
  budget?: ProductTarget;
  impressions?: ProductTarget;
  frames?: ProductTarget;
};

export interface ProductManagementFields {
  mediaType?: {
    selectionPermission: SelectionPermission;
    value?: DirectSalesMediaType;
  };
  pricingMode?: {
    selectionPermission: SelectionPermission;
    value?: DirectSalesPricingMode;
  };
  range?: {
    selectionPermission: SelectionPermission;
    value?: ProductScheduleRange;
  };
  channels?: {
    selectionPermission: SelectionPermission;
    value?: Environment[];
  };
  locations?: {
    selectionPermission: SelectionPermission;
    value?: DirectSalesCodeNameModel[];
  };
  formats?: {
    selectionPermission: SelectionPermission;
    value?: DirectSalesCodeNameModel[];
  };
  objectives?: {
    selectionPermission: SelectionPermission;
  };
  budget?: ProductTarget;
  frames?: ProductTarget;
  impressions?: ProductTarget;
  networks?: {
    selectionPermission: SelectionPermission;
    value?: NetworkCriteriaType[];
  };
}

export interface ProductManagement {
  newProduct: {
    id: string;
    name: string;
    folder: string;
  } & ProductManagementFields;
  selectedProduct: SelectedProductTemplateFields;
  secondaryPanel: PlannerSecondaryPanelType;
  toastMessages: ToastProps[];
}
