import Button, { Size } from 'lib/Button';
import usePendoTracking from 'customHooks/usePendoTracking';
import AdvertiserInfoConfirmationModal from 'components/common/Modals/AdvertiserInfoConfirmationModal';
import useCampaignType from 'components/pages/Planner/hooks/useCampaignType';
import useIsReadOnly from 'components/pages/Planner/useIsReadOnly';
import useHandleSaveButton from 'customHooks/useHandleSaveButton';
import { SaveCampaignButtonProps } from './SaveCampaignButton.types';

const SaveCampaignButton: React.FC<SaveCampaignButtonProps> = ({ color }) => {
  const { isDirectSalesCampaignType } = useCampaignType();
  const {
    handleSave,
    handleUndoChanges,
    handleConfirmChanges,
    showSaveButton,
    isSaveDisabled,
    showAdvertiserInfoConfirmationModal,
  } = useHandleSaveButton();

  const { isCampaignReadOnly } = useIsReadOnly();

  const { getPendoDataId } = usePendoTracking();

  if (!isDirectSalesCampaignType && !showSaveButton) return null;

  return (
    <>
      <div className="flex h-8 mx-6 mb-4 mt-auto">
        <Button
          size={Size.FIT_CONTAINER}
          color={color}
          label="Confirm"
          onClick={handleSave}
          isDisabled={isSaveDisabled || isCampaignReadOnly}
          dataPendoId={getPendoDataId('Bottom Save Campaign')}
        />
      </div>
      <AdvertiserInfoConfirmationModal
        isVisible={showAdvertiserInfoConfirmationModal}
        onUndoChanges={handleUndoChanges}
        onConfirm={handleConfirmChanges}
      />
    </>
  );
};

export default SaveCampaignButton;
