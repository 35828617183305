import { FC, useEffect, useMemo, useState } from 'react';

import { isEqual } from 'lodash';

import { useDispatch, useSelector } from 'react-redux';
import { changePlannerSecondaryPanelType, PlannerSecondaryPanelType } from 'store/dealManagement/reducer';

import PlannerSecondaryPanel from 'components/pages/Planner/PlannerSecondaryPanel';
import { useCategoryOptions } from 'components/pages/Planner/PlannerSecondaryPanel/useCategoryOptions';
import { canDisplayOptionResults } from 'components/pages/Planner/PlannerSecondaryPanel/utils';
import { shouldDisplayCategoryClearAll } from 'components/pages/Planner/PlannerSections/FiltersContent/utils';

import CollapsibleList from 'lib/CollapsibleList';
import CollapsibleListStatsHeader from 'lib/CollapsibleList/CollapsibleListStatsHeader';
import Input from 'lib/Input';
import SelectOption from 'lib/SelectOption';

import { InputType } from 'components/common/types/Input.types';
import { CodeNameModel } from 'components/common/types';
import { Store } from 'components/common/types/Store.types';
import { DirectSalesCodeNameModel } from 'components/common/types/DirectSalesCampaign.types';
import { JuxtapositionPanelProps } from './JuxtapositionPanel.types';

const JuxtapositionPanel: FC<JuxtapositionPanelProps> = ({ productCategories, setProductCategories }) => {
  const dispatch = useDispatch();
  const productCategoryGroups = useSelector((state: Store) => state.dealManagement.meta.productCategoryGroups) || [];

  const [tmpProductCategories, setTmpProductCategories] = useState<DirectSalesCodeNameModel[]>(productCategories);

  const productCategoriesGroupMap = useMemo(() => {
    return productCategoryGroups.reduce(
      (acc, cur) => {
        acc[cur.code] = cur.productCategories ?? [];
        return acc;
      },
      {} as Record<string, CodeNameModel[]>,
    );
  }, [productCategoryGroups]);

  const {
    searchText,
    selectedOptions: selectedCampaignProductFormats,
    onCategoryDirectionSort,
    openCategory,
    setSelectedOptions,
    filteredOptions,
    getNumberOfFilteredOptions,
    sortDirection,
    includedOptions,
    excludedOptions,
    isOptionSelected,
    onFilterTextChange,
    onToggleCategory,
    onOptionIncludeExcludeChange,
  } = useCategoryOptions(productCategoriesGroupMap, tmpProductCategories);

  const categoryNameMap = useMemo(() => {
    return Object.keys(productCategoriesGroupMap).reduce(
      (acc, categoryId) => {
        productCategoriesGroupMap[categoryId].forEach((category) => {
          acc[category.code] = categoryId;
        });
        return acc;
      },
      {} as Record<string, string>,
    );
  }, [productCategoriesGroupMap]);

  const excludedOptionsWithGroupName = useMemo(() => {
    return excludedOptions.map((excludedCategory) => ({
      ...excludedCategory,
      category: categoryNameMap[excludedCategory.code],
    }));
  }, [excludedOptions, categoryNameMap]);

  const productCategoryLabels = useMemo(() => {
    return Object.keys(productCategoriesGroupMap).reduce(
      (acc, cur) => {
        acc[cur] = productCategoryGroups.find((group) => group.code === cur)?.name || '';
        return acc;
      },
      {} as Record<string, string>,
    );
  }, [productCategoriesGroupMap]);

  const isChange = useMemo(
    () => !isEqual(productCategories, selectedCampaignProductFormats),
    [productCategories, selectedCampaignProductFormats],
  );

  const onSubmit = (): void => {
    setProductCategories(excludedOptionsWithGroupName);
    dispatch(changePlannerSecondaryPanelType(PlannerSecondaryPanelType.EXCLUSION_CRITERIA));
  };

  useEffect(() => {
    setTmpProductCategories(excludedOptionsWithGroupName);
  }, [isChange]);

  useEffect(() => {
    setTmpProductCategories(productCategories);
  }, [productCategories]);

  return (
    <PlannerSecondaryPanel
      title="Add product category"
      confirmButtonLabel="Save"
      customStyles={{ left: '430px' }}
      isLineLevel={false}
      isChange={isChange}
      onSubmit={onSubmit}
      onCancel={() => dispatch(changePlannerSecondaryPanelType(PlannerSecondaryPanelType.EXCLUSION_CRITERIA))}
    >
      <div className="mb-2">
        <Input
          type={InputType.SEARCH}
          value={searchText}
          placeholder="Search product category"
          onChange={onFilterTextChange}
        />
      </div>

      {canDisplayOptionResults(filteredOptions) ? (
        <>
          {!!filteredOptions &&
            Object.keys(filteredOptions).map((category: keyof typeof productCategoryLabels) => {
              const selectedOptions = selectedCampaignProductFormats.filter(
                (dealLineFormat) => dealLineFormat.category !== productCategoryLabels[category],
              );

              return (
                <CollapsibleList
                  label={productCategoryLabels[category]}
                  isSortVisible
                  onSort={onCategoryDirectionSort}
                  sortDir={sortDirection}
                  isOpen={category === openCategory}
                  onToggle={() => onToggleCategory(category)}
                  isClearAllVisible={shouldDisplayCategoryClearAll(
                    selectedCampaignProductFormats,
                    productCategoryLabels[category],
                  )}
                  onClearAll={() => setSelectedOptions(selectedOptions)}
                  key={`product-group-${productCategoryLabels[category]}`}
                  headerComponent={
                    <CollapsibleListStatsHeader
                      available={filteredOptions[category].length}
                      included={getNumberOfFilteredOptions(category, includedOptions)}
                      excluded={getNumberOfFilteredOptions(category, excludedOptions)}
                    />
                  }
                >
                  {filteredOptions[category].map((subOption) => {
                    return (
                      <SelectOption
                        value={{
                          code: subOption.code,
                          name: subOption.name,
                        }}
                        key={`select-category-${subOption.code}`}
                        isSelected={isOptionSelected(subOption.code, category)}
                        isDisabled={false}
                        isMultiple
                        hasCheckbox
                        isIncludeExcludeAlwaysVisible
                        classNames="!m-0"
                        onClick={(value: CodeNameModel) =>
                          onOptionIncludeExcludeChange({
                            ...value,
                            category,
                          })
                        }
                      >
                        <p className="max-w-[250px] truncate">{subOption.name}</p>
                      </SelectOption>
                    );
                  })}
                </CollapsibleList>
              );
            })}
        </>
      ) : (
        <p>There are no results matching your search</p>
      )}
    </PlannerSecondaryPanel>
  );
};

export default JuxtapositionPanel;
