import { useCancelRequest } from 'customHooks/useCancelRequest';
import { getDirectSalesAssetList } from 'modules/api/directSalesCampaign';
import { useDispatch } from 'react-redux';
import {
  changeFormParams,
  clearAssets,
  clearDirectSalesAssetList,
  clearDirectSalesPrices,
  setDirectSalesAssetList,
  setDirectSalesGrid,
  setDirectSalesPrices,
} from 'store/dealManagement/reducer';
import { DirectSalesLine, DirectSalesPricingSolutionResponse } from 'components/common/types/DirectSalesCampaign.types';
import { transformAssetList } from './utils/transformAssetList';
import transformDirectSalesPrice from './utils/transformDirectSalesPrice';
import { isDirectSalesLinePriced } from './utils/getIsDirectSalesLinePriced';
import { isCampaignCancelled } from './utils/isCampaignCancelled';

type Args = {
  campaignId: string;
  marketId: string;
  pricingSolution: DirectSalesPricingSolutionResponse | null;
  lines: Partial<DirectSalesLine>[];
};

type UseDirectSalesPricedSolution = (args: Args) => Promise<void>;

export const useDirectSalesPricedSolution = (): UseDirectSalesPricedSolution => {
  const dispatch = useDispatch();
  const cancelFunctions = useCancelRequest();

  return async ({ campaignId, marketId, pricingSolution, lines }: Args): Promise<void> => {
    const isPriced = lines.every((line: DirectSalesLine) => isDirectSalesLinePriced(line.state.status));

    if (isPriced && pricingSolution) dispatch(setDirectSalesGrid(pricingSolution.grid));

    if (isPriced && pricingSolution && !isCampaignCancelled(lines)) {
      try {
        dispatch(changeFormParams({ isEditingDisabled: true, isLoading: true }));
        const data = await getDirectSalesAssetList(campaignId, marketId, cancelFunctions);

        dispatch(setDirectSalesPrices(transformDirectSalesPrice(pricingSolution)));
        dispatch(setDirectSalesAssetList(transformAssetList(data)));
        dispatch(changeFormParams({ isEditingDisabled: false, isLoading: false }));
        // eslint-disable-next-line no-empty
      } catch {
        dispatch(changeFormParams({ isEditingDisabled: false, isLoading: false }));
      }
    } else {
      dispatch(clearDirectSalesAssetList());
      dispatch(clearAssets());
      dispatch(clearDirectSalesPrices());
    }
  };
};
