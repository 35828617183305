import React, { useState } from 'react';
import cx from 'classnames';
import SVG from 'react-inlinesvg';
import warningSvg from 'assets/icons/diamond_warning.svg';
import closeSvg from 'assets/icons/close.svg';
import IconButton from 'lib/IconButton';
import { ButtonSize } from 'lib/IconButton/IconButton.types';
import { Z_INDEX, LAST_TOAST_OPACITY, NUM_OF_FADING_TOAST, SPACING, ToastElementProps } from './Toaster.types';

export const ToasterElement: React.FC<ToastElementProps> = ({ index, toast, onRemove, maxVisibleToasts }) => {
  const [isNew, setIsNew] = useState(true);
  const [isRemoved, setIsRemoved] = useState(false);

  const onAnimationEnd = (): void => {
    if (isNew) setIsNew(false);
    if (isRemoved) onRemove(toast.id);
  };

  const onDialogClose = (): void => setIsRemoved(true);

  const getToastPositionAndOpacity = (): React.CSSProperties => {
    const calculateOpacity = (): number => {
      if (index < 0) return 0;
      if (index === 0) return 1;

      const stackDepth = index - (maxVisibleToasts - NUM_OF_FADING_TOAST - 1);
      return Math.max(0, 1 - stackDepth * ((1 - LAST_TOAST_OPACITY) / NUM_OF_FADING_TOAST));
    };

    return {
      bottom: `${index * SPACING}px`,
      opacity: calculateOpacity(),
      zIndex: Z_INDEX,
      transform: `scale(1)`,
    };
  };

  return (
    <div
      className={cx(`w-full h-[150px] absolute transition-all duration-300 ease-in-out`, {
        'animate-pop-in-0.3s': isNew,
        'animate-pop-out-0.15s': isRemoved,
      })}
      style={getToastPositionAndOpacity()}
      onAnimationEnd={onAnimationEnd}
      data-test-id={`toaster-element-${toast.id}`}
    >
      <div className="w-full max-w-lg mx-auto bg-white rounded-lg shadow-lg p-4 relative border border-gray-200 flex flex-col space-y-4">
        <div className="flex flex-row justify-between items-center">
          <SVG src={warningSvg} className="w-5 h-5 text-pinkRed-900" />
          <IconButton
            icon={closeSvg}
            name="objective-notification-close"
            dataTestId={`${toast.id}-close`}
            buttonSize={ButtonSize.LARGE}
            handleOnClick={onDialogClose}
          />
        </div>

        <div className="space-y-1">
          <h2 className="header-base text-gray-900">{toast.header}</h2>
          <p className="sub-header-base text-gray-600">{toast.description} </p>
        </div>
      </div>
    </div>
  );
};
