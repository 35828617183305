import { DirectSalesState } from 'components/common/types/DirectSalesCampaign.types';
import { DirectSalesCampaignDealLineStateResponse } from 'components/common/types/DirectSalesCampaignResponse.types';

export const transformCampaignStatusResponse = (
  data: DirectSalesCampaignDealLineStateResponse,
): Record<string, { state: DirectSalesState }> => {
  return Object.entries(data.states).reduce(
    (acc: Record<string, { state: DirectSalesState }>, [lineId, { state: savedState }]) => {
      acc[lineId] = {
        state: {
          status: savedState.status,
          expires: savedState.expires ? new Date(savedState.expires) : undefined,
        },
      };
      return acc;
    },
    {},
  );
};
