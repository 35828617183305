import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import PlannerSecondaryPanel from 'components/pages/Planner/PlannerSecondaryPanel';
import Input from 'lib/Input';
import { Label } from 'lib/Label';
import SectionDivider from 'components/pages/Planner/SectionDivider';
import { changeNewProduct, hideSecondaryPanel } from 'store/productManagement/reducer';
import PlannerCategoryOptions from 'components/pages/Planner/PlannerSecondaryPanel/PlannerCategoryOptions/PlannerCategoryOptions';

import { InputType } from 'components/common/types/Input.types';
import { LocationLabel } from 'store/userOptions/reducer.types';
import { Store } from 'components/common/types/Store.types';
import { DirectSalesCodeNameModel } from 'components/common/types/DirectSalesCampaign.types';
import { CodeNameModel } from 'components/common/types';
import { ProductFieldName } from 'store/productManagement/reducer.types';
import { ProductLocationsProps } from './ProductLocations.types';

import { secondaryPanelLayoutStyles } from '../../styles';
import { isValueRequired } from '../../utils/isValueRequired';
import { isPanelConfirmDisabled } from '../../utils/isPanelConfirmDisabled';
import { permissionOptions } from '../../consts';
import SelectionPermissionDropdown from '../SelectionPermissionDropdown/SelectionPermissionDropdown';

const ProductLocationsPanel: FC<ProductLocationsProps> = ({ onCancel }) => {
  const dispatch = useDispatch();

  const productLocations = useSelector((state: Store) => state.productManagement.newProduct?.locations);
  const storedOptions = productLocations?.value || [];
  const locationOptions = useSelector(
    (state: Store) => state.userOptions.referenceOptions.location as unknown as Record<string, CodeNameModel[]>,
  );

  const defaultSelectedProductFormats =
    permissionOptions.find(({ code }) => code === productLocations?.selectionPermission) ?? permissionOptions[0];

  const [selectedPermission, setSelectedPermission] = useState<CodeNameModel>(defaultSelectedProductFormats);
  const [selectedOptions, setSelectedOptions] = useState<DirectSalesCodeNameModel[]>([]);
  const [searchText, setSearchText] = useState<string>('');

  const fieldName = ProductFieldName.LOCATIONS;

  const onSubmit = (): void => {
    const value = selectedOptions.length ? selectedOptions : undefined;
    dispatch(changeNewProduct({ fieldName, selectedPermission, value }));
    dispatch(hideSecondaryPanel());
  };

  const isChange = useMemo(() => !isEqual(selectedOptions, storedOptions), [selectedOptions, storedOptions]);

  useEffect(() => {
    setSelectedOptions(storedOptions);
  }, [productLocations]);

  return (
    <PlannerSecondaryPanel
      title="Location"
      isChange={isChange}
      isDisabled={isPanelConfirmDisabled({
        field: productLocations,
        selectedPermission,
        newFieldValue: selectedOptions.length ? selectedOptions : undefined,
      })}
      customStyles={secondaryPanelLayoutStyles}
      onSubmit={onSubmit}
      onCancel={onCancel}
      onClose={onCancel}
    >
      <SelectionPermissionDropdown
        selectedPermission={selectedPermission}
        setSelectedPermission={setSelectedPermission}
        permissionOptions={permissionOptions}
      />
      {isValueRequired(selectedPermission.code) && (
        <>
          <div className="my-4">
            <SectionDivider />
          </div>
          <Label label="Select locations" />
          <div className="mb-2">
            <Input
              type={InputType.SEARCH}
              value={searchText}
              placeholder="Search location"
              onChange={(value: string) => setSearchText(value)}
            />
          </div>
          <PlannerCategoryOptions
            searchText={searchText}
            labelMap={LocationLabel}
            selectedOptions={selectedOptions}
            options={locationOptions}
            localStateSetter={setSelectedOptions}
          />
        </>
      )}
    </PlannerSecondaryPanel>
  );
};

export default ProductLocationsPanel;
