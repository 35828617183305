import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { NOTIFICATION_TIMEOUT } from 'consts/notifications';
import { updateTransientDealLineState } from 'modules/api/directSalesCampaign';
import { changeFormParams, setDirectSalesStates, updateTransientLineStates } from 'store/dealManagement/reducer';
import { notifyError, notifyInfo } from 'store/notification/reducer';
import { Store } from 'components/common/types/Store.types';
import {
  DirectSalesStatusOption,
  DirectSalesTransientDealLineState,
} from 'components/common/types/DirectSalesCampaign.types';
import useCommonPlannerActions from './useCommonPlannerActions';

const useTransientDealLineStates = (): {
  saveTransientDealLineState: (states: DirectSalesTransientDealLineState) => void;
  cancelTransientDealLine: (dealLineId: string, states: DirectSalesTransientDealLineState) => void;
} => {
  const dispatch = useDispatch();

  const { cancelFunctions } = useCommonPlannerActions();
  const marketId = useSelector((state: RootState) => state.publisher.configuration.marketId);
  const campaignId = useSelector((state: Store) => state.dealManagement.commonDeal.dealId);

  const saveTransientDealLineState = async (states: DirectSalesTransientDealLineState): Promise<void> => {
    dispatch(changeFormParams({ isEditingDisabled: true, isLoading: true }));

    dispatch(
      notifyInfo({
        message: 'Saving your line changes',
        timeout: NOTIFICATION_TIMEOUT.DEFAULT,
      }),
    );

    const transformedStatesToIntendedStates = Object.fromEntries(
      Object.entries(states).map(([key, value]) => [
        key,
        { intendedDealLineState: value.status, expires: value.expires },
      ]),
    );

    const data = JSON.stringify({ dealLineTargetStates: transformedStatesToIntendedStates });

    try {
      await updateTransientDealLineState(data, marketId, campaignId, cancelFunctions);

      dispatch(
        notifyInfo({
          message: 'Successfully updated deal line state',
          timeout: NOTIFICATION_TIMEOUT.DEFAULT,
        }),
      );
      dispatch(
        changeFormParams({
          isEditingDisabled: false,
          isLoading: false,
        }),
      );
    } catch (error) {
      dispatch(
        changeFormParams({
          isEditingDisabled: false,
          isLoading: false,
        }),
      );

      dispatch(
        notifyError({
          message: 'Unable to update deal line state',
          timeout: NOTIFICATION_TIMEOUT.DEFAULT,
        }),
      );
    }
  };

  const cancelTransientDealLine = async (
    dealLineId: string,
    states: DirectSalesTransientDealLineState,
  ): Promise<void> => {
    dispatch(changeFormParams({ isEditingDisabled: true, isLoading: true }));

    dispatch(
      notifyInfo({
        message: 'Cancelling your line',
        timeout: NOTIFICATION_TIMEOUT.DEFAULT,
      }),
    );

    const data = JSON.stringify({
      dealLineTargetStates: { [dealLineId]: { intendedDealLineState: DirectSalesStatusOption.CANCELLED } },
    });

    try {
      await updateTransientDealLineState(data, marketId, campaignId, cancelFunctions);

      dispatch(
        notifyInfo({
          message: 'Successfully cancelled deal line',
          timeout: NOTIFICATION_TIMEOUT.DEFAULT,
        }),
      );
      dispatch(
        changeFormParams({
          isEditingDisabled: false,
          isLoading: false,
        }),
      );

      dispatch(
        updateTransientLineStates({
          ...states,
          [dealLineId]: {
            status: DirectSalesStatusOption.CANCELLED,
          },
        }),
      );
      dispatch(
        setDirectSalesStates({
          [dealLineId]: {
            state: {
              status: DirectSalesStatusOption.CANCELLED,
            },
          },
        }),
      );
    } catch (error) {
      dispatch(
        changeFormParams({
          isEditingDisabled: false,
          isLoading: false,
        }),
      );

      dispatch(
        notifyError({
          message: 'Unable to cancel deal line',
          timeout: NOTIFICATION_TIMEOUT.DEFAULT,
        }),
      );
    }
  };

  return {
    saveTransientDealLineState,
    cancelTransientDealLine,
  };
};

export default useTransientDealLineStates;
