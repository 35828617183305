import { DirectSalesState, DirectSalesStatusOption } from 'components/common/types/DirectSalesCampaign.types';
import { useSelector } from 'react-redux';
import { getEveryLineState } from 'store/dealManagement/selectors';
import { isEmpty } from 'lodash';
import { Store } from 'components/common/types/Store.types';
import { getIsDraftStatus } from 'components/pages/Planner/PlannerLineActions/DirectSalesPlannerLineActions/utils';

interface UseDirectSalesCampaignStatus {
  campaignState: DirectSalesState;
  campaignStateOptions: DirectSalesStatusOption[];
  isDisabled: boolean;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
const useDirectSalesCampaignStatus = (): UseDirectSalesCampaignStatus => {
  const linesStates = useSelector(getEveryLineState);
  const transientLineStates = useSelector((state: Store) => state.dealManagement.directSales.transientLineStates);
  const allowedStateTransitions =
    useSelector((state: Store) => state.dealManagement.meta.allowedStateTransitions) || {};

  const getCampaignState = (data: DirectSalesState[]): DirectSalesState => {
    if (!data.length) return { status: DirectSalesStatusOption.DRAFT_UPDATING };

    const activeStates = data.filter((state) => state.status !== DirectSalesStatusOption.CANCELLED);
    const isEveryStateCancelled = !activeStates.length;

    if (isEveryStateCancelled) return { status: DirectSalesStatusOption.CANCELLED };

    const [singleActiveState] = activeStates;
    const isEveryStateOption = activeStates.every((state) => state.status === DirectSalesStatusOption.OPTION);

    if (isEveryStateOption) {
      const isEveryOptionWithDropDate = activeStates.every(
        (state) => state.expires instanceof Date && !Number.isNaN(state.expires.getTime()),
      );

      if (!isEveryOptionWithDropDate) return { status: DirectSalesStatusOption.OPTION };

      const isEveryLineDropDateEqual = activeStates.every(
        (state) => (state.expires as Date).getTime() === (singleActiveState.expires as Date).getTime(),
      );

      if (isEveryLineDropDateEqual) return singleActiveState;

      return { status: DirectSalesStatusOption.OPTION };
    }

    const isEveryStateEqual = activeStates.every((state) => state.status === singleActiveState.status);

    if (isEveryStateEqual) return singleActiveState;

    return { status: DirectSalesStatusOption.MIXED };
  };

  const mappedTransientForCampaignToDealLineStates = !isEmpty(transientLineStates)
    ? Object.entries(transientLineStates).map(([, { status, expires }]) => ({
        status,
        expires,
      }))
    : [];

  const filteredTransientCampaignStates = mappedTransientForCampaignToDealLineStates.filter(
    ({ status }) => !getIsDraftStatus(status),
  );

  const campaignState = isEmpty(transientLineStates)
    ? getCampaignState(linesStates)
    : getCampaignState(mappedTransientForCampaignToDealLineStates);

  const mixedTransientCampaignStateOption = getCampaignState(filteredTransientCampaignStates);

  const campaignStateOptions = isEmpty(transientLineStates)
    ? allowedStateTransitions[campaignState.status]
    : allowedStateTransitions[mixedTransientCampaignStateOption.status];

  return {
    campaignState,
    campaignStateOptions,
    isDisabled: isEmpty(campaignStateOptions),
  };
};

export default useDirectSalesCampaignStatus;
