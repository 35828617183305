import { FC, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { changeMeta, changePlannerSecondaryPanelType, PlannerSecondaryPanelType } from 'store/dealManagement/reducer';

import { getMetaData } from 'modules/api/deal';

import { useCancelRequest } from 'customHooks/useCancelRequest';

import ToggleSwitch from 'components/patterns/ToggleSwitch';
import Button, { Color } from 'lib/Button';
import Chip from 'lib/Chip';

import plusSVG from 'assets/icons/plus.svg';

import { Size, TextPriority } from 'lib/Chip/Chip.types';
import { Store } from 'components/common/types/Store.types';
import { DirectSalesExclusionFlag } from 'components/common/types/DirectSalesDeal.types';
import { JuxtapositionProps } from './Juxtaposition.types';

const Juxtaposition: FC<JuxtapositionProps> = ({
  juxtapositionExclusion,
  productCategories,
  setJuxtapositionExclusion,
  onRemove,
}) => {
  const dispatch = useDispatch();
  const cancelFunctions = useCancelRequest();

  const meta = useSelector((state: Store) => state.dealManagement.meta);

  const isJuxtapositionOn = juxtapositionExclusion === DirectSalesExclusionFlag.ON;

  const onJuxtapositionChange = (): void => {
    setJuxtapositionExclusion(isJuxtapositionOn ? DirectSalesExclusionFlag.OFF : DirectSalesExclusionFlag.ON);
  };

  useEffect(() => {
    const getMeta = async (): Promise<void> => {
      try {
        const metaData = await getMetaData({ cancelFunctions });
        dispatch(changeMeta({ ...meta, productCategoryGroups: metaData.productCategoryGroups }));
      } catch (error) {
        // eslint-disable-next-line no-console
        console.warn(`Error fetching meta data: ${error}`);
      }
    };
    if (!meta.productCategoryGroups.length) {
      getMeta();
    }
  }, [meta]);

  return (
    <div className="relative w-full gap-x-2 py-3">
      <div className="relative flex w-full justify-between mb-2">
        <p className="body-base font-semibold">Juxtaposition</p>
        <ToggleSwitch
          isChecked={isJuxtapositionOn}
          onChange={() => onJuxtapositionChange()}
          dataTestId="juxtaposition-switch"
        />
      </div>
      {isJuxtapositionOn && (
        <div className="relative flex w-full justify-between align-top">
          <div>
            <Button
              color={Color.TRANSPARENT}
              label="Add"
              svg={plusSVG}
              onClick={() => dispatch(changePlannerSecondaryPanelType(PlannerSecondaryPanelType.JUXTAPOSITION))}
            />
          </div>
          <div className="justify-items-end">
            {productCategories.map((subOption) => {
              return (
                <div className="pb-2" key={subOption.code}>
                  <Chip
                    label={subOption.name}
                    isRemovable
                    size={Size.MEDIUM}
                    textPriority={TextPriority.EQUAL}
                    onRemove={onRemove}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Juxtaposition;
