import { FC, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SVG from 'react-inlinesvg';
import cx from 'classnames';

import { Store } from 'components/common/types/Store.types';
import {
  PlannerSecondaryPanelType,
  changeDealData,
  changeFormParams,
  hidePlannerSecondaryPanel,
} from 'store/dealManagement/reducer';
import {
  DirectSalesProductCategoryExclusivityType,
  DirectSalesCodeNameModel,
} from 'components/common/types/DirectSalesCampaign.types';

import { useOnChangePanelVisibility } from 'components/pages/Planner/hooks/useOnChangePanelVisibility';
import useDirectSalesPlannerActions from 'components/pages/Planner/hooks/useDirectSalesPlannerActions';
import excludeSVG from 'assets/icons/exclude.svg';
import slidersSVG from 'assets/icons/sliders.svg';
import { DirectSalesExclusionFlag } from 'components/common/types/DirectSalesDeal.types';
import useHandleSaveButton from 'customHooks/useHandleSaveButton';
import useIsReadOnly from 'components/pages/Planner/useIsReadOnly';
import ExclusionCriteriaPanel from './ExclusionCriteriaPanel';
import JuxtapositionPanel from './Juxtaposition/JuxtapositionPanel';
import DuplicationPanel from './Duplication/DuplicationPanel';

const ExclusionCriteria: FC = () => {
  const dispatch = useDispatch();

  const { saveDeal } = useDirectSalesPlannerActions();
  const onChangePanelVisibility = useOnChangePanelVisibility();

  const plannerSecondaryPanelType = useSelector((state: Store) => state.dealManagement.plannerSecondaryPanelType);
  const storedProductCategoryExclusivity = useSelector(
    (state: Store) => state.dealManagement.commonDeal.productCategoryExclusivity,
  );
  const { productCategories: excludedProductCategories, organisations: excludedOrganisations } = useSelector(
    (state: Store) => state.dealManagement.commonDeal.exclusions,
  );
  const savedDuplicationExclusion = useSelector((state: Store) => state.dealManagement.commonDeal.duplicationExclusion);
  const savedJuxtapositionExclusion = useSelector(
    (state: Store) => state.dealManagement.commonDeal.juxtapositionExclusion,
  );
  const excludedSpecialist = useSelector((state: Store) => state.dealManagement.commonDeal.specialistBlockExclusion);
  const excludedAgency = useSelector((state: Store) => state.dealManagement.commonDeal.agencyBlockExclusion);
  const excludedAdvertiser = useSelector((state: Store) => state.dealManagement.commonDeal.advertiserBlockExclusion);

  const [productCategories, setProductCategories] = useState<DirectSalesCodeNameModel[]>(excludedProductCategories);
  const [organisations, setOrganisations] = useState<DirectSalesCodeNameModel[]>(excludedOrganisations);
  const [juxtapositionExclusion, setJuxtapositionExclusion] = useState<DirectSalesExclusionFlag>(
    DirectSalesExclusionFlag.ON,
  );
  const [productCategoryExclusivity, setProductCategoryExclusivity] =
    useState<DirectSalesProductCategoryExclusivityType>(DirectSalesProductCategoryExclusivityType.OFF);
  const [specialistBlockExclusion, setSpecialistBlockExclusion] = useState<DirectSalesExclusionFlag>(
    DirectSalesExclusionFlag.ON,
  );
  const [agencyBlockExclusion, setAgencyBlockExclusion] = useState<DirectSalesExclusionFlag>(
    DirectSalesExclusionFlag.ON,
  );
  const [advertiserBlockExclusion, setAdvertiserBlockExclusion] = useState<DirectSalesExclusionFlag>(
    DirectSalesExclusionFlag.ON,
  );
  const [duplicationExclusion, setDuplicationExclusion] = useState<DirectSalesExclusionFlag>(
    DirectSalesExclusionFlag.ON,
  );

  const isExclusionCriteriaPanelOpen =
    plannerSecondaryPanelType === PlannerSecondaryPanelType.EXCLUSION_CRITERIA ||
    plannerSecondaryPanelType === PlannerSecondaryPanelType.DUPLICATION ||
    plannerSecondaryPanelType === PlannerSecondaryPanelType.JUXTAPOSITION;

  const { isSaveDisabled } = useHandleSaveButton();
  const { isCampaignReadOnly } = useIsReadOnly();

  const onRemoveOrganisation = (label: string): void => {
    setOrganisations(organisations.filter((excludedOptions) => excludedOptions.name !== label));
  };

  const onRemoveProductCategory = (label: string): void => {
    setProductCategories(productCategories.filter((excludedOptions) => excludedOptions.name !== label));
  };

  useEffect(() => {
    setProductCategoryExclusivity(storedProductCategoryExclusivity);
  }, [storedProductCategoryExclusivity]);

  useEffect(() => {
    setOrganisations(excludedOrganisations);
  }, [excludedOrganisations]);

  useEffect(() => {
    setProductCategories(excludedProductCategories);
  }, [excludedProductCategories]);

  useEffect(() => {
    setDuplicationExclusion(savedDuplicationExclusion);
  }, [savedDuplicationExclusion]);

  useEffect(() => {
    setJuxtapositionExclusion(savedJuxtapositionExclusion);
  }, [savedJuxtapositionExclusion]);

  useEffect(() => {
    setAgencyBlockExclusion(excludedAgency);
    setAdvertiserBlockExclusion(excludedAdvertiser);
    setSpecialistBlockExclusion(excludedSpecialist);
  }, [excludedSpecialist, excludedAgency, excludedAdvertiser]);

  const exclusionData = {
    duplicationExclusion,
    exclusions: { organisations, productCategories },
    juxtapositionExclusion,
    specialistBlockExclusion,
    agencyBlockExclusion,
    advertiserBlockExclusion,
    productCategoryExclusivity,
  };

  const onConfirm = async (): Promise<void> => {
    try {
      saveDeal(
        {
          ...exclusionData,
        },
        () => {
          dispatch(
            changeDealData({
              ...exclusionData,
            }),
          );
          dispatch(changeFormParams({ isCampaignDrawerOpen: true }));
          dispatch(hidePlannerSecondaryPanel());
        },
      );
    } catch (e) {
      // eslint-disable-next-line no-console
    }
  };

  const onCancel = (): void => {
    setDuplicationExclusion(savedDuplicationExclusion);
    setJuxtapositionExclusion(savedJuxtapositionExclusion);
    setOrganisations(excludedOrganisations);
    setProductCategoryExclusivity(storedProductCategoryExclusivity);
    setProductCategories(excludedProductCategories);
    setSpecialistBlockExclusion(excludedSpecialist);
    setAgencyBlockExclusion(excludedAgency);
    setAdvertiserBlockExclusion(excludedAdvertiser);
    dispatch(hidePlannerSecondaryPanel());
  };
  return (
    <div className="mx-6">
      <button
        type="button"
        onClick={() => onChangePanelVisibility(PlannerSecondaryPanelType.EXCLUSION_CRITERIA)}
        className="w-full py-4 flex justify-between items-center sub-header-base"
        disabled={isSaveDisabled || isCampaignReadOnly}
      >
        <div className="flex gap-1 items-center text-essential-tertiary body-sm">
          <SVG className="w-4 h-4 font-light text-neutral-800" src={excludeSVG} />
          Exclusion Criteria
        </div>
        <div
          className={cx(
            'font-light p-[3px] rounded',
            isExclusionCriteriaPanelOpen ? 'text-neutral-950 bg-neutral-950-opacity-10' : 'text-neutral-800',
          )}
        >
          <SVG className="w-4 h-4 font-light" src={slidersSVG} />
        </div>
      </button>
      {plannerSecondaryPanelType === PlannerSecondaryPanelType.EXCLUSION_CRITERIA && (
        <ExclusionCriteriaPanel
          duplicationExclusion={duplicationExclusion}
          juxtapositionExclusion={juxtapositionExclusion}
          organisations={organisations}
          productCategories={productCategories}
          productCategoryExclusivity={productCategoryExclusivity}
          advertiserBlockExclusion={advertiserBlockExclusion}
          agencyBlockExclusion={agencyBlockExclusion}
          specialistBlockExclusion={specialistBlockExclusion}
          setProductCategoryExclusivity={setProductCategoryExclusivity}
          setAdvertiserExclusion={setAdvertiserBlockExclusion}
          setAgencyExclusion={setAgencyBlockExclusion}
          setSpecialistExclusion={setSpecialistBlockExclusion}
          setDuplicationExclusion={setDuplicationExclusion}
          setJuxtapositionExclusion={setJuxtapositionExclusion}
          onCancel={onCancel}
          onConfirm={onConfirm}
          onRemoveOrganisation={onRemoveOrganisation}
          onRemoveProductCategory={onRemoveProductCategory}
          isConfirmDisabled={isSaveDisabled}
        />
      )}
      {plannerSecondaryPanelType === PlannerSecondaryPanelType.JUXTAPOSITION && (
        <JuxtapositionPanel productCategories={productCategories} setProductCategories={setProductCategories} />
      )}
      {plannerSecondaryPanelType === PlannerSecondaryPanelType.DUPLICATION && (
        <DuplicationPanel organisations={organisations} setOrganisations={setOrganisations} />
      )}
    </div>
  );
};

export default ExclusionCriteria;
