import React from 'react';
import { upperFirst } from 'lodash';
import frameSvg from 'assets/icons/frame.svg';
import CollapsibleList from 'lib/CollapsibleList';
import CollapsibleListStatsHeader from 'lib/CollapsibleList/CollapsibleListStatsHeader';
import CustomSelectOption from 'lib/CustomSelectOption/CustomSelectOption';
import TargetSection from 'components/pages/Planner/PlannerSections/TargetSection/TargetSection';
import { TargetType } from 'components/pages/Planner/PlannerSections/TargetSection/TargetSection.types';
import { NetworkCriteriaType } from 'components/common/types/DirectSalesNetworks.types';
import { TargetLabel } from 'consts/targetLabel';
import { NetworkOptionsProps } from './NetworksOptions.types';

export const NetworkOptions: React.FC<NetworkOptionsProps> = ({
  options,
  selectedOptions,
  onCategoryDirectionSort,
  setSelectedOptions,
  openCategory,
  sortDirection,
  onToggleCategory,
  isOptionSelected,
  getNumberOfFilteredOptions,
  networkTarget,
}) => {
  return (
    <>
      {Object.keys(options).map((category) => {
        const selected = selectedOptions.length
          ? selectedOptions.filter((network) => network.category !== category)
          : [];

        return (
          <CollapsibleList
            label={upperFirst(category.toLowerCase())}
            isSortVisible
            onSort={onCategoryDirectionSort}
            isClearAllVisible={false}
            onClearAll={() => setSelectedOptions(selected)}
            isOpen={category === openCategory}
            onToggle={() => onToggleCategory(category)}
            sortDir={sortDirection}
            key={`tag-category-${category}`}
            headerComponent={
              <CollapsibleListStatsHeader
                available={options?.[category].length}
                included={getNumberOfFilteredOptions(category, selectedOptions)}
              />
            }
          >
            {options[category].map((option) => {
              const isSelected = isOptionSelected(option.code, category);
              const currentOption = selectedOptions.find(({ code }) => code === option.code) as NetworkCriteriaType;

              return (
                <CustomSelectOption
                  key={option.code}
                  value={{
                    code: option.code,
                    name: option.name,
                  }}
                  isSelected={isSelected}
                  onClick={() => {
                    networkTarget.handleSelectionToggle(option, category, isSelected);
                  }}
                  isMultiple
                  selectOptionChildren={option.name}
                >
                  {isSelected ? (
                    <div className="mx-1 my-0.5">
                      <TargetSection
                        target={networkTarget}
                        targetType={TargetType.NETWORK}
                        currentOption={currentOption}
                        icon={frameSvg}
                        isTargetValueRequired
                        label={TargetLabel.FRAMES}
                        hasBorder
                      />
                    </div>
                  ) : null}
                </CustomSelectOption>
              );
            })}
          </CollapsibleList>
        );
      })}
    </>
  );
};
