import React from 'react';

import { CodeName } from 'components/common/types/CodeName.types';
import { Advertiser } from 'components/common/types/Deal.types';

export const InputContainer: React.FC = ({ children }) => {
  return <div className="grid grid-cols-[1fr_180px] items-center gap-[8px] my-[12px] mx-[24px]">{children}</div>;
};

export const updateOrganisations = (
  organisations: CodeName[],
  selectedAdvertiser: Advertiser | null,
  previousAdvertiser: Advertiser | null,
): CodeName[] => {
  const isSelected = organisations.some(({ code }) => code === selectedAdvertiser?.code);
  let organisationsUpdate = [...organisations];
  if (previousAdvertiser) {
    organisationsUpdate = organisations.filter(({ code }) => code !== previousAdvertiser.code);
  }
  if (selectedAdvertiser && !isSelected) {
    return [...organisationsUpdate, { code: selectedAdvertiser.code, name: selectedAdvertiser.name }];
  }
  return organisationsUpdate;
};
