import { useDispatch, useSelector } from 'react-redux';
import { ShadowStyle } from 'consts/shadow';
import { changeDealInfo } from 'store/dealManagement/actions';
import { Label } from 'lib/Label';
import { Padding } from 'lib/BaseSelect/BaseSelect.types';
import { InputShape, InputSize, InputTheme } from 'components/common/types/Input.types';
import useIsReadOnly from 'components/pages/Planner/useIsReadOnly';
import AsyncSelect from 'components/patterns/AsyncSelect';
import { Store } from 'components/common/types/Store.types';
import { CodeNameModel } from 'components/common/types';
import { isReadOnly } from 'utils/isReadOnly';
import { getIsReseller } from 'store/publisher/selectors';
import { useLoadLookup } from 'components/common/FilterArea/Filters/useLoadLookup';

const SelectAgency: React.FC = () => {
  const dispatch = useDispatch();

  const agency = useSelector((state: Store) => state.dealManagement.commonDeal.agency);
  const isEditingDisabled = useSelector((state: Store) => state.dealManagement.isEditingDisabled);
  const bookingStatusCode = useSelector((state: Store) => state.dealManagement.commonDeal.bookingStatusCode);
  const isReseller = useSelector(getIsReseller);

  const { onLoadLookup } = useLoadLookup();

  const { isCampaignReadOnly } = useIsReadOnly();

  const handleSelectAgency = (key: string, value: CodeNameModel): Promise<void> =>
    dispatch(changeDealInfo({ [key]: value }));

  if (isReseller) return null;

  return (
    <>
      <Label id="agency" theme={InputTheme.FLAT_GRAY} label="Agency" />
      <AsyncSelect
        name="agency"
        dataTestId="async-select-agency"
        placeholder=""
        theme={InputTheme.FLAT_GRAY}
        size={InputSize.EXTRA_SMALL}
        padding={Padding.NONE}
        shadow={ShadowStyle.NONE}
        shape={InputShape.ROUNDED}
        selectedValue={agency}
        loadData={onLoadLookup}
        onSelect={handleSelectAgency}
        defaultOptions={agency}
        isDisabled={isReadOnly(bookingStatusCode, isEditingDisabled) || isCampaignReadOnly}
      />
    </>
  );
};

export default SelectAgency;
