import { DirectSalesStatusOption } from 'components/common/types/DirectSalesCampaign.types';
import { Store } from 'components/common/types/Store.types';
import { useSelector } from 'react-redux';
import { isReadOnly } from 'utils/isReadOnly';
import {
  getEveryLineState,
  getIsDirectSalesSessionLockedAndReadOnly,
  getIsLineBeingSaved,
  getIsLineCancelled,
  getIsLineLocked,
} from 'store/dealManagement/selectors';
import { CampaignType } from './PlannerSections/types';

interface UseIsReadOnly {
  isLineReadOnly: boolean;
  isCampaignReadOnly: boolean;
}

const useIsReadOnly = (lineId?: string): UseIsReadOnly => {
  const isEditingDisabled = useSelector((state: Store) => state.dealManagement.isEditingDisabled);
  const bookingStatusCode = useSelector((state: Store) => state.dealManagement.commonDeal.bookingStatusCode);
  const campaignType = useSelector((state: Store) => state.dealManagement.campaignType);
  const { isDirectSalesSessionLockedAndReadOnly } = useSelector(getIsDirectSalesSessionLockedAndReadOnly);
  const isLineLocked = useSelector((state: Store) => getIsLineLocked(state, lineId));
  const isSavingLine = useSelector((state: Store) => getIsLineBeingSaved(state, lineId));
  const isLineCancelled = useSelector((state: Store) => getIsLineCancelled(state, lineId));
  const lineStates = useSelector(getEveryLineState);
  const isCampaignCancelled =
    lineStates.length > 0 && lineStates.every((state) => state.status === DirectSalesStatusOption.CANCELLED);

  const readOnly = isReadOnly(bookingStatusCode, isEditingDisabled);

  if (campaignType !== CampaignType.DIRECT)
    return {
      isLineReadOnly: readOnly,
      isCampaignReadOnly: readOnly,
    };

  return {
    isLineReadOnly:
      isEditingDisabled || isLineCancelled || isDirectSalesSessionLockedAndReadOnly || isLineLocked || isSavingLine,
    isCampaignReadOnly: isEditingDisabled || isDirectSalesSessionLockedAndReadOnly || isCampaignCancelled,
  };
};

export default useIsReadOnly;
