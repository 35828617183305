import { DirectSalesAssetLine, DirectSalesCount } from 'components/common/types/DirectSalesCampaign.types';
import { DirectSalesAssetListResponse } from 'components/common/types/DirectSalesCampaignResponse.types';

export const transformAssetList = (
  data: DirectSalesAssetListResponse,
): {
  campaign: {
    framesCount: DirectSalesCount;
    impressionsCount: DirectSalesCount;
  };
  lines: Record<string, DirectSalesAssetLine>;
} => {
  const assetLines = Object.entries(data.campaign.dealLines).reduce(
    (acc: Record<string, DirectSalesAssetLine>, [lineId, lineRecord]) => {
      acc[lineId] = {
        framesCount: {
          universe: lineRecord.assetCount.filtered,
          allocated: lineRecord.assetCount.available,
        },
        impressionsCount: {
          universe: lineRecord.impressions.filtered,
          allocated: lineRecord.impressions.available,
        },
        assets: lineRecord.assets.map((asset) => ({
          frameId: asset.id,
          longitude: Number(asset.longitude),
          latitude: Number(asset.latitude),
        })),
      };

      return acc;
    },
    {},
  );

  return {
    campaign: {
      framesCount: {
        universe: data.campaign.assetCount.filtered,
        allocated: data.campaign.assetCount.available,
      },
      impressionsCount: {
        universe: data.campaign.impressions.filtered,
        allocated: data.campaign.impressions.available,
      },
    },
    lines: assetLines,
  };
};
