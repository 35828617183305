import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { GridApi, IDatasource } from 'ag-grid-community';
import Session from 'modules/Session';
import { Store } from 'components/common/types/Store.types';
import { ColumnDef } from 'components/common/types/Table.types';
import PageSize from 'components/patterns/PageSize';
import Table, { TableHeight, TableRowHeight } from 'components/patterns/Table';
import { useCancelRequest } from 'customHooks/useCancelRequest';
import { getDirectSalesFilteredCampaignsTable } from 'modules/api/directSalesCampaigns';
import { getValidPageSize } from 'utils/pageSize';
import { formatDDMMYYYYDate } from 'utils/dateFormatUtil';
import { isEmpty } from 'lodash';
import { usePageFilters } from 'customHooks/usePageFilters';
import CampaignNameAndRef from '../../common/CampaignNameAndRef';
import ActionRenderer from './ActionRenderer/ActionRenderer';
import { DirectSalesDealManagementFilters } from '../Filters/Filters.types';
import { StatusRenderer } from './StatusRenderer/StatusRenderer';

const pageKey = 'directSalesCampaignsTable';

const DealsTable: React.FC = () => {
  const { marketId, localeCode } = useSelector((state: Store) => state.publisher.configuration);

  const [pageSize, setPageSize] = useState(getValidPageSize(Session.getPageSizeByKey(pageKey)));

  const tableApiRef: MutableRefObject<GridApi | undefined> = useRef();
  const { filters } = usePageFilters<DirectSalesDealManagementFilters>('insights/direct-sales');
  const cancelFunctions = useCancelRequest();

  const getDataSource = (): IDatasource => ({
    getRows: async (params) => {
      try {
        const { content, totalElements } = await getDirectSalesFilteredCampaignsTable({
          externalPublisherId: marketId,
          cancelFunctions,
          filters,
          size: pageSize,
          page: params.startRow / pageSize,
        });

        if (totalElements === 0) {
          tableApiRef.current?.showNoRowsOverlay();
        } else {
          tableApiRef.current?.hideOverlay();
        }

        params.successCallback(content, totalElements);
      } catch {
        params.failCallback();
      }
    },
  });

  const onPageSizeChange = (size: number): void => {
    setPageSize(size);
    Session.setPageSizeByKey(pageKey, size);
    tableApiRef.current?.paginationSetPageSize(size);
    tableApiRef.current?.setDatasource(getDataSource());
  };

  const getTableApi = (tableApi: GridApi): void => {
    tableApiRef.current = tableApi;
    tableApi.setDatasource(getDataSource());
  };

  const columnDefs: ColumnDef[] = [
    {
      headerName: '',
      field: 'actions',
      minWidth: 90,
      headerTooltip: '',
      cellRenderer: ActionRenderer,
      resizable: false,
      sortable: false,
    },
    {
      headerName: 'Status',
      field: 'campaignStatus',
      minWidth: 90,
      headerTooltip: 'Campaign status',
      cellRenderer: ({ data }) => StatusRenderer({ campaignStatus: data?.campaignStatus }),
      colId: 'status',
      sortable: false,
    },
    {
      headerName: 'Campaign name / Ref',
      field: 'campaignNameAndRef',
      minWidth: 350,
      headerTooltip: '',
      cellRenderer: ({ data }) =>
        CampaignNameAndRef({ campaignName: data?.campaignName, campaignRef: data?.reference }),
      sortable: false,
    },
    {
      headerName: 'From',
      field: 'startDate',
      minWidth: 120,
      headerTooltip: '',
      cellRenderer: ({ data }) => {
        return <span>{formatDDMMYYYYDate(data?.startDate, localeCode)}</span>;
      },
      sortable: false,
    },
    {
      headerName: 'To',
      field: 'endDate',
      minWidth: 120,
      headerTooltip: '',
      cellRenderer: ({ data }) => {
        return <span>{formatDDMMYYYYDate(data?.endDate, localeCode)}</span>;
      },
      sortable: false,
    },
    {
      headerName: 'Advertiser',
      field: 'advertiser',
      minWidth: 150,
      headerTooltip: '',
      cellRenderer: ({ data }) => {
        return <span>{data?.advertiser}</span>;
      },
      sortable: false,
    },
    {
      headerName: 'Brand',
      field: 'brand',
      minWidth: 150,
      headerTooltip: '',
      cellRenderer: ({ data }) => {
        return <span>{data?.brand}</span>;
      },
      sortable: false,
    },
    {
      headerName: 'Product category',
      field: 'productCategory',
      minWidth: 150,
      headerTooltip: 'Product category',
      cellRenderer: ({ data }) => {
        return <span>{data?.productCategory}</span>;
      },
      sortable: false,
    },
    {
      headerName: 'Agency',
      field: 'agencyName',
      minWidth: 150,
      headerTooltip: '',
      cellRenderer: ({ data }) => {
        return <span>{data?.agency}</span>;
      },
      sortable: false,
    },
    {
      headerName: 'Specialist',
      field: 'specialist',
      minWidth: 150,
      headerTooltip: '',
      cellRenderer: ({ data }) => {
        return <span>{data && data.specialist}</span>;
      },
      sortable: false,
    },
    {
      headerName: 'Updated by',
      field: 'updatedBy',
      minWidth: 150,
      headerTooltip: '',
      cellRenderer: ({ data }) => {
        return <span>{data?.updatedBy}</span>;
      },
      sortable: false,
    },
    {
      headerName: 'Created by',
      field: 'createdBy',
      minWidth: 150,
      headerTooltip: '',
      cellRenderer: ({ data }) => {
        return <span>{data?.createdBy}</span>;
      },
      sortable: false,
    },
    {
      headerName: 'Created date',
      field: 'createdAt',
      minWidth: 150,
      headerTooltip: '',
      cellRenderer: ({ data }) => {
        return <span>{formatDDMMYYYYDate(data?.createdAt, localeCode)}</span>;
      },
      sortable: false,
    },
    {
      headerName: 'Drop date',
      field: 'dropDate',
      minWidth: 150,
      headerTooltip: '',
      cellRenderer: ({ data }) => {
        return <span>{formatDDMMYYYYDate(data?.dropDate, localeCode)}</span>;
      },
      sortable: false,
    },
    {
      headerName: 'Sales team',
      field: 'salesTeam',
      minWidth: 150,
      headerTooltip: '',
      cellRenderer: ({ data }) => {
        return <span>{data?.salesTeam}</span>;
      },
      sortable: false,
    },
    {
      headerName: 'Admin person',
      field: 'adminPerson',
      minWidth: 150,
      headerTooltip: '',
      cellRenderer: ({ data }) => {
        return <span>{data?.adminPerson}</span>;
      },
      sortable: false,
    },
    {
      headerName: 'Sales person',
      field: 'salesPerson',
      minWidth: 150,
      headerTooltip: '',
      cellRenderer: ({ data }) => {
        return <span>{data?.salesPerson}</span>;
      },
      sortable: false,
    },
  ];

  useEffect(() => {
    if (!isEmpty(filters) && tableApiRef.current) {
      tableApiRef.current?.setDatasource(getDataSource());
    }
  }, [filters]);

  return (
    <>
      <div className="mb-3">
        <PageSize
          dataTestId="page-size-selection"
          onPageSizeChange={onPageSizeChange}
          pageSize={pageSize}
          pageInfoText="campaigns per page"
        />
      </div>
      <Table
        dataTestId="direct-sales-campaigns-table"
        key={pageSize}
        columnDefs={columnDefs}
        getTableApi={getTableApi}
        extraGridOptions={{
          rowModelType: 'infinite',
          rowHeight: TableRowHeight.NORMAL,
          pagination: true,
          paginationPageSize: pageSize,
          cacheBlockSize: pageSize,
          enableCellTextSelection: true,
          domLayout: 'autoHeight',
        }}
        tableSize={{ tableHeight: TableHeight.AUTO }}
      />
    </>
  );
};

export default DealsTable;
