import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideSecondaryPanel } from 'store/productManagement/reducer';
import { Store } from 'components/common/types/Store.types';
import { SelectionPermission } from 'store/productManagement/reducer.types';
import PlannerSecondaryPanel from 'components/pages/Planner/PlannerSecondaryPanel';
import { permissionOptions } from 'components/pages/PCM/Products/consts';
import SelectionPermissionDropdown from 'components/pages/PCM/Products/ProductFields/SelectionPermissionDropdown/SelectionPermissionDropdown';
import { ProductObjectivesPanelProps } from './ProductObjectivesPanel.types';
import { secondaryPanelLayoutStyles } from '../../styles';

const noDefinedValuePermissionOptions = permissionOptions.filter(
  ({ code }) => code !== SelectionPermission.FIXED_VALUE,
);

const ProductObjectivesPanel: React.FC<ProductObjectivesPanelProps> = ({ onConfirm }) => {
  const dispatch = useDispatch();
  const objectives = useSelector((state: Store) => state.productManagement.newProduct.objectives);
  const defaultSelectedPermission =
    noDefinedValuePermissionOptions.find(({ code }) => code === objectives?.selectionPermission) ??
    noDefinedValuePermissionOptions[0];
  const [selectedPermission, setSelectedPermission] = useState<{
    code: SelectionPermission;
    name: string;
  }>(defaultSelectedPermission);

  return (
    <PlannerSecondaryPanel
      title="Objective"
      onSubmit={() => onConfirm({ selectedPermission })}
      onCancel={() => dispatch(hideSecondaryPanel())}
      isDisabled={objectives?.selectionPermission === selectedPermission.code}
      customStyles={secondaryPanelLayoutStyles}
    >
      <SelectionPermissionDropdown
        selectedPermission={selectedPermission}
        setSelectedPermission={setSelectedPermission}
        isReadOnly={false}
        permissionOptions={noDefinedValuePermissionOptions}
      />
    </PlannerSecondaryPanel>
  );
};

export default ProductObjectivesPanel;
