/* eslint-disable sonarjs/cognitive-complexity */
import {
  DirectSales,
  DirectSalesAssetLine,
  DirectSalesCodeNameModel,
  DirectSalesCount,
  DirectSalesFilterScope,
  DirectSalesTransientDealLineState,
  DirectSalesGrid,
  DirectSalesLine,
  DirectSalesLockState,
  DirectSalesLockStatusOption,
  DirectSalesPricingMode,
  DirectSalesProductCategoryExclusivityType,
  DirectSalesState,
  DirectSalesStatusOption,
  DirectSalesLineAllocation,
} from 'components/common/types/DirectSalesCampaign.types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import merge from 'lodash/merge';

import { ToastProps } from 'lib/Toaster/Toaster.types';
import { CodeNameModel } from 'components/common/types';
import { FromTo } from 'components/common/types/Range.types';
import {
  OpenStreetMapPoiWithPoints,
  PlannerPoiWithPoints,
  PoiOption,
  VisibleAsset,
} from 'components/common/types/Planner.types';
import {
  AverageScoresByFrame,
  Segment,
  SegmentFrameAverageByHour,
  SegmentHourAverageByFrame,
  SelectedSegment,
} from 'components/common/types/Segment.types';
import {
  Advertiser,
  AuctionModel,
  Availability,
  Brand,
  CommonDeal,
  DealPriority,
  DealStatus,
  DealType,
  Dsp,
  FrontEndType,
  ImpressionMetrics,
  IndexOptimisation,
  Line,
  Poi,
  Points,
  PostCode,
  PreservedAvailability,
  Programmatic,
  RouteFrameCode,
  SourceSystem,
} from 'components/common/types/Deal.types';

import { PermissionsEnum } from 'consts/permissions';
import Auth from 'modules/Auth';
import { CampaignType } from 'components/pages/Planner/PlannerSections/types';

import { Asset } from 'components/common/types/Asset.types';
import { cloneDeep, last, sortBy } from 'lodash';
import { PublisherType } from 'components/common/types/Publisher.types';
import { ActiveAllocationReportSolution } from 'components/common/AllocationReport/AllocationReports/AllocationReports.types';
import { DEFAULT_CRITERIA, TargetObjectiveId } from 'consts/targetCriteria';
import { DEFAULT_OBJECTIVE } from 'consts/objective';
import { CampaignVersioningEditingStatus, CampaignVersioningErrors } from 'consts/directSalesCampaignVersioning';
import { DirectSalesExclusionFlag } from 'components/common/types/DirectSalesDeal.types';
import { isDirectSalesCampaignCloned } from 'components/pages/Planner/hooks/utils/isDirectSalesCampaignCloned';
import { getAssetByFrameId, getDuplicateLineState, getLineById } from './reducerUtils';
import { getNewObjectiveId, getNewTargetId, getUniqueObjectiveName } from './utils';
import { defaultDirectSales, defaultLine } from './defaults';

const getDefaultDealType = (publisherType?: PublisherType): DealType => {
  let dealType = DealType.NON_GUARANTEED_FLOOR_PRICE;

  if (publisherType === PublisherType.RESELLER || !publisherType) return dealType;

  if (Auth.hasPermission(PermissionsEnum.DEAL_TYPE_PRICE_VIEW)) {
    dealType = DealType.NON_GUARANTEED_FIXED_PRICE;
  } else if (Auth.hasPermission(PermissionsEnum.DEAL_TYPE_PRICEIMPRESSION_VIEW)) {
    dealType = DealType.GUARANTEED;
  }

  return dealType;
};

export const generateUniqueDealLineName = (existingNames: string[], idx: number): string => {
  const newLineName = `Deal line ${idx}`;

  if (!existingNames.includes(newLineName.toLowerCase())) return newLineName;

  return generateUniqueDealLineName(existingNames, idx + 1);
};

const updateCurrentLineAndBackupLineProperty = <T extends keyof Line>(
  state: DealManagement,
  lineId: string,
  lineProperty: T,
  value: Line[T],
): void => {
  const lineIndex = state.backupFormData.lines?.findIndex((line) => line.lineId === lineId);
  const isLineInBackupLines = lineIndex > -1;

  if (!isLineInBackupLines) {
    state.commonDeal.currentLine[lineProperty] = value;
  }

  if (isLineInBackupLines) {
    state.backupFormData.lines[lineIndex] = {
      ...state.backupFormData.lines[lineIndex],
      [lineProperty]: value,
    };

    if (state.backupFormData.lines[lineIndex].lineId === state.commonDeal.currentLine.lineId) {
      state.commonDeal.currentLine[lineProperty] = value;
    }
  }
};

export interface DisclaimerObjectives {
  success: string[];
  warning: string[];
  danger: string[];
  default?: string[];
}

export type AllowedStateTransitionsMetadata = Record<string, DirectSalesStatusOption[]>;

type DealManagementMeta = {
  poi: {
    attributes: CodeNameModel[];
    datasources: CodeNameModel[];
  };
  productCategoryGroups: (Pick<CodeNameModel, 'code' | 'name'> & { productCategories?: CodeNameModel[] })[];
  allowedStateTransitions: AllowedStateTransitionsMetadata;
};

type BackupFormData = {
  commonDeal: CommonDeal;
  programmatic: Programmatic;
  lines: (Line | DirectSalesLine)[];
};

export type ImpressionsMinMax = {
  min: number;
  max: number;
};

export type FrameIdIndex = {
  frameId: string;
  index: number;
};

export enum PlannerSecondaryPanelType {
  NONE = 'NONE',
  STATUS = 'STATUS',
  PRICING_MODE = 'PRICING_MODE',
  SCHEDULE = 'SCHEDULE',
  CHANNEL = 'CHANNEL',
  MEDIA_TYPE = 'MEDIA_TYPE',
  DUPLICATION = 'DUPLICATION',
  ENVIRONMENT = 'ENVIRONMENT',
  EXCLUSION_CRITERIA = 'EXCLUSION_CRITERIA',
  FORMAT = 'FORMAT',
  JUXTAPOSITION = 'JUXTAPOSITION',
  TAG = 'TAG',
  LOCATION = 'LOCATION',
  TARGETS = 'TARGETS',
  FRAME_LIST = 'FRAME_LIST',
  SOT = 'SOT',
  PROGRAMMATIC_STATUS = 'PROGRAMMATIC_STATUS',
  DIRECT_SALES_STATUS = 'DIRECT_SALES_STATUS',
  OBJECTIVE = 'OBJECTIVE',
  OBJECTIVE_BUDGET = 'OBJECTIVE_BUDGET',
  OBJECTIVE_FRAMES = 'OBJECTIVE_FRAMES',
  OBJECTIVE_IMPRESSIONS = 'OBJECTIVE_IMPRESSIONS',
  OBJECTIVE_LOCATIONS = 'OBJECTIVE_LOCATIONS',
  OBJECTIVE_FORMAT = 'OBJECTIVE_FORMAT',
  OBJECTIVE_POI = 'OBJECTIVE_POI',
  OBJECTIVE_TAGS = 'OBJECTIVE_TAGS',
  OBJECTIVE_FRAME_LIST = 'OBJECTIVE_FRAME_LIST',
  NETWORKS = 'NETWORKS',
  POI = 'POI',
  PRODUCT_TEMPLATE = 'PRODUCT_TEMPLATE',
}

export enum Loading {
  GET_POI_DATA = 'GET_POI_DATA',
  GET_AVERAGE_SEGMENT_SCORES_PER_FRAME = 'GET_AVERAGE_SEGMENT_SCORES_PER_FRAME',
  GET_SEGMENT_FRAME_AVERAGE_BY_HOUR = 'GET_SEGMENT_FRAME_AVERAGE_BY_HOUR',
}

export enum CampaignPlannerMode {
  MAP,
  OVERVIEW,
  REVIEW,
}

export type DistributionOfAvailabilityAsset = Pick<Asset, 'frameId' | 'district' | 'productFormat'>;

type PlannerSettings = {
  isImpressionsClusterVisible: boolean;
  isAvailabilityClusterVisible: boolean;
  isAllocationClusterVisible: boolean;
  impressions: ImpressionsMinMax;
  selectedDistributionOfAvailabilityAssets: DistributionOfAvailabilityAsset[];
  enabledImpressionsRange: FromTo[];
  assetsOnViewport: VisibleAsset[];
  selectedFrames: number;
  selectedImpressions: number;
  isAvailabilityClusterToggleDisabled: boolean;
  isAllocationClusterToggleDisabled: boolean;
  zoom: number;
  frameIdIndexes: FrameIdIndex[];
  selectedIndexRange?: FromTo;
  openStreetMapPoiSearchText: string;
  isDirectSummaryPanelOpen: boolean;
};

type DealManagement = {
  backupFormData: BackupFormData;
  programmatic: Programmatic;
  directSales: DirectSales;
  directSalesSessionLock: DirectSalesLockState;
  commonDeal: CommonDeal;
  meta: DealManagementMeta;
  isNewDeal: boolean;
  isDisclaimerVisible: boolean;
  isDuplicated?: boolean;
  isEditingDisabled: boolean;
  loading: Partial<Record<Loading, boolean>>;
  isLoading: boolean;
  isFetchingAllocation: boolean;
  isFetchingReferences: boolean;
  isFetchingNetworks: boolean;
  isActionsDisabled: boolean;
  isForecastedAllocation: boolean;
  isCpmCampaignLevel: boolean;
  selectedIndexOptimisationDataProvider: string;
  plannerSettings: PlannerSettings;
  plannerSecondaryPanelType: PlannerSecondaryPanelType;
  selectedEnvironmentChannel?: {
    dayPartGroupCode: string;
    audienceCategoryGroupCode: string;
  };
  temporaryDealId: string;
  disclaimer: DisclaimerObjectives;
  segmentFrameAverageByHour: SegmentFrameAverageByHour;
  campaignType: CampaignType;
  activeAllocationReportSolution: ActiveAllocationReportSolution;
  isCampaignDrawerOpen: boolean;
  campaignPlannerMode: CampaignPlannerMode;
};

type ToastPropsWithOptionalId = Omit<ToastProps, 'id'> & Partial<Pick<ToastProps, 'id'>>;
type ToastId = string;

const defaultResellerEntityValues = {
  code: 'RESELLER',
  name: 'RESELLER',
};

const defaultResellerFormValues = {
  advertiser: defaultResellerEntityValues,
  brand: defaultResellerEntityValues,
  productCategory: defaultResellerEntityValues,
};

export const initialState: DealManagement = {
  backupFormData: {
    commonDeal: {} as unknown as CommonDeal,
    programmatic: {} as unknown as Programmatic,
    lines: [],
  },
  programmatic: {
    dsp: null as unknown as Dsp,
    syncWithDsp: false,
    dspSeatId: '',
    enableCreativeSubmissionInBidStream: false,
    enableOpenMarketplace: false,
    enableLineLevelTrading: false,
  },
  directSales: defaultDirectSales,
  directSalesSessionLock: {
    status: DirectSalesLockStatusOption.UNLOCKED,
    expires: '',
    owner: '',
  },
  commonDeal: {
    advertiser: null as unknown as Advertiser,
    advertiserBlockExclusion: DirectSalesExclusionFlag.ON,
    agencyBlockExclusion: DirectSalesExclusionFlag.ON,
    auctionModel: AuctionModel.FIRST_PRICE,
    bookingStatusCode: '' as DealStatus,
    brand: null as unknown as Brand,
    cpm: '',
    dealId: '',
    dealName: '',
    dealPriority: DealPriority.STANDARD,
    dealType: getDefaultDealType(),
    duplicationExclusion: DirectSalesExclusionFlag.ON,
    exclusions: {
      organisations: [],
      productCategories: [],
    },
    externalReference: '',
    internalId: '',
    impressionMetrics: undefined,
    juxtapositionExclusion: DirectSalesExclusionFlag.ON,
    overriddenCampaignId: '',
    pricingSolution: { grid: [] },
    productCategory: null as unknown as CodeNameModel,
    productCategoryExclusivity: DirectSalesProductCategoryExclusivityType.OFF,
    sbId: '',
    specialist: null as unknown as CodeNameModel,
    salesPerson: null as unknown as CodeNameModel,
    salesTeam: null as unknown as CodeNameModel,
    specialistBlockExclusion: DirectSalesExclusionFlag.ON,
    adminPerson: null as unknown as CodeNameModel,
    agency: null as unknown as CodeNameModel,
    sourceSystem: '' as SourceSystem,
    summary: {
      adjustedSots: [],
      availability: {
        allocatedImpressions: 0,
        availableImpressions: 0,
        allocatedFrames: 0,
        assetCpmDistribution: {
          max: 0,
          min: 0,
          weightedMean: 0,
        },
        assets: [],
        availableFrames: 0,
        grossMediaValue: 0,
        netMediaValue: 0,
        totalCost: 0,
      },
      budget: 0,
      cities: [],
      counties: [],
      countries: [],
      deliveredImpressions: 0,
      endDate: null,
      environments: [],
      frames: 0,
      impressions: 0,
      listFiles: [],
      maxTargetSot: 0,
      minTargetSot: 0,
      postCodes: [],
      productFormats: [],
      routeFrameCodes: [],
      sot: [],
      startDate: null,
      streets: [],
      sweeps: [],
      tags: [],
    },
    currentLine: defaultLine,
    expirationDate: null as unknown as Date,
    bids: {
      bidRequests: 0,
      bidResponses: 0,
      bookedImpressions: 0,
      fillRate: 0,
      revenue: 0,
      soldImpressions: 0,
      wonImpressions: 0,
    },
  },
  isActionsDisabled: false,
  isDisclaimerVisible: false,
  isDuplicated: false,
  isEditingDisabled: false,
  isLoading: false,
  isFetchingAllocation: false,
  isFetchingReferences: false,
  isFetchingNetworks: false,
  loading: {},
  isForecastedAllocation: false,
  isNewDeal: false,
  isCpmCampaignLevel: true,
  plannerSettings: {
    isImpressionsClusterVisible: true,
    isAvailabilityClusterVisible: true,
    isAllocationClusterVisible: true,
    impressions: {
      min: 0,
      max: 0,
    },
    selectedDistributionOfAvailabilityAssets: [],
    enabledImpressionsRange: [],
    assetsOnViewport: [],
    selectedFrames: 0,
    selectedImpressions: 0,
    isAvailabilityClusterToggleDisabled: false,
    isAllocationClusterToggleDisabled: false,
    zoom: 0,
    frameIdIndexes: [],
    selectedIndexRange: undefined,
    openStreetMapPoiSearchText: '',
    isDirectSummaryPanelOpen: false,
  },
  meta: {
    poi: {
      attributes: [],
      datasources: [],
    },
    productCategoryGroups: [],
    allowedStateTransitions: {},
  },
  temporaryDealId: '',
  disclaimer: {
    success: [],
    warning: [],
    danger: [],
  },
  segmentFrameAverageByHour: {
    segmentValues: [],
    avgScoresByHour: [],
  },
  selectedIndexOptimisationDataProvider: '',
  plannerSecondaryPanelType: PlannerSecondaryPanelType.NONE,
  campaignType: CampaignType.PROGRAMMATIC,
  activeAllocationReportSolution: ActiveAllocationReportSolution.NATIVE,
  isCampaignDrawerOpen: false,
  campaignPlannerMode: CampaignPlannerMode.MAP,
};

export const dealManagementSlice = createSlice({
  name: 'dealManagement',
  initialState,
  reducers: {
    addIndexOptimisation: (state, action: PayloadAction<IndexOptimisation>) => {
      state.commonDeal.currentLine.indexOptimisation.push(action.payload);
    },
    resetIndexOptimisation: (state) => {
      state.commonDeal.currentLine.indexOptimisation = [];
    },
    addIndexOptimisationDisplayName: (
      state,
      {
        payload: { secondaryAudienceKey, displayName },
      }: PayloadAction<Pick<IndexOptimisation, 'secondaryAudienceKey' | 'displayName'>>,
    ) => {
      const index = state.commonDeal.currentLine.indexOptimisation.findIndex(
        (value) => value.secondaryAudienceKey === secondaryAudienceKey,
      );

      if (index > -1) {
        state.commonDeal.currentLine.indexOptimisation[index].displayName = displayName;
      }
    },
    changeCurrentLine: (state, action: PayloadAction<string>) => {
      const chosenLine = getLineById(state.backupFormData.lines, action.payload);

      state.commonDeal.currentLine = chosenLine;
    },
    changeCurrentLineToLastLine: (state) => {
      const lastLine = last(state.backupFormData.lines);

      if (lastLine) {
        state.commonDeal.currentLine = lastLine;
      }
    },
    changeCurrentLineAvailability: (state, action: PayloadAction<Partial<Availability>>) => {
      state.commonDeal.currentLine.availability = {
        ...state.commonDeal.currentLine.availability,
        ...action.payload,
      };
    },
    changeDealCurrentLineData: (state, action: PayloadAction<Partial<Line | DirectSalesLine>>) => {
      state.commonDeal.currentLine = { ...state.commonDeal.currentLine, ...action.payload };
    },
    changeDealCurrentLineAvailabilityAssetsSot: (state, action: PayloadAction<Pick<Asset, 'frameId' | 'sot'>[]>) => {
      state.commonDeal.currentLine.availability.assets = state.commonDeal.currentLine.availability.assets.map(
        (currentLineAvailabilityAsset: Asset) => {
          const assetInPayload = action.payload.find(
            (payloadAsset) => payloadAsset.frameId === currentLineAvailabilityAsset.frameId,
          );

          return {
            ...currentLineAvailabilityAsset,
            sot: assetInPayload?.sot ?? currentLineAvailabilityAsset.sot,
          };
        },
      );
    },
    changeDealData: (state, action: PayloadAction<Partial<CommonDeal>>) => {
      state.commonDeal = { ...state.commonDeal, ...action.payload };
    },
    changeOnlyProgrammaticDealData: (state, action: PayloadAction<Partial<Programmatic>>) => {
      state.programmatic = { ...state.programmatic, ...action.payload };
    },
    changeDealDetails: (
      state,
      action: PayloadAction<{
        backupFormData: BackupFormData;
        isAdServerMarket: boolean;
        commonDeal: Partial<CommonDeal>;
        hasAdsDealLevelCPMEnabled: boolean;
      }>,
    ) => {
      const backupLines = action.payload.backupFormData.lines.map((line: Line) =>
        merge({}, { ...initialState.commonDeal.currentLine }, line),
      );

      const cpm =
        action.payload.isAdServerMarket && action.payload.hasAdsDealLevelCPMEnabled
          ? backupLines[0].cpm
          : action.payload.commonDeal.cpm || '';

      const programmaticData = { ...initialState.programmatic, ...action.payload.backupFormData.programmatic };

      const backupFormData = {
        commonDeal: merge(
          {},
          { ...initialState.commonDeal },
          {
            ...action.payload.backupFormData.commonDeal,
            cpm,
          },
        ),
        programmatic: programmaticData,
        lines: backupLines,
      };

      const commonDeal = merge({}, { ...initialState.commonDeal }, { ...action.payload.commonDeal });
      const currentLineFromBackup =
        getLineById(action.payload.backupFormData.lines, state.commonDeal.currentLine.id) ||
        action.payload.commonDeal.currentLine;
      const currentLine = merge({}, { ...initialState.commonDeal.currentLine }, { ...currentLineFromBackup });

      state.isNewDeal = initialState.isNewDeal;
      state.commonDeal = { ...commonDeal, currentLine, cpm };
      state.programmatic = { ...programmaticData };
      state.backupFormData = { ...backupFormData };

      const directSales = {
        state: currentLine.state || initialState.directSales.state,
        lockState: currentLine.lockState || initialState.directSales.lockState,
        dealLineFormats: currentLine.dealLineFormats || [],
        locations: currentLine.locations || [],
        poi: currentLine.poi || [],
        mediaType: currentLine.mediaType,
        pricingMode: currentLine.pricingMode || DirectSalesPricingMode.CPM,
        allocation: currentLine.allocation || initialState.directSales.allocation,
        budgetCriteria: DEFAULT_CRITERIA,
        framesCriteria: DEFAULT_CRITERIA,
        impressionsCriteria: DEFAULT_CRITERIA,
        networkCriteria: [],
        objectives: currentLine.objectives || initialState.directSales.objectives,
        draftFailuresToasts: state.directSales.draftFailuresToasts || initialState.directSales.draftFailuresToasts,
        productTemplate: state.directSales.productTemplate || initialState.directSales.productTemplate,
        floatingRange: state.directSales.floatingRange || initialState.directSales.floatingRange,
      };

      state.directSales = directSales;
    },
    changeCurrentDealLineAvailability: (state, action: PayloadAction<Availability & PreservedAvailability>) => {
      const assets: Asset[] = action.payload.assets.map((asset: Asset) => {
        asset.mediaOwner = asset.mediaOwnerAssetId?.mediaOwner;
        asset.frameId = asset.mediaOwnerAssetId ? asset.mediaOwnerAssetId.assetId : asset.frameId;
        return {
          ...(getAssetByFrameId(state.commonDeal.currentLine.availability.assets, asset.frameId) ?? asset),
          street: asset.street ?? asset.address,
          postCode: asset.postCode ?? asset.postcode,
          impressions: asset.impressions,
          availableImpressions: asset.impressions,
        };
      });

      state.commonDeal.currentLine.availability = { ...action.payload, assets };
      state.commonDeal.currentLine.preservedAvailability = {
        ...action.payload,
        assets: undefined,
      } as PreservedAvailability;
    },
    changeBackupDealLineAvailabilityWithoutAssets: (
      state,
      action: PayloadAction<{ lineId: string; availability: Availability & PreservedAvailability }>,
    ) => {
      const idx = state.backupFormData.lines?.findIndex((line) => line.lineId === action.payload.lineId);
      const isLineInBackupLines = idx > -1;

      if (!isLineInBackupLines) {
        return;
      }

      if (isLineInBackupLines) {
        state.backupFormData.lines[idx] = {
          ...state.backupFormData.lines[idx],
          availability: { ...action.payload.availability, assets: [] },
          preservedAvailability: { ...action.payload.availability, assets: undefined } as PreservedAvailability,
        };
      }
    },
    changeDealLineAllocatedImpressions: (state, action: PayloadAction<Availability & PreservedAvailability>) => {
      const assets: Asset[] = action.payload.assets.map((asset: Asset) => ({
        ...(getAssetByFrameId(state.commonDeal.currentLine.availability.assets, asset.frameId) ?? asset),
        impressions: asset.impressions,
        allocatedImpressions: asset.impressions,
      }));

      state.commonDeal.currentLine.availability = { ...action.payload, assets };
      state.commonDeal.currentLine.preservedAvailability = {
        ...action.payload,
        assets: undefined,
      } as PreservedAvailability;
    },
    changeDealLineProximityPoi: (state, action: PayloadAction<Partial<Poi>>) => {
      state.commonDeal.currentLine.proximity.poi = { ...state.commonDeal.currentLine.proximity.poi, ...action.payload };
    },
    changeDealLineProximityPoints: (state, action: PayloadAction<Partial<Points>>) => {
      state.commonDeal.currentLine.proximity.points = {
        ...state.commonDeal.currentLine.proximity.points,
        ...action.payload,
      };
    },
    changeDealLineProximityPostcode: (state, action: PayloadAction<Partial<PostCode>>) => {
      state.commonDeal.currentLine.proximity.postCode = {
        ...state.commonDeal.currentLine.proximity.postCode,
        ...action.payload,
      };
    },
    changeDealLineVenueTaxonomy: (state, action: PayloadAction<number[]>) => {
      state.commonDeal.currentLine.venueTaxonomies = action.payload;
    },
    addDealLineVenueTaxonomy: (state, action: PayloadAction<number>) => {
      state.commonDeal.currentLine.venueTaxonomies = [...state.commonDeal.currentLine.venueTaxonomies, action.payload];
    },
    removeDealLineVenueTaxonomy: (state, action: PayloadAction<number>) => {
      state.commonDeal.currentLine.venueTaxonomies = [
        ...state.commonDeal.currentLine.venueTaxonomies.filter(
          (venueTaxonomyCode) => venueTaxonomyCode !== action.payload,
        ),
      ];
    },
    changeSegmentFrameAverageByHour: (state, action: PayloadAction<SegmentFrameAverageByHour>) => {
      state.segmentFrameAverageByHour = action.payload;
    },
    clearSegmentFrameAverageByHour: (state) => {
      state.segmentFrameAverageByHour = initialState.segmentFrameAverageByHour;
    },
    changeIsCpmCampaignLevel: (state, action: PayloadAction<boolean>) => {
      state.isCpmCampaignLevel = action.payload;
    },
    changeDisclaimer: (state, action: PayloadAction<DisclaimerObjectives>) => {
      state.disclaimer = action.payload;
    },
    changeFormParams: (state, action: PayloadAction<Partial<DealManagement>>) => {
      return { ...state, ...action.payload };
    },
    changeFrontEndType: (state, action: PayloadAction<FrontEndType>) => {
      return {
        ...state,
        commonDeal: {
          ...state.commonDeal,
          currentLine: {
            ...state.commonDeal.currentLine,
            frontEndType: action.payload,
          },
        },
      };
    },
    changeIsNewDeal: (state, action: PayloadAction<PublisherType>) => {
      return {
        ...state,
        isNewDeal: true,
        isCampaignDrawerOpen: true,
        isEditingDisabled: false,
        isLoading: false,
        isFetchingReferences: false,
        isFetchingNetworks: false,
        isActionsDisabled: false,
        commonDeal: {
          ...state.commonDeal,
          dealType: getDefaultDealType(action.payload),
          ...(action.payload === PublisherType.RESELLER ? defaultResellerFormValues : null),
        },
      };
    },
    changeIsNewLineInEndedDeal: (state) => {
      return {
        ...state,
        isNewDeal: false,
        isEditingDisabled: false,
        isLoading: false,
        isFetchingReferences: false,
        isFetchingNetworks: false,
        isActionsDisabled: false,
      };
    },
    changeMeta: (state, action: PayloadAction<DealManagementMeta>) => {
      state.meta = action.payload;
    },
    changeSegment: (state, action: PayloadAction<Partial<Segment>>) => {
      state.commonDeal.currentLine.segment = {
        ...state.commonDeal.currentLine.segment,
        ...action.payload,
      };
    },
    changeCampaignType: (state, action: PayloadAction<CampaignType>) => {
      state.campaignType = action.payload;
    },
    clearAllocationInputs: (state) => {
      state.commonDeal.currentLine = {
        ...state.commonDeal.currentLine,
        impressions: undefined,
        budget: undefined,
        sot: undefined,
        frames: undefined,
      };
    },
    clearDealLineVenueTaxonomies: (state) => {
      state.commonDeal.currentLine.venueTaxonomies = [];
    },
    clearSegment: (state) => {
      state.commonDeal.currentLine.segment.selectedSegments = {};
    },
    clearDisclaimer: (state) => {
      state.disclaimer = { success: [], warning: [], danger: [] };
    },
    clearForm: (state) => {
      return {
        ...initialState,
        isNewDeal: true,
        isCampaignDrawerOpen: state.isCampaignDrawerOpen,
        meta: state.meta,
        campaignType: state.campaignType,
        commonDeal: {
          ...initialState.commonDeal,
          currentLine: {
            ...initialState.commonDeal.currentLine,
            name: state.commonDeal.currentLine.name,
            frontEndType: state.commonDeal.currentLine.frontEndType,
          },
        },
      };
    },
    createNewLine: (state) => {
      state.commonDeal.currentLine = { ...initialState.commonDeal.currentLine };
    },
    createNewLineWithUniqueName: (state) => {
      const lines = state.backupFormData.lines.map((line) => line.name.toLowerCase());
      const idx = lines.length + 1;

      const newLineName = generateUniqueDealLineName(lines, idx);
      state.commonDeal.currentLine = {
        ...initialState.commonDeal.currentLine,
        frontEndType: state.commonDeal.currentLine.frontEndType,
        name: newLineName,
      };

      if (state.isCpmCampaignLevel) {
        state.commonDeal.currentLine.cpm = state.commonDeal.cpm ?? 0;
      }
    },
    createNewProgrammaticLine: (state) => {
      state.commonDeal.currentLine = { ...initialState.commonDeal.currentLine };
      state.programmatic = { ...initialState.programmatic };
    },
    appendSegment: (state, action: PayloadAction<SelectedSegment>) => {
      state.commonDeal.currentLine.segment.selectedSegments = {
        ...state.commonDeal.currentLine.segment.selectedSegments,
        ...action.payload,
      };
    },
    duplicateLine: (state, action: PayloadAction<string>) => {
      const backupLine = getLineById(state.backupFormData.lines, action.payload);
      state.commonDeal.currentLine = {
        ...state.commonDeal.currentLine,
        ...getDuplicateLineState(backupLine),
        availability: { ...initialState.commonDeal.currentLine.availability },
        preservedAllocation: { ...initialState.commonDeal.currentLine.preservedAllocation },
      };
    },
    hideDisclaimer: (state) => {
      state.isDisclaimerVisible = false;
    },
    updatePlannerSettings: (state, action: PayloadAction<Partial<PlannerSettings>>) => {
      state.plannerSettings = { ...state.plannerSettings, ...action.payload };
    },
    updatePlannerPoi: (
      state,
      action: PayloadAction<Pick<PlannerPoiWithPoints, 'poiPoints' | 'code' | 'visibility'>[]>,
    ) => {
      state.commonDeal.currentLine.proximity.plannerPoi = state.commonDeal.currentLine.proximity.plannerPoi.map(
        (plannerPoi) => {
          const payloadPlannerPoi = action.payload.find((poi) => poi.code === plannerPoi.code);
          if (!payloadPlannerPoi) return plannerPoi;

          return {
            ...plannerPoi,
            poiPoints: payloadPlannerPoi.poiPoints.map((poiPoint) => ({
              ...poiPoint,
              properties: {
                ...poiPoint.properties,
                visibility: payloadPlannerPoi.visibility,
              },
            })),
          };
        },
      );
    },
    resetPlannerFrameIdIndexes: (state) => {
      state.plannerSettings.frameIdIndexes = [];
      state.plannerSettings.selectedIndexRange = undefined;
    },
    updatePlannerFrameIdIndexes: (state, action: PayloadAction<SegmentHourAverageByFrame>) => {
      const getSegmentFromPayloadMatchingAssetFrameId = (assetFrameId: string): AverageScoresByFrame | undefined =>
        action.payload.avgScoresByFrame.find((payloadSegment) => payloadSegment.frameId === assetFrameId);

      const getIndexScoreOfAsset = (assetFrameId: string): number => {
        const segmentFromPayload = getSegmentFromPayloadMatchingAssetFrameId(assetFrameId);

        if (segmentFromPayload) return Object.values(segmentFromPayload.scores)[0];
        return 0;
      };

      state.plannerSettings.frameIdIndexes = state.commonDeal.currentLine.availability.assets.map((asset) => ({
        frameId: asset.frameId,
        index: getIndexScoreOfAsset(asset.frameId),
      }));
    },
    updateIndexOptimisation: (
      state,
      action: PayloadAction<Omit<IndexOptimisation, 'displayName' | 'dataProvider'>>,
    ) => {
      state.commonDeal.currentLine.indexOptimisation = state.commonDeal.currentLine.indexOptimisation?.map((option) => {
        return option.secondaryAudienceKey === action.payload.secondaryAudienceKey
          ? { ...option, ...action.payload }
          : option;
      });
    },
    removeIndexOptimisation: (state, action: PayloadAction<Pick<IndexOptimisation, 'secondaryAudienceKey'>>) => {
      state.commonDeal.currentLine.indexOptimisation = state.commonDeal.currentLine.indexOptimisation?.filter(
        (option) => option.secondaryAudienceKey !== action.payload.secondaryAudienceKey,
      );
    },
    resetCurrentLineProperty: (state, action: PayloadAction<keyof Line>) => {
      state.commonDeal.currentLine = {
        ...state.commonDeal.currentLine,
        [action.payload]: initialState.commonDeal.currentLine[action.payload],
      };
    },
    resetPlannerImpressions: (state) => {
      state.plannerSettings.impressions = initialState.plannerSettings.impressions;
    },
    resetDealLineAvailability: (state) => {
      state.commonDeal.currentLine.availability = { ...initialState.commonDeal.currentLine.availability };
    },
    resetForm: (state) => {
      const { lines } = state.backupFormData;
      const id = state.commonDeal.currentLine.id || lines[lines.length - 1]?.id;

      state.programmatic = {
        ...state.backupFormData.programmatic,
      };
      state.commonDeal = {
        ...state.backupFormData.commonDeal,
        currentLine: { ...state.commonDeal.currentLine, ...getLineById(lines, id) },
      };
    },
    resetProperty: (state, action: PayloadAction<keyof DealManagement>) => {
      return {
        ...state,
        [action.payload]: initialState[action.payload],
      };
    },
    resetSelectedIndexRange: (state) => {
      state.plannerSettings.selectedIndexRange = undefined;
    },
    resetState: (state) => ({
      ...initialState,
      campaignType: state.campaignType,
    }),
    setSelectedEnvironmentChannel: (
      state,
      action: PayloadAction<{
        audienceCategoryGroupCode: string;
        dayPartGroupCode: string;
      }>,
    ) => {
      state.selectedEnvironmentChannel = action.payload;
    },
    showDisclaimer: (state) => {
      state.isDisclaimerVisible = true;
    },
    toggleCampaignDrawerOpen: (state, action: PayloadAction<boolean>) => {
      state.plannerSecondaryPanelType = PlannerSecondaryPanelType.NONE;
      state.isCampaignDrawerOpen = action.payload ?? !state.isCampaignDrawerOpen;
    },
    startLoading: (state, action: PayloadAction<Loading | undefined>) => {
      if (!state.loading) state.loading = {};

      if (action?.payload) {
        state.loading[action.payload] = true;
      }

      state.isLoading = true;
    },
    stopLoading: (state, action: PayloadAction<Loading | undefined>) => {
      if (!action?.payload) {
        state.loading = {};
        state.isLoading = false;

        return;
      }

      if (state.loading[action.payload]) delete state.loading[action.payload];

      state.isLoading = !!Object.values(state.loading).length;
    },
    deleteSegment: (state, action: PayloadAction<string>) => {
      delete state.commonDeal.currentLine.segment.selectedSegments[action.payload];
    },
    updateIndexOptimisationDataProvider: (state, action: PayloadAction<string>) => {
      state.commonDeal.currentLine.indexOptimisation = state.commonDeal.currentLine.indexOptimisation.map(
        (value: IndexOptimisation) => ({
          ...value,
          dataProvider: action.payload,
        }),
      );
    },
    updateRouteFrameCodes: (state) => {
      const routeFrameCodes: RouteFrameCode[] = state.commonDeal.currentLine.availability.assets.map((asset: Asset) => {
        const isFrameVisible = !!state.plannerSettings.assetsOnViewport.find(
          (assetOnViewport) => assetOnViewport.isVisible && assetOnViewport.frameId === asset.frameId,
        );

        return {
          code: asset.frameId,
          name: asset.frameId,
          include: isFrameVisible,
          mediaOwner: asset.mediaOwner,
        };
      });

      state.commonDeal.currentLine.routeFrameCodes = routeFrameCodes;
    },
    addPlannerPoi: (state, action: PayloadAction<PlannerPoiWithPoints>) => {
      state.commonDeal.currentLine.proximity.plannerPoi = [
        ...state.commonDeal.currentLine.proximity.plannerPoi,
        action.payload,
      ];
    },
    removePlannerPoi: (state, action: PayloadAction<PoiOption['code']>) => {
      state.commonDeal.currentLine.proximity.plannerPoi = state.commonDeal.currentLine.proximity.plannerPoi.filter(
        ({ code }) => action.payload !== code,
      );
    },
    resetPlannerPoi: (state) => {
      state.commonDeal.currentLine.proximity.plannerPoi = initialState.commonDeal.currentLine.proximity.plannerPoi;
    },
    changeIncludeExcludePlannerPoi: (
      state,
      action: PayloadAction<{
        id: string;
        include: boolean;
      }>,
    ) => {
      const poiOption: PlannerPoiWithPoints | undefined = state.commonDeal.currentLine.proximity.plannerPoi.find(
        ({ code }) => code === action.payload.id,
      );

      if (poiOption) {
        poiOption.visibility.include = action.payload.include;
        poiOption.poiPoints.forEach((_, index) => {
          poiOption.poiPoints[index].properties.visibility.include = action.payload.include;
        });
      }
    },
    changePlannerPoiRadius: (
      state,
      action: PayloadAction<{
        id: string;
        radius: number;
      }>,
    ) => {
      const poiOption: PlannerPoiWithPoints | undefined = state.commonDeal.currentLine.proximity.plannerPoi.find(
        ({ code }) => code === action.payload.id,
      );

      if (poiOption) {
        poiOption.visibility.radius = action.payload.radius;
        poiOption.poiPoints.forEach((_, index) => {
          poiOption.poiPoints[index].properties.visibility.radius = action.payload.radius;
        });
      }
    },
    addOpenStreetMapPoi: (state, action: PayloadAction<OpenStreetMapPoiWithPoints>) => {
      state.commonDeal.currentLine.proximity.openStreetMapPoi = [
        ...state.commonDeal.currentLine.proximity.openStreetMapPoi,
        action.payload,
      ];
    },
    changeOpenStreetMapPoiRadius: (
      state,
      action: PayloadAction<{
        name: string;
        radius: number;
      }>,
    ) => {
      const openStreetMapPoi: OpenStreetMapPoiWithPoints | undefined =
        state.commonDeal.currentLine.proximity.openStreetMapPoi.find(({ name }) => name === action.payload.name);

      if (openStreetMapPoi) {
        openStreetMapPoi.visibility.radius = action.payload.radius;
        openStreetMapPoi.poiPoints.forEach((_, index) => {
          openStreetMapPoi.poiPoints[index].properties.visibility.radius = action.payload.radius;
        });
      }
    },
    resetOpenStreetMapPoi: (state) => {
      state.commonDeal.currentLine.proximity.openStreetMapPoi =
        initialState.commonDeal.currentLine.proximity.openStreetMapPoi;
      state.plannerSettings.openStreetMapPoiSearchText = '';
    },
    removeTemporaryDealId: (state) => {
      state.temporaryDealId = initialState.temporaryDealId;
    },
    changePlannerSecondaryPanelType: (state, action: PayloadAction<PlannerSecondaryPanelType>) => {
      state.plannerSecondaryPanelType = action.payload;
    },
    hidePlannerSecondaryPanel: (state) => {
      state.plannerSecondaryPanelType = PlannerSecondaryPanelType.NONE;
    },
    duplicateDeal: (
      state,
      action: PayloadAction<{
        backupFormData: BackupFormData;
        commonDeal: CommonDeal;
        hasAdsDealLevelCPMEnabled: boolean;
        programmatic: Programmatic;
      }>,
    ) => {
      const {
        bids,
        bookingStatusCode: dealInitialBookingStatusCode,
        dealId,
        internalId,
        overriddenCampaignId,
        summary,
      } = initialState.commonDeal;

      const {
        availability,
        bookingStatusCode,
        createdDate,
        currentRemainingImpressions,
        deliveredImpressions,
        dischargedFrameIds,
        earliestFrameStartDate,
        earliestFrameTimezone,
        endDate,
        id,
        isCurrentLineWithProposalAllocation,
        latestFrameEndDate,
        latestFrameTimezone,
        lineId,
        patternLength,
        preservedAllocation,
        preservedAvailability,
        proximity,
        routeFrameCodes,
        segment,
        selectedDays,
        startDate,
        terminated,
        venueTaxonomies,
      } = initialState.commonDeal.currentLine;

      const cpm = action.payload.hasAdsDealLevelCPMEnabled
        ? action.payload.commonDeal.currentLine.cpm
        : action.payload.commonDeal.cpm || '';

      const duplicatedLine = {
        ...action.payload.commonDeal.currentLine,
        availability,
        bookingStatusCode,
        createdDate,
        currentRemainingImpressions,
        deliveredImpressions,
        dischargedFrameIds,
        earliestFrameStartDate,
        earliestFrameTimezone,
        endDate,
        id,
        isCurrentLineWithProposalAllocation,
        latestFrameEndDate,
        latestFrameTimezone,
        lineId,
        name: `${action.payload.commonDeal.currentLine.name} - copy`,
        patternLength,
        preservedAllocation,
        preservedAvailability,
        proximity: {
          ...action.payload.commonDeal.currentLine.proximity,
          postCode: {
            ...action.payload.commonDeal.currentLine.proximity.postCode,
            files: action.payload.commonDeal.currentLine.proximity?.postCode?.files || proximity.postCode.files,
          },
          points: {
            ...action.payload.commonDeal.currentLine.proximity.points,
            files: action.payload.commonDeal.currentLine.proximity?.points?.files || proximity.points.files,
          },
        },
        routeFrameCodes: action.payload.commonDeal.currentLine.routeFrameCodes || routeFrameCodes,
        segment: action.payload.commonDeal.currentLine.segment || segment,
        selectedDays,
        startDate,
        terminated,
        venueTaxonomies: action.payload.commonDeal.currentLine.venueTaxonomies || venueTaxonomies,
      };

      const duplicatedDeal = {
        ...action.payload.commonDeal,
        bids,
        bookingStatusCode: dealInitialBookingStatusCode,
        cpm,
        dealId,
        dealName: `${action.payload.commonDeal.dealName} - copy`,
        internalId,
        overriddenCampaignId,
        summary,
        currentLine: duplicatedLine,
      };

      const { programmatic } = action.payload;

      state.isDuplicated = true;
      state.isNewDeal = true;
      state.programmatic = programmatic;
      state.commonDeal = duplicatedDeal;
      state.backupFormData = {
        commonDeal: duplicatedDeal,
        programmatic,
        lines: [duplicatedLine],
      };
    },
    addAllDirectSalesLocations: (state, action: PayloadAction<DirectSalesCodeNameModel[]>) => {
      const locations = state.directSales.locations.filter(
        (location) => location.category !== action.payload[0].category,
      );
      const newLocations = locations.concat(
        ...action.payload.map((data) => ({
          ...data,
          status: DirectSalesFilterScope.INCLUDE,
        })),
      );

      state.directSales.locations = sortBy(newLocations, ['category', 'name'], ['asc']);
    },
    addDirectSalesLocations: (state, action: PayloadAction<DirectSalesCodeNameModel[]>) => {
      state.directSales.locations = action.payload.map((location) => ({
        ...location,
        status: DirectSalesFilterScope.INCLUDE,
      }));
    },
    addDirectSalesLineId: (state, action: PayloadAction<string>) => {
      state.commonDeal.currentLine.lineId = action.payload;
      state.backupFormData.lines[state.backupFormData.lines.length - 1].lineId = action.payload;
    },
    addDirectSalesObjective: (state) => {
      const { lineId } = state.commonDeal.currentLine;

      const idx = state.backupFormData.lines.findIndex((line) => line.lineId === lineId);

      if (idx > -1) {
        const line = state.backupFormData.lines[idx] as DirectSalesLine;
        const currentObjectives = line.objectives ?? [];

        const newObjectiveId = getNewObjectiveId(currentObjectives);
        const newObjectiveName = getUniqueObjectiveName(currentObjectives, newObjectiveId);
        const newObjective = {
          ...DEFAULT_OBJECTIVE,
          objectiveGroupId: newObjectiveId,
          name: newObjectiveName,
          budget: {
            ...DEFAULT_OBJECTIVE.budget,
            objectiveId: getNewTargetId(TargetObjectiveId.BUDGET),
          },
          frames: {
            ...DEFAULT_OBJECTIVE.budget,
            objectiveId: getNewTargetId(TargetObjectiveId.FRAMES),
          },
          impressions: {
            ...DEFAULT_OBJECTIVE.budget,
            objectiveId: getNewTargetId(TargetObjectiveId.IMPRESSIONS),
          },
        };

        const newObjectives = [...currentObjectives, newObjective];

        state.backupFormData.lines[idx] = {
          ...line,
          objectives: newObjectives,
        };
        state.directSales = { ...state.directSales, objectives: newObjectives };
      }
    },
    deleteDirectSalesObjective: (state, action: PayloadAction<number>) => {
      const { lineId } = state.commonDeal.currentLine;

      const idx = state.backupFormData.lines.findIndex((line) => line.lineId === lineId);

      if (idx > -1) {
        const line = state.backupFormData.lines[idx] as DirectSalesLine;
        const currentObjectives = line.objectives ?? [];

        const newObjectives = currentObjectives.filter((objective) => objective.objectiveGroupId !== action.payload);

        state.backupFormData.lines[idx] = {
          ...line,
          objectives: newObjectives,
        };
        state.directSales = { ...state.directSales, objectives: newObjectives };
      }
    },
    changeActiveDirectSalesLine: (state, action: PayloadAction<string>) => {
      const lineId = action.payload;
      const idx = state.backupFormData.lines.findIndex((line) => line.lineId === lineId);

      if (idx >= 0) {
        const activeLine = state.backupFormData.lines[idx] as DirectSalesLine;

        const {
          dealLineFormats,
          mediaType,
          pricingMode,
          locations,
          poi,
          state: lineState,
          lockState,
          allocation,
          budgetCriteria,
          framesCriteria,
          impressionsCriteria,
          networkCriteria,
          objectives,
          draftFailuresToasts,
          productTemplate,
          floatingRange,
          sotObjectiveId,
          ...currentLine
        } = activeLine;

        const directSales = {
          state: lineState || initialState.directSales.state,
          lockState: lockState || initialState.directSales.lockState,
          dealLineFormats: dealLineFormats || [],
          locations: locations || [],
          poi: poi || [],
          budgetCriteria: budgetCriteria || DEFAULT_CRITERIA,
          framesCriteria: framesCriteria || DEFAULT_CRITERIA,
          impressionsCriteria: impressionsCriteria || DEFAULT_CRITERIA,
          networkCriteria: networkCriteria || [],
          mediaType: mediaType || initialState.directSales.mediaType,
          pricingMode: pricingMode || initialState.directSales.pricingMode,
          allocation: allocation || initialState.directSales.allocation,
          objectives: objectives || initialState.directSales.objectives,
          transientState: state.directSales.transientState || initialState.directSales.transientState,
          transientLineStates: state.directSales.transientLineStates || initialState.directSales.transientLineStates,
          draftFailuresToasts: draftFailuresToasts || initialState.directSales.draftFailuresToasts,
          productTemplate: productTemplate || initialState.directSales.productTemplate,
          floatingRange: floatingRange || initialState.directSales.floatingRange,
          sotObjectiveId: sotObjectiveId || initialState.directSales.sotObjectiveId,
        };

        state.commonDeal.currentLine = currentLine;
        state.directSales = directSales;
      }
    },
    setDirectSalesUniverseAvailability: (
      state,
      action: PayloadAction<{
        campaign: {
          framesCount: DirectSalesCount;
          impressionsCount: DirectSalesCount;
        };
        lines: Record<string, DirectSalesAssetLine>;
      }>,
    ) => {
      state.backupFormData.lines.forEach((line: DirectSalesLine) => {
        const {
          framesCount: { universe: universeFrames },
          impressionsCount: { universe: universeImpressions },
        } = action.payload.lines[line.lineId];

        line.availability.universeFrames = universeFrames;
        line.availability.universeImpressions = universeImpressions;
      });

      const {
        framesCount: { universe: universeFrames },
        impressionsCount: { universe: universeImpressions },
      } = action.payload.lines[state.commonDeal.currentLine.lineId];

      state.commonDeal.currentLine.availability.universeFrames = universeFrames;
      state.commonDeal.currentLine.availability.universeImpressions = universeImpressions;

      state.commonDeal.summary.availability.universeFrames = action.payload.campaign.framesCount.universe;
      state.commonDeal.summary.availability.universeImpressions = action.payload.campaign.impressionsCount.universe;
    },
    setDirectSalesAssetList: (
      state,
      action: PayloadAction<{
        campaign: {
          framesCount: DirectSalesCount;
          impressionsCount: DirectSalesCount;
        };
        lines: Record<string, DirectSalesAssetLine>;
      }>,
    ) => {
      state.backupFormData.lines.forEach((line: DirectSalesLine) => {
        const {
          framesCount: { allocated: allocatedFrames },
          impressionsCount: { allocated: allocatedImpressions },
          assets,
        } = action.payload.lines[line.lineId];

        line.availability.allocatedFrames = allocatedFrames;
        line.availability.allocatedImpressions = allocatedImpressions;
        line.availability.assets = assets;
      });

      const {
        framesCount: { allocated: allocatedFrames },
        impressionsCount: { allocated: allocatedImpressions },
        assets,
      } = action.payload.lines[state.commonDeal.currentLine.lineId];

      state.commonDeal.currentLine.availability.allocatedFrames = allocatedFrames;
      state.commonDeal.currentLine.availability.allocatedImpressions = allocatedImpressions;
      state.commonDeal.currentLine.availability.assets = assets;

      state.commonDeal.summary.availability.allocatedFrames = action.payload.campaign.framesCount.allocated;
      state.commonDeal.summary.availability.allocatedImpressions = action.payload.campaign.impressionsCount.allocated;
    },
    clearAssets: (state) => {
      state.backupFormData.lines.forEach((line: DirectSalesLine) => {
        line.availability.assets = [];
      });

      state.commonDeal.currentLine.availability.assets = [];
    },
    clearDirectSalesAssetList: (state) => {
      state.backupFormData.lines.forEach((line: DirectSalesLine) => {
        line.availability.allocatedFrames = initialState.commonDeal.currentLine.availability.allocatedFrames;
        line.availability.allocatedImpressions = initialState.commonDeal.currentLine.availability.allocatedImpressions;
      });

      state.commonDeal.currentLine.availability.allocatedFrames =
        initialState.commonDeal.currentLine.availability.allocatedFrames;
      state.commonDeal.currentLine.availability.allocatedImpressions =
        initialState.commonDeal.currentLine.availability.allocatedImpressions;
      state.commonDeal.summary.availability.allocatedFrames =
        initialState.commonDeal.summary.availability.allocatedFrames;
      state.commonDeal.summary.availability.allocatedImpressions =
        initialState.commonDeal.summary.availability.allocatedImpressions;
    },
    setDirectSalesPrices: (
      state,
      action: PayloadAction<{ campaignPrice: number; linePrices: Record<string, number> }>,
    ) => {
      state.commonDeal.summary.availability.totalCost = action.payload.campaignPrice;
      state.backupFormData.commonDeal.summary.availability.totalCost = action.payload.campaignPrice;

      state.backupFormData.lines.forEach((line: DirectSalesLine) => {
        const linePrice = action.payload.linePrices[line.lineId];

        line.availability.totalCost = linePrice;

        if (line.lineId === state.commonDeal.currentLine.lineId) {
          state.commonDeal.currentLine.availability.totalCost = linePrice;
        }
      });
    },
    clearDirectSalesPrices: (state) => {
      state.commonDeal.summary.availability.totalCost = 0;
      state.backupFormData.commonDeal.summary.availability.totalCost = 0;

      state.backupFormData.lines.forEach((line: DirectSalesLine) => {
        line.availability.totalCost = 0;
      });

      state.commonDeal.currentLine.availability.totalCost = 0;
    },
    updateDirectSalesLineLockState: (state, action: PayloadAction<DirectSalesLockStatusOption>) => {
      state.directSales.lockState.status = action.payload;
    },
    changeDirectSalesData: (state, action: PayloadAction<Partial<DirectSales>>) => {
      state.directSales = { ...state.directSales, ...action.payload };
    },
    addDraftFailureToast: (state, action: PayloadAction<ToastPropsWithOptionalId>) => {
      const id = action.payload.id ?? uuidv4();
      state.directSales.draftFailuresToasts.push({
        id,
        header: action.payload.header,
        description: action.payload.description,
      });
    },
    removeDraftFailureToast: (state, action: PayloadAction<ToastId>) => {
      const index = state.directSales.draftFailuresToasts.findIndex((toast: ToastProps) => toast.id === action.payload);
      if (index > -1) state.directSales.draftFailuresToasts.splice(index, 1);
    },
    changeDirectSalesLineName: (state, action: PayloadAction<string>) => {
      const idx = state.backupFormData.lines.findIndex((line) => line.lineId === state.commonDeal.currentLine.lineId);

      if (idx > -1) {
        state.backupFormData.lines[idx].name = action.payload;
        state.commonDeal.currentLine.name = action.payload;
      }
    },
    clearDirectSalesAllocation: (state) => {
      state.backupFormData.lines.forEach((line: DirectSalesLine) => {
        line.allocation = initialState.directSales.allocation;
        line.objectives.forEach((objective) => {
          objective.objectiveStatus = undefined;
        });
      });

      state.directSales.allocation = initialState.directSales.allocation;
      state.directSales.objectives.forEach((objective) => {
        objective.objectiveStatus = undefined;
      });
    },
    changeCampaignPlannerMode: (state, action: PayloadAction<CampaignPlannerMode>) => {
      state.campaignPlannerMode = action.payload;
      state.plannerSecondaryPanelType = PlannerSecondaryPanelType.NONE;
    },
    clearAllDirectSalesDealLineFormats: (state) => {
      state.directSales.dealLineFormats = [];
    },
    clearAllDirectSalesLocations: (state) => {
      state.directSales.locations = [];
    },
    createNewDirectSalesLine: (state) => {
      const lines = state.backupFormData.lines.map((line) => line.name.toLowerCase());
      const idx = lines.length + 1;

      const newLineName = generateUniqueDealLineName(lines, idx);

      state.commonDeal.currentLine = {
        ...initialState.commonDeal.currentLine,
        id: state.commonDeal.dealId,
        name: newLineName,
        frontEndType: FrontEndType.PLANNER,
      };
      state.directSales = { ...initialState.directSales };
      state.backupFormData.lines.push({
        ...initialState.commonDeal.currentLine,
        ...initialState.directSales,
        id: state.commonDeal.dealId,
        state: initialState.directSales.state,
        lockState: initialState.directSales.lockState,
        frontEndType: FrontEndType.PLANNER,
        name: newLineName,
      });
    },
    duplicateDirectSalesLine: (state) => {
      const lines = state.backupFormData.lines.map((line) => line.name.toLowerCase());
      const index = lines.length + 1;
      const newLineName = generateUniqueDealLineName(lines, index);

      const duplicatedCurrentLine = cloneDeep(state.commonDeal.currentLine);
      const duplicatedDirectSales = cloneDeep(state.directSales);

      duplicatedCurrentLine.lineId = '';

      let baseTargetId = getNewTargetId();

      if (duplicatedDirectSales.budgetCriteria.objectiveId) {
        duplicatedDirectSales.budgetCriteria.objectiveId = baseTargetId + 1;
        baseTargetId += 1;
      }

      if (duplicatedDirectSales.framesCriteria.objectiveId) {
        duplicatedDirectSales.framesCriteria.objectiveId = baseTargetId + 1;
        baseTargetId += 1;
      }

      if (duplicatedDirectSales.impressionsCriteria.objectiveId) {
        duplicatedDirectSales.impressionsCriteria.objectiveId = baseTargetId + 1;
        baseTargetId += 1;
      }

      duplicatedDirectSales.networkCriteria.forEach((networkCriterion) => {
        networkCriterion.objectiveId = baseTargetId + 1;
        baseTargetId += 1;
      });

      duplicatedDirectSales.objectives.forEach((objective) => {
        if (objective.budget.objectiveId) {
          objective.budget.objectiveId = baseTargetId + 1;
          baseTargetId += 1;
        }

        if (objective.frames.objectiveId) {
          objective.frames.objectiveId = baseTargetId + 1;
          baseTargetId += 1;
        }

        if (objective.impressions.objectiveId) {
          objective.impressions.objectiveId = baseTargetId + 1;
          baseTargetId += 1;
        }
      });

      if (duplicatedCurrentLine.sot) {
        duplicatedDirectSales.sotObjectiveId = baseTargetId + 1;
        baseTargetId += 1;
      }

      state.commonDeal.currentLine = {
        ...initialState.commonDeal.currentLine,
        ...duplicatedCurrentLine,
        id: state.commonDeal.dealId,
        name: newLineName,
        frontEndType: FrontEndType.PLANNER,
      };
      state.directSales = { ...initialState.directSales, ...duplicatedDirectSales };
      state.backupFormData.lines.push({
        ...initialState.commonDeal.currentLine,
        ...initialState.directSales,
        ...duplicatedCurrentLine,
        ...duplicatedDirectSales,
        id: state.commonDeal.dealId,
        state: initialState.directSales.state,
        lockState: initialState.directSales.lockState,
        frontEndType: FrontEndType.PLANNER,
        name: newLineName,
      });
    },
    removeDirectSalesLine: (state) => {
      const { lineId } = state.commonDeal.currentLine;

      const idx = state.backupFormData.lines.findIndex((line) => line.lineId === lineId);
      const lines = state.backupFormData.lines.filter((line) => line.lineId !== lineId) as DirectSalesLine[];
      const newLineIdx = idx > -1 && idx === state.backupFormData.lines.length - 1 ? lines.length - 1 : idx;

      const newActiveLine = lines[newLineIdx] || initialState.commonDeal.currentLine;

      state.commonDeal.currentLine = newActiveLine;
      state.directSales = {
        state: newActiveLine.state,
        lockState: newActiveLine.lockState,
        dealLineFormats: newActiveLine.dealLineFormats,
        locations: newActiveLine.dealLineFormats,
        poi: newActiveLine.poi,
        mediaType: newActiveLine.mediaType,
        pricingMode: newActiveLine.pricingMode,
        allocation: newActiveLine.allocation,
        budgetCriteria: newActiveLine.budgetCriteria,
        framesCriteria: newActiveLine.framesCriteria,
        impressionsCriteria: newActiveLine.impressionsCriteria,
        networkCriteria: newActiveLine.networkCriteria,
        objectives: newActiveLine.objectives,
        draftFailuresToasts: newActiveLine.draftFailuresToasts || [],
        productTemplate: newActiveLine.productTemplate,
        floatingRange: newActiveLine.floatingRange,
        transientState: state.directSales.transientState ?? initialState.directSales.transientState,
        transientLineStates: state.directSales.transientLineStates ?? initialState.directSales.transientLineStates,
      };
      state.backupFormData.lines = lines;
    },
    removeDirectSalesLocation: (state, action: PayloadAction<string>) => {
      const locations = [...state.directSales.locations];
      const index = locations.findIndex((location) => location.code === action.payload);

      if (index > -1) {
        locations.splice(index, 1);
        state.directSales.locations = locations;
      }
    },
    removeDirectSalesLocations: (state, action: PayloadAction<CodeNameModel[]>) => {
      const newLocations = state.directSales.locations.filter(
        (location) => !action.payload.find((locationToRemove) => location.code === locationToRemove.code),
      );
      state.directSales.locations = newLocations;
    },
    setLoadedDirectSalesData: (
      state,
      action: PayloadAction<{
        commonDeal: Partial<CommonDeal>;
        lines: Partial<DirectSalesLine>[];
      }>,
    ) => {
      const { commonDeal, lines } = action.payload;

      const currentLine =
        lines.find((line) => line.lineId === state.commonDeal.currentLine.lineId) || lines[lines.length - 1];

      const directSales = {
        state: currentLine.state || initialState.directSales.state,
        lockState: currentLine.lockState || initialState.directSales.lockState,
        dealLineFormats: currentLine.dealLineFormats || [],
        locations: currentLine.locations || [],
        poi: currentLine.poi || [],
        budgetCriteria: currentLine.budgetCriteria || initialState.directSales.budgetCriteria,
        framesCriteria: currentLine.framesCriteria || initialState.directSales.framesCriteria,
        impressionsCriteria: currentLine.impressionsCriteria || initialState.directSales.impressionsCriteria,
        networkCriteria: currentLine.networkCriteria || initialState.directSales.networkCriteria,
        mediaType: currentLine.mediaType || initialState.directSales.mediaType,
        pricingMode: currentLine.pricingMode || initialState.directSales.pricingMode,
        allocation: currentLine.allocation || initialState.directSales.allocation,
        objectives: currentLine.objectives || initialState.directSales.objectives,
        transientState: state.directSales.transientState || initialState.directSales.transientState,
        transientLineStates: state.directSales.transientLineStates || initialState.directSales.transientLineStates,
        draftFailuresToasts: state.directSales.draftFailuresToasts || initialState.directSales.draftFailuresToasts,
        productTemplate: state.directSales.productTemplate || initialState.directSales.productTemplate,
        floatingRange: currentLine.floatingRange || null,
        sotObjectiveId: currentLine.sotObjectiveId || initialState.directSales.sotObjectiveId,
        enabledForSharing: state.directSales.enabledForSharing || initialState.directSales.enabledForSharing,
      };

      state.backupFormData = {
        commonDeal: { ...initialState.backupFormData.commonDeal, ...initialState.commonDeal, ...commonDeal },
        lines: lines.map((line) => ({
          ...initialState.commonDeal.currentLine,
          ...line,
          frontEndType: FrontEndType.PLANNER,
          availability: { ...initialState.commonDeal.currentLine.availability, ...line.availability },
        })),
        programmatic: initialState.backupFormData.programmatic,
      };

      state.directSales = directSales;
      state.commonDeal = {
        ...initialState.commonDeal,
        ...commonDeal,
        currentLine: {
          ...initialState.commonDeal.currentLine,
          ...currentLine,
          frontEndType: FrontEndType.PLANNER,
          availability: {
            ...initialState.commonDeal.currentLine.availability,
            ...state.commonDeal.currentLine.availability,
          },
        },
      };
      state.isNewDeal = false;
    },
    updateDirectSalesSessionLock: (state, action: PayloadAction<DirectSalesLockState>) => {
      state.directSalesSessionLock = action.payload || initialState.directSalesSessionLock;
    },
    updateBackupFormDataLine: (
      state,
      action: PayloadAction<{
        lineId: string;
        newBackupLineData: Partial<DirectSalesLine>;
      }>,
    ) => {
      const idx = state.backupFormData.lines.findIndex((line) => line.lineId === action.payload.lineId);

      if (idx > -1) {
        state.backupFormData.lines[idx] = { ...state.backupFormData.lines[idx], ...action.payload.newBackupLineData };
      }
    },
    changeLineIsFetchingAvailability: (
      state,
      action: PayloadAction<{ lineId: string; isFetchingAvailability: boolean }>,
    ) => {
      updateCurrentLineAndBackupLineProperty(
        state,
        action.payload.lineId,
        'isFetchingAvailability',
        action.payload.isFetchingAvailability,
      );
    },
    changeLineIsSaving: (state, action: PayloadAction<{ lineId: string; isSaving: boolean }>) => {
      updateCurrentLineAndBackupLineProperty(state, action.payload.lineId, 'isSaving', action.payload.isSaving);
    },
    setDirectSalesStates: (
      state,
      action: PayloadAction<Record<string, { state: DirectSalesState; allocation?: DirectSalesLineAllocation }>>,
    ) => {
      state.backupFormData.lines.forEach((line: DirectSalesLine) => {
        const newLineData = action.payload[line.lineId];

        if (newLineData) {
          line.state = newLineData.state;

          if (newLineData.allocation) line.allocation = newLineData.allocation;

          if (line.lineId === state.commonDeal.currentLine.lineId) {
            state.directSales.state = newLineData.state;

            if (newLineData.allocation) state.directSales.allocation = newLineData.allocation;
          }
        }
      });
    },
    setDirectSalesGrid: (state, action: PayloadAction<DirectSalesGrid>) => {
      state.commonDeal.pricingSolution.grid = action.payload;
      state.backupFormData.commonDeal.pricingSolution.grid = action.payload;
    },
    updateDirectSalesBackupFormDataLine: (state) => {
      const idx = state.backupFormData.lines.findIndex((line) => line.lineId === state.commonDeal.currentLine.lineId);

      if (idx > -1) {
        state.backupFormData.lines[idx] = {
          ...state.commonDeal.currentLine,
          ...state.directSales,
          availability: {
            ...state.commonDeal.currentLine.availability,
            assets: [],
          },
          frontEndType: FrontEndType.PLANNER,
        };
      }
    },
    updateDirectSalesStatus: (state, action: PayloadAction<DirectSalesState>) => {
      const idx = state.backupFormData.lines.findIndex((line) => line.lineId === state.commonDeal.currentLine.lineId);

      if (idx > -1 && !isDirectSalesCampaignCloned(state.commonDeal.dealId)) {
        state.backupFormData.lines[idx] = {
          ...state.backupFormData.lines[idx],
          state: action.payload,
        };
      }

      state.directSales = { ...state.directSales, state: action.payload };
    },
    updateLineImpressionMetrics: (state, action: PayloadAction<ImpressionMetrics>) => {
      state.commonDeal.currentLine.impressionMetrics = action.payload;
      state.backupFormData.commonDeal.currentLine.impressionMetrics = action.payload;
    },
    changeTransientState: (
      state,
      action: PayloadAction<CampaignVersioningEditingStatus | CampaignVersioningErrors | undefined>,
    ) => {
      state.directSales.transientState = action.payload;
    },
    updateAllowedStateTransitionsMetadata: (state, action: PayloadAction<AllowedStateTransitionsMetadata>) => {
      state.meta.allowedStateTransitions = { ...state.meta.allowedStateTransitions, ...action.payload };
    },
    updateTransientLineStates: (state, action: PayloadAction<DirectSalesTransientDealLineState>) => {
      state.directSales.transientLineStates = action.payload;
    },
    clearTransientLineStates: (state) => {
      state.directSales.transientState = undefined;
      state.directSales.transientLineStates = {};
    },
  },
});

export const {
  addDealLineVenueTaxonomy,
  addIndexOptimisation,
  addIndexOptimisationDisplayName,
  addOpenStreetMapPoi,
  addPlannerPoi,
  changeCurrentLine,
  changeCurrentLineToLastLine,
  changeDealCurrentLineData,
  changeDealCurrentLineAvailabilityAssetsSot,
  changeDealData,
  changeDealDetails,
  changeCurrentDealLineAvailability,
  changeBackupDealLineAvailabilityWithoutAssets,
  changeDealLineAllocatedImpressions,
  changeDealLineProximityPoi,
  changeDealLineProximityPoints,
  changeDealLineProximityPostcode,
  changeDealLineVenueTaxonomy,
  changeIncludeExcludePlannerPoi,
  changeOpenStreetMapPoiRadius,
  changePlannerPoiRadius,
  changeOnlyProgrammaticDealData,
  changeSegment,
  changeCampaignType,
  changeTransientState,
  changeSegmentFrameAverageByHour,
  clearSegmentFrameAverageByHour,
  changeDisclaimer,
  changeFormParams,
  changeFrontEndType,
  changeIsCpmCampaignLevel,
  changeCurrentLineAvailability,
  changeIsNewDeal,
  changeIsNewLineInEndedDeal,
  changeMeta,
  updatePlannerSettings,
  updatePlannerPoi,
  updatePlannerFrameIdIndexes,
  resetPlannerFrameIdIndexes,
  resetSelectedIndexRange,
  clearAllocationInputs,
  clearDealLineVenueTaxonomies,
  clearSegment,
  clearDisclaimer,
  clearForm,
  createNewLine,
  createNewLineWithUniqueName,
  createNewProgrammaticLine,
  duplicateDeal,
  duplicateLine,
  appendSegment,
  hideDisclaimer,
  removeDealLineVenueTaxonomy,
  removeIndexOptimisation,
  removePlannerPoi,
  resetIndexOptimisation,
  resetCurrentLineProperty,
  resetDealLineAvailability,
  resetForm,
  resetOpenStreetMapPoi,
  resetPlannerPoi,
  resetProperty,
  resetState,
  setSelectedEnvironmentChannel,
  showDisclaimer,
  startLoading,
  stopLoading,
  toggleCampaignDrawerOpen,
  changeCampaignPlannerMode,
  updateIndexOptimisation,
  deleteSegment,
  updateRouteFrameCodes,
  updateIndexOptimisationDataProvider,
  changePlannerSecondaryPanelType,
  hidePlannerSecondaryPanel,
  removeTemporaryDealId,
  addAllDirectSalesLocations,
  addDirectSalesLocations,
  addDirectSalesLineId,
  addDirectSalesObjective,
  deleteDirectSalesObjective,
  changeActiveDirectSalesLine,
  setDirectSalesUniverseAvailability,
  setDirectSalesAssetList,
  clearDirectSalesAssetList,
  setDirectSalesPrices,
  clearDirectSalesPrices,
  clearAssets,
  changeDirectSalesData,
  changeDirectSalesLineName,
  clearDirectSalesAllocation,
  clearAllDirectSalesDealLineFormats,
  clearAllDirectSalesLocations,
  createNewDirectSalesLine,
  duplicateDirectSalesLine,
  removeDirectSalesLine,
  removeDirectSalesLocation,
  removeDirectSalesLocations,
  setLoadedDirectSalesData,
  updateBackupFormDataLine,
  changeLineIsFetchingAvailability,
  changeLineIsSaving,
  setDirectSalesStates,
  setDirectSalesGrid,
  updateDirectSalesBackupFormDataLine,
  updateDirectSalesStatus,
  updateLineImpressionMetrics,
  updateDirectSalesSessionLock,
  updateAllowedStateTransitionsMetadata,
  updateTransientLineStates,
  clearTransientLineStates,
  updateDirectSalesLineLockState,
  addDraftFailureToast,
  removeDraftFailureToast,
} = dealManagementSlice.actions;

export { DealManagement, DealManagementMeta, BackupFormData };

export default dealManagementSlice.reducer;

/* eslint-enable sonarjs/cognitive-complexity */
