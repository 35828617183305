import {
  DIRECT_SALES_FAILED_STATUSES,
  DirectSalesFailedStatus,
  DirectSalesState,
  DirectSalesStatusOption,
} from 'components/common/types/DirectSalesCampaign.types';

type LineWithState = {
  state: DirectSalesState;
};

export const isDirectSalesFailedStatus = (status: DirectSalesStatusOption): status is DirectSalesFailedStatus => {
  return DIRECT_SALES_FAILED_STATUSES.includes(status);
};

export const isSomeDirectSalesStatusFailed = (dealLines: LineWithState[]): boolean => {
  return dealLines.some((dealLine) => isDirectSalesFailedStatus(dealLine.state.status));
};

export const getDirectSalesFailedLines = <T extends LineWithState>(dealLines: T[]): T[] => {
  return dealLines.reduce<T[]>((result, dealLine) => {
    if (isDirectSalesFailedStatus(dealLine.state.status)) result.push(dealLine);

    return result;
  }, []);
};
